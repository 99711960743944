/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"; //, useRef
import { useParams, useNavigate } from 'react-router-dom';
import Spreadsheet from "react-spreadsheet"; // , { createEmptyMatrix }
// import * as ReactDoM from "react-dom";
import { APIEndpoint } from "../../../utils/axios";
import { AlertModal } from "../../../common";
import { DEFAULT_SHEET_ROWS, UPLOADED_LOI_ATTACHMENT_KEY } from "../../../utils/configs";
import { validateCellAndGetData } from "../../../utils/commonFunctions";
import closeIcon from "../../../images/icons/Icon2.png"
import * as ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";
import { FaRegCheckSquare, FaSave } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiSolidSelectMultiple } from "react-icons/bi";

let spreadSheetErrTimout;


const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    ITEM_QTY: 2,
    ITEM_UNIT: 3,
    ITEM_REMARKS: 4
};
const sheetLabels = ['Item Code', 'Item Name', 'Quantity', 'Unit', 'Remarks'];
const createEmptySheetRows = (noOfRows = DEFAULT_SHEET_ROWS,sheetData) => {
    let len = sheetData ? +sheetData.length:0
    const rows = [];
    for( var i=0; i < noOfRows; i++) {
        rows.push([
            {
                id: 'itemCode',
                value: '',
                className: 'cellClass cellClass_' + len + '_' + iIndexes.ITEM_CODE,
                width: '200px',
                showPopup: true
            },
            {
                id: 'itemName',
                value: '',
                className: 'cellClass cellClass_' + len + '_' + iIndexes.ITEM_NAME,
                width: '200px',
                showPopup: true
            },
            {
                id: 'quantity',
                value: '',
                className: 'cellClass1',
                width: '200px',
                type:'number'
            },
            {
                id: 'unit',
                value: '',
                className: 'cellClass1',
                width: '400px',
                readOnly: true
            },
            {
                id: 'remarks',
                value: '',
                className: 'cellClass1',
                width: '200px'
            }
        ]);
        len++
    }
    return rows;
}

/**
 * Automatically creating rows
 */
// const initialData = createEmptyMatrix(10, sheetLabels.length);
/**
 * Manually creating rows
 */

export const LetterOfIntentsCreate = (props) => {
    const urlParams = useParams();
    const navigate = useNavigate();
    const { t,ready } = useTranslation();
    const [sheetData, setSheetData] = useState(createEmptySheetRows(15));
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const [state, setState] = useState({ isInvalidAccess: false, message: '' });
    const modelStyle = { maxWidth: '50%', margin: 'auto 10% 100% 10%' };
    const [LOIDetails, setLOIDetails] = useState(null);
    const [isEditForm, setIsEditForm] = useState(false);
    const [formData, setCreateFormData] = useState(null);
    const [LOIAttachment, setLOIAttachment] = useState(null);
    const [activeCell, setActiveCell] = useState([])
    const [itemData, setItemData] = useState([])
    const [count, setCount] = useState(1);
    const [isLoading, setIsLoading] = useState(false)

    const targetEltCodes = document.getElementsByClassName("cellSelectionBlockItemCodes")[0];
    const targetEltNames = document.getElementsByClassName("cellSelectionBlockItemNames")[0];

    // const sheetRef = useRef(null);
    // const LOICELLComponent = (props) =>  {
    //     console.log('LOICELLComponent:', props);
    //     return <>11</>;
    // }
    // const LOIViewComponent = (props) =>  {
    //     console.log('LOIViewComponent:', props);
    //     return <>222</>
    // }

    useEffect(() => {
        document.querySelector(".sheet-container").addEventListener("scroll",()=>{
          hideItemListPopup()
        })
      }, [targetEltCodes,targetEltNames])


    async function getItemData(distData) {
        let distId=""
        if(!isEditForm && !urlParams.hasOwnProperty('LOI_ID')){
            const {distributorName}=JSON.parse(distData);
            distId=distributorName;
        }else{
            distId= distData.distributorId
        }
      const data=  {
            "distributorId": distId
        }
        try {

             const [res,resDist] = await Promise.all([
                                APIEndpoint.post('distributors/items/enumerate',data),
                                APIEndpoint.get(`warehouses/distributors/enumerate`),
                            ])

            if(res && resDist){
            setItemData(res.items);
            console.log(distId)
            setState((_)=>({..._,distName:resDist.items.find((rDt,index)=>rDt.id===distId)?.name}))

            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect( () => {
        if ( urlParams.hasOwnProperty('formInputs') ) {
            console.log( 'Is create form, formInputs are ', urlParams.formInputs );
            getItemData(urlParams.formInputs)
            const attachment = localStorage.getItem( UPLOADED_LOI_ATTACHMENT_KEY );
            setLOIAttachment( JSON.parse(attachment) );
            setCreateFormData( JSON.parse(urlParams.formInputs) );
            if (sheetData.length === 0) {
                setSheetData( createEmptySheetRows( 15 ) );
            }
        } else if ( urlParams.hasOwnProperty('LOI_ID') ) {

            console.log( 'Is edit form, LOI_ID is ', urlParams);
            setIsEditForm(true);
            getLOIDetailsById(urlParams.LOI_ID);
        }
    }, [ urlParams ]);

    useEffect( () => {
        // const updatedSpreadsheetData = sheetData.map( (row) => {
        //     const nextRow = [ ...row ];
        //     nextRow.length = 10;
        //     return nextRow;
        // } );
        // setSheetData(updatedSpreadsheetData);
    }, [sheetData]);

    useEffect( () => {
        if (LOIDetails) {
            setLOIItemsToSheet(LOIDetails);
        }
    }, [LOIDetails]);

    const getLOIDetailsById = (LOI_ID) => {
        APIEndpoint.get('letterOfIntent/findById/' + LOI_ID)
        .then( resp => {
            if (resp) {
                const lOIDetails = resp.data;
                getItemData( resp.data)
                setLOIDetails(lOIDetails);
            }
        })
        .catch( error => {
            console.log('Invalid page access!, Letter of Intent ID not found.', error.message);

            setState({ ...state, isInvalidAccess: true,
                message: "Invalid page access!, Letter of Intent ID not found, On click OK, you will be redirected to Letter of Intents page." });
        });
    };

    const setLOIItemsToSheet = (LOIDetails) => {

        let updatedSpreadsheetData = Object.assign([], sheetData);
        const items = LOIDetails.items;
        const itmLngth = items.length;
        /**
         * Validate whether required items already plotted
         */
        let newRows = [];
        if ( items && itmLngth > 0 && updatedSpreadsheetData.length < itmLngth ) {

            let newlyRequiredRows = itmLngth - updatedSpreadsheetData.length;
            if (newlyRequiredRows < DEFAULT_SHEET_ROWS) {
                newlyRequiredRows = DEFAULT_SHEET_ROWS;
            }
            newRows = createEmptySheetRows(newlyRequiredRows);

        } else {
            newRows = createEmptySheetRows(15);
        }

        updatedSpreadsheetData = [ ...newRows ];

        updatedSpreadsheetData.forEach( (sheetRow, i) => {
            if ( items[i] ) {
                updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = items[i].itemCode;
                updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = items[i].itemName;
                updatedSpreadsheetData[i][iIndexes.ITEM_QTY].value = items[i].quantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_UNIT].value = items[i].unit;
                updatedSpreadsheetData[i][iIndexes.ITEM_REMARKS].value = items[i].remarks;
                // sheetRef.setCellData(updatedSpreadsheetData[i]);
            }

        });

        // const updatedSpreadsheetData = sheetData.map( (row) => {
        //     const nextRow = [ ...row ];
        //     // nextRow.length = 10;
        //     nextRow.value = ;
        //     return nextRow;
        // } );

        setSheetData( [ ...updatedSpreadsheetData ]);

    };

    const addRowsToSheet = (rowsToAdd = 15) => {
        const newRows = createEmptySheetRows(rowsToAdd,sheetData);
        setSheetData( [...sheetData, ...newRows ]);
    };

    const removeRow = () => {
            if(count%2 === 0){
                const newRows = createEmptySheetRows(15,[]);
                setSheetData([...newRows])
            }else{
                let row = activeCell.row;
                let deletedData=sheetData.filter((data,index)=>{
                    if(index!==row){
                        return data
                    }
                })
                setSheetData(deletedData)
            }
            hideItemListPopup();
        };

    const saveSheetData = (e) => {
        let error = false;
        clearTimeout(spreadSheetErrTimout)
        // updateButtonTxtStatus(true, e, 'Saving...', true);
        const postItems = [];

        let inSheetData = [ ...sheetData ];
        inSheetData.map( (cellRow, idx) => {
            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE]);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME]);
            const quantity = validateCellAndGetData(cellRow[iIndexes.ITEM_QTY]);
            const unit = validateCellAndGetData(cellRow[iIndexes.ITEM_UNIT]);
            const remarks = validateCellAndGetData(cellRow[iIndexes.ITEM_REMARKS]);


            if (itemCode && itemName && quantity && unit) {
                postItems.push({
                    index: idx,
                    itemCode,
                    itemName,
                    quantity: parseInt(quantity),
                    unit,
                    remarks
                });
            }
            else if (itemCode || itemName) {
                error = true
                /**
                 * SET Error status
                 */
                // cellRow[iIndexes.ITEM_UNIT].className += ' errorCellData';
                // cellRow[iIndexes.ITEM_REQ_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_RECI_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_REMARKS].className= 'errorCellData';

                // updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
                setSpreadsheetErrors([ { message: 'Please fill all required item details.' } ]);
            }
            return cellRow;
        });

        // sheetData.map( (row) => {
        //     const nextRow = [ ...row ];
        //     nextRow.length = 10;
        //     return nextRow;
        // } );

        setSheetData(inSheetData);

        if(error){
            // updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            return;
        }

        if (!spreadsheetErrors.length && postItems.length === 0) {
            // show global alert message
            // updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'We did not find anything to save!' } ]);
            return;
        }


        if ( !postItems.length ) {
            // updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            return false;
        }
        let data = {}, apiURL = '';
        if (isEditForm) {
            data = {
                distributorId: LOIDetails.distributorId,
                loiId: urlParams.LOI_ID,
                items: postItems
            };
            apiURL = 'letterOfIndents/create';
        }else {
            data = {
                distributorId: formData.distributorName,
                agreementNumber: formData.agreementNumber,
                expiryDate: formData.expiryDate,
                remarks: formData.remarks,
                attachment: LOIAttachment || null,
                items: postItems
            }
            apiURL = 'letterOfIndents/create';
        }
        setIsLoading(true)

        APIEndpoint.post(apiURL, data).then( apiResp => {
            /**
             * Update success response
             */
            console.log(apiResp)
            setIsLoading(false)
            // updateButtonTxtStatus(true, e, 'Redirecting...', true, { defaultButtonText: 'Save'} );
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: `Successfully ${urlParams.LOI_ID?'updated':"created"} Letter of Intent.` } ]);
            setTimeout( () => {
                goToLOIHome();
            }, 2000);
        })
        .catch( (error) => {
            setIsLoading(false)
            const apiData = JSON.parse(error.message);
            // updateButtonTxtStatus(false, e, 'Save failed', false, { defaultButtonText: 'Save' });
            if ( apiData?.data.errors ) {
                const errorMsg = apiData.data.errors.message;
                const errorArr = apiData.data.errors.errors;
                setSpreadsheetErrors([ { message: errorMsg, error: 1 },...errorArr ]);
                spreadSheetErrTimout=setTimeout(() => {
                    setSpreadsheetErrors([])
                }, 4000);
            }
            else if (apiData.msg) {
                setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiData.msg, error: 1 } ]);
            }
        });

    };

    const showItemListPopup = (eltPosition, activeCellIn) => {
        if (activeCellIn.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'block';
                targetEltCodes.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltCodes.style.left = eltPosition.left.toFixed() + 'px';
            }
        } else if (activeCellIn.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'block';
                targetEltNames.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltNames.style.left = eltPosition.left.toFixed() + 'px';
            }
        }
    }

    const handleActiveCellChange = (activeCell) => {
        setActiveCell(activeCell)
        console.log('ActiveCell:', activeCell);

        //lsjhjhdsjfh
        if ( sheetData[activeCell.row][activeCell.column] ) {
            const cellDetailsObj = sheetData[activeCell.row][activeCell.column];
            if ( cellDetailsObj.hasOwnProperty('showPopup') && cellDetailsObj.showPopup === true ) {

                const sourceElt = document.getElementsByClassName('cellClass_' + activeCell.row + '_' + activeCell.column)[0];
                if ( sourceElt ) {
                    const eltPosition = sourceElt.getBoundingClientRect();

                    showItemListPopup(eltPosition, activeCell);
                }

            }

        }
    }

    const onClickPopupListItem = (e, row) => {
        setItemToSheet(row);
    }

    const setItemToSheet = (item) => {
        if (Object.keys(item).length === 0) {
            return 0;
        }

        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                sheetRow[iIndexes.ITEM_CODE].value = item.itemCode;
                sheetRow[iIndexes.ITEM_NAME].value = item.itemName;
                sheetRow[iIndexes.ITEM_UNIT].value = item.unit;
            }
            return sheetRow;
        });

        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);
        });

        setActiveCell({});
        hideItemListPopup();
    }

    const hideItemListPopup = () =>{
        // if (activeCell.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'none';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'none';
            }
        // }
    }


    const handleCellBlur = () => {

    }
    const handleCellKeyDown = (keyboardEvent) => {


    }
    const handleModeChange = (mode) => {

    };
    const handleCellSelect = (selectedCell) => {

    };

    const goToLOIHome = () => {
        navigate('/procurement/letter-of-intents');
    };
    const handleOnContinue = async () => {
        goToLOIHome();
    }

    const onChangeLOIdata=(data)=>{
        let jsonSpreadsheetData = JSON.stringify( data );
        let SpreadsheetData = JSON.parse(jsonSpreadsheetData);

        const updatedData = SpreadsheetData.map(row =>
            row.map(cell => {
              if (cell.id==='quantity' && isNaN(cell.value)) {
                return { ...cell, value: "" };
              }
              return cell;
            })
          );
          setSheetData(updatedData);

    }

    const selectAllCells = (e) => {
        setCount((prev)=>prev+1)
        let updatedSheetData=[]
        if(count%2!==0){
            updatedSheetData = sheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `selectcell-class cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
        }else{
            updatedSheetData = sheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
        }

        setSheetData(updatedSheetData);

    };

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                <div className="sheet-container sm-scrollbar">
                    <div className="header  mb-2 flexRow justifyContentSpaceBetween">
                        <div className="leftBlock alignItemsCenter">
                            <button onClick={ (e) => saveSheetData(e) }
                                className="add-item-button margRight10">
                                    <FaSave style={{ marginRight: "5px",marginBottom:"3px" }} />
                                    {isLoading ? "Saving...":"Save"}</button>
                                <button onClick={selectAllCells} className="add-item-button-all margRight10">{count%2===0?<BiSolidSelectMultiple  style={{marginRight:'5px'}} />:<FaRegCheckSquare  style={{marginRight:'5px'}} />}Select all</button>
                            <button onClick={ (e) => removeRow(e) }
                                className="remove-button">
                                    <RiDeleteBin6Line style={{ marginRight: "5px" ,marginBottom:"3px" }} />
                                    Delete Row</button>
                        </div>
                        {spreadsheetErrors.length >= 1? <div className={`padding4 margTop9-5 alert alert-${spreadsheetErrors[0].message.includes('Successfully') ? 'success' : 'danger'}`}>
                        {spreadsheetErrors.length >= 1 ? spreadsheetErrors[0]?.message : null}
                        </div>:null}
                        <button onClick={goToLOIHome}>
                            <img src={closeIcon} alt=""/>
                        </button>
                    </div>
                <div className="se-titlebar margBottom10">
            {state && state.distName && !urlParams.LOI_ID ?`Letter of Indent - ${state.distName??"----"}`:`Letter of Indent - ${urlParams?.LOI_CODE??"----"}`}
                   </div>
                   <div className="sheet-container sm-scrollbar letterOfIntetntCreate">
                    <Spreadsheet
                        // ref={sheetRef}
                        data={sheetData}
                        columnLabels={sheetLabels}
                        darkMode={true}

                        // getBindingsForCell={[]}
                        onChange={onChangeLOIdata}
                        onActivate={handleActiveCellChange}
                        onBlur={handleCellBlur}
                        onKeyDown={handleCellKeyDown}
                        onModeChange={handleModeChange}
                        onSelect={handleCellSelect}

                        // Cell={LOICELLComponent}
                        // DataViewer={LOIViewComponent}

                    /> </div>


                    <div className="margTop8 flexRow justifyContentFlexStart alignItemsCenter">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button" title={ t('spreadsheet.addmoreRows') }>{ t('buttons.addRows') }</button>
                    </div>
                    <div className = "cellSelectionBlockItemCodes sm-scrollbar">
                       <ul>
                        {
                            itemData.map((d,i)=>{
                                return <li key={i} onClick={(e) => onClickPopupListItem(e, d) }>{d.itemCode}</li>
                            })
                        }
                       </ul>
                    </div>
                    <div className = "cellSelectionBlockItemNames sm-scrollbar">
                       <ul>
                        {
                            itemData.map((d,i)=>{
                                return <li key={i} onClick={(e) => onClickPopupListItem(e, d) }>{d.itemName}</li>
                            })
                        }
                       </ul>
                    </div>
                </div>


                {
                    Boolean(state.isInvalidAccess) && (
                    <AlertModal
                        title={'Alert: Invalid Access'}
                        customModalStyle={ modelStyle }
                        description={ state.message }
                        onContinue={handleOnContinue}
                        onClose={handleOnContinue}
                    />
                    )
                }

{
                  !ready &&<div className='overlay_hide sub'></div>
                }

            </div>
        </>
    );
}
