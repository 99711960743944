/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { fetchCashDeposit, DenominationColumns, CashDepositSectorCard, getPendingSectors, getSalesInfo, SectorSelect } from "./depositSectorCard";
import { DataGrid,Loading, Modal, EditIconButton } from "../../../common";
import { APIEndpoint } from "../../../utils/axios";
import Currency from '../../../assets/json/currency.json'
import moment from 'moment';

const EditDenominationColumns = [
  { label: 'Currency Code', type: 'select', labelProp: 'code', attribute: 'currencyCode', required: true },
  { label: 'Currency Name', type: 'select', labelProp: 'currency', attribute: 'currencyName', required: true },
  { label: 'Denomination', type: 'number', attribute: 'denomination', required: true },
  { label: 'Value', type: 'number', attribute: 'value', required: true },
  { label: 'Net Amount', type: 'number', attribute: 'netAmount', readOnly: true, required: true }
]

export const CashDepositEditModal = (props) => {
  const { detail } = props;
  const navigate = useNavigate();
  const [deposit, setDeposit] = useState(null);
    const [refreshDepositDetails,setRefreshDepositDetails]=useState(false);

  const [state, setState] = useState({ showAdd: '' });
  const { t } = useTranslation();

  useEffect(() => {
    _fetchCashDeposit();
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [refreshDepositDetails]);

  const _fetchCashDeposit = async () => {
    let deposit = await fetchCashDeposit(detail);
    setDeposit(deposit);
  }
  const handleRemoveSector = (i) => {
    let sectorDeposits = [...deposit.sectorDeposits];
    sectorDeposits.splice(i, 1);
    setDeposit({ ...deposit, sectorDeposits: [...sectorDeposits] });
  }
  const handleAddSector = async () => {
    setState((_) => ({ ..._, showAdd: 'add', pendingSectors: 'wait', message: null }))
    const res = await getPendingSectors(deposit.crew.employeeId, deposit.createdAt)
    setState((_) => ({ ..._, pendingSectors: res.sectors, selectedSectors: {} }))
  }
  const handleAddSectortoDeposit = async () => {
    const sectors = [], _deposit = { ...deposit }
    state.pendingSectors.forEach(({ aircraftNumber, key, storeId, flightDate }) => {
      if (state.selectedSectors[key]) {
        sectors.push({ aircraftNumber, storeId, flightDate })
      }
    });
    let message = { success: false, msg: '' }
    try {
      const body = { sectors: sectors };
      const res = await APIEndpoint.post('cashRegister/deposits/addSector/' + deposit.id, body);
      message.success = true;
      message.msg = res.message
      for (let i = 0; i < res.sectors.length; i++) {
        const sector = res.sectors[i];
        const salesInfo = await getSalesInfo({
          cashDrawerId: deposit.cashDrawer.id,
          flightDate: moment(deposit.createdAt).startOf("day").format(),
          storeId: sector.storeId
        });
        _deposit.sectorDeposits.push(sector);
        _deposit.sectorsInfo.push(salesInfo);
      }
      setDeposit({ ..._deposit });
    } catch (er) {
      message.success = false;
      message.msg = er.data.message
    }
    setState((_) => ({ ..._, message: message }))
    let timerId = setTimeout(() => {
      clearTimeout(timerId)
      setState((_) => ({ ..._, showAdd: !message.success, }))
    }, 1000)
  }
  const handleEdit = () => {
    navigate("/cashRegister/cashDeposits/create",{state:{deposit:deposit,details:detail}})
  }
  // const getDominationColumns = () => {
  //   return EditDenominationColumns.map((_) => {
  //     return { ..._, options: Currency }
  //   })
  // }
  // const getDenominationsRowData = (rowData) => {
  //   let row = []
  //   EditDenominationColumns.forEach(({ attribute, readOnly }, i) => {
  //     let cellVal = { readOnly: readOnly || false, value: '' }
  //     if (Array.isArray(rowData)) {
  //       cellVal.value = rowData[i].value;
  //       cellVal.error = rowData[i].error;
  //       if (!rowData[i].updated) {
  //         if (attribute === 'netAmount' && rowData[2].value !== '' && rowData[3].value !== '') {
  //           cellVal.value = Number(rowData[2].value) * Number(rowData[3].value)
  //           cellVal.error = false
  //         } else if (attribute === 'currencyName' && rowData[0].value !== '') {
  //           cellVal.value = Currency.find((_) => _.code === rowData[0].value)?.currency || "";
  //           cellVal.error = false
  //         } else if (attribute === 'currencyCode' && rowData[1].value !== '') {
  //           cellVal.value = Currency.find((_) => _.currency === rowData[1].value)?.code || "";
  //           cellVal.error = false
  //         }
  //       }
  //     } else {
  //       cellVal.value = rowData[attribute] || ''
  //     }
  //     row.push(cellVal)
  //   });
  //   return row
  // }
  // const saveDenominations = (rows) => {
  //   const body = { depositId: deposit.id, items: rows }
  //   return APIEndpoint.post("cashRegister/deposits/denominations", body)
  // }
  // const handleDenominationSave = (res) => {
  //   setDeposit((_) => ({ ..._, denominations: res.items }))
  //   setState((_) => ({ ..._, showAdd: '' }))
  // }
  const enableAdd = Object.values(state.selectedSectors || {}).filter((_) => _).length === 0;
  return (
    <Modal
      showDone={false}
      className='cash-reg-edit'
      onClose={props.onClose}
      title={`${t("pages.cashRegisterContent.cashDeposits.editModalTitle")}${detail?.code}`}>
      {
        Boolean(deposit) ?
          <div className='flexCol full-flex'>
            {
              Array.isArray(deposit.sectorDeposits) &&
              deposit.sectorDeposits.map((sector, index) => {
                return (
                  <CashDepositSectorCard
                    isEdit
                    key={index}
                    sector={sector}
                    depositId={deposit.id}
                    cashDrawerId={deposit.cashDrawer.id}
                    flightDate={moment(deposit.createdAt).startOf("day").format()}
                    sectorsInfo={deposit.sectorsInfo[index]}
                    setRefreshDepositDetails={setRefreshDepositDetails}
                    onRemoveSector={handleRemoveSector}
                    setDeposit={setDeposit}
                    />
                )
              })
            }
            <div className='flexRow edit-title-row margBottom10'>
              <div className='large-button-text-medium'>{t("pages.cashRegisterContent.cashDeposits.denominationsTitle")}</div>&nbsp;
              <EditIconButton onClick={handleEdit} />
            </div>
            <DataGrid
              columnResize
              showDefaultFilters={false}
              enableGridSearch={false}
              className='full-flex'
              rowModelType='clientSide'
              noResultsMessage={'No Items Found'}
              columns={DenominationColumns}
              rowData={deposit.denominations}
            />
            <div className='flexRow alignItemsCenter justifyContentSpaceBetween add-sc'>
              <button className='btn btn-primary' onClick={handleAddSector}>
                <span className='small-button-text-regular'>{t("pages.cashRegisterContent.cashDeposits.sectorAddBtn")}</span>
              </button>
              <button className='btn btn-danger' onClick={props.onClose}>
                <span className='small-button-text-regular'>{t("buttons.done")}</span>
              </button>
            </div>
          </div>
          :
          <Loading />
      }
      {
        state.showAdd === 'add' &&
        <Modal
          isSubModal
          showDone={false}
          className='cash-sector-add'
          onClose={() => setState((_) => ({ ..._, showAdd: '' }))}
          title={`${t("pages.cashRegisterContent.cashDeposits.sectorAddModalTitle")}${detail.code}`}>
          <SectorSelect
            label='Scheduled Sectors'
            sectors={state.pendingSectors}
            selected={state.selectedSectors}
            onSelect={(key) => {
              setState((_) => ({ ..._, selectedSectors: { ...state.selectedSectors, [key]: !Boolean(state.selectedSectors[key]) } }))
            }}
          />
          {
            Boolean(state.message) &&
            <div className={`alert alert-${state.message.success ? 'success' : 'danger'}`}>
              {state.message.msg}
            </div>
          }
          {
            Array.isArray(state.pendingSectors) && state.pendingSectors.length > 0 &&
            <div className='flexRow alignItemsCenter justifyContentCenter' style={{ marginTop: "16px" }}>
              <button disabled={enableAdd} onClick={handleAddSectortoDeposit} className='btn btn-primary'><span>Add</span></button>
            </div>
          }
        </Modal>
      }

    </Modal>
  )
}