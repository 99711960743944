/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Spreadsheet from "react-spreadsheet";
import { APIEndpoint } from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import { formatDateByTimezone ,validateCellAndGetData} from "../../../utils/commonFunctions";
import * as ReactDOM from 'react-dom';
import { FaRegCheckSquare ,FaSave} from "react-icons/fa";
import { BiSolidSelectMultiple } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import closeIcon from "../../../images/icons/Icon2.png"
import { Loading } from '../../../common';
import { DEFAULT_SHEET_ROWS_PLIFERAGE } from "../../../utils/configs";
import moment from 'moment'
import { RiDeleteBin6Line } from "react-icons/ri";



const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    AVAILABLE_STOCK: 2,
    ESTIMATED_QTY: 3,
    BOB: 4,
    CORPORATE: 5,
    WEB_MEAL: 6,
    TOTAL: 7,
    UNIT: 8,
    REMARKS: 9,
};
const upliftsSheetLabels = ['Item Code', 'Item Name', 'Available Stock', 'Estimated Quantity', 'BoB', 'Corporate', 'Web Meal','Total','Unit','Remarks'];


export const UpliftsItem = (props) => {
    const navigate = useNavigate();
    const urlParams = useParams();
  const { t } = useTranslation();
    const { id, warehouseid, sectorid, dateid } = urlParams;
    const [upliftSheetData, setUpliftSheetData] = useState([]);
    const [warehouseItemsFull, setWarehouseItemsFull] = useState([]);
    const [dateEdited,setDateEdited]=useState(null);
    const [warehouseItemsFiltered, setWarehouseItemsFiltered] = useState([]);
    const [count, setCount] = useState(1);
    const [selectedCellsArr, setSelectedCellsArr] = useState([]);
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const [activeCell, setActiveCell] = useState([]);
    const [sectorName, setSectorName] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect( () => {
        fetchItems()
        setSpreadsheetErrors([]);
    }, []);

    useEffect( () => {
        if(warehouseid){

            fetchSectors(warehouseid)
        }
    }, [warehouseid]);

    const fetchSectors = async (id) => {
        try {
          const resp =  await APIEndpoint.get('warehouses/sectors/'+id);
          if (resp.result.length > 0) {
            const sector = resp.result.find(data=>data.storeId == sectorid);
            const sectorName = sector.flightNumber+ " ("+ sector.originName+" - "+sector.destinationName+") ";
            setSectorName(sectorName)
          }
        } catch (error) {
           console.log(error)
        }

      };

    const targetEltCodes = document.getElementsByClassName("cellSelectionBlockItemCodes")[0];
    const targetEltNames = document.getElementsByClassName("cellSelectionBlockItemNames")[0];
    const targetEltReasonTypes = document.getElementsByClassName("cellSelectionBlockReasonTypes")[0];

const defaultCellFieldData = {
    id: 'id',
    value: '',
    defaultValue: '',
    className: 'cellClass',
    width: '200px',
    readOnly: false,
    type: 'string',
    required: true,
    showPopup: false
};
const checkSheetData=()=>{
    let data=false;
    upliftSheetData?.map(upl=>{
        upl?.forEach((val,ind)=>{
            if(val&&val?.value){
                data=true
            }
        })

    })
    return data
}
    const createEmptySheetRows = (addtionalRows=0,noOfRows = DEFAULT_SHEET_ROWS_PLIFERAGE) => {
        const rows = [];
        if(addtionalRows>0){
           let i=0;
            while(i<10) {
                rows.push([
                    {
                        ...defaultCellFieldData,
                        id: 'itemCode',
                        className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.ITEM_CODE,
                        showPopup: true
                    },
                    {
                        ...defaultCellFieldData,
                        id: 'itemName',
                        className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.ITEM_NAME,
                        showPopup: true
                    },
                    {
                        ...defaultCellFieldData,
                        id: 'availableStock',
                        className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.AVAILABLE_STOCK,
                        width: '400px',
                        type:'number',
                        required:true,
                        readOnly: true,
                    },
                    {
                        ...defaultCellFieldData,
                        id: 'estimatedQuantity',
                        type:'number',
                        className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.ESTIMATED_QTY,
                        width: '400px',
                        required:true,
                        readOnly: true,
                    },
                    {
                        ...defaultCellFieldData,
                        type:'number',
                        id: 'bob',
                        className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.BOB,
                        readOnly: false,
                        required: true
                    },
                    {
                        ...defaultCellFieldData,
                        type:'number',
                        id: 'corporate',
                        className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.CORPORATE,
                        readOnly: false,
                        required: true
                    },
                    {
                        ...defaultCellFieldData,
                        type:'number',
                        id: 'webMeal',
                        className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.WEB_MEAL,
                        readOnly: false,
                        required: true
                    },
                    {
                        ...defaultCellFieldData,
                        type:'number',
                        id: 'total',
                        className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.TOTAL,
                        readOnly: false,
                        required: true
                    },
                    {
                        ...defaultCellFieldData,
                        id: 'unit',
                        className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.UNIT,
                        readOnly: true,
                        required: true
                    },
                    {
                        ...defaultCellFieldData,
                        id: 'remarks',
                        required:false,
                        readOnly:false,
                        className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.REMARKS,
                    },

                ]);
                i++;
                addtionalRows++;
            }

        }else{
            for( var i=0; i < noOfRows; i++) {
                rows.push([
                    {
                        ...defaultCellFieldData,
                        id: 'itemCode',
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_CODE,
                        showPopup: true
                    },
                    {
                        ...defaultCellFieldData,
                        id: 'itemName',
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_NAME,
                        showPopup: true
                    },
                    {
                        ...defaultCellFieldData,
                        id: 'availableStock',
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.AVAILABLE_STOCK,
                        width: '400px',
                        type:'number',
                        required:true,
                        readOnly: true,
                    },
                    {
                        ...defaultCellFieldData,
                        id: 'estimatedQuantity',
                        type:'number',
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.ESTIMATED_QTY,
                        width: '400px',
                        required:true,
                        readOnly: true,
                    },
                    {
                        ...defaultCellFieldData,
                        type:'number',
                        id: 'bob',
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.BOB,
                        readOnly: false,
                        required: true
                    },
                    {
                        ...defaultCellFieldData,
                        type:'number',
                        id: 'corporate',
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.CORPORATE,
                        readOnly: false,
                        required: true
                    },
                    {
                        ...defaultCellFieldData,
                        type:'number',
                        id: 'webMeal',
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.WEB_MEAL,
                        readOnly: false,
                        required: true
                    },
                    {
                        ...defaultCellFieldData,
                        type:'number',
                        id: 'total',
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.TOTAL,
                        readOnly: true,
                        required: true
                    },
                    {
                        ...defaultCellFieldData,
                        id: 'unit',
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.UNIT,
                        readOnly: true,
                        required: true
                    },
                    {
                        ...defaultCellFieldData,
                        id: 'remarks',
                        required:false,
                        readOnly:false,
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.REMARKS,
                    },
                ]);
            }
        }

        return rows;
    }


    const addRowsToSheet = (rowsToAdd = 10) => {
        const newRows = createEmptySheetRows(upliftSheetData.length,10)
        if(newRows.length > 0 ){
            setUpliftSheetData(data=>[...data,...newRows])
        }
    };

    const removeRow = () => {

        if(count%2===0){
            let updatedSheetData = upliftSheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        value:'',
                        className: `cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
            setUpliftSheetData(updatedSheetData);

        }else{

        let row = activeCell.row
        let deletedSheetData = upliftSheetData.filter((data,index) => {
            if(index !== row){
                return data
            }
            return null
        })
        setUpliftSheetData(deletedSheetData)
    }
    };


    const findByIdData = async() => {
        const IdData = await APIEndpoint.get('uplifts/findById/'+id)
        setSectorName(`${IdData.result.store.flightNumber} (${IdData.result.store.originName}-${IdData.result.store.destinationName}) `)
        return IdData
    }

    const fetchItems = async () => {
        setLoading(true)
        let filter1,filter2;
if(urlParams["*"].includes('edituplift')){
     try {
        let IdData = await findByIdData();
        setDateEdited(IdData.result?formatDateByTimezone(IdData.result.targetDate):null);
        filter1 ={
            departureDate:formatDateByTimezone( IdData.result.targetDate),
            storeId: IdData.result.storeId,
            upliftId: id,
            warehouseId: IdData.result.warehouseId
        }
        filter2={departureDate:formatDateByTimezone( IdData.result.targetDate),
            operationDate: formatDateByTimezone( IdData.result.targetDate),
            storeId: IdData.result.storeId,
            warehouseId: IdData.result.warehouseId}

                const [respEnum,resPriceList,resPriceListEnum] = await Promise.all([
                    APIEndpoint.get('items/enumerate'),
                    APIEndpoint.post(`stores/pricelist/items`,filter1),
                    APIEndpoint.post(`stores/pricelist/items/enumerate`,filter2),
                ],

                );
                if(respEnum.success &&resPriceList.success && resPriceListEnum.success && IdData.success){
                    const pList= resPriceList.items.filter((item,index)=>respEnum.items.find((resI,index)=>resI.itemId===item.itemId)).filter((eIt,index)=>resPriceListEnum.items.find((rIp,index)=>rIp.itemId===eIt.itemId));
        if(pList && pList.length){
             setLoading(false)
            const options = [];
            pList.filter( (fieldValue, index) => {
                options.push([
                    {
                        id: 'itemCode',
                        value: fieldValue.itemCode,
                        className: 'cellClass1',
                        width: '300px',
                        showPopup:true,
                        readOnly:false,
                        required:true,
                        type:"string"
                    },
                    {
                        id: 'itemName',
                        value: fieldValue.itemName,
                        className: `cellClass cellClass_${index}_${iIndexes.ITEM_NAME}`,
                        width: '400px',
                        showPopup:true,
                        readOnly:false,
                        required:true,
                        type:"string"
                    },
                    {
                        id: 'availableStock',
                        value: fieldValue.availableStock,
                        className: `cellClass cellClass_${index}_${iIndexes.AVAILABLE_STOCK}`,
                        width: '100px',
                        readOnly: true
                    },
                    {
                        id: 'estimatedQuantity',
                        value: fieldValue.estimatedQuantity,
                        className: `cellClass cellClass_${index}_${iIndexes.ESTIMATED_QTY}`,
                        width: '100px',
                        readOnly: true,
                    },
                    {
                        id: 'bob',
                        value: fieldValue.bob,
                        className: `cellClass cellClass_${index}_${iIndexes.BOB}`,
                        width: '100px',
                        readOnly: false
                    },
                    {
                        id: 'corporate',
                        value: fieldValue.corporate,
                        className: `cellClass cellClass_${index}_${iIndexes.CORPORATE}`,
                        width: '100px',
                        readOnly: false
                    },
                    {
                        id: 'webMeal',
                        value: fieldValue.webMeal,
                        className: `cellClass cellClass_${index}_${iIndexes.WEB_MEAL}`,
                        width: '200px',
                        readOnly: false
                    },
                    {
                        id: 'total',
                        value: fieldValue.total,
                        className: `cellClass cellClass_${index}_${iIndexes.TOTAL}`,
                        width: '200px',
                        readOnly: true
                    },
                    {
                        id: 'unit',
                        value: fieldValue.unit,
                        className: `cellClass cellClass_${index}_${iIndexes.UNIT}`,
                        width: '200px',
                        readOnly:true,
                        required:true
                    },
                    {
                        id: 'remarks',
                        value: fieldValue.remarks,
                        className: `cellClass cellClass_${index}_${iIndexes.REMARKS}`,
                        width: '200px'
                    }                ]);
                return fieldValue;
            });
            setUpliftSheetData(options)
            if(options){
                const newRows = createEmptySheetRows(pList.length,10);
                setWarehouseItemsFiltered( [...pList, ...newRows ]);
                setWarehouseItemsFull(pList);
                setEditTransferItemsToSheet({items:[...pList]},options)
                }
}

                }
            }
            catch (error) {
                setLoading(true)
                console.log("Error occured while fetching list:");
            }
}else{
    try {
        setDateEdited(null);
        filter1 ={
            departureDate:formatDateByTimezone( dateid),
            storeId: Number(sectorid),
            warehouseId: Number(warehouseid)
        }
        filter2={departureDate:formatDateByTimezone( dateid),
            operationDate: formatDateByTimezone( dateid),
            storeId: Number(sectorid),
            warehouseId: Number(warehouseid)}

                const [respEnum,resPriceList,resPriceListEnum] = await Promise.all([
                    APIEndpoint.get('items/enumerate'),
                    APIEndpoint.post(`stores/pricelist/items`,filter1),
                    APIEndpoint.post(`stores/pricelist/items/enumerate`,filter2),
                ],

                );
                if(respEnum.success &&resPriceList.success && resPriceListEnum.success){
                    const pList= resPriceList.items.filter((item,index)=>respEnum.items.find((resI,index)=>resI.itemId===item.itemId)).filter((eIt,index)=>resPriceListEnum.items.find((rIp,index)=>rIp.itemId===eIt.itemId));
        if(pList && pList.length){
             setLoading(false)
            const options = [];
            pList.filter( (fieldValue, index) => {
                options.push([
                    {
                        id: 'itemCode',
                        value: fieldValue.itemCode,
                        className: 'cellClass1',
                        width: '300px',
                        showPopup:true,
                        readOnly:false,
                        required:true,
                        type:"string"
                    },
                    {
                        id: 'itemName',
                        value: fieldValue.itemName,
                        className: `cellClass cellClass_${index}_${iIndexes.ITEM_NAME}`,
                        width: '400px',
                        showPopup:true,
                        readOnly:false,
                        required:true,
                        type:"string"
                    },
                    {
                        id: 'availableStock',
                        value: fieldValue.availableStock,
                        className: `cellClass cellClass_${index}_${iIndexes.AVAILABLE_STOCK}`,
                        width: '100px',
                        readOnly: true
                    },
                    {
                        id: 'estimatedQuantity',
                        value: fieldValue.estimatedQuantity,
                        className: `cellClass cellClass_${index}_${iIndexes.ESTIMATED_QTY}`,
                        width: '100px',
                        readOnly: true,
                    },
                    {
                        id: 'bob',
                        value: fieldValue.bob,
                        className: `cellClass cellClass_${index}_${iIndexes.BOB}`,
                        width: '100px',
                        readOnly: false
                    },
                    {
                        id: 'corporate',
                        value: fieldValue.corporate,
                        className: `cellClass cellClass_${index}_${iIndexes.CORPORATE}`,
                        width: '100px',
                        readOnly: false
                    },
                    {
                        id: 'webMeal',
                        value: fieldValue.webMeal,
                        className: `cellClass cellClass_${index}_${iIndexes.WEB_MEAL}`,
                        width: '200px',
                        readOnly: false
                    },
                    {
                        id: 'total',
                        value: fieldValue.total,
                        className: `cellClass cellClass_${index}_${iIndexes.TOTAL}`,
                        width: '200px',
                        readOnly: true
                    },
                    {
                        id: 'unit',
                        value: fieldValue.unit,
                        className: `cellClass cellClass_${index}_${iIndexes.UNIT}`,
                        width: '200px',
                        readOnly:true,
                        required:true
                    },
                    {
                        id: 'remarks',
                        value: fieldValue.remarks,
                        className: `cellClass cellClass_${index}_${iIndexes.REMARKS}`,
                        width: '200px'
                    }                ]);
                return fieldValue;
            });
            setUpliftSheetData(options)
            if(options){
                const newRows = createEmptySheetRows(pList.length,10);
                setWarehouseItemsFiltered( [...pList, ...newRows ]);
                setWarehouseItemsFull(pList);
                setEditTransferItemsToSheet({items:[...pList]},options)
                }
}

                }
            }
            catch (error) {
                setLoading(true)
                console.log("Error occured while fetching list:");
            }
}


      };


      const setEditTransferItemsToSheet = (transferDetails,options) => {
        let jsonSpreadsheetData = JSON.stringify( options );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData)
            const items = transferDetails.items;
        if(transferDetails.items &&updatedSpreadsheetData.length){
            items.map( (item, i) => {
                if (updatedSpreadsheetData[i]) {
                    updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = item.itemCode;
                    updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = item.itemName;
                    updatedSpreadsheetData[i][iIndexes.AVAILABLE_STOCK].value = item.availableStock;
                    updatedSpreadsheetData[i][iIndexes.ESTIMATED_QTY].value = item.estimatedQuantity;
                    updatedSpreadsheetData[i][iIndexes.BOB].value = item.bob;
                    updatedSpreadsheetData[i][iIndexes.CORPORATE].value = item.corporate;
                    updatedSpreadsheetData[i][iIndexes.WEB_MEAL].value = item.webMeal;
                    updatedSpreadsheetData[i][iIndexes.TOTAL].value = item.total;
                    updatedSpreadsheetData[i][iIndexes.UNIT].value = item.unit;
                    updatedSpreadsheetData[i][iIndexes.REMARKS].value = item.remarks;
                    return item;
        }});
        }

        ReactDOM.flushSync( () => {
             setUpliftSheetData( [ ...updatedSpreadsheetData ]);

        });

    };

    const saveSheetData = async () => {
        const postItems = [];
        setSpreadsheetErrors([]);
        let inSheetData = [ ...upliftSheetData ];
            inSheetData.map( (cellRow, idx) => {
            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE]);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME]);
            if (!itemCode || !itemName) {
                return false;
            }
            const availableStock = validateCellAndGetData(cellRow[iIndexes.AVAILABLE_STOCK]);
            const estimatedQuantity = validateCellAndGetData(cellRow[iIndexes.ESTIMATED_QTY]);
            const bob = validateCellAndGetData(cellRow[iIndexes.BOB]);
            const corporate = validateCellAndGetData(cellRow[iIndexes.CORPORATE]);
            const webMeal = validateCellAndGetData(cellRow[iIndexes.WEB_MEAL]);
            const total = validateCellAndGetData(cellRow[iIndexes.TOTAL]);
            const unit = validateCellAndGetData(cellRow[iIndexes.UNIT]);
            if (itemCode && itemName ) {
                postItems.push({
                    index: idx,
                    itemCode ,
                    itemName,
                    availableStock,
                    estimatedQuantity: Number(estimatedQuantity),
                    bob: Number(bob),
                    corporate: Number(corporate),
                    webMeal: Number(webMeal),
                    total: Number(total),
                    unit: Number(unit),
                });
            return cellRow;

            }
            else {
                /**
                 * SET Error status
                 */
                // cellRow[iIndexes.ITEM_UNIT].className += ' errorCellData';
                // cellRow[iIndexes.ITEM_REQ_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_RECI_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_REMARKS].className= 'errorCellData';

                setSpreadsheetErrors([ { message: 'Please fill all required item details.', error: 1} ]);
                return false
            }

        });
        if (postItems.length === 0) {
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'We did not find anything to save!' } ]);
            return false;
        }
        let data;

        if(warehouseid !== undefined){
            data ={
                flightDate: dateid,
                storeId: Number(sectorid),
                warehouseId: Number(warehouseid),
                items: postItems
            }
        }else{
            let IdData = await findByIdData();
            data = {
                upliftId: id,
                warehouseId: IdData.result.warehouseId,
                storeId: IdData.result.storeId,
                flightDate: IdData.result.dateModified,
                items: postItems
            }
        }
        APIEndpoint.post('uplifts/save', data)
        .then( apiResp => {
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiResp?.message || 'Successfully created Uplift.' } ]);
            setTimeout(() => {
                goToInventory()
            }, 1000);
        })
        .catch( (error) => {
            const apiData = JSON.parse(error.message).data;

            if ( apiData.errors ) {
                const errorMsg = apiData.errors.errors[0].message;
                setSpreadsheetErrors([ ...spreadsheetErrors, { message: errorMsg, error: 1 } ]);
            }
            else if (apiData.msg) {
                setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiData.msg, error: 1 } ]);
            }
        });

    };



    const setWarehouseTransferItemToSheet = (item) => {
        if (Object.keys(item).length === 0) {
            return 0;
        }

        let jsonSpreadsheetData = JSON.stringify( upliftSheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {

                sheetRow[iIndexes.ITEM_CODE].value = item.itemCode;
                sheetRow[iIndexes.ITEM_NAME].value = item.itemName;
                sheetRow[iIndexes.AVAILABLE_STOCK].value = '';
                sheetRow[iIndexes.ESTIMATED_QTY].value ='';
                sheetRow[iIndexes.BOB].value = '';
                sheetRow[iIndexes.CORPORATE].value = '';
                sheetRow[iIndexes.WEB_MEAL].value = '';
                sheetRow[iIndexes.TOTAL].value = '';
                sheetRow[iIndexes.UNIT].value = item.unit;
                sheetRow[iIndexes.REMARKS].value = '';
            }

            return sheetRow;
        } );


        ReactDOM.flushSync( () => {
            // let newRows=createEmptySheetRows(null,10);
              setUpliftSheetData( [ ...updatedSpreadsheetData ]);

         });

        setActiveCell({});
        hidePopupList();
    }


    const onClickPopupListItem = (e, row, type='reason') => {

        if (type === 'reason') {
            setReasonField(row);
        }
        else {
            setWarehouseTransferItemToSheet(row);
        }
    }

    const selectAllCells = (e) => {
        setCount((prev)=>prev+1)
        let updatedSheetData=[]
        if(count%2!==0){
            updatedSheetData = upliftSheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `selectcell-class cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
        }else{
            updatedSheetData = upliftSheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
        }

    setUpliftSheetData(updatedSheetData);

    };


    const showItemListPopup = (eltPosition, activeCellIn) => {
        if (activeCellIn.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'block';
                targetEltCodes.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltCodes.style.left = eltPosition.left.toFixed() + 'px';
            }
        } else if (activeCellIn.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'block';
                targetEltNames.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltNames.style.left = eltPosition.left.toFixed() + 'px';
            }
        }
    }


    const hidePopupList = () =>{
        // if (activeCell.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'none';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'none';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if ( targetEltReasonTypes ) {
                targetEltReasonTypes.style.display = 'none';
            }
        // }
    }

    const setReasonField = (reason) => {
        if (Object.keys(reason).length === 0) {
            return 0;
        }

        let jsonSpreadsheetData = JSON.stringify( upliftSheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                sheetRow[iIndexes.ITEM_REASON].value = reason.label;
            }

            return sheetRow;
        } );


        ReactDOM.flushSync( () => {
            setUpliftSheetData( [ ...updatedSpreadsheetData ]);
        });

        setActiveCell({});
        hidePopupList();
    }

    const handleActiveCellChange = (activeCell) => {
        setActiveCell({});
        hidePopupList();
        setActiveCell(activeCell);

        if (!loading && upliftSheetData[activeCell.row][activeCell.column] ) {
            const cellDetailsObj = upliftSheetData[activeCell.row][activeCell.column];
            if ( cellDetailsObj.hasOwnProperty('showPopup') && cellDetailsObj.showPopup === true ) {

                const sourceElt = document.getElementsByClassName('cellClass_' + activeCell.row + '_' + activeCell.column)[0];
                if ( sourceElt ) {
                    const eltPosition = sourceElt.getBoundingClientRect();

                    showItemListPopup(eltPosition, activeCell);
                }

            }

        }

    }
    const handleCellBlur = () => {
        console.log('CellBlur.');
    }

    const handleCellKeyDown = (keyboardEvent) => {

        const searchText = keyboardEvent.target.value;
        if (activeCell && Object.keys(activeCell).length > 0) {
            if (upliftSheetData[activeCell.row][activeCell.column].type === 'number' &&  keyboardEvent.target.value ) {
                keyboardEvent.target.value = keyboardEvent.target.value.replace(/[^0-9]$/g, '');
            }
        }

        // const eltPosition = keyboardEvent.target.getBoundingClientRect();
        // showItemListPopup(eltPosition);

        /**
         * Filter poup items
         */
        const searchPattern = new RegExp((searchText || '').replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&'),'gi');
        const filteredList = warehouseItemsFull?.filter( row => row.itemCode.search( searchPattern ) !== -1 );
        setWarehouseItemsFiltered([ ...filteredList ]);
    }

    const handleCellSelect = (selectedCell) => {
        if (selectedCell.length !== 0) {
            setSelectedCellsArr([ ...selectedCell ]);
        }
    };

    function goToInventory(e) {
      navigate("/inventory/uplifts")
    }

    function calcTotal(data){
        let jsonSpreadsheetData = JSON.stringify( data );
        let SpreadsheetData = JSON.parse(jsonSpreadsheetData).filter(row=>row[0]!=undefined)
        const updatedSpreadsheetData = SpreadsheetData.map( (sheetRow, i) => {
            const updatedSheet=sheetRow.map(cell => {
                if ((cell.id==='bob'|| cell.id==='webMeal'|| cell.id==='coporate') && isNaN(cell.value)) {
                  return { ...cell, value: "" };
                }
                return cell;
              })
            updatedSheet[iIndexes.TOTAL].value = Number(updatedSheet[4].value)+Number(updatedSheet[5].value)+Number(updatedSheet[6].value)
            return updatedSheet;
        });
        setUpliftSheetData([...updatedSpreadsheetData])
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
             { !loading?  <div className="sheet-container sm-scrollbar scrollBarUplift">
                    <div className="header mb-2 flexRow justifyContentSpaceBetween">
                        <div className="leftBlock alignItemsCenter">
                        <button onClick={ (e) => saveSheetData(e) }
                                className="add-item-button mr-2"><FaSave style={{marginRight:'5px',marginBottom:"3px"}}/>{ t('buttons.save') }</button>
                                {checkSheetData()?<><button onClick={ (e) => selectAllCells(e) }
                                className="alignItemsCenter flexRow add-item-button-all m-3">{count%2===0?<BiSolidSelectMultiple  style={{marginRight:'5px'}} />:<FaRegCheckSquare  style={{marginRight:'5px'}} />}Select All</button><button onClick={ () => removeRow() }
                                className="remove-button"> <RiDeleteBin6Line style={{ marginRight: "5px" ,marginBottom:"3px" }} />Delete Row</button></>:<></> }&nbsp;

                        </div>
                        {spreadsheetErrors.length >= 1? <div className={`padding4 margTop9-5 alert alert-${spreadsheetErrors[0].message.includes('fully') ? 'success' : 'danger'}`}>
                        {spreadsheetErrors.length >= 1 ? spreadsheetErrors[0]?.message : null}
                        </div>:null}
                        <button onClick={goToInventory}>
                          <img src={closeIcon} alt="close"/>
                        </button>
                    </div>
                    <div className="se-titlebar">
                    {sectorName ? `Uplift New* - ${sectorName}- ${dateid?moment.parseZone(dateid).format("DD/MM/YYYY"):moment.parseZone(dateEdited).format("DD/MM/YYYY")}`:""}
                   </div>
                   <div className="sheet-container sm-scrollbar reconCreate">    <Spreadsheet
                        className='spread-sheet maxHeight-uplif'
                        data={upliftSheetData}
                        onChange={calcTotal}
                        columnLabels={upliftsSheetLabels}
                        darkMode={true}
                        // getBindingsForCell={[]}
                        onActivate={handleActiveCellChange}
                        onBlur={handleCellBlur}
                        onKeyDown={handleCellKeyDown}
                        onSelect={handleCellSelect}
                        cellClassName="custom-cell"

                    /></div>

                    {loading && (
                        <div style={{display:"flex",justifyContent:"center",height:"20vh",alignItems:"center"}}>Loading data...</div>
                    )}


                    <div className="flexRow alignItemsCenter margTop8">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button" title={ t('spreadsheet.addmoreRows') }>{ t('buttons.addRows') }</button>
                    </div>
                    </div>:<span style={{marginTop:'40vh'}}><Loading /></span>
}
                    <div className = "cellSelectionBlockItemCodes sm-scrollbar">
                {
                    warehouseItemsFiltered.length ? (
                    <ul>
                        {warehouseItemsFiltered.length && warehouseItemsFiltered.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row, 'item') }>{ row.itemCode }</li>
                        ) )
                        }
                    </ul>
                    )
                    : null
                }
                </div>
                <div className = "cellSelectionBlockItemNames sm-scrollbar">
                {
                    warehouseItemsFiltered.length ? (
                    <ul>
                        {warehouseItemsFiltered.length && warehouseItemsFiltered.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row, 'item') }>{ row.itemName }</li>
                        ) )
                        }
                    </ul>
                    )
                    : null
                }
                </div>



            </div>
        </>
    );
}