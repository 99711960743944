import React,{useState} from "react";
import moment from "moment-timezone";
import { saveAs } from 'file-saver';
import closeIcon from '../../../images/icons/Icon2.png'
import { DataGrid,Loading } from "../../../common";
import { useTranslation } from 'react-i18next';
import { Tabs, EmainOrDownload, MainColumns, Columns, Card, EstimateColumns } from "./Cards";
import { DFPAPIEndpoint } from "../../../utils/axios";
import { CateringSummary } from "./Calender-Summary";
import CalendarSales from "./CalendarSales";
import ReconciliationsDfp from "./reconciliationsDfp";
import { Calenderoffload } from "./Calender-Offload";

// const TABS = ['summary', 'uplifts', 'estimates', 'sales', 'retreval', 'reconcilliation', "wastage", "offload"]

const Estimates = (props) => {
  const { t } = useTranslation();
  const { group,state,setMessage } = props;
  const { estimates } = group
  const getMealOrderEstimate = () => {
    var t4estimate = null;
    if (Array.isArray(estimates)) {
      t4estimate = estimates.find((_) => (_.relativeExecutionOffset === 4))
    }
    return t4estimate;
  }
  const mealorder = getMealOrderEstimate()
  const handleEstimateDownload = async (estimate) => {
    const path = DFPAPIEndpoint.getFullPath(
      `operations/cateringGroups/${group.id}/estimates/${estimate.id}/download`
    );
    let headers = DFPAPIEndpoint.getHeaders();
    headers = headers.headers;

    try {
          const res = await fetch(path, {
        method: "GET",
        headers: headers,
      });
      if (res.status === 200) {
           const file = await res.blob();
        saveAs(file, `${group.plan}_${getEstimateTitle(estimate)}.xlsx`);
      } else {
        setMessage('Failed to Download Estimate')
        setTimeout(() => {
        setMessage('')
        }, 5000);
      }
    } catch (error) {
      setMessage('Failed to Download Estimate')
       setTimeout(() => {
       setMessage('')
       }, 5000);
    }
  }
  const getEstimateTitle = (estimate) => {
    const date = moment(estimate.scheduledTimeOfExecution).tz(moment.tz.guess()).format("DD-MM-YYYY hh:mm");
    return `${date} - ${estimate.relativeExecutionTime}`
  }
  return (
    <div className="flexCol full-flex scrollHide">
{  Boolean(estimates) && (state.selectedTab!=='meal-order') ?<h6 className="flexCol flex-ctr hor-ctr w-100 title">{t(`pages.controlPanel.views.catering.estimates`)}</h6>:<></>}
      {
       Boolean(estimates) && (state.selectedTab!=='meal-order') ?
          <div className="flexCol full-flex scrollY">
            {
              estimates.map(estimate => {
                return (
                  <div style={{cursor:'pointer',padding:'0.3rem 0rem 0.3rem 0.3rem'}} className="flexRow hor-ctr alert alert-info estimate w-100 space-btn" key={estimate.id} onClick={() => props.onEstimate(estimate)}>
                     <span className="h7">{getEstimateTitle(estimate)}</span>
                    <EmainOrDownload className={"icon-estm"} showEmail={false} onDownload={() => handleEstimateDownload(estimate)} />
                  </div>
                )
              })
            }
            {
             Boolean(mealorder)&&
              <React.Fragment>
                <h6 className="flexCol flex-ctr hor-ctr w-100 title">{t(`pages.controlPanel.views.catering.mealorder`)}</h6>
                <div style={{cursor:'pointer',padding:'0.3rem 0rem 0.3rem 0.3rem'}} className="flexRow hor-ctr alert alert-info estimate w-100 space-btn" onClick={() => props.onEstimate(mealorder)}>
                  <span className="h7">{getEstimateTitle(mealorder)}</span>
                  <EmainOrDownload  className={"icon-estm"} showEmail={false} onDownload={() => handleEstimateDownload(mealorder)} />
                </div>
              </React.Fragment>
            }
          </div>
          : Boolean(mealorder) && (state.selectedTab==='meal-order')?
          <React.Fragment>
          <h6 className="flexCol flex-ctr hor-ctr w-100 title">{t(`pages.controlPanel.views.catering.mealorder`)}</h6>
          <div style={{cursor:'pointer',padding:'0.3rem 0rem 0.3rem 0.3rem'}} className="flexRow hor-ctr alert alert-info estimate w-100 space-btn" onClick={() => props.onEstimate(mealorder)}>
            <span className="h7">{getEstimateTitle(mealorder)}</span>
            <EmainOrDownload className='icon-estm' showEmail={false} onDownload={() => handleEstimateDownload(mealorder)} />
          </div>
        </React.Fragment>:

          <div className="flexCol full-flex flex-ctr hor-ctr">
            <h6 className="h8">{t(`pages.controlPanel.views.catering.selectGroupForEstimate`)}</h6>
          </div>
      }
    </div>
  )
 }
  const RightBlockScreen = (props) => {
    const { selected, title, group, nonSelectedTitle, Columns,onEstimate, showConfirm ,state} = props;
    const SubTitleRow = () => {
      return (
        <div className="flexRow space-btn margBottom10">
          <span className="margBottom10">
            <code>{group.airport.locationCode}</code> | <span>{group.code}</span>
          </span>
          <EmainOrDownload  onDownload={()=>onEstimate(selected)}/>
        </div>
      )
    }
    return (
      <div className="right-bloc">
        {
           state.loading? <div className="w-100 h-100 flexCol justifyContentCenter alignItemsCenter">
         <Loading/>
         </div>: Boolean(selected) ?
            <div className="flexCol w-100 h-100 cont">
              <h6 className="h6 textAlignCenter">{title}</h6>
              <SubTitleRow />
              <div className="flexCol full-flex ag-theme-alpine-dark">
              <div className="justifyContentSpaceBetween flex alignItemsCenter"></div>
              <DataGrid
                columnResize
                rowModelType='clientSide'
                showDefaultFilters={false}
                className='full-flex'
                columns={Columns}
                rowData={selected.items}
              />
              </div>
              {
                Boolean(showConfirm) &&
                <div className="w-100 h-100 flexCol justifyContentCenter alignItemsCenter">
                  <button className="done-button">Confirm</button>
                </div>
              }
            </div>
            :
            <div className="w-100 h-100 flexCol justifyContentCenter alignItemsCenter">
              <h6 className="h8">{nonSelectedTitle}</h6>
            </div>
        }
      </div>
    )
  }
export const CalendarCateringScreen = (props) => {
  const [state, setState] = React.useState({ selectedTab: props.item, show: '',loading:false })

  const { group } = props;
  const [message,setMessage]=useState(null);

  const { t } = useTranslation()
  const viewId = 'catering'
  const handleTab = (tab) => {
    setState((_) => ({ ..._, selectedTab: tab }))
  }


  const getTabs = () => {
    return [props.item]
  }
  const handleEstimateClick = async (estimate) => {
    try {
      setState((_) => ({ ..._,loading:!_.loading}))
      const res = await DFPAPIEndpoint.get('operations/cateringGroups/estimates/' + estimate.id)
      if (res && res.estimate) {
        setState((_) => ({ ..._, selectedEstimate: res.estimate ,loading:!_.loading}))
      }
    } catch (error) {
      setState((_) => ({ ..._,loading:!_.loading}))

    }
  }

  const getEstimateTitleTop = (estimate) => {
    const date = moment(estimate.scheduledTimeOfExecution).tz(moment.tz.guess()).format("DD-MM-YYYY hh:mm");
    return `${date} - ${estimate.relativeExecutionTime}`
  }

  const handleEstimateDownloadTopLeft = async (estimate) => {
    const path = DFPAPIEndpoint.getFullPath(
      `operations/cateringGroups/${group.id}/estimates/${estimate.id}/download`
    );
    let headers = DFPAPIEndpoint.getHeaders();
    headers = headers.headers;
    try {
      setState(prev=>({...prev,loading:!state.loading}));
      const res = await fetch(path, {
        method: "GET",
        headers: headers,
      });
      if (res.status === 200) {
        setState(prev=>({...prev,loading:!state.loading}));
        const file = await res.blob();
        saveAs(file, `${group.plan}_${getEstimateTitleTop(estimate)}.xlsx`);
      } else {
        setState(prev=>({...prev,loading:!state.loading}));
       setMessage('Failed to Download Estimate')
       setTimeout(() => {
       setMessage('')
       }, 5000);
      }
    } catch (error) {
      setState(prev=>({...prev,loading:!state.loading}));
     setMessage('Failed to Download Estimate')
       setTimeout(() => {
       setMessage('')
       }, 5000);
    }
  }

  const getCateringModelTitle = (context=null) => {
    const { name } = group
    let title = !context?t(`pages.controlPanel.views.catering.estimates`) + ' - ' + name:t(`pages.controlPanel.views.catering.meal-order`) + ' - ' + name;
    if (state.selectedEstimate) {
      title += ` - ${state.selectedEstimate.relativeExecutionTime}`
    }
    return title;
  }
  const getUplifts = () => {
    let uplifts = group.uplift;
    if (uplifts && !Array.isArray(uplifts)) {
      uplifts = [uplifts]
    };
    return uplifts
  }
  const handleSelectedUplift = (uplift) => {
    setState((_) => ({ ..._, selectedUplift: uplift }))
  }
  const uplifts = getUplifts();
  return (
    <section className="flexCol full o-hide ctrl-container ">
      <div className="flexRow justifyContentSpaceBetween alignItemsCenter">
        <span>{t(`pages.controlPanel.views.catering.title`)} / {group.name}</span>
        {message?(<div className='flexRow confirmation_popup_dfp_context alert_error'>
          <h6 className="subheading-text-medium-title fontLight">{message}!</h6>
          </div>):null}
        <img className="close-icon" alt='Close' src={closeIcon} onClick={props.onBack} />
      </div>
      <div className="flexCol full-flex w-100 cntrl-calender-catering">
        <Tabs TABS={getTabs()} view={viewId} selectedTab={state.selectedTab} onClick={handleTab} />
        <div className="flexCol full-flex">
          {
            state.selectedTab === 'summary' &&
            <div className="flexCol w-100 h-100 items ">
              <CateringSummary {...props} />
            </div>
          }
          {
            state.selectedTab === 'uplifts' &&
            <div className="flexRow w-100 h-100 uplifts">
              <div className="flexCol left-bloc scrollY">
                <h3 className="flexCol flex-ctr hor-ctr w-100 title h4">{t(`pages.controlPanel.views.catering.upliftHeading`)} </h3>
                {
                  Boolean(uplifts)
                    ?
                    uplifts.map((uplift, i) => {
                      return (
                        <Card
                          group={{ model: group, id: group.name }}
                          uplift={uplift} key={uplift.id} slNo={i + 1}
                          onClick={() => handleSelectedUplift(uplift)}
                        />)
                    })
                    :
                    <h5 className="h8">{t(`pages.controlPanel.views.catering.noUplift`)}</h5>
                }
              </div>
              <RightBlockScreen
                group={group}
                Columns={Columns}
                onEstimate={handleEstimateDownloadTopLeft}
                selected={state.selectedUplift}
                state={state}
                nonSelectedTitle={t(`pages.controlPanel.views.catering.selectUplift`)}
                title={t(`pages.controlPanel.views.catering.upliftHeading`) + ' - ' + group.name}
              />
            </div>
          }
          {
            state.selectedTab === 'estimates' &&
            <div className="flexRow w-100 h-100">
              <div className="flexCol left-bloc scrollY">
                <Estimates setMessage={setMessage} group={group} state={state} setState={setState} onEstimate={handleEstimateClick} />
              </div>
              <RightBlockScreen
                title={getCateringModelTitle()}
                group={group}
                onEstimate={handleEstimateDownloadTopLeft}
                Columns={MainColumns.concat(EstimateColumns)}
                selected={state.selectedEstimate}
                state={state}
                showConfirm={state.selectedEstimate && state.selectedEstimate.cateringStatus === 'confirmed'}
                nonSelectedTitle={t(`pages.controlPanel.views.catering.selectEstimate`)}
              />
            </div>
          }
                  {
            state.selectedTab === 'meal-order' &&
            <div className="flexRow w-100 h-100">
              <div className="flexCol left-bloc scrollY">
                <Estimates group={group} state={state} setMessage={setMessage} onEstimate={handleEstimateClick} />
              </div>
              <RightBlockScreen
                title={getCateringModelTitle('meal-order')}
                group={group}
                onEstimate={handleEstimateDownloadTopLeft}
                Columns={MainColumns.concat(EstimateColumns)}
                selected={state.selectedEstimate}
                state={state}
                showConfirm={state.selectedEstimate && state.selectedEstimate.cateringStatus === 'confirmed'}
                nonSelectedTitle={t(`pages.controlPanel.views.catering.selectMealOrder`)}
              />
            </div>
          }
          {state.selectedTab === 'sales' && (
            <div className="w-100 h-100 p-2">
              <CalendarSales group={group}/>
            </div>
          )}

           {(state.selectedTab === 'reconciliation'||state.selectedTab === 'retrieval') && (
            <div className="w-100 h-100 p-2">
              <ReconciliationsDfp tab={state.selectedTab} group={group}/>
            </div>
          )}

          {
            state.selectedTab === 'offload' && (
              <div className="w-100 h-100 p-2">
              <Calenderoffload  group={group}/>
            </div>
            )
          }
          {
            (state.selectedTab === 'wastage') &&
            <div className="flexRow w-100 h-100 justifyContentCenter alignItemsCenter">
              No Data
            </div>
          }
        </div>
      </div>
    </section>
  )
}