

/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { APIEndpoint, DFPAPIEndpoint } from '../../../utils/axios';
import {editFields,editFields2,editFields3,defaultEditFields} from '../../../utils/itemSel'
import { DataGrid, EditModal,UploadModal } from '../../../common';
import { onDownloadXlsFile,translationLength } from '../../../utils/commonFunctions';
import {HOST_SERVER} from '../../../utils/configs';
import { MIMETypes } from '../../../utils/configs';
import usePermissions from '../../../utils/userPermissions';
import { useTranslation } from 'react-i18next';
import editIcon2 from "../../../assets/edit2.svg";
import searchIcon from '../../../assets/icons/search.svg';
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import LoadingGIF from '../../../assets/loadingIn.gif';

const Columns = [
  { field: 'itemCode',headerName: 'Item Code',tooltipField:'itemCode',flex:1, suppressSizeToFit:true, resizable:true, minWidth: 300,width:350 },
  { field: 'itemName',headerName: 'Name', flex:1,suppressSizeToFit:true, resizable:true, minWidth: 200,
    tooltipField: 'itemName',  valueGetter: (params) => {
      if (params.data&&params.data.itemName) {
         return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
      }
      return null;
    },
  },
  { field: 'ssrCode', headerName: 'SSR Code',flex:1, suppressSizeToFit:true, resizable:true, minWidth: 135 },
  { field: 'itemType',headerName: 'Type', flex:1,suppressSizeToFit:true, resizable:true, minWidth: 135 },
  { field: 'category',headerName: 'Category',flex:1, suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'subCategory',headerName: 'Sub Category',flex:1, minWidth: 175,suppressSizeToFit:true, resizable:true, },
  { field: 'hsn', headerName: 'HSN',flex:1,  suppressSizeToFit:true, resizable:true,minWidth: 125 },
];

const EditFormFields = [
  {label: 'Item Type', attribute: 'itemType', type: 'select', disabled: false, options: [], required: true },
  {label: 'Item Code', attribute: 'itemCode', type: 'text', disabled: false, required: true },
  {label: 'Item Name', attribute: 'itemName', type: 'text', disabled: false, required: true},
  {label: 'SSR Code', attribute: 'ssrCode', type: 'text', disabled: false},
  {label: 'Category', attribute: 'category', type: 'select', disabled: false, options: [], required: true },
  {label: 'Sub category', attribute: 'subCategory', type: 'select', disabled: true, options: [], required: true },
  {label: 'HSN Code', attribute: 'hsn', type: 'text', disabled: false},
  {label: 'Bar Code', attribute: 'barcode', type: 'text', disabled: false},
  {label: 'Section', attribute: 'section', type: 'select', disabled: false, options: [] },
  {label: 'Unit', attribute: 'unit', type: 'select', disabled: false, options: [], required: true },
  {label: 'Base Unit', attribute: 'baseUnit', type: 'text', disabled: false},
  {label: 'Color code', attribute: 'colorCode', type: 'text', disabled: false},
  {label: 'Replenishable', attribute: 'replenishable', type: 'number', disabled: false,note:"(Place an order even after stock is exhausted)" },
  {label: 'Description', attribute: 'description', type: 'textarea', disabled: false},
  {label: 'ItemId', attribute: 'itemId', type: 'hidden', disabled: true, required: true },
];

export const ItemMasterPage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({});
  const tableRef = useRef();
  const [perPageLimit, ] = useState(10);
  const [searchKey, setSearchKey] = useState('');
  const [itemTypeList, setItemTypeList] = useState([]);
  const [selItemType, setItemType] = useState('');
  const [category, setCategory] = useState(null);
  const [subCat, setSubCat] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [, setUnits] = useState([]);
  const [, setSections] = useState([]);
  const [image, setImage] = useState({});
  const [selectFields, setSelectFields] = useState({unit:null,section:null});
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [fileUpload, setFileUpload] = useState(null)
  const [showUpload, setShowUpload] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [editFormFields, setEditFormFields] = useState(EditFormFields);
  const[itemTypeValue,setItemTypeValue]=useState(null);
  const [blobFile, setBlobFile] = useState(null)
  const[widthHeight,setWidthHeight]=useState();
  const[base64,setBase64]=useState("");
  const sType = localStorage.getItem('sType');
  const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);
  let invData={},
  itemIdMaster=''
  const preCompile = {
    itemType: t('selectButtons.selItemType'),
    category: t('selectButtons.selCategory'),
    subCategory: t('selectButtons.selSubCategory'),
    unit: t('selectButtons.selUnit'),
    section:t('selectButtons.selSection'),
    uploadTitle:t('pages.inventoryContent.itemMaster.uploadItems')
  }

  const { hasPermission: createitemMaster } = usePermissions('create', "inventory.item-master");
  const { hasPermission: edititemMaster } = usePermissions('edit', "inventory.item-master");
  const { hasPermission: viewitemMaster } = usePermissions('viewDetails',"inventory.item-master");
  const { hasPermission: downloaditemMaster } = usePermissions('download',"inventory.item-master");
  const { hasPermission: uploaditemMaster } = usePermissions('upload',"inventory.item-master");


  const fetchSubCategoryList = useCallback(
    async (categoryObj) => {
      setSubCatData([]);
      setSubCat([]);
      const resp = await DOMAIN.get('subcategories/' + categoryObj.key);
      if (resp.result.length > 0||resp.success) {
        const options=[{key:0,label:preCompile.subCategory,value1:"",value:""}];
        resp.result.forEach( (fieldValue, index) => {
            options.push({key: fieldValue.id, label: fieldValue.value,value1: fieldValue.value});
        });
        /**
         * Set to edit form sub category list
         */

        setSubCat( editFormFields.map( editRow => {
          if (editRow.attribute === 'subCategory' ) {
            editRow.options=options;
            editRow.disabled=false;
          }
          return editRow;
        }))

        setSubCatData(options)
      }
      else {
        // const options = [];
        // editFormFields.map( editRow => {
        //   if (editRow.attribute === 'subCategory' ) {
        //     editRow.options = options;
        //   }
        //   setSubCat(editRow)
        //   return editRow;
        // });
      }
    },
    [DOMAIN, editFormFields, preCompile.subCategory],
  )
  ;

  const fetchItemTypes = useCallback(
    async () => {
      try {
          const resp = await DOMAIN.get('itemTypes');
      if (resp.result.length > 0) {
        const options = [{key: 0, label:preCompile.itemType, value: ''}];
        resp.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: fieldValue, value: fieldValue});
          return fieldValue;
        });

        setItemTypeList( options );

        /**
         * Set to edit form itemType list
         */
         editFormFields.map( row => {
          if (row.attribute === 'itemType' ) {
            row.options = options;
          }
          return row;
        });
      }
      else {
        console.log('Log: Error while fetching item types API');
      }
      } catch (error) {

      }

    },
    [DOMAIN, editFormFields, preCompile.itemType],
  )
  ;
  const fetchUnits = useCallback(
    async () => {
      try {
         const resp = await DOMAIN.get('units');
      if (resp.result.length > 0) {
        const options = [{key: 0, label: preCompile.unit, value: ''}];
        resp.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: fieldValue, value: fieldValue});
          return fieldValue;
        });
        setUnits( options );

        /**
         * Set to edit form itemType list
         */
         editFormFields.map( row => {
          if (row.attribute === 'unit' ) {
            row.options = options;
          }
          return row;
        });

      }
      else {
        console.log('Log: Error while fetching item types API');
      }
      } catch (error) {

      }

    },
    [DOMAIN, editFormFields, preCompile.unit],
  )
  ;

  const fetchSection = useCallback(
    async () => {
      try {
        const resp = await DOMAIN.get('sections');
      if (resp.result.length>0) {
        const options = [{key: 0, label: preCompile.section, value: ''}];
        resp.result.filter( (fieldValue, index) => {
          if (fieldValue!==""){
            if(fieldValue===null){
              options.push({key: index+1, label: "null", value: ""});
            }else{
              options.push({key: index+1, label: fieldValue, value: fieldValue});
            }
          }
          return fieldValue;
        });
        setSections( options );
        /**
         * Set to edit form section list
         */
         editFormFields.map( row => {
          if (row.attribute === 'section' ) {
            row.options = options;
          }
          return row;
        });

      }
      else {
        console.log('Log: Error while fetching item types API');
      }
      } catch (error) {

      }

    },
    [DOMAIN, editFormFields, preCompile.section],
  )
  ;
  const fetchCategoryList = useCallback(
    async () => {
      try {
        const resp = await DOMAIN.get('categories');
      if (resp.result.length > 0) {
        const options = [{key: 0, label: preCompile.category, value: ''}];
        resp.result.filter( (fieldValue) => {
          options.push({key: fieldValue.id, label: fieldValue.value, value: fieldValue.value});
          setCatData(options)
          return fieldValue;
        });

        /**
         * Set to edit form category list
         */
         editFormFields.map( row => {
          if (row.attribute === 'category' ) {
            row.options = options;
          }
          return row;
        });
      } else {
        console.log('Log: Error while fetching categories');
      }
      } catch (error) {

      }


    },
    [DOMAIN, editFormFields, preCompile.category],
  )
  const refreshTable = useCallback(
    () => {
      tableRef.current.refreshTableHandler(selItemType);
    },
    [selItemType],
  )
  const setImageObj=useCallback(
    ()=>{
      let locS=''
      if(blobFile?.length){
        const timestamp = new Date().getTime();
        locS= blobFile.replace("http://localhost:3000", HOST_SERVER)+`?t=${timestamp}`;
        setImage({
        imgName: fileUpload?.name,
        imgType: fileUpload?.type,
        imgSize: fileUpload?.size,
        _data: base64,
        width: widthHeight?.width,
        height: widthHeight?.height,
        localSrc: locS
    })
      }
    },
    [base64, blobFile, fileUpload, widthHeight],
  )
  const setEditFormCheckFields=useCallback(
    ()=>{
      if(state && state.selected?.action==='editItem'){
        switch (itemTypeValue) {
          case "Product": setEditFormFields([...EditFormFields,...editFields]);
          break;
          case "Combo": setEditFormFields([...EditFormFields,...editFields]);
          break;
          case "Serving":setEditFormFields([...EditFormFields,...editFields2]);
          break;
          case "MRO": setEditFormFields([...EditFormFields,...editFields2]);
          break;
          case "SeatUpgrade": setEditFormFields([...EditFormFields,...editFields3]);
          break;
          default:
            setEditFormFields([...defaultEditFields])
            break;
          }
          setEditFormFields((prev)=>{
            return prev.map(item=>{
              if(item.attribute==='itemType'||item.attribute==='itemCode'||item.attribute==='itemName'){
                return {...item,disabled:true}
              }
              return item
            })
          })
      }
      if(state && state?.selected?.action==='addNewItem'){
        switch (itemTypeValue) {
          case "Product": setEditFormFields([...EditFormFields,...editFields]);
          break;
          case "Combo": setEditFormFields([...EditFormFields,...editFields]);
          break;
          case "Serving":setEditFormFields([...defaultEditFields])
          break;
          case "MRO": setEditFormFields([...defaultEditFields])
          break;
          default:
            setEditFormFields([...defaultEditFields])
            break;
          }
      }
        },
    [itemTypeValue, state],
  )
  React.useEffect(() => {
    fetchItemTypes();
    fetchCategoryList();
    fetchSection();
    fetchUnits();
  }, [editFormFields, fetchCategoryList, fetchItemTypes, fetchSection, fetchUnits, t]);

  React.useEffect( () => {
    refreshTable();
  }, [refreshTable, selItemType, tableRef]);

  React.useEffect( () => {
    setImageObj();
  }, [base64, blobFile, setImageObj, widthHeight]);
  React.useEffect( () => {
    t('pages.inventoryContent.itemMaster.uploadItems');
  }, [t]);

  React.useEffect( () => {
    if(state?.selected?.action!=="" && itemTypeValue){
      setEditFormCheckFields();
    }
  }, [state?.selected, itemTypeValue, setEditFormCheckFields]);
  React.useEffect( () => {
    if(itemTypeValue===null){
      setEditFormFields([...defaultEditFields])
    }
  }, [itemTypeValue]);

  useEffect( () => {
    if (category) {
      fetchSubCategoryList(category);
    }
  }, [category, fetchSubCategoryList]);


  const onAction = async (e, action, selectedRowData) => {
    itemIdMaster=selectedRowData.itemId;
    setItemTypeValue(selectedRowData?.itemType)
    const resp = await DOMAIN.get(`inventory/items/${itemIdMaster}`);
    if (resp.success) {
       invData=resp.result;
      invData.category=resp.result.category.value;
      invData.subCategory=resp.result.subCategory.value;
      if(invData){
        setState( (_prev) => {
          return { ..._prev, selected: {...invData,  action: 'editItem' }}
        });
      }
    }


    else {
      console.log('Log: Error while fetching item types API');
    }
    /**
     * Set to edit form
     */

     editFormFields.map( editRow => {
      if (editRow.attribute === 'category') {
        editRow.value = selectedRowData.category;
        const selCategoryObj = editRow.options.find(row => row.value === selectedRowData.category);
        if (selCategoryObj) {
          //
          setCategory(selCategoryObj);
        }
      }
      return editRow;
    });

    EditFormFields.map( editRow => {
      if (editRow.attribute === 'itemType' ) {
        editRow.initialValue = selectedRowData.itemType;
      }
      if (editRow.attribute === 'itemCode') {
        editRow.disabled = false;
      }
      if (editRow.attribute === 'category' ) {
        editRow.initialValue =  invData.category;

      }
      if (editRow.attribute === 'subCategory' ) {
        editRow.initialValue =  selectedRowData.subCategory;
        editRow.value =  selectedRowData.subCategory;
        editRow.disabled=false
      }

      return editRow;
    });
    setSubCat(editFormFields.map( editRow => {
      if (editRow.attribute === "subCategory") {
        editRow.initialValue =  selectedRowData.subCategory;
        editRow.value =  selectedRowData.subCategory;
        editRow.disabled=false
      }
      return editRow;
    }))
  };
  const onFieldChangeHandler = (e) => {
    const {name,value}=e.target;
    setModalFormStatusObj({ text: "" });
    if(name==='unit'){
      setSelectFields({...selectFields,unit:value})
    }
    if(name==='section'){
      setSelectFields({...selectFields,section:value})
    }
    if (name === 'category') {
      const selCategory = value;
      /**
       * Set to edit form
       */
       editFormFields.map( editRow => {
        if (editRow.attribute === name) {
          editRow.value = selCategory;
          const selCategoryObj = editRow.options.find(row => row.value === selCategory);
          if (selCategoryObj) {
            //
            setCategory(selCategoryObj);
          }
        }
        return editRow;
      });
    }
    if (name === 'subCategory') {
      const selSubCategory = value;
      /**
       * Set to edit form
       */

       setSubCat(editFormFields.map( editRow => {
        if (editRow.attribute === "subCategory") {
          editRow.value = selSubCategory;
        }
        return editRow;
      }))
    }
  };
  const onClose = () => {
    setState((_prev) => {
      return { ..._prev, selected: false }
    });
    editFormFields.forEach((items)=>{
    if(items.value||items.initialValue){
        delete items["value"];
        delete items["initialValue"];
      }
    })
    setModalFormStatusObj({text:''});
    fetchCategoryList();
    setItemTypeValue(null);
    setSubCat([]);
    invData={};
    setImage({});
    setSubCatData([]);
    setCategory(null);
    resetImageSelection(false);
  }

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchItemMaster = async (page=1, perPage=10) => {
    if(!viewitemMaster){
      return{items:[],count:0}
    }
    filterData.page = page;
    filterData.limit = perPage;
    filterData.searchParam = searchKey;
    if (selItemType) {
      const isItemTypeExists = filterData.filters.find(row => row.itemType ? true : false);
      if (!isItemTypeExists) {
        filterData.filters.push({ itemType: selItemType });
      } else {
        filterData.filters.map( row => {
          row.itemType = selItemType;
          return row;
        });
      }
    }
    const res = await DOMAIN.post('master/process', JSON.stringify(filterData));
    return res;
  }

const onDownloadItemMaster=async()=>{
  if(isDownloading) return;
  let header={
    'Accept':'text/csv'
  }
    try {
      setIsDownloading(true)
    const res= await DOMAIN.post(`downloads/itemMaster`,{},header);
    if(res.status==='scheduled'){
      setTimeout(() => {
        const dowId = DOMAIN.get(`downloads/status/${res.id}`);
        dowId.then((val) => {
          if (val.status === "completed") {
              DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                  .then((ItemMasterDwnld) => {
                      const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', val.fileName + '.csv');
                      document.body.appendChild(link);
                      link.click();
                      setIsDownloading(false);
                  })
                  .catch((error) => {

                      console.error('Error downloading CSV:', error);
                      setIsDownloading(false);
                  });
          }
      });
        }, 8000);
    }
    }
     catch (error) {
      setState((_) => {
        return { ..._,message:{text:error?.data?.message},};
      })
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          message: null,
        }));
      }, 5000);
       setIsDownloading(false)
     }
  }
 const getCatSubCatObj=(obj=null,stateVal=null)=>{
let catObj={},
   subCatObj={}
   if(obj&&obj.category){
    catObj=catData.find(c=>c.label===obj.category);
    if(catObj){
     fetchSubCategoryList(catObj)
    }
   }
     if(obj&&!obj.category){
     catObj=catData.find(c=>c.label===stateVal.category);
    if(catObj){
     fetchSubCategoryList(catObj)
    }
   }
if(obj&&!obj.subCategory){
         subCatObj=subCatData.find(c=>c.label===stateVal.subCategory);
   }
   if(obj&&obj.subCategory && subCatData.length){
    subCatObj=subCatData.find(c=>c.label===obj.subCategory);
   }
   if(obj===null&& stateVal){
    catObj=catData.find(c=>c.label===stateVal.category);
   subCatObj=subCatData.find(c=>c.label===stateVal.subCategory);
    if(catObj){
     fetchSubCategoryList(catObj)
    }
   }
return removeKeys(catObj,subCatObj)
 }
const removeKeys=(catObj,subObj)=>{
  const modifiedCatObj = { ...catObj };
  const modifiedSubObj = { ...subObj };

  if (modifiedCatObj && modifiedSubObj) {
    delete modifiedCatObj['label'];
    modifiedCatObj['id'] = modifiedCatObj['key'];
    delete modifiedCatObj['key'];

    delete modifiedSubObj['label'];
    modifiedSubObj['id'] = modifiedSubObj['key'];
    delete modifiedSubObj['key'];
  }
  return [catObj,subObj]
}
 const imageDimension=(imgData)=>{
  let i = new Image();
  i.onload = function(){
  setWidthHeight({width:i.width,height:i.height})
  };
  i.src = imgData;

}
const encodeImageFileAsURL = (element) => {

  if (element && (element instanceof File || element instanceof Blob)) {
    setBlobFile(URL.createObjectURL(element));
    setFileUpload(element);

    const reader = new FileReader();
    reader.onloadend = () => {
      imageDimension(reader.result);
      setBase64(reader.result);
    };
    reader.readAsDataURL(element);
  } else {

  }
};



const resetImageSelection=(closeReset=false)=>{
  if(closeReset){
    setState((_)=>({..._,selected:{..._.selected,image:null}}))
  }
  setImage({})
  setBlobFile(null);
  setFileUpload(null);
  setModalFormStatusObj({text:""})
 }

const getItemChecked=(updated,option,stateSel=false)=>{
if(updated && (updated[option]===true || updated[option]===false)){
 return updated[option];
 }
 else{
 return stateSel
}
}
const handleReset = () => {

  let timerId = setTimeout(() => {
    clearTimeout(timerId);
    setState((_) => {
      return { ..._, resetDataSource: !_.resetDataSource };
    })
    onClose();
  }, 1200)
}
const getImageStat = (mod, data) => {
  const imageSrc = Boolean(Object.keys(image).length) ? image : (mod?.image ?? data?.image);

  if (Boolean(Object.keys(image).length)) {
    return image;
  }
  return imageSrc ? { localSrc: imageSrc } : null;
};


const createItemMaster = (updated, selected, actionType) => {
  const [cat, subCat] = getCatSubCatObj(updated, selected);

const baseItemMaster = {
  itemId: updated?.itemId ?? selected?.itemId,
  itemName: updated?.itemName ?? selected?.itemName,
  itemCode: updated?.itemCode ?? selected?.itemCode,
  itemType: updated?.itemType ?? selected?.itemType,
  ssrCode: updated?.ssrCode ?? selected?.ssrCode,
  hsn: updated?.hsn ?? selected?.hsn,
  barcode: updated?.barcode ?? selected?.barcode,
  category: cat,
  subCategory: subCat,
  description: updated?.description ?? selected?.description,
  unit: selectFields.unit ?? selected?.unit,
  baseUnit: updated?.baseUnit ?? selected?.baseUnit,
  section: selectFields.section ?? selected?.section,
  colorCode: updated?.colorCode ?? selected?.colorCode,
  replenishable: updated?.replenishable ?? selected?.replenishable,
  image:getImageStat(updated,selected),
  domestic: getItemChecked(updated, 'domestic', selected?.domestic),
  international: getItemChecked(updated, 'international', selected?.international),
  bob: getItemChecked(updated, 'bob', selected?.bob),
  corporate: getItemChecked(updated, 'corporate', selected?.corporate),
  webMeal: getItemChecked(updated, 'webMeal', selected?.webMeal),
  perishable: getItemChecked(updated, 'perishable', selected?.perishable),
  saleable: getItemChecked(updated, 'saleable', selected?.saleable),
  beverage: getItemChecked(updated, 'beverage', selected?.beverage),
  merchandise: getItemChecked(updated, 'merchandise', selected?.merchandise),
  stationary: getItemChecked(updated, 'stationary', selected?.stationary),
  groupMeal: getItemChecked(updated, 'groupMeal', selected?.groupMeal),
  crewMeal: getItemChecked(updated, 'crewMeal', selected?.crewMeal),
  liquor: getItemChecked(updated, 'liquor', selected?.liquor),
  dutyFree: getItemChecked(updated, 'dutyFree', selected?.dutyFree),
  additionalSmuLoadable: getItemChecked(updated, 'additionalSmuLoadable', selected?.additionalSmuLoadable),
  shouldDeleteImage: getImageStat(updated, selected) === null,
  smuLoadable:getItemChecked(updated, 'smuLoadable', selected?.smuLoadable),
  weight: 0,
};


  if (actionType === 'addNewItem') {
    baseItemMaster.itemName = updated?.itemName ?? '';
    baseItemMaster.itemCode = updated?.itemCode ?? '';
    baseItemMaster.description = updated?.description ?? '';
    baseItemMaster.unit = selectFields.unit ?? '';
    baseItemMaster.image = Boolean(Object.keys(image).length) ? image : null;
  }

  return baseItemMaster;
};

const onEditSubmit = async (e, updated) => {
  const { selected } = state;
  const actionType = selected?.action;

  if (Boolean(updated) && actionType === 'editItem') {
    const bodyItemMaster = createItemMaster(updated, selected, 'editItem');
    try {
      const res = await DOMAIN.put(`inventory/items/${selected.itemId}`, bodyItemMaster);
      if (res.success) {
        setModalFormStatusObj({ text: res.msg });
        handleReset();
      }
    } catch (error) {
      console.log(error)
      const errObj = JSON.parse(error.message);
      setModalFormStatusObj({ error: errObj.data.msg });
    }
  }

  else if (updated === null && actionType === 'editItem') {
    const bodyItemMaster = createItemMaster(updated, selected, 'editItem');
    delete bodyItemMaster['action'];

    try {
      const res = await DOMAIN.put(`inventory/items/${bodyItemMaster.itemId}`, bodyItemMaster);
      if (res.success) {
        setModalFormStatusObj({ text: res.msg });
        handleReset();
      }
    } catch (error) {
      const errObj = JSON.parse(error?.message??error);
      if(!errObj.status){
        setModalFormStatusObj({ error:'Something went wrong. Please try again after some time.'});
      }else{
        setModalFormStatusObj({ error: errObj.data.message||errObj.data?.msg ||'Something went wrong. Please try again after some time.' });
      }

    }
  }

  else if (Boolean(updated) && actionType === 'addNewItem') {
    const bodyItemMaster = createItemMaster(updated, selected, 'addNewItem');
    try {
      const res = await DOMAIN.post(`inventory/items`, bodyItemMaster);
      if (res.success) {
        setModalFormStatusObj({ text: res.msg });
        handleReset();
      } else {
        setModalFormStatusObj({ text: res.message });
        handleReset();
      }
    } catch (error) {
      const errObj = JSON.parse(error.message);
      setModalFormStatusObj({ error: errObj.data.message||errObj.data?.msg });
    }
  }

  else if (actionType === 'addNewItem') {
    setModalFormStatusObj({ error: "Please fill all mandatory fields*" });
  }
};


  const onAddItemHandler = () => {
    setState( (_prev) => {
      return { ..._prev, selected: { action: 'addNewItem' } };
    });

    /**
     * Set to edit form
     */
     editFormFields.forEach( editRow => {
      if (editRow.attribute === 'itemCode') {
        editRow.disabled = false;
      }
      if(editRow.attribute === 'category'){
        editRow.value="";
        editRow.initialValue="";
      }
      if(editRow.attribute === 'subCategory'){
        editRow.options=[{key: 0, label: preCompile.subCategory, value1: '',value: ''}];
        editRow.disabled=true;
        editRow.initialValue=""
      }
      return editRow;
    });
  }
  // const onPerLimitChange = (e) => {
  //   setPerPageLimit(Number(e.target.value));
  // };

  const onSearch = (e) => {
      setSearchKey(e.target.value);
  };

  const onItemTypeChange = (e) => {
      setItemType(e.target.value);
  }
  const onUploadTaxMaster=()=>{
    setShowUpload(true)

  }
  const onUploadClose = () => {
    setUploadedFile([]);
    setShowUpload(false);
    setUploadFileStatusBlock('');
  }
  const onDownloadSampleFile = () => {
    onDownloadXlsFile('samples/downloads/Item_Master_Upload_Sample','Item_Master_Upload_Sample')
  }
  const onUploadFileChangeHandler = async (e) => {
    const file=e.target.files[0];
    setUploadedFile(e.target.files[0]);
    setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

    const formData = new FormData();
    formData.append('scheduleLater', false);
    formData.append('listName', undefined);
    formData.append('targetSectorType', undefined);
    formData.append('stores', []);
    formData.append('warehouses', []);
    formData.append('file', file);

    try {
      await DOMAIN.post('inventory/upload', formData);
      setUploadedFile([]);
      setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
      setState( (_prev) => {
        return { ..._prev,resetDataSource:!_prev.resetDataSource}
      });

    }
    catch(error) {
      e.target.value = null;
      setUploadedFile([]);
      let { status, data } = JSON.parse( error.message );
      const msgHeading = data?.Error || data?.message || error.message;

      let msgContent = "";
      if (status === 400) {
            data.errors.forEach( _ => {
            const lineNum = _.lineNumber;
            const errorMsgArr = [];
            _.errors.forEach(erObj => {
              errorMsgArr.push( erObj.field + ' | ' + erObj.message + ' \n<br>' );
            });

            msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
        });
      } else if(status===0 && !data){
        msgContent += `Error occurred while uploading items sheet!<br>Please try again.`;
    }
      else {
        msgContent += `Error occurred while uploading items sheet!<br>Please fix the errors and re-upload.`;
      }

      console.log('Log: Error occured while upload items sheet!', status, data);

      setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                              );
    }
};

  return (
      <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
{ downloaditemMaster?<button className='upload dropdownStyle1-TextMedium' onClick={() => onUploadTaxMaster(true)}> <span><img  className="icon-size mt-1" alt='uploadItem' src={uploadItemIcon} /><u>{t('filters.uploadItems')}</u></span></button>:<></>}
             {uploaditemMaster? <button className='download dropdownStyle1-TextMedium' onClick={onDownloadItemMaster}><span>{isDownloading?<img src={LoadingGIF} alt='loading' className='cell-loading' />:<img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} />}<u className={`download__banner_${isDownloading ? "active" : ""}`}>{isDownloading ? t('filters.downloadingMaster'):t('filters.downloadMaster')}</u></span></button>:<></>}

          </div>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
          {
          Boolean(state.message) &&
          <div className={`validationMsgDiv error`}>
            {state.message.text}
          </div>
        }
          </div>

          <div className="flexRow gap10 width100 margTop8 margBot10 justifyContentSpaceBetween">
  <div className="flexRow justifyContentFlexStart alignItemsBaseline">
    <button style={{ marginLeft: '0.5rem' }} className="saleType-div m-2">
      <select
        className="select-item darkBlue-bg"
        onChange={onItemTypeChange}
        value={selItemType !== null ? selItemType : ''}
        id="saleTypeId"
      >
        {itemTypeList.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </button>
  </div>

  <div className="flexRow justifyContentCenter alignItemsCenter content-tabs">
    <div className="margLeft margBot10 margTop8 underline">
      <span><img className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
      <span>
        <input
          className="search"
          onChange={onSearch}
          type="search"
          placeholder={t('filters.searchText')}
          value={searchKey}
        />
      </span>
    </div>
  </div>

  {createitemMaster && (
    <div className="flexRow justifyContentFlexEnd">
      <button className="add-item-button m-2" onClick={onAddItemHandler}>
        {t('buttons.addItem')}
      </button>
    </div>
  )}

</div>


          <DataGrid
            ref={tableRef}
            className="full-flex"
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            selItemType={selItemType}
            getRows={fetchItemMaster}
            rowSelection="multiple"
            actions={[{ title: '', detail: [{src: editIcon2,value:"EditItem",title:'Edit'}] }]}
            onAction={onAction}
            onAddItemHandler={() => onAddItemHandler()}
            columnResize={true}
            resetDataSource={state.resetDataSource}
          />
        </>
      }
      {
        state && Boolean(state.selected) ?  (
          <EditModal
            key={state.selected.action}
            title={(state.selected.action === 'editItem' && edititemMaster) ? 'Edit Item' : 'Add Item'}
            onClose={onClose}
            catData={catData}
            subCatData={subCatData}
            blobFile={blobFile}
            fileUpload={fileUpload}
            imageUrl={state?.selected?.image}
            resetImageSelection={resetImageSelection}
            encodeImageFileAsURL={encodeImageFileAsURL}
            modalFormStatusObj={modalFormStatusObj}
            setItemTypeValue={setItemTypeValue}
            onSubmit={onEditSubmit}
            onChange = {onFieldChangeHandler}
            fields={ subCat?.length>0?subCat.map( (elt) => {
              if (state.selected.action === 'editItem') {
                return {...elt, initialValue: state.selected[elt.attribute] || ''}
              }
              else {
                return {...elt, initialValue: '' }
              }
            }):editFormFields.map( (elt) => {
                if (state.selected?.action === 'editItem') {
                  return {...elt, initialValue: state.selected[elt.attribute] || ''}
                }
                else {
                  return {...elt, initialValue: '' }
                }
              })
            }
          />
        )
        : null
      }
       {
        showUpload && uploaditemMaster &&
        <UploadModal
        modelTitle={preCompile.uploadTitle}
        onUploadClose={onUploadClose}
        onDownloadSampleFile={onDownloadSampleFile}
        uploadedFile = { uploadedFile }
        onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
        uploadFileStatusBlock = { uploadFileStatusBlock }
        allowedExtensions={ MIMETypes.excel }
        />
      }
      {
      t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}