/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ReactSpreadsheet from "react-spreadsheet"; // , { createEmptyMatrix }
import * as ReactDOM from 'react-dom';
import '../../../../assets/styles/styles.scss'
import { FaRegSave } from "react-icons/fa";
import { FaRegCheckSquare } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { validateCellAndGetData } from "../../../../utils/commonFunctions";
import { DEFAULT_SHEET_ROWS_PLIFERAGE,DEFAULT_SHEET_ROWS } from "../../../../utils/configs";
import { useTranslation } from "react-i18next";

const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    ITEM_QTY: 2,
    ITEM_UNIT: 3,
    ITEM_REMARKS: 4
};
const sheetLabels = ['Item Code', 'Item Name', 'Quantity', 'Unit', 'Remarks'];
const defaultCellFieldData = {
    id: 'id',
    value: '',
    defaultValue: '',
    className: 'cellClass',
    width: '200px',
    readOnly: false,
    type: 'string',
    required: true,
    showPopup: false
};

const createEmptySheetRows = (noOfRows = DEFAULT_SHEET_ROWS) => {
    const rows = [];
    for( var i=0; i < noOfRows; i++) {
        rows.push([
            {
                ...defaultCellFieldData,
                id: 'itemCode',
                value: '',
                className: 'cellClass1',
                width: '200px'
            },
            {
                ...defaultCellFieldData,
                id: 'itemName',
                value: '',
                className: 'cellClass1',
                width: '200px'
            },
            {
                ...defaultCellFieldData,
                id: 'quantity',
                value: '',
                type:'number',
                className: 'cellClass1',
                width: '200px'
            },
            {
                ...defaultCellFieldData,
                id: 'unit',
                value: '',
                className: 'cellClass1',
                width: '200px',
            },
            {
                ...defaultCellFieldData,
                id: 'remarks',
                required: false,
                readOnly:false,
                value: '',
                className: 'cellClass1',
                width: '400px'
            }
        ]);
    }
    return rows;
}
/**
 * Automatically creating rows
 */
// const initialData = createEmptyMatrix(10, sheetLabels.length);
/**
 * Manually creating rows
 */
const initialData = createEmptySheetRows( DEFAULT_SHEET_ROWS_PLIFERAGE );

export const SectorEditCreate = (props) => {
     const {data}=props;

    const { t } = useTranslation();
    const [warehouseItemsFull, setWarehouseItemsFull] = useState([]);
    const [warehouseItemsFiltered, setWarehouseItemsFiltered] = useState([]);
    const [, setEditTransferDetails] = useState({});
    const [state, setState] = useState({ isInvalidAccess: false, message: '', isEditForm: false });
    const [sheetData, setSheetData] = useState(initialData);
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const [activeCell, setActiveCell] = useState({});
    const [selectedCellsArr, setSelectedCellsArr] = useState([]);

    const targetEltCodes = document.getElementsByClassName("cellSelectionBlockItemCodes")[0];
    const targetEltNames = document.getElementsByClassName("cellSelectionBlockItemNames")[0];


    useEffect( () => {
      if ( data&&data.items) {
            setWarehouseItemsFull(data.items);
                setWarehouseItemsFiltered(data.items);
                setEditTransferDetails(data.items);
                // setEditTransferItemsToSheet(data);
                setState( { ...state, isEditForm: true } );
        }

    }, [data]);

    // const setEditTransferItemsToSheet = (transferDetails) => {

    //     let jsonSpreadsheetData = JSON.stringify( sheetData );

    //     let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
    //     const items = transferDetails.items;
    //     console.log("er",updatedSpreadsheetData.length)

    //        items.map( (item, i) => {
    //         console.log("item",item,i)
    //         updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = item.itemCode;
    //         updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = item.itemName;
    //         updatedSpreadsheetData[i][iIndexes.UNIT].value = item.unit;
    //         return item;
    //     });

    //     ReactDOM.flushSync( () => {
    //         setSheetData( [ ...updatedSpreadsheetData ]);

    //     });



    // };

    const addRowsToSheet = (rowsToAdd = 10) => {
        const newRows = createEmptySheetRows(rowsToAdd);
        setSheetData( [...sheetData, ...newRows ]);
    };

    const removeRow = (e) => {

        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let inSheetData = JSON.parse(jsonSpreadsheetData);

        selectedCellsArr.map( selectedCell => {

            const defaultVal = inSheetData[selectedCell.row][selectedCell.column].defaultValue;

            inSheetData[selectedCell.row][selectedCell.column].value = defaultVal || '';

            return selectedCell;
        });

        ReactDOM.flushSync( () => {
            setSheetData([ ...inSheetData ]);
        });

		setSelectedCellsArr([]);

    };
    const showValidationErrors = (e, inSheetData, errorClass='cellDataErrorCls' ) => {
        let haveErrors = false;
        const postItems = [];

        inSheetData.map( (cellRow, rowIdx) => {
            let isRowHaveError = false;

            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE]);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME]);
            const quantity = validateCellAndGetData(cellRow[iIndexes.ITEM_QTY]);
            const unit = validateCellAndGetData(cellRow[iIndexes.ITEM_UNIT]);
            const remarks = validateCellAndGetData(cellRow[iIndexes.ITEM_REMARKS]);

            if (!itemCode || !itemName) {
                return cellRow;
            }

            for (let colIdx = 0; colIdx < Object.keys(iIndexes).length; colIdx++) {

                if ( cellRow[colIdx].required === true) {

                    const fieldValue = validateCellAndGetData(cellRow[colIdx]);
                    if ( !fieldValue ) {
                        haveErrors = true;
                        isRowHaveError = true;
                    }
                }
            }
            if ( !isRowHaveError&& warehouseItemsFull.length ) {

                const item = warehouseItemsFull?.find( row => row.itemCode === cellRow[iIndexes.ITEM_CODE].value );
                if (item) {
                    postItems.push({
                        itemCode,
                        itemName,
                        quantity: parseInt(quantity),
                        unit,
                        remarks
                    });
                }

            }
            return cellRow;
        });

        if (haveErrors) {
            setSpreadsheetErrors([ { message: 'Please fill all required item details.' } ]);
        }

        return {
            sheetData: inSheetData,
            validRows: postItems,
            haveErrors
        };

    };
    const saveSheetData = async(e) => {
        setSpreadsheetErrors([]);

        let inSheetData = Object.assign([], sheetData);
        const respArrayData = showValidationErrors(e, inSheetData);

        if (!spreadsheetErrors.length && respArrayData.validRows.length === 0 &&!respArrayData.haveErrors) {
            // show global alert message
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'We did not find anything to save!' } ]);
            return false;
        }

        if ( respArrayData.haveErrors ) {
            return false;
        }

        if ( !respArrayData.validRows.length ) {
            return false;
        }


        // const data = {
        //     items: respArrayData.validRows || []
        // }

        if((state.isEditForm||!state.isEditForm)&&warehouseItemsFull.length){
            const filteredItems = warehouseItemsFull.filter(wItem => {
                return respArrayData.validRows.some(vId => wItem.itemCode === vId.itemCode);
            }).map(wItem => ({
                itemId: wItem.itemId,
                itemCode: wItem.itemCode,
                // quantity: respArrayData.validRows.find(vId => vId.itemCode === wItem.itemCode)?.quantity || 1
            }));

        //    const resp=await props.handleEditOrder(filteredItems,stateSpreadSheet.data)

        //     APIEndpoint.post(`sandboxing/containerIsolates/editDocument/edits/modifyOrder`, data)
        // .then( apiResp => {
        //     setSheetData(initialData);
        //     updateButtonTxtStatus(true, e, 'Redirecting...', true, { defaultButtonText: 'Save'} );
        //     setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiResp.msg || 'Successfully saved Items.' } ]);

        // })
        // .catch( (error) => {
        //     const apiData = error.data;
        //     setSpreadsheetErrors([]);

        //     ReactDOM.flushSync( () => {
        //         if ( apiData.errors ) {
        //             const errorMsg = apiData.errors.errors[0].message;
        //             setSpreadsheetErrors([ ...spreadsheetErrors, { message: errorMsg, error: 1 } ]);
        //             updateButtonTxtStatus(false, e, 'Please wait...', true, { defaultButtonText: 'Save'} );
        //         }
        //         else if (apiData.msg) {
        //             setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiData.msg, error: 1 } ]);
        //             updateButtonTxtStatus(false, e, 'Please wait...', true, { defaultButtonText: 'Save'} );
        //         }
        //         else {
        //             setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'Something went wrong, please try again later!', error: 1 } ]);
        //             updateButtonTxtStatus(false, e, 'Please wait...', true, { defaultButtonText: 'Save'} );
        //         }
        //     });

        // });

    }


        return false;
    };


    const handleActiveCellChange = (activeCell) => {
        setActiveCell({});
        hideItemListPopup();
        setActiveCell(activeCell);

        if ( sheetData[activeCell.row][activeCell.column] ) {

            const cellDetailsObj = sheetData[activeCell.row][activeCell.column];
            if ( cellDetailsObj.hasOwnProperty('showPopup') && cellDetailsObj.showPopup === true ) {

                const sourceElt = document.getElementsByClassName('cellClass_' + activeCell.row + '_' + activeCell.column)[0];

                if ( sourceElt ) {
                    const eltPosition = sourceElt.getBoundingClientRect();
                    showItemListPopup(eltPosition, activeCell);
                }

            }

        }

    }
    const handleCellBlur = () => {
        // setActiveCell({});
    }

    const handleCellKeyDown = (keyboardEvent) => {

        const searchText = keyboardEvent.target.value;
        if (activeCell && Object.keys(activeCell).length > 0) {

            if (sheetData[activeCell.row][activeCell.column].type === 'number' &&  keyboardEvent.target.value ) {
                keyboardEvent.target.value = keyboardEvent.target.value.replace(/[^0-9]$/g, '');
            }
        }

        // const eltPosition = keyboardEvent.target.getBoundingClientRect();
        // showItemListPopup(eltPosition);

        /**
         * Filter poup items
         */
        const searchPattern = new RegExp((searchText || '').replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&'),'gi');
        const filteredList = warehouseItemsFull.filter( row => row.itemCode.search( searchPattern ) !== -1 );
        setWarehouseItemsFiltered([ ...filteredList ]);
    }

    const showItemListPopup = (eltPosition, activeCellIn) => {
        const screenHeight = window.innerHeight

        if (activeCellIn.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'block';
                targetEltCodes.style.top = ( eltPosition.height + (eltPosition.top-70) + 2 ).toFixed() + 'px';
                targetEltCodes.style.left =( screenHeight-eltPosition.left-75).toFixed() + 'px';
            }
        } else if (activeCellIn.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'block';
                targetEltNames.style.top = ( eltPosition.height + (eltPosition.top-70) + 2 ).toFixed() + 'px';
                targetEltNames.style.left = ( screenHeight-eltPosition.left+210).toFixed() + 'px';
            }
        }
    }

    const hideItemListPopup = () =>{
        // if (activeCell.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'block';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'block';
            }
        // }
    }

    const onClickPopupListItem = (e, row) => {
        setWarehouseTransferItemToSheet(row);
    }

    const setWarehouseTransferItemToSheet = (item) => {
        if (Object.keys(item).length === 0) {
            return 0;
        }
        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = item.itemCode;
                updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = item.itemName;
                updatedSpreadsheetData[i][iIndexes.UNIT].value = item.unit;
            }
            return sheetRow;
        } );


        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);
        });

        setActiveCell({});
        hideItemListPopup();
    }


    const handleModeChange = (mode) => {
        // if (mode === 'edit') {
        //     setEditingCell
        // }
    };

    const handleCellSelect = (selectedCell) => {
        if (selectedCell.length !== 0) {
            setSelectedCellsArr([ ...selectedCell ]);
        }
    };

    const onCellCommitHandle = (prevCell, nextCell, coordinates) => {
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                <div className={ "flexRow alignItemsCenter margBot10 justifyContentSpaceBetween" } >
                </div>
                <div className="sheet-container sm-scrollbar">
                    <div className="header m-2 mb-2 flexRow justifyContentSpaceBetween">
                        <div className="leftBlock alignItemsCenter">
                            <button onClick={ (e) => saveSheetData(e) }
                                className="add-item-button m-3"> <FaRegSave  style={{marginRight:'5px'}} />{ t('buttons.save') }</button> &nbsp;
                                     <button onClick={ (e) => saveSheetData(e) }
                                className="add-item-button-all m-3"><FaRegCheckSquare  style={{marginRight:'5px'}} />Select All</button> &nbsp;
                            <button onClick={ (e) => removeRow(e) }
                                className="remove-button"><MdDeleteOutline   style={{marginRight:'5px'}}/>{ t('buttons.deleteRows') }</button>
                        </div>
                        <div className="rightBlock alignItemsCenter">
                            {spreadsheetErrors.length >= 1 ? spreadsheetErrors[0].message : null}
                        </div>
                    </div>
                    <div className="se-titlebar">
               {<span>{props.title}</span>}
                   </div>

                <ReactSpreadsheet
                data={sheetData}
                columnLabels={sheetLabels}
                darkMode={true}
                onActivate={handleActiveCellChange}
                onBlur={handleCellBlur}
                onKeyDown={handleCellKeyDown}
                onModeChange={handleModeChange}
                onSelect={handleCellSelect}
                onCellCommit={onCellCommitHandle}
                />
                    <div className="flexRow alignItemsCenter margTop8">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button">{ t('buttons.addRows') }</button> &nbsp;<label>10 row at bottom</label>
                    </div>

                </div>
                <div className = "cellSelectionBlockItemCodes sm-scrollbar">
                {
                    warehouseItemsFiltered.length ? (
                    <ul>
                        {warehouseItemsFiltered.length && warehouseItemsFiltered.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row) }>{ row.itemCode }</li>
                        ) )
                        }
                    </ul>
                    )
                    : null
                }
                </div>
                <div className = "cellSelectionBlockItemNames sm-scrollbar">
                {
                    warehouseItemsFiltered.length ? (
                    <ul>
                        {warehouseItemsFiltered.length && warehouseItemsFiltered.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row) }>{ row.itemName }</li>
                        ) )
                        }
                    </ul>
                    )
                    : null
                }
                </div>


            </div>
        </>
    );
}