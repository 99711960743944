/* eslint-disable react-hooks/exhaustive-deps */

import React,{useEffect,useState,useRef} from 'react'
import moment from 'moment';
import { APIEndpoint } from '../../../../utils/axios';
export const  EventLogContainer=(props)=> {
  const{id}=props;
const [page,setPage]=useState({paging:1,data:true});
const [eventsLog,setEventsLog]=useState(()=>{
  const evntLog=sessionStorage.getItem("eventLg")
  return evntLog?JSON.parse(evntLog):[]
})
const containerRef = useRef();

  const filterData = {
    page: page.paging,
    limit: 50,
    filters: []
}

useEffect(() => {
  if(eventsLog.length>0 && !page.data){
    sessionStorage.setItem('eventLg',JSON.stringify(eventsLog));
  }
 }, [eventsLog]);

useEffect(() => {
 
  const container = document.getElementById('eventLogContainer'); 

  if (container) {
    container.addEventListener('scroll', handleScroll);
  }

  return () => {
    if (container) {
      container.removeEventListener('scroll', handleScroll);
    }
  };
}, [page.paging]); 
const handleScroll = (e) => {
  const bottom = Math.floor(e.target.scrollHeight) === Math.floor(e.target.scrollTop + e.target.clientHeight)
  if (bottom) {
    setPage((_) => ({..._,paging:_.paging + 1}));
  }
};

useEffect(() => {
  const updateHeight = () => {
    const availableHeight = window.innerHeight * 0.55;
    if (containerRef.current) {
      containerRef.current.style.maxHeight = `${availableHeight}px`;
    }
  };

  updateHeight();
  window.addEventListener('resize', updateHeight);

  return () => {
    window.removeEventListener('resize', updateHeight);
  };
}, []);

useEffect(() => {
  if(id && page.data){
    fetchOperationsPanel(id,page,50);
  }
 }, [id,page.paging]);

  const fetchOperationsPanel = async (sectId,page, perPage) => {
    filterData.page = page.paging;
    filterData.limit = perPage;
   if (!page.data)return;
try {
  const res = await APIEndpoint.post(`sandboxing/containerIsolates/${sectId}/events`, JSON.stringify(filterData));
   if(res && res.items.length>0){
    setEventsLog((prev) => {
      const evtLogs= prev && prev.length ? [...prev, ...res.items] : res.items;
      return [...new Set(evtLogs)];
    });
   }else{
    setPage((_) => ({..._,data:false}));

   }
} catch (error) {
  console.log("object",error);

}

  }
  const eventLogView=(events)=>{

const listItems = events?.map((list,index) =>
{
  return(<React.Fragment key={index}>
    <div>
      <li className="list-group-item">
        <div className='row'>
          <div className='col-xs-11'>
            <p className='margBottom5'><strong>{getEventConfigMain(list.name)}</strong>&nbsp;<span className='code'>{list.target.name}</span></p>
            <div  className='flexRow justifyContentSpaceBetween'>
            <p className='margBottom5'>{moment(list.timestamp).format('DD-MM-YYYY  hh:mm A')}</p>
           {list.description? <>{list.description}</>:<></>}

            </div>
            <p className='margBottom5'>{list.user.employeeName} ({list.user.employeeCode})</p>
            <p className='margBottom5'>{list.userAgent.appName}@{list.userAgent.appVersion}</p>
            <p className='margBottom5'>{list.userAgent.deviceModel},MAC ID:{list.userAgent.deviceName}</p>


          </div>
        </div>
      </li>
    </div>
  </React.Fragment>)}
 );
 return listItems
  }
const getEventConfig=(st)=>{
const resp=st.split(".");
if(resp[0].includes('isolate')&&!resp[1].includes('sector') && !resp[1].includes('order')){
  return <span style={{ color: '#70CEEA',fontSize:'1rem' }}>Instance {resp[1].charAt(0).toUpperCase()+resp[1].slice(1)}</span>;
}else{
  return <span style={{ color: '#70CEEA',fontSize:'1rem'  }}>{resp[1].replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^([a-z])/, (match) => match.toUpperCase())}</span>;

}
}
const getEventConfigMain=(st)=>{
  const resp=st.split(".");
  if(st.length<15){
    return <span style={{ color: '#70CEEA',fontSize:'1rem' }}> {resp[0].charAt(0).toUpperCase()+resp[0].slice(1)} {resp[1].charAt(0).toUpperCase()+resp[1].slice(1)}</span>;
  }else{
    return <span style={{ color: '#70CEEA',fontSize:'1rem'  }}>{resp[1].split(/(?=[A-Z])/).map(wrd=>wrd!=='Isolate'?wrd.charAt(0).toUpperCase() + wrd.slice(1):"Instance").join(' ')}</span>;

  }
  }

  const eventlogsItems=()=>{
    const listItems = eventsLog.map((list,index) =>
{
  return(<React.Fragment key={index}>
    <div>
      <li className="list-group-item">
        <div className='row'>
          <div className='col-xs-11'>
          <p className='margBottom5'><strong>{getEventConfig(list.name)}</strong>&nbsp;<span className='code'>{list.target.name}</span></p>
            <div  className='flexRow justifyContentSpaceBetween'>
            <p className='margBottom5'>{moment(list.timestamp).format('DD-MM-YYYY  hh:mm A')}</p>
           {list.description? <>{list.description}</>:<></>}

            </div>
            <p className='margBottom5'>{list.user.employeeName} ({list.user.employeeCode})</p>
            <p className='margBottom5'>{list.userAgent.appName}@{list.userAgent.appVersion}</p>
            <p className='margBottom5'>{list.userAgent.deviceModel},{list.userAgent.deviceName}</p>


          </div>
        </div>
      </li>
    </div>
  </React.Fragment>)}
 );
 return listItems
  }



  return (
          <div ref={containerRef}  id="eventLogContainer" className={props?.data?"width100 dialog__body_container flexRow justifyContentSpaceBetween flexWrap":"dialog__body_container flexRow justifyContentSpaceBetween flexWrap"} style={{overflowY:'scroll'}}>
            <ul className='list-group'>
                {eventlogsItems().length?eventlogsItems():eventLogView(props?.data)}
                </ul>
          </div>



  );
}