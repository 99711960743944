export const tableGridSettings = { height: '80vh', width: '100%', paddingLeft: 0 };

export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm A';
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

//Pick up the environment variable
export const HOST_SERVER = process.env.REACT_APP_API_SERVER || 'https://ngo3-sandbox-svcs.ngopos.com';

export const PageSizes = [
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '100', value: 100 },
  { label: '500', value: 500 },
];
export const PlansStatusSelectList = [
  { label: 'Select Status', value: '' },
  { label: 'Active Plans', value: 'active' },
  { label: 'Upcoming Plans', value: 'upcoming' },
  { label: 'Expired Plans', value: 'expired' },
];
export const FilterBodyTemplate = {
  action: 4,
  collection: '',
  filterOperation: 'or',
  filters: [],
  limit: 10,
  page: 1,
  searchParam: ''
};
export const ErrorFields=[{id:1,label:'Error',message:'Quanatity cannot be greater than the balance'},{id:2,label:'Error',message:'Field is required'}]
export const paymentOptions=[
  { key: 0, label: 'Select Payment Terms', value: '' },
  { key: 1, label: 'Net60', value: 'Net60' },
  { key: 2, label: 'Net30', value: 'Net30' },
  { key: 3, label: 'Net60', value: 'Net60' },
  { key: 4, label: 'Net30', value: 'Net30' },
  { key: 5, label: 'Net30', value: 'Net30' },
  { key: 6, label: 'Net60', value: 'Net60' },
  { key: 7, label: 'Net145', value: 'Net145' },
  { key: 8, label: 'Net60', value: 'Net60' },
  { key: 9, label: 'Net145', value: 'Net145' },
  { key: 10, label: 'Net30', value: 'Net30' },
  { key: 11, label: 'Net145', value: 'Net145' },
  { key: 12, label: 'Net30', value: 'Net30' },
  { key: 13, label: 'Net30', value: 'Net30' },
  { key: 14, label: 'Net60', value: 'Net60' },
  { key: 15, label: 'Net145', value: 'Net145' },
  { key: 16, label: 'Net60', value: 'Net60' },
  { key: 17, label: 'Net145', value: 'Net145' },
  { key: 18, label: 'Net30', value: 'Net30' },
  { key: 19, label: 'Net145', value: 'Net145' },
  { key: 20, label: 'Net30', value: 'Net30' },
  { key: 21, label: 'Net60', value: 'Net60' },
  { key: 22, label: 'Net145', value: 'Net145' },
  { key: 23, label: 'Net30', value: 'Net30' }
]
export const validationMenuPlanner= [
    'cateringStation',
    'sourceStation',
    'caterer',
    'class',
    'fromDate',
    'toDate',
    'aircraftType'
  ];

export const  menuSettingPal= [
    {
        "mealCategoryId": "continentalBreakfast",
        "mealCategoryLabel": "Continental Breakfast",
        "mealsSelection": [

        ]
    },
    {
        "mealCategoryId": "breakfast",
        "mealCategoryLabel": "Breakfast",
        "mealsSelection": [

        ]
    },
    {
        "mealCategoryId": "morningSnack",
        "mealCategoryLabel": "Morning Snack",
        "mealsSelection": [

        ]
    },
    {
        "mealCategoryId": "lunch",
        "mealCategoryLabel": "Lunch",
        "mealsSelection": [

        ]
    },
    {
        "mealCategoryId": "eveningSnack",
        "mealCategoryLabel": "Evening Snack",
        "mealsSelection": [

        ]
    },
    {
        "mealCategoryId": "dinner",
        "mealCategoryLabel": "Dinner",
        "mealsSelection": [

        ]
    },
    {
        "mealCategoryId": "supper",
        "mealCategoryLabel": "Supper",
        "mealsSelection": [

        ]
    },
    {
        "mealCategoryId": "alcoholicBeverages",
        "mealCategoryLabel": "Alcoholic-Beverages",
        "mealsSelection": [

        ]
    },
    {
        "mealCategoryId": "nonAlcoholic",
        "mealCategoryLabel": "Non-Alcoholic Beverages",
        "mealsSelection": [

        ]
    }
  ]
export const sectorTypesList=[{key:1,label:'Domestic',value:"domestic"},{key:2,label:'International',value:"international"}]
  export const  downloadReportOptions= [

    {
           "id": 1,
           "menu":"POS Sales",
           "reportsType": "Item Wise Sales Report",
           "disabled":false
         },
         {
          "id": 2,
          "menu":"POS Sales",
          "reportsType": "Invoice Wise Report",
          "disabled":false

        },  {
          "id": 3,
          "menu":"POS Sales",
          "reportsType": "Sector Wise Sales Report",
          "disabled":false
        },  {
          "id": 4,
          "menu":"POS Sales",
          "reportsType": "Flight Routes Status Report",
          "disabled":false

        },  {
          "id": 5,
          "menu":"POS Sales",
          "reportsType": "Inventory Snapshot Report",
          "disabled":false

        },  {
          "id": 6,
          "menu":"POS Sales",
          "reportsType": "Group-wise Inventory Snapshot Report",
          "disabled":false

        },  {
          "id": 7,
          "menu":"POS Sales",
          "reportsType": "Uplift Audit Report",
          "disabled":false

        },  {
          "id": 8,
          "menu":"POS Sales",
          "reportsType": "Meal Refused Report",
          "disabled":true

        },
        {
          "id": 9,
          "menu":"Reconciled Sale",
          "reportsType": "Sales Report",
          "disabled":false
        },
        {
          "id": 10,
          "menu":"Reconciled Sale",
          "reportsType": "Inventory Transaction Aggregate",
          "disabled":false

        }, {
          "id": 11,
          "menu":"Reconciled Sale",
          "reportsType": "Item Wise Report",
          "disabled":false

        }, {
          "id": 12,
          "menu":"Reconciled Sale",
          "reportsType": "Invoice Report",
          "disabled":false

        } ,{
          "id": 13,
          "menu":"Reconciled Sale",
          "reportsType": "Invoice Item Wise Report",
          "disabled":false

        }, {
          "id": 14,
          "menu":"Reconciled Sale",
          "reportsType": "Sector Wise Sales Report",
          "disabled":false

        }

        ,{
          "id": 15,
          "menu":"Crew Performance",
          "reportsType": "Crew Commission Report",
          "disabled":false

        },
        {
          "id": 16,
          "menu":"Crew Performance",
          "reportsType": "Crew Commission Summary Report",
          "disabled":false

        },
        {
          "id": 17,
          "menu":"Crew Performance",
          "reportsType": "Crew Stock Variance Report",
          "disabled":false

        },

        {
          "id": 18,
          "menu":"Crew Performance",
          "reportsType": "Crew Reconciliation Variance Report",
          "disabled":false

        },


        {
          "id": 19,
          "menu":"Crew Performance",
          "reportsType": "Customer Interactions Report",
          "disabled":false

        },


        {
          "id": 20,
          "menu":"Uplifts",
          "reportsType": "Uplift report",
          "disabled":true

        },
        {
          "id": 21,
          "menu":"Offload Summary",
          "reportsType": "Offload summary Aggregate",
          "disabled":true

        },
        {
          "id": 22,
          "menu":"Cash register",
          "reportsType": "Cash Collected - Sector Wise Report",
          "disabled":false

        },
        {
          "id": 23,
          "menu":"Cash register",
          "reportsType": "Cash collected Aggregate",
          "disabled":true

        },
        {
          "id": 24,
          "menu":"Cash register",
          "reportsType": "Cash Collected Report",
          "disabled":false

        },
        {
          "id": 25,
          "menu":"Cash register",
          "reportsType": "Bank Transfer Report",
  "disabled":false
        },
        {
          "id": 26,
          "menu":"Procurement",
          "reportsType": "Replenishment Report",
  "disabled":false

        },
        {
          "id": 27,
          "menu":"Procurement",
          "reportsType": "PO vs GRN Report",
  "disabled":false

        },
        {
          "id": 28,
          "menu":"Procurement",
          "reportsType": "PO vs GRN Report (Active PO)",
  "disabled":false

        },

        {
          "id": 29,
          "menu":"Procurement",
          "reportsType": "Warehouse Transfer Report",
  "disabled":false

        },
        {
          "id": 30,
          "menu":"Procurement",
          "reportsType": "GST Transfer Report",
  "disabled":false

        }
,
{
  "id": 31,
  "menu":"Procurement",
  "reportsType": "Warehouse Wastage Report",
  "disabled":false

},
{
  "id": 32,
  "menu":"Procurement",
  "reportsType": "Warehouse Stock Report",
  "disabled":false

},
{
  "id": 33,
  "menu":"Procurement",
  "reportsType": "Stock Reduction Register",
  "disabled":false

},{
  "id": 34,
  "menu":"Procurement",
  "reportsType": "Item Stock Movement",
  "disabled":false

},{
  "id": 35,
  "menu":"Procurement",
  "reportsType": "LOI Balance Report",
  "disabled":false

},{
  "id": 36,
  "menu":"Procurement",
  "reportsType": "Generate Sector Status",
  "disabled":true

}

,{
  "id": 37,
  "menu":"DFP",
  "reportsType": "DFP sector Status report",
  "disabled":true

},{
  "id": 38,
  "menu":"DFP",
  "reportsType": "Group commit status report",
  "disabled":true

},{
  "id": 39,
  "menu":"ERP Report",
  "reportsType": "Consignment Fillup Report",
  "disabled":true

},
{
  "id": 40,
  "menu":"ERP Report",
  "reportsType": "Consignment Sales Report",
  "disabled":true

},
{
  "id": 41,
  "menu":"ERP Report",
  "reportsType": 'Consignment Returns Report',
  "disabled":true

}
  ]
export const crewInc=[{
  "id":1,
  "type": "incentive",
  "openingBalance": 2281,
  "closingBalance": 5194,
  "salesCommission": 725,
  "salesCommissionBoB": 365
}, {
  "id":2,
  "type": "incentive",
  "openingBalance": 2828,
  "closingBalance": 9988,
  "salesCommission": 276,
  "salesCommissionBoB": 761
}, {
  "id":3,
  "type": "incentive",
  "openingBalance": 2667,
  "closingBalance": 7135,
  "salesCommission": 737,
  "salesCommissionBoB": 327
}, {
  "id":4,
  "type": "incentive",
  "openingBalance": 4822,
  "closingBalance": 9978,
  "salesCommission": 543,
  "salesCommissionBoB": 627
}, {
  "id":5,
  "type": "incentive",
  "openingBalance": 3390,
  "closingBalance": 6542,
  "salesCommission": 493,
  "salesCommissionBoB": 812
}, {
  "id":6,
  "type": "incentive",
  "openingBalance": 2931,
  "closingBalance": 4309,
  "salesCommission": 501,
  "salesCommissionBoB": 361
}, {
  "id":7,
  "type": "incentive",
  "openingBalance": 2254,
  "closingBalance": 389,
  "salesCommission": 179,
  "salesCommissionBoB": 660
}, {
  "id":8,
  "type": "incentive",
  "openingBalance": 3013,
  "closingBalance": 5472,
  "salesCommission": 876,
  "salesCommissionBoB": 309
}, {
  "id":9,
  "type": "incentive",
  "openingBalance": 2128,
  "closingBalance": 4017,
  "salesCommission": 442,
  "salesCommissionBoB": 134
}, {
  id:10,
  "type": "incentive",
  "openingBalance": 2315,
  "closingBalance": 1929,
  "salesCommission": 300,
  "salesCommissionBoB": 925
}]

export const sectSales=[{
  "id":1,
  "totalBoBSales": 6647,
  "totalCashSales": 9275,
  "totalCardSales": 3931,
  "specialSales": 2041,
  "dutySales": 490,
  "crewShare": 517,
  "sectorWiseSummary": [

  ]
}, {
  "id":2,
  "totalBoBSales": 1422,
  "totalCashSales": 6128,
  "totalCardSales": 2889,
  "specialSales": 1341,
  "dutySales": 210,
  "crewShare": 956,
  "sectorWiseSummary": [

  ]
}, {
  "id":3,
  "totalBoBSales": 4970,
  "totalCashSales": 3277,
  "totalCardSales": 9707,
  "specialSales": 1942,
  "dutySales": 450,
  "crewShare": 487,
  "sectorWiseSummary": [

  ]
}, {
  "id":4,
  "totalBoBSales": 7378,
  "totalCashSales": 5307,
  "totalCardSales": 7650,
  "specialSales": 1042,
  "dutySales": 120,
  "crewShare": 987,
  "sectorWiseSummary": [

  ]
}, {
  "id":5,
  "totalBoBSales": 2079,
  "totalCashSales": 1234,
  "totalCardSales": 8993,
  "crewShare": 490,
  "dutySales": 110,
  "specialSales": 1332,
  "sectorWiseSummary": [

  ]
}, {
  "id":6,
  "totalBoBSales": 5603,
  "totalCashSales": 6839,
  "totalCardSales": 1171,
  "specialSales": 1442,
  "dutySales": 333,
  "crewShare": 870,
  "sectorWiseSummary": [

  ]
}, {
  "id":7,
  "totalBoBSales": 2749,
  "totalCashSales": 1924,
  "totalCardSales": 5728,
  "specialSales": 1222,
  "dutySales": 273,
  "crewShare": 622,
  "sectorWiseSummary": [

  ]
}, {
  "id":8,
  "totalBoBSales": 2546,
  "totalCashSales": 4482,
  "totalCardSales": 2408,
  "specialSales": 2942,
  "dutySales": 130,
  "crewShare": 182,
  "sectorWiseSummary": [

  ]
}, {
  "id":9,
  "totalBoBSales": 1899,
  "totalCashSales": 2231,
  "totalCardSales": 3930,
  "specialSales": 1342,
  "dutySales": 323,
  "crewShare": 704,
  "sectorWiseSummary": [

  ]
}, {
  "id":10,
  "totalBoBSales": 3934,
  "totalCashSales": 1635,
  "totalCardSales": 7888,
  "specialSales": 1982,
  "crewShare": 392,
  "dutySales": 223,
  "sectorWiseSummary": [

  ]
}]

export const durationFilterSelect = [
  {key:0,label:'This Week',value:'thisWeek'},
  {key:1,label:'Last Week',value:'lastWeek'},
  {key:2,label:'This Month',value:'thisMonth'},
  {key:3,label:'Last Month',value:'lastMonth'},
  {key:4,label:'This Year',value:'thisYear'},
  {key:5,label:'Last Year',value:'lastYear'},
  {key:6,label:'Between Dates',value:'betweenDates'},
  {key:7,label:'Current Financial Year',value:'currentFinancialYear'},
]



export const MIMETypes = {
  excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: 'application/vnd.ms-excel',
  csv: 'text/csv',
  pdf: 'application/pdf'
};

export const BUTTON_STATUSUPDATE_INTERVAL = 1500;
export const DOWNLOAD_STATUSCHECK_INTERVAL = 15000;
export const DEFAULT_SHEET_ROWS = 10;
export const DEFAULT_SHEET_ROWS_PLIFERAGE = 30;


export const CONTROL_PANEL_VIEWS = [
  'caterer', 'catering', 'operations'
];

export const defaultCellFieldData = {
  id: 'id',
  value: '',
  defaultValue: '',
  className: 'cellClass',
  width: '200px',
  readOnly: false,
  type: 'string',
  required: true,
  showPopup: false
};
export const gstTypes=[
  {key:0,label:'Select GST Type',value:""},
  {key:1,label:'IGST',value:"IGST"},
  {key:2,label:'SGST',value:"SGST"},
  {key:3,label:'UTGST',value:"UTGST"}
]
export const columnDefs=[
  // {
  //     "field": "",
  //     "headerName": "",
  //     "filter": false,
  //     "checkboxSelection": false,
  //     "width": 30,
  // },
  {
      "field": "",
      "headerName": "",
      "resizable": true,
      "flex":1,
      "suppressSizeToFit":true,
      "cellStyle": {
        "text-align":"left",
          "whiteSpace": "pre-wrap"
      },
      "rowHeight": 150
  },
  {
      "field": "",
      "headerName": "",
      "suppressMenuHide": true,
      "resizable": true,
      "flex":1,
      "cellStyle": {
        "textAlign": "left"
    },
      "suppressSizeToFit":true,
      "filterParams": {},
      "width": 150
  },
  {
      "field": "",
      "headerName": "",
      "resizable": true,
      "flex":1,
      "suppressSizeToFit":true,
      "width": 150,
      "cellStyle": {
        "textAlign": "left"
    },
      "suppressMenuHide": false
  },
  {
      "field": "",
      "headerName": "",
      "resizable": true,
      "flex":1,
      "cellStyle": {
        "textAlign": "left"
    },
      "suppressSizeToFit":true,
      "width": 150
  },
  {
      "field": "",
      "headerName": "",
      "resizable": true,
      "flex":1,
      "cellStyle": {
        "textAlign": "left"
    },
      "suppressSizeToFit":true,
      "width": 150
  }
]

export const UPLOADED_LOI_ATTACHMENT_KEY = 'loiAttachmentData';

const prdQlityWarehouseAr = [];
prdQlityWarehouseAr['columnDefs'] = [
  { field: '', headerName: '', filter: false, checkboxSelection: true, width: 30 },
  {
    field: 'ruleSet', headerName: 'Rule set',
    width: 90,
    suppressMenuHide: true,
    cellStyle: { 'whiteSpace': 'pre-wrap' },
    rowHeight: 150
  },
  {
    field: 'ruleStatus', headerName: 'Rule status', suppressMenuHide: true, filterParams: {
      // cellRenderer: CellRenderer
    }, width: 130
  },
  { field: 'runStatus', headerName: 'Run status', width: 130, suppressMenuHide: false },
  { field: 'ruleType', headerName: 'Rule type', width: 130 },
  {
    field: 'failedRow', headerName: 'Failed Row', minWidth: 80,
    valueGetter: params => params.data ? params.data.failedRow : ''
  },
  {
    field: 'delta', headerName: 'Delta', width: 130,
    valueGetter: params => params.data ? params.data.delta + ' ' : ''
  },
];


prdQlityWarehouseAr['rowsData'] = [
  { ruleSet: 'Lorem Ipsum Dataset 1', ruleStatus: 'Candidate', runStatus: 'Successful', ruleType: 'Data rule', failedRow: 70, delta: 3 },
  { ruleSet: 'Lorem Ipsum Dataset 2', ruleStatus: 'Candidate', runStatus: 'Successful', ruleType: 'Rule set', failedRow: 70, delta: 3 },
  { ruleSet: 'Lorem Ipsum Dataset 3', ruleStatus: 'Candidate', runStatus: 'Successful', ruleType: 'Quality rule', failedRow: 70, delta: 3 },

  { ruleSet: 'Lorem Ipsum Dataset 4', ruleStatus: 'Candidate', runStatus: 'Successful', ruleType: 'Data rule', failedRow: 70, delta: 4 },
  { ruleSet: 'Lorem Ipsum Dataset 5', ruleStatus: 'Candidate', runStatus: 'Successful', ruleType: 'Rule set', failedRow: 70, delta: 13 },
  { ruleSet: 'Lorem Ipsum Dataset 6', ruleStatus: 'Candidate', runStatus: 'Successful', ruleType: 'Quality rule', failedRow: 70, delta: 3 },

  { ruleSet: 'Lorem Ipsum Dataset 7', ruleStatus: 'Candidate', runStatus: 'Successful', ruleType: 'Data rule', failedRow: 70, delta: 20 },
  { ruleSet: 'Lorem Ipsum Dataset 8', ruleStatus: 'Candidate', runStatus: 'Successful', ruleType: 'Rule set', failedRow: 10, delta: 3 },
  { ruleSet: 'Lorem Ipsum Dataset 9', ruleStatus: 'Candidate', runStatus: 'Successful', ruleType: 'Quality rule', failedRow: 7, delta: 3 },

  { ruleSet: 'Lorem Ipsum Dataset 10', ruleStatus: 'Candidate', runStatus: 'Successful', ruleType: 'Data rule', failedRow: 5, delta: 4 },
  { ruleSet: 'Lorem Ipsum Dataset 11', ruleStatus: 'Candidate', runStatus: 'Successful', ruleType: 'Rule set', failedRow: 5, delta: 3 },
  { ruleSet: 'Lorem Ipsum Dataset 12', ruleStatus: 'Candidate', runStatus: 'Successful', ruleType: 'Quality rule', failedRow: 5, delta: 3 },
];
export const prdQlityWarehouse = prdQlityWarehouseAr;


export const galleyComponentData1 = [
  {id: "box1", name:"",width: "calc(41.5% - 12px)", height: "calc(16.1% - 7px)", bottom: "81.4%", left: "5.1%" },
  {id: "box2", name:"", width: "calc(43.5% - 12px)", height: "calc(16.1% - 7px)", bottom: "81.4%", left: "43.1%"},
  {id: "box3", name:"",width: "calc(41.5% - 12px)", height: "calc(14.9% - 7px)", bottom: "67.6%", left: "5.1%" },
  {id: "box4", name:"", width: "calc(43.5% - 12px)", height: "calc(14.9% - 7px)", bottom: "67.6%", left: "43.1%" },
  {id: "box5", name:"", width: "calc(41.5% - 12px)", height: "calc(15.5% - 7px)", bottom: "53.1%", left: "5.1%" },
  {id: "box6",name:"",width: "calc(43.5% - 12px)", height: "calc(15.5% - 7px)", bottom: "53.1%", left: "43.1%"},

  {id: "box7", name:"",width: "calc(52.5% - 12px)", height: "calc(12.3% - 7px)", bottom: "54.3%", left: "85.5%" },
  {id: "box8", name:"",width: "calc(41.8% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "6.6%"},
  {id: "box9", name:"",width: "calc(43.8% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "43.7%" },
  {id: "box10",name:"",width: "calc(47.5% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "84.5%" },
  {id: "box11",name:"", width: "calc(41.8% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "128%" },
  {id: "box12",name:"", width: "calc(43.8% - 12px)", height: "calc(26% - 7px)", bottom: "14.68%", left: "165.8%" },

]

export const galleyComponentData2 = [
  {id: "box1",name:"", width: "calc(73.7% - 12px)", height: "calc(15.6% - 7px)", bottom: "81.2%", left: "130.8%"},
  {id: "box2",name:"",width: "calc(47.3% - 12px)", height: "calc(17.5% - 7px)", bottom: "64.5%", left: "60%"},
  {id: "box3", name:"",width: "calc(41.7% - 12px)", height: "calc(17.5% - 7px)", bottom: "64.5%", left: "102.7%" },
  {id: "box4",name:"", width: "calc(63.7% - 12px)", height: "calc(14.1% - 7px)", bottom: "67.6%", left: "139.8%" },
  {id: "box5",name:"", width: "calc(63.7% - 12px)", height: "calc(14.1% - 7px)", bottom: "54.2%", left: "139.8%" },
  {id: "box6",name:"", width: "calc(43.8% - 12px)", height: "calc(27.6% - 7px)", bottom: "19.68%", left: "37.5%"},
  {id: "box7",name:"", width: "calc(63.8% - 12px)", height: "calc(47.6% - 7px)", bottom: "6.68%", left: "80.5%" },
  {id: "box8",name:"",width: "calc(61.8% - 12px)", height: "calc(47.6% - 7px)", bottom: "6.68%", left: "140.5%" },
]

export const galleyComponentData3 = [
  {id: "box1",name:"", width: "calc(53.2% - 12px)", height: "calc(13.8% - 7px)", bottom: "80.2%", left: "109.5%" },
  {id: "box2",name:"", width: "calc(50.2% - 12px)", height: "calc(13.8% - 7px)", bottom: "80.2%", left: "159.2%"},
  {id: "box3",name:"",width: "calc(53.2% - 12px)", height: "calc(13.8% - 7px)", bottom: "66.5%", left: "109.5%" },
  {id: "box4",name:"", width: "calc(50.2% - 12px)", height: "calc(13.8% - 7px)", bottom: "66.5%",left: "159.2%"},
  {id: "box5",name:"", width: "calc(53.2% - 12px)", height: "calc(14.1% - 7px)", bottom: "52.3%", left: "109.5%" },
  {id: "box6",name:"",width: "calc(50.2% - 12px)", height: "calc(14.1% - 7px)", bottom: "52.3%", left: "159.2%" },
   {id: "box7",name:"", width: "calc(53.2% - 12px)", height: "calc(14.1% - 7px)", bottom: "52.3%", left: "61.8%" },
  {id: "box8", name:"",width: "calc(52.3% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "158.2%" },
  {id: "box9",name:"",width: "calc(52.9% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "108.5%" },
  {id: "box10",name:"", width: "calc(57.8% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "54.8%" },
  {id: "box11",name:"", width: "calc(43.8% - 12px)", height: "calc(26% - 7px)", bottom: "19.68%", left: "13.8%" },

]

export const galleyComponentData4 = [
  {id: "box13", name:"",width: "calc(53.1% - 12px)", height: "calc(13.8% - 7px)", bottom: "80.8%",  left: "93.5%" },
  {id: "box14", name:"",width: "calc(53.7% - 12px)", height: "calc(13.8% - 7px)", bottom: "80.8%", left: "142.8%"},
  {id: "box15",name:"",width: "calc(53.7% - 12px)", height: "calc(13.8% - 7px)", bottom: "67.2%", left: "93.5%"},
 {id: "box16", name:"",width: "calc(53.7% - 12px)", height: "calc(13.8% - 7px)", bottom: "67.2%", left: "142.8%" },
  {id: "box17",name:"", width: "calc(53.7% - 12px)", height: "calc(13.5% - 7px)", bottom: "53.3%", left: "93.5%" },
  {id: "box18",name:"",width: "calc(53.7% - 12px)", height: "calc(13.5% - 7px)", bottom: "53.3%", left: "142.8%"},
   {id: "box19",name:"", width: "calc(45.3% - 12px)", height: "calc(16.3% - 7px)", bottom: "59.9%", left: "52.5%" },
  // {id: "Galley8", width: "calc(57.3% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "300.5%"},
  {id: "box20", name:"",width: "calc(47.3% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "152.5%"},
  {id: "box21",name:"",width: "calc(47.3% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "108.5%" },
  {id: "box22",name:"", width: "calc(45.8% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "67.2%" },
  {id: "box23",name:"", width: "calc(50.3% - 12px)", height: "calc(32% - 7px)", bottom: "12.18%", left: "20.2%" },

]

export const galleyComponentData5 = [
  {id: "box24", name:"",width: "calc(26.2% - 12px)", height: "calc(16.8% - 7px)", bottom: "80.8%",  left: "45.5%" },
  {id: "box25", name:"",width: "calc(26.9% - 12px)", height: "calc(16.8% - 7px)", bottom: "80.8%", left: "68.8%"},
  {id: "box26",name:"",width: "calc(26.5% - 12px)", height: "calc(16.8% - 7px)", bottom: "80.8%", left: "92.3%"},
 {id: "box27", name:"",width: "calc(26.4% - 12px)", height: "calc(16.8% - 7px)", bottom: "80.8%", left: "116.8%" },
  {id: "box28",name:"",width: "calc(26.8% - 12px)", height: "calc(16.8% - 7px)", bottom: "80.8%", left: "140.8%" },
  {id: "box29",name:"",width: "calc(30.3% - 12px)", height: "calc(13.8% - 7px)", bottom: "63.8%", left: "18.5%"},
  {id: "box30",name:"", width: "calc(30.3% - 12px)", height: "calc(13.8% - 7px)", bottom: "50.8%", left: "18.5%" },

  {id: "box31",name:"", width: "calc(24.8% - 12px)", height: "calc(30.1% - 7px)", bottom: "51.3%", left: "46.5%"},
  {id: "box32", name:"",width: "calc(26.1% - 12px)", height: "calc(30.1% - 7px)", bottom: "51.3%", left: "68.8%"},
  {id: "box33",name:"",width: "calc(26.5% - 12px)", height: "calc(30.1% - 7px)", bottom: "51.3%", left: "92.3%" },
  {id: "box34",name:"", width: "calc(26.7% - 12px)", height: "calc(30.1% - 7px)", bottom: "51.3%", left: "116.4%" },
  {id: "box35",name:"", width: "calc(26.4% - 12px)", height: "calc(30.1% - 7px)", bottom: "51.3%", left: "140.8%" },

  {id: "box36", name:"",width: "calc(30.3% - 12px)", height: "calc(13.8% - 7px)", bottom: "63.8%",  left: "164.5%" },
  {id: "box37", name:"",width: "calc(30.3% - 12px)", height: "calc(13.8% - 7px)", bottom: "50.8%", left: "164.5%"},

   {id: "box38",name:"",width: "calc(24.8% - 12px)", height: "calc(16.8% - 7px)", bottom: "25.2%", left: "3.3%"},

  {id: "box39", name:"",width: "calc(24.3% - 12px)", height: "calc(18.1% - 7px)", bottom: "32.7%", left: "25.8%" },
  {id: "box40",name:"",width: "calc(24.3% - 12px)", height: "calc(15.3% - 7px)", bottom: "18.3%", left: "25.8%" },
  {id: "box41",name:"",width: "calc(24.3% - 12px)", height: "calc(16.1% - 7px)", bottom: "3.3%", left: "25.8%"},

  {id: "box42",name:"",  width: "calc(24.8% - 12px)", height: "calc(45.8% - 7px)", bottom: "5.9%", left: "48.2%" },
  {id: "box43",name:"", width: "calc(24.3% - 12px)", height: "calc(45.8% - 7px)", bottom: "5.9%", left: "70.7%"},

  {id: "box44", name:"", width: "calc(24.3% - 12px)", height: "calc(45.8% - 7px)", bottom: "5.9%", left: "92.8%"},
  {id: "box45",name:"", width: "calc(26.5% - 12px)", height: "calc(45.8% - 7px)", bottom: "5.9%", left: "114.8%" },
  {id: "box46",name:"", width: "calc(26.5% - 12px)", height: "calc(45.8% - 7px)", bottom: "5.9%", left: "139.3%" },

  {id: "box47",name:"",width: "calc(24.3% - 12px)", height: "calc(17.5% - 7px)", bottom: "32.6%", left: "163.5%" },
  {id: "box48",name:"", width: "calc(24.3% - 12px)", height: "calc(15.1% - 7px)", bottom: "18.3%", left: "163.5%" },
  {id: "box49",name:"",width: "calc(25.3% - 12px)", height: "calc(15.8% - 7px)", bottom: "3.3%", left: "163.5%" },

   {id: "box50",name:"", width: "calc(24.7% - 12px)", height: "calc(16.8% - 7px)", bottom: "25.2%", left: "185.5%" },
]
export const galleyComponentData6 = [
  {id: "box51", name:"",width: "calc(53.3% - 12px)", height: "calc(14.3% - 7px)", bottom: "80.8%",  left: "103.7%" },
  {id: "box52", name:"",width: "calc(53.3% - 12px)", height: "calc(14.3% - 7px)", bottom: "80.8%", left: "152.8%"},

  {id: "box53",name:"",width: "calc(53.3% - 12px)", height: "calc(14.3% - 7px)", bottom: "67.2%", left: "103.7%"},
  {id: "box54", name:"",width: "calc(53.3% - 12px)", height: "calc(14.3% - 7px)", bottom: "67.2%", left: "152.8%" },

  {id: "box55",name:"", width: "calc(53.3% - 12px)", height: "calc(14.5% - 7px)", bottom: "53.3%", left: "103.7%" },
  {id: "box56",name:"",width: "calc(53.3% - 12px)", height: "calc(14.5% - 7px)", bottom: "53.3%", left: "152.8%"},

   {id: "box57",name:"", width: "calc(44.8% - 12px)", height: "calc(16.3% - 7px)", bottom: "59.9%", left: "63.4%" },

  {id: "box58", name:"",width: "calc(51.1% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "153.2%"},

  {id: "box59",name:"",width: "calc(46.5% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "110.1%" },
  {id: "box60",name:"", width: "calc(46.5% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "67.2%" },
  {id: "box61",name:"", width: "calc(50.5% - 12px)", height: "calc(32% - 7px)", bottom: "12.18%", left: "20.2%" },

]
export const galleyComponentData7 = [
    {id: "box62",name:"",width: "calc(68.3% - 12px)", height: "calc(33.8% - 7px)", bottom: "53.2%", left: "7.2%"},
    {id: "box63", name:"",width: "calc(48.7% - 12px)", height: "calc(20.8% - 7px)", bottom: "58.2%", left: "69.8%" },
    {id: "box64",name:"",width: "calc(55.3% - 12px)", height: "calc(36% - 7px)", bottom: "6.68%", left: "130.5%" },
    {id: "box65",name:"", width: "calc(65.4% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "69.8%" },
    {id: "box66",name:"", width: "calc(68.3% - 12px)", height: "calc(46% - 7px)", bottom: "6.68%", left: "7.2%" },
]
export const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#0C5061" : "#fff",
    backgroundColor: state.isSelected ? "#fff" : "#162E3E",
  }),
  input: provided => ({
    ...provided,
    color: '#fff'
  }),
  control: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: "#162E3E",
    padding: "3px",
    width: '17rem',
    border: "0.4px solid #FFFFFF",
    boxShadow: "none",
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff",height:'1rem',
  paddingTop:'5px'}),

};

export const customSelSearch={
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    position: "relative",
    zIndex: state.isOpenMenu ? 9999 : null,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999, // Set a high z-index for the portal
  }),
  input: (defaultStyles) => ({
        ...defaultStyles,
        color: '#fff',
        }),
        control: (base) => ({
          ...base,
          minHeight: '38px',
          height: '38px',
          borderColor: '#fff',
          border:'0.7px solid #ffff',
          borderRadius: '3px',
          backgroundColor: '#162c3b',
          '&:hover': {
            borderColor: '#fff'
          },
        }),
        placeholder: (provided,state) => ({
          ...provided,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color:  state.isFocused ? '#6e7c84' : '#fff',
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none', // Removing the indicator separator
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: '#fff',
        }),
}

export const ORDER_STATUS_LIST = [
  { key: 'Cancelled Order', value: 'Cancelled', colorCode_text: '#DC143C' },
  { key: 'Accepted Order', value: 'Accepted', colorCode_text: '#008000' },
];
export const INSTANCE_STATE_LIST = [
  { key: 'locked', value: 'Locked', colorCode_text: '#cc0000' },
  { key: 'open', value: 'Active', colorCode_text: '#00ffff' },
  { key: 'committed', value: 'Committed', colorCode_text: '#33cc33' },
  { key: 'commitScheduled', value: 'Commit Scheduled', colorCode_text: '#e68a00' },
];

export const STATUS_STATE_LIST=[{key: 'confirmed', label: "Confirmed", value: 'confirmed',colorCode_text: '#70CEEA' },{key: 'created', label: "Pending", value: 'created',colorCode_text: '#e68a00'},{key: 'completed', label: "Processed", value: 'completed',colorCode_text: '#33cc33'},{key: 'cancelled', label: "Cancelled", value: 'cancelled',colorCode_text: '#cc0000'}]

/**
* saleTypeRaw
*/
export const CATERER_STATUS_LIST = [
  { key: 'Rejected', value: 'Rejected', colorCode_text: '#DC143C' },
  { key: 'Accepted', value: 'Accepted', colorCode_text: '#008000' },
  { key: 'Clarification', value: 'Clarification', colorCode_text: '#3dabf5' },

];
export const LOI_STATUS_LIST = [
  { key: 'suspended', value: 'Suspended', colorCode_text: '#DC143C' },
  { key: 'finalized', value: 'Finalized', colorCode_text: '#fc7f03' },
  { key: 'approved', value: 'Approved', colorCode_text: '#008000' },
  { key: 'created', value: 'Draft', colorCode_text: '#3dabf5' },
];

export const ORDER_SALE_TYPE_LIST = [
  { key: 1, label: 'Buy On Board', value: 'buyOnBoard' },
  { key: 2, label: 'Pre-Booked', value: 'preBooked' },
  { key: 3, label: 'Corporate Meal', value: 'corporate' },
  { key: 4, label: 'Corporate Meal CRPX', value: 'corporateCRPX' },
  { key: 5, label: 'Corporate Meal CRLM', value: 'corporateCRLM' },
  { key: 6, label: 'Cabin Crew', value: 'cabinCrew' },
  { key: 7, label: 'Gratis Meal', value: 'gratisMeal' },
  { key: 8, label: 'Group', value: 'group' },
  { key: 9, label: 'Cockpit Crew', value: 'cockpitCrew' },
  { key: 10, label: 'Sky Marshal', value: 'skyMarshal' },
  { key: 11, label: 'AME', value: 'AME' },
  { key: 12, label: 'Delayed / Diverted', value: 'delayedDiverted' },
  { key: 13, label: 'Complimentary', value: 'complimentary' },
  { key: 14, label: 'Offers / Promotions', value: 'promotions' },
  { key: 15, label: 'Service Recovery', value: 'serviceRecovery' },
  { key: 16, label: 'airportBundle', value: 'airportBundle' },
  { key: 17, label: 'FLXI', value: 'flxi ' },
  { key: 18, label: 'PLUS', value: 'plus' },
  { key: 19, label: 'Seat Upgrade', value: 'seatUpgrade' },
  { key: 20, label: 'Duty Free', value: 'dutyFree' }
];

export const ORDER_SALE_TYPE_LIST_DYNAMIC =[
  { key: 1, label: 'Buy On Board', value: 'buyOnBoard' },
  { key: 2, label: 'Pre-purchased', value: 'preBooked' },
  { key: 3, label: 'Corporate Meal', value: 'corporate' },
  { key: 4, label: 'Corporate Meal CRPX', value: 'corporateCRPX' },
  { key: 5, label: 'CRLM', value: 'corporateCRLM' },
  { key: 6, label: 'Cabin Crew', value: 'cabinCrew' },
  { key: 7, label: 'Gratis Meal', value: 'gratisMeal' },
  { key: 8, label: 'Group', value: 'group' },
  { key: 9, label: 'Cockpit Crew', value: 'cockpitCrew' },
  { key: 10, label: 'Sky Marshal', value: 'skyMarshal' },
  { key: 11, label: 'AME', value: 'AME' },
  { key: 12, label: 'Delayed / Diverted', value: 'delayedDiverted' },
  { key: 13, label: 'Complimentary', value: 'complimentary' },
  { key: 14, label: 'Offers / Promotions', value: 'promotions' },
  { key: 15, label: 'Service Recovery', value: 'serviceRecovery' },
  { key: 16, label: 'Airport Bundle', value: 'airportBundle' },
  { key: 17, label: 'FLXI', value: 'flxi ' },
  { key: 18, label: 'PLUS', value: 'plus' },
  { key: 19, label: 'Seat Upgrade', value: 'seatUpgrade' },
  { key: 20, label: 'INAD', value: 'inad' },
  { key: 21, label: 'IROPS', value: 'irops' }
];

export const ORDER_STATUS_DYNAMIC = [
  {
    key: 1,
    value: 'accepted',
    colorCode_text: '#70ceea',
    label: 'Accepted'
  },
  {
    key: 2,
    value: 'cancelled',
    colorCode_text: '#70ceea',
    label: 'Cancelled'
  },
  {
    key: 3,
    value: 'denied',
    colorCode_text: '#70ceea',
    label: 'Denied Demand'
  }
];

export const ORDER_PAYMENT_TYPE_LIST = [
  { key: 1, label: 'SSR', value: 'none' },
  { key: 2, label: 'Cash', value: 'cash' },
  { key: 3, label: 'Card', value: 'card' },
  { key: 4, label: 'Multiple', value: 'multiple' }
];
