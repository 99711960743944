import React, { useEffect, useState } from 'react';
import { APIEndpoint } from '../../../utils/axios';
import { IoPerson } from "react-icons/io5";
import LoadingGIF from '../../../assets/loadingIn.gif';
import { IoArrowForwardOutline } from "react-icons/io5";
import moment from 'moment';
import {formatCamelString} from '../../../utils/commonFunctions';
import { CollapsebleSectorInstance } from '../../../common';

function SectorInstanceCard(props) {
  const [sectors, setSectors] = useState(()=>{
    const secDyo=sessionStorage.getItem('sectorsDynm');
    return secDyo?JSON.parse(secDyo):[];
  });
  const [sectorIsolateData, setSectorIsolateData] = useState(()=>{
    const secIso=sessionStorage.getItem('secISoDynamic');
    return secIso?JSON.parse(secIso):{};
  });
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      if(Object.keys(sectorIsolateData).length){
        sessionStorage.setItem('secISoDynamic',JSON.stringify(sectorIsolateData))
      }
  }, [sectorIsolateData])


  const getSectorData = async (data,reload=false) => {
    if((Object.keys(sectorIsolateData).length||sectors.length) && !reload)return;
    setLoading(true);
    const { id } = data;
    try {
      const res = await APIEndpoint.get(`sandboxing/containerIsolates/${id}`);
      if (res.isolate) {
        setSectorIsolateData((prev) => ({ ...prev, ...res.isolate }));
        const sectData = res.isolate.sectors.map(async (sec) => {
          const res = await APIEndpoint.get(
            `sandboxing/isolatedSectors/${sec.id}?mutableOnly=${true}`
          );
          return res.sector || null;
        });
        const resolvedSectors = await Promise.all(sectData);
        setSectors(resolvedSectors.filter((sector) => sector !== null));
        sessionStorage.setItem('sectorsDynm',JSON.stringify(resolvedSectors.filter((sector) => sector !== null)))
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handleView = (id) => {
    setSelectedItemIndex(prevIndex => (prevIndex === id ? null : id));
  };

  useEffect(() => {
    if (props.header) {
      const { header } = props;
      getSectorData(header,false);
    }
  }, [props]);
  useEffect(() => {
    if (props.header) {
      const { header } = props;
      getSectorData(header,true);
    }
  }, [props.reload]);

  const CrewLabels = (list) => {
    if (list.length) {
      return (
        <div className="crew-container">
          {list.map((item, index) => (
            <div className="employee-crew" key={item.employeeId}>
              <div className='flexRow'>
                {item.employeeName}&nbsp;({item.role})
              </div>
              <div className='flexRow'>{item.employeeCode}</div>
            </div>
          ))}
        </div>
      );
    } else {
      return "No crew members added yet";
    }
  }
const upliftsSectors=(sect,list)=>{


  if (list.length) {
    return (
      <div className="flexRow">
        {list.map((item, index) => (
          <button key={index} className="status-dynamic" onClick={()=>props.inventoryDetails(sect,index,'uplifts',null,sectorIsolateData?.sectors[index])} style={{ width:list.length<=1?'17rem':'10rem',marginRight: '2px',whiteSpace:'nowrap',backgroundColor: '#06cc48' }}>Uplift from {item.warehouse?.warehouseCode}</button>
        ))}
      </div>
    );
  } else {
    return null;
  }
}
  const getSectorStatus = (sec) => {
    switch (sec) {
      case 'open':
        return <div className="status-instance" style={{ backgroundColor: '#03a5fc' }}>Active</div>;
      case 'locked':
        return <div className="status-instance" style={{ backgroundColor: '#f03630' }}>Locked</div>;
        case 'cancelled':
        return <div className="status-dynamic" style={{ backgroundColor: '#f03630' }}>Cancelled</div>;
        case 'unknown':
        return <div className="status-dynamic" style={{ backgroundColor: '#1d3f4f' }}>Unknown</div>;
      case 'committed':
        return <div className="status-instance" style={{ backgroundColor: '#09ab16' }}>Committed</div>;
        case 'completed':
          return <div className="status-dynamic" style={{ backgroundColor: '#09ab16' }}>Completed</div>;
      case 'commitScheduled':
        return <div className="status-instance" style={{ backgroundColor: '#f28016' }}>Commit Scheduled</div>;
      default:
        return "";
    }
  };


    const getSyncStatus = (sec) => {
      switch (sec) {
        case 'syncPending':
          return <div className="status-dynamic" style={{ backgroundColor: '#f03630' }}>{formatCamelString(sec)}</div>;
          case 'partiallySynced':
          return<><div className="status-dynamic" style={{ backgroundColor: '#ff9d15' }}>{formatCamelString(sec)}</div>&nbsp;<span>0%</span></> ;
          case 'synced':
          return<><div className="status-dynamic" style={{ backgroundColor: '#09ab16' }}>{formatCamelString(sec)}</div>&nbsp;<span>100%</span></> ;
        default:
          return <div className="status-dynamic" style={{ backgroundColor: '#1d3f4f' }}>{formatCamelString(sec)}</div>;
      }
    };


  useEffect(() => {
    const timer = setTimeout(() => {
      if (sectors.length === 0 && loading) {
        setLoading(false);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [sectors, loading]);

  return (
    <ul className='list-group' style={{ display: 'flex', flexWrap: 'wrap' }}>
      {loading ? (
        <li className='flexRow justifyContentCenter alignitemsCenter'>
          <div className='flexRow'>
            <img src={LoadingGIF}  className='icon-size' style={{width:'25px',height:'25px'}} alt="Loading" />
            <div className="margTop8">Please wait while we fetch currently attached data for this instance...</div>
          </div>
        </li>
      ) : sectors.length && sectorIsolateData.sectors ? (
        sectorIsolateData?.sectors?.map((item, index) => (
          <React.Fragment key={index}>
            <li
              className={`flexRow col-xs-11 wrapper-sector justifyContentSpaceBetween ${
                index === selectedItemIndex ? 'selected' : ''
              }`}
              onClick={() => handleView(item.id)}
              style={{ cursor: 'pointer' }}
            >
              <div className="flexRow justifyContentCenter alignItemsCenter">
                <span className="justifyContentFlexStart instance-member fontExtrabold">{item.key.split("/")[0]}</span><span className="margLeft5 fontSemibold">{item.key.split("/")[1]}</span>
              </div>
              <div className="flexRow">
                <span className="justifyContentFlexEnd instance-member">
                  <IoPerson size={15} style={{ marginRight: '7px' }} />
                  {item.createdBy.employeeName}
                  {getSectorStatus(sectorIsolateData.state)}
                </span>
              </div>
            </li>
            {selectedItemIndex === item.id && (
              <CollapsebleSectorInstance id={item.id} selectedItemIndex={selectedItemIndex}>
              <div className='fitcontent justifyContentSpaceBetween col-xs-11 wrapper-sector'>
              {sectors[index].originalSector?<div className='flexRow alignItemsCenter textAlignLeft sector-origin-m'>This sector was created because original sector<span className='code'>{sectors[index].originalSector.name}</span> was locked at the time of sync.</div>:null }
              <div className='flexRow sector-banner justifyContentSpaceBetween'>
                <div className='flexRow justifyContentFlexStart'>
                   <div className='sector-number fontMedium'>{item.flightNumber}</div>
                   <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow sector-tag'>{item.origin}</div>
                <div className='flexRow fontMedium'>{item.originName}</div>
                </div>
                </div>

                <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow'> <IoArrowForwardOutline size={17} color={'#F06548'}/></div>
                <div className='flexRow fontMedium'>{item.key.split("/")[1]}</div>
                </div>
               <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow sector-tag'>{item.destination}</div>
                <div className='flexRow fontMedium'>{item.destinationName}</div>
                </div>
              </div>
              <div className='flexRow fitContent'>
                  <div style={{float:'left'}} className="margTop8 flexCol width60 justifyContentFlexStart">
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Sector ID</label>
                  <div>{item.name}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Operation Status</label>
                  <div>{getSectorStatus(sectors[index]?.operationStatus)}&nbsp;{sectors[index]?.cancellationReason?sectors[index]?.cancellationReason.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^([a-z])/, (match) => match.toUpperCase()):""}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Created At</label>
                  <div>{item.createdAt?moment(item.createdAt).format('DD-MM-YYYY hh:mm A'):'Not available'}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Created By</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {item.createdBy.employeeName}
                    </div>
                    <div className='flexRow'>
                    {item.createdBy.employeeCode}
                    </div>
                    </div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Created Using</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {item.userAgent.appName}@ {item.userAgent.appVersion}
                    </div>
                    <div className='flexRow'>
                    {item.userAgent.deviceModel},&nbsp;{item.userAgent.macId?`MAC ID:${item.userAgent.macId}`:item.userAgent.deviceName}
                    </div>
                    </div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Started At</label>
                  <div>{sectors[index].startedAt?moment(sectors[index].startedAt).format('DD-MM-YYYY hh:mm A'):'Not Available'}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Ended At</label>
                  <div>{sectors[index].endedAt?moment(sectors[index].endedAt).format('DD-MM-YYYY hh:mm A'):"Not Available"}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Price List</label>
                  <div>{sectors[index].priceList}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Seat Price List</label>
                  <div>{sectors[index].seatPriceListName||'Unknown'}</div>
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Event Log</label>
                  <button className="status-dynamic dynamic-sl" onClick={()=>props.eventLogs(item,sectorIsolateData?.sectors[0])}>View Event Logs</button>
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Sales Summary</label>
               <button className="status-dynamic dynamic-sl" onClick={()=>props.inventoryDetails(sectors[index],null,'salesSummary',null,sectorIsolateData?.sectors[0])}>{item?.name}</button>

                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>PAX Reported by Crew </label>
                  <div>{sectors[index].pax??'Not available'}</div>
                </div>
                <div className=''>
                  <label className='fontExtrabold'>Crew</label>
                  <div>{CrewLabels(sectors[index].crew)}</div>
                </div>

              </div>
              <div className="flexCol width40">

              <div className='margBottom10'>
                  <label className='fontExtrabold'>Sync Status</label>
                  <div>{getSyncStatus(sectors[index].syncStatus)}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Synced At</label>
                  <div>{sectors[index].syncedAt?moment(sectors[index].syncedAt).format('DD-MM-YYYY hh:mm A'):'Not available'}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Number of Orders To Sync</label>
                  <div>{sectors[index].numberOfItemsToSync??'Not available'}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Number of Orders Synced</label>
                  <div>{sectors[index].numberOfItemsSynced??"Not available"}</div>
                </div>


                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Uplifts</label>
               {  sectors[index]?.uplifts.length? <>{upliftsSectors(sectors[index],sectors[index]?.uplifts)}</>:"None"}
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Transfer In</label>
                <button className="status-dynamic" onClick={()=>props.inventoryDetails(sectors[index],null,'transferIn',null,sectorIsolateData?.sectors[index])} style={{ width:'17rem',whiteSpace:'nowrap',backgroundColor: '#06cc48' }}>{item?.name}</button>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Offloads</label>
                  <div>{sectors[index]?.offloads||'None'}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Wastages </label>
                  <div>{sectors[index]?.wastages||'None'}</div>
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Orders</label>
                  {  sectors[index].state!=='committed'&&item?.name?  <button className="status-dynamic dynamic-act" onClick={()=>props.inventoryDetails(sectors[index],null,'orders',null,sectorIsolateData?.sectors[index])}>{item?.name}</button>:<>None</>}

                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Transfer Out</label>
               { sectors[index]?.transferOutReport?  <button className="status-dynamic dynamic-act" onClick={()=>props.inventoryDetails(sectors[index],null,'transferOut',null,sectorIsolateData?.sectors[index])}>{sectors[index]?.transferOutReport?.sector?.name}</button>:<>None</>}
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Uplift Audit Report  </label>
                  { sectors[index]?.upliftAuditReport?  <button className="status-dynamic dynamic-upt" onClick={()=>props.inventoryDetails(sectors[index],null,'upliftAudit',null,sectorIsolateData.sectors[index])}>{sectors[index]?.upliftAuditReport?.sector?.name}</button>:<>None</>}
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Transfer Audit Report </label>
                  { sectors[index]?.transferAuditReport?  <button className="status-dynamic dynamic-act" onClick={()=>props.inventoryDetails(sectors[index],null,'transferAudit',null,sectorIsolateData.sectors[index])}>{sectors[index]?.transferAuditReport?.sector?.name}</button>:<>None</>}
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Stock Movement Overview </label>
                 {item?.name? <button className="status-dynamic dynamic-sl" onClick={()=>props.inventoryDetails(sectors[index],null,'stockMvmnt',null,sectorIsolateData?.sectors[index])}>{item?.name||"None"}</button>:<>None</>}
                </div>
              </div>


              </div>
            </div>
              </CollapsebleSectorInstance>
            )}
          </React.Fragment>
        ))
      ) : (
        <li className='flexRow'>
          <div className='flexRow col-xs-11 wrapper-sector justifyContentCenter alignitemsCenter'>No sectors attached to this instance yet.</div>
        </li>
      )}
    </ul>
  )
}

export default SectorInstanceCard;