import React, { useState } from 'react';
import { DataGrid } from '../../../common';
import { useTranslation } from 'react-i18next'
const Columns = [
  {field:"slno",headerName:"SL No", flex:1, suppressSizeToFit:true},
  {field:"errMsg",headerName:"Error Message", flex:1, suppressSizeToFit:true},
  {field:"errType",headerName:"Error Type", flex:1, suppressSizeToFit:true}
]

const rowData = [
  {
    slno: "01",
    errMsg: "New SSR found, configure it under SSR tab",
    errType: "SSR"
  },
  {
    slno: "02",
    errMsg: "Flight delay by 30 minutes",
    errType: "IROP"
  },
  {
    slno: "03",
    errMsg: "Flight diverted",
    errType: "IROP"
  },
  {
    slno: "04",
    errMsg: "PSS API failure",
    errType: "API"
  },
  {
    slno: "05",
    errMsg: "Crew Rostering System API failure",
    errType: "API"
  },
  {
    slno: "06",
    errMsg: "Error message 6",
    errType: "SSR"
  },
  {
    slno: "07",
    errMsg: "Error message 7",
    errType: "IROP"
  },
  {
    slno: "08",
    errMsg: "Error message 8",
    errType: "SSR"
  },
  {
    slno: "09",
    errMsg: "Error message 9",
    errType: "API"
  },
  {
    slno: "10",
    errMsg: "Error message 10",
    errType: "API"
  },
  {
    slno: "11",
    errMsg: "Error message 11",
    errType: "SSR"
  },
  {
    slno: "12",
    errMsg: "Error message 12",
    errType: "IROP"
  },
  {
    slno: "13",
    errMsg: "Error message 13",
    errType: "API"
  },
  {
    slno: "14",
    errMsg: "Error message 14",
    errType: "IROP"
  },
  {
    slno: "15",
    errMsg: "Error message 15",
    errType: "API"
  },
  {
    slno: "16",
    errMsg: "Error message 16",
    errType: "SSR"
  },
  {
    slno: "17",
    errMsg: "Error message 17",
    errType: "IROP"
  },
  {
    slno: "18",
    errMsg: "Error message 18",
    errType: "IROP"
  },
  {
    slno: "19",
    errMsg: "Error message 19",
    errType: "SSR"
  },
  {
    slno: "20",
    errMsg: "Error message 20",
    errType: "API"
  },
  {
    slno: "21",
    errMsg: "Error message 21",
    errType: "SSR"
  },
  {
    slno: "22",
    errMsg: "Error message 22",
    errType: "IROP"
  },
  {
    slno: "23",
    errMsg: "Error message 23",
    errType: "API"
  },
  {
    slno: "24",
    errMsg: "Error message 24",
    errType: "API"
  },
  {
    slno: "25",
    errMsg: "Error message 25",
    errType: "IROP"
  },
  {
    slno: "26",
    errMsg: "Error message 26",
    errType: "SSR"
  },
  {
    slno: "27",
    errMsg: "Error message 27",
    errType: "IROP"
  },
  {
    slno: "28",
    errMsg: "Error message 28",
    errType: "SSR"
  },
  {
    slno: "29",
    errMsg: "Error message 29",
    errType: "API"
  },
  {
    slno: "30",
    errMsg: "Error message 30",
    errType: "SSR"
  },
  {
    slno: "31",
    errMsg: "Error message 31",
    errType: "API"
  },
  {
    slno: "32",
    errMsg: "Error message 32",
    errType: "SSR"
  },
  {
    slno: "33",
    errMsg: "Error message 33",
    errType: "SSR"
  },
  {
    slno: "34",
    errMsg: "Error message 34",
    errType: "API"
  },
  {
    slno: "35",
    errMsg: "Error message 35",
    errType: "IROP"
  }
];


export const SSRpage = (props) => {
  const [rows, setRows] = useState(rowData)
  const { ready } = useTranslation();

  function errorTypeChangeHandler(e) {
    const {value} = e.target
    if(value){
      const rows = rowData.filter(row=>row.errType === value)
      setRows([...rows])
    }else{
      setRows([...rowData])
    }
  }



  return (
    <div className="flexCol full-flex ag-theme-alpine-dark">
      <div className='flexRow margTop8 margBot10'>
        <select className='select-item' name="errorType" id="" onChange={errorTypeChangeHandler}>
          <option value="">Error Type</option>
          <option value="IROP">IROP</option>
          <option value="API">API Failure</option>
          <option value="SSR">SSR</option>
        </select>
      </div>
      <div className='flexCol full-flex'>
        <DataGrid
        className="full-flex"
        columns={[...Columns]}
        // actionProperties={actionColumnProperties}
        showDefaultFilters={false}
        rowModelType="clientSide"
        // actions={actionItems}
        rowData={rows}
        columnResize={true}
        rowSelection="multiple"
        // getActionCellIcons={getActionCellIcons}
        // onAction={(e, action, data) => {
        //   return onAction(e, action, data);
        // }}
      />
      </div>
      {
       !ready &&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}