/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import ReactSpreadsheet from "react-spreadsheet"; // , { createEmptyMatrix }
import * as ReactDOM from 'react-dom';
import '../../../assets/styles/styles.scss'
import { APIEndpoint } from "../../../utils/axios";

import {AlertModal } from "../../../common";
import { validateCellAndGetData } from "../../../utils/commonFunctions";
import { DEFAULT_SHEET_ROWS_PLIFERAGE } from "../../../utils/configs";
import { useTranslation } from "react-i18next";

const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    PERCENTAGE: 2,

};
const sheetLabels = ['Item Code', 'Item Name', 'Percentage'];

const defaultCellFieldData = {
    id: 'id',
    value: '',
    defaultValue: '',
    className: 'cellClass',
    width: '200px',
    readOnly: false,
    type: 'string',
    required: true,
    showPopup: false
};

const createEmptySheetRows = (noOfRows = DEFAULT_SHEET_ROWS_PLIFERAGE) => {
    const rows = [];
    for( var i=0; i < noOfRows; i++) {
        rows.push([
            {
                ...defaultCellFieldData,
                id: 'itemCode',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_CODE,
                showPopup: true
            },
            {
                ...defaultCellFieldData,
                id: 'itemName',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_NAME,
                showPopup: true
            },
            {
                ...defaultCellFieldData,
                id: 'percentage',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.PERCENTAGE,
                width: '400px',
                required:false,
                readOnly: false,
            }
        ]);
    }
    return rows;
}

/**
 * Automatically creating rows
 */
// const initialData = createEmptyMatrix(10, sheetLabels.length);
/**
 * Manually creating rows
 */
const initialData = createEmptySheetRows( DEFAULT_SHEET_ROWS_PLIFERAGE );

export const CorporateSegmentCreate = (props) => {
    const urlParams = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [warehouseItemsFull, setWarehouseItemsFull] = useState([]);
    const [warehouseItemsFiltered, setWarehouseItemsFiltered] = useState([]);
    const [, setEditTransferDetails] = useState({});
    const [state, setState] = useState({ isInvalidAccess: false, message: '', isEditForm: false });
    const [sheetData, setSheetData] = useState(initialData);
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const modelStyle = { maxWidth: '50%', margin: 'auto 10% 100% 10%' };
    const [activeCell, setActiveCell] = useState({});
    const [selectedCellsArr, setSelectedCellsArr] = useState([]);

    const targetEltCodes = document.getElementsByClassName("cellSelectionBlockItemCodes")[0];
    const targetEltNames = document.getElementsByClassName("cellSelectionBlockItemNames")[0];
    useEffect( () => {

      if ( urlParams.hasOwnProperty('id') ) {
            setState( { ...state, isEditForm: false } );
            getWarehouseItems(urlParams.id);
            setState((_)=>{return{..._,data:urlParams.id}})
        }
    }, [ urlParams ]);

    const getWarehouseItems = async(segment) => {
        APIEndpoint.post('estimation/corporatesegment/corporateitems')
        .then( resp => {
            if (resp) {
                const warehouseItems = resp.items;
                setWarehouseItemsFull(warehouseItems);
                setWarehouseItemsFiltered(warehouseItems);
            }
        })
        .catch( error => {
            setState({ ...state, isInvalidAccess: true,
                message: "Invalid page access!, Segment Items are not found, On click OK, you will be redirected to Corporate Segment page." });
        });
        APIEndpoint.post(`estimation/corporatesegment/items/get/${segment}`)
        .then( resp => {
            if (resp.items.length) {
                setEditTransferDetails(resp.items)
                setEditTransferItemsToSheet(resp.items)
                setState( { ...state, isEditForm: true } );
            }
        })
        .catch( error => {
            setState({ ...state, isInvalidAccess: true,
                message: "Invalid page access!, Segment Items are not found, On click OK, you will be redirected to Corporate Segment page." });
        });

    };


    const setEditTransferItemsToSheet = (transferDetails) => {
        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
        const items = transferDetails;

        items.map( (item, i) => {
            updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = item.itemCode;
            updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = item.itemName;
            updatedSpreadsheetData[i][iIndexes.PERCENTAGE].value = item.percentage;


            return item;
        });

        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);

        });

    };

    const addRowsToSheet = (rowsToAdd = 10) => {
        const newRows = createEmptySheetRows(rowsToAdd);
        setSheetData( [...sheetData, ...newRows ]);
    };

    const removeRow = (e) => {

        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let inSheetData = JSON.parse(jsonSpreadsheetData);

        selectedCellsArr.map( selectedCell => {

            const defaultVal = inSheetData[selectedCell.row][selectedCell.column].defaultValue;

            inSheetData[selectedCell.row][selectedCell.column].value = defaultVal || '';

            return selectedCell;
        });

        ReactDOM.flushSync( () => {
            setSheetData([ ...inSheetData ]);
        });

		setSelectedCellsArr([]);

    };
    const showValidationErrors = (e, inSheetData, errorClass='cellDataErrorCls' ) => {
        let haveErrors = false;
        const postItems = [];

        inSheetData.map( (cellRow, rowIdx) => {
            let isRowHaveError = false;

            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE]);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME]);

            if (!itemCode || !itemName) {
                return cellRow;
            }

            for (let colIdx = 0; colIdx < Object.keys(iIndexes).length; colIdx++) {

                if ( cellRow[colIdx].required === true) {

                    const fieldValue = validateCellAndGetData(cellRow[colIdx]);
                    if ( !fieldValue ) {
                        haveErrors = true;
                        isRowHaveError = true;
                    }
                }
            }
            if ( !isRowHaveError &&warehouseItemsFull.length ) {

                const item = warehouseItemsFull?.find( row => row.itemCode === cellRow[iIndexes.ITEM_CODE].value );
                if (item) {
                    postItems.push({
                        // _id: item._id,
                        index: rowIdx,
                        // itemId: item.itemId,
                        itemCode: item.itemCode, // cellRow[iIndexes.ITEM_CODE].value,
                        itemName: item.itemName, // cellRow[iIndexes.ITEM_NAME].value,
                        percentage: item.percentage, // cellRow[iIndexes.ITEM_UNIT].value,

                    });
                }

            }
            return cellRow;
        });

        if (haveErrors) {
            setSpreadsheetErrors([ { message: 'Please fill all required item details.' } ]);
        }

        return {
            sheetData: inSheetData,
            validRows: postItems,
            haveErrors
        };

    };
    const saveSheetData = (e) => {
        setSpreadsheetErrors([]);

        let inSheetData = Object.assign([], sheetData);
        const respArrayData = showValidationErrors(e, inSheetData);

        if (!spreadsheetErrors.length && respArrayData.validRows.length === 0 &&!respArrayData.haveErrors) {
            // show global alert message
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'We did not find anything to save!' } ]);
            return false;
        }

        if ( respArrayData.haveErrors ) {
            return false;
        }

        if ( !respArrayData.validRows.length ) {
            return false;
        }

        const itemCodeToPercentage = {};
        respArrayData.sheetData.forEach(row => {
            const itemCodeCell = row.find(cell => cell.id === "itemCode");
            const percentageCell = row.find(cell => cell.id === "percentage");

            if (itemCodeCell && percentageCell && itemCodeCell.value) {
                itemCodeToPercentage[itemCodeCell.value] = Number(percentageCell.value);
            }
        });

        respArrayData.validRows.forEach(row => {
            const percentageValue = itemCodeToPercentage[row.itemCode];
            if (percentageValue !== undefined) {
                row.percentage = Number(percentageValue);
            }
        });
        const data = {
            items: respArrayData.validRows || []
        }

        if(state.isEditForm||!state.isEditForm){APIEndpoint.post(`estimation/corporatesegment/update/items/${urlParams.id}`, data)
        .then( apiResp => {
            setSheetData(initialData);
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiResp.msg || 'Successfully saved Items.' } ]);
            setTimeout( () => {
                goToModuleHome();
            }, 2500);
        })
        .catch( (error) => {
            const apiData = error.data;
            setSpreadsheetErrors([]);

            ReactDOM.flushSync( () => {
                if ( apiData.errors ) {
                    const errorMsg = apiData.errors.errors[0].message;
                    setSpreadsheetErrors([ ...spreadsheetErrors, { message: errorMsg, error: 1 } ]);
                }
                else if (apiData.msg) {
                    setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiData.msg, error: 1 } ]);
                }
                else {
                    setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'Something went wrong, please try again later!', error: 1 } ]);
                }
            });

        });}


        return false;
    };


    const handleActiveCellChange = (activeCell) => {
        setActiveCell({});
        hideItemListPopup();
        setActiveCell(activeCell);

        if ( sheetData[activeCell.row][activeCell.column] ) {
            const cellDetailsObj = sheetData[activeCell.row][activeCell.column];
            if ( cellDetailsObj.hasOwnProperty('showPopup') && cellDetailsObj.showPopup === true ) {

                const sourceElt = document.getElementsByClassName('cellClass_' + activeCell.row + '_' + activeCell.column)[0];
                if ( sourceElt ) {
                    const eltPosition = sourceElt.getBoundingClientRect();

                    showItemListPopup(eltPosition, activeCell);
                }

            }

        }

    }
    const handleCellBlur = () => {
        // setActiveCell({});
    }

    const handleCellKeyDown = (keyboardEvent) => {
        const searchText = keyboardEvent.target.value;

        if (activeCell && Object.keys(activeCell).length > 0) {
            if (sheetData[activeCell.row][activeCell.column].type === 'number' &&  keyboardEvent.target.value ) {
                keyboardEvent.target.value = keyboardEvent.target.value.replace(/[^0-9]$/g, '');
            }
        }

        // const eltPosition = keyboardEvent.target.getBoundingClientRect();
        // showItemListPopup(eltPosition);

        /**
         * Filter poup items
         */
        const searchPattern = new RegExp((searchText || '').replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&'),'gi');
        const filteredList = warehouseItemsFull.filter( row => row.itemCode.search( searchPattern ) !== -1 );
        setWarehouseItemsFiltered([ ...filteredList ]);
    }

    const showItemListPopup = (eltPosition, activeCellIn) => {
        if (activeCellIn.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'block';
                targetEltCodes.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltCodes.style.left = eltPosition.left.toFixed() + 'px';
            }
        } else if (activeCellIn.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'block';
                targetEltNames.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltNames.style.left = eltPosition.left.toFixed() + 'px';
            }
        }
    }

    const hideItemListPopup = () =>{
        // if (activeCell.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'none';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'none';
            }
        // }
    }

    const onClickPopupListItem = (e, row) => {
        setWarehouseTransferItemToSheet(row);
    }

    const setWarehouseTransferItemToSheet = (item) => {

        if (Object.keys(item).length === 0) {
            return 0;
        }

        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                sheetRow[iIndexes.ITEM_CODE].value = item.itemCode;
                sheetRow[iIndexes.ITEM_NAME].value = item.itemName;
                sheetRow[iIndexes.PERCENTAGE].value = item.percentage;
            }

            return sheetRow;
        } );


        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);
        });

        setActiveCell({});
        hideItemListPopup();
    }

    const handleModeChange = (mode) => {
        // if (mode === 'edit') {
        //     setEditingCell
        // }
    };

    const handleCellSelect = (selectedCell) => {
        if (selectedCell.length !== 0) {
            setSelectedCellsArr([ ...selectedCell ]);
        }
    };

    const onCellCommitHandle = (prevCell, nextCell, coordinates) => {
    }
    const goToModuleHome = () => {
        navigate('/estimation/corporate-segments');
    };
    const handleOnContinue = async () => {
        goToModuleHome();
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                <div className={ "flexRow alignItemsCenter margBot10 justifyContentSpaceBetween" } >
                </div>
                <div className="sheet-container sm-scrollbar">
                    <div className="header m-2 mb-2 flexRow justifyContentSpaceBetween">
                        <div className="leftBlock alignItemsCenter">
                            <button onClick={ (e) => saveSheetData(e) }
                                className="add-item-button m-3">{ t('buttons.save') }</button> &nbsp;&nbsp;
                            <button onClick={ (e) => removeRow(e) }
                                className="remove-button">{ t('buttons.deleteRows') }</button>
                        </div>
                        <div className="rightBlock alignItemsCenter">
                            {spreadsheetErrors.length >= 1 ? spreadsheetErrors[0].message : null}
                        </div>
                    </div>
                    <div className="se-titlebar">
               {<span>{urlParams.id||state.data} Segment Items*</span>}
                   </div>

            <ReactSpreadsheet
                data={sheetData}
                columnLabels={sheetLabels}
                darkMode={true}
                onChange={setSheetData}
                onActivate={handleActiveCellChange}
                onBlur={handleCellBlur}
                onKeyDown={handleCellKeyDown}
                onModeChange={handleModeChange}
                onSelect={handleCellSelect}
                onCellCommit={onCellCommitHandle}
                />
                    <div className="flexRow alignItemsCenter margTop8">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button">{ t('buttons.addRows') }</button> &nbsp;<label>10 row at bottom</label>
                    </div>

                </div>
                <div className = "cellSelectionBlockItemCodes sm-scrollbar">
                {
                    warehouseItemsFiltered.length ? (
                    <ul>
                        {warehouseItemsFiltered.length && warehouseItemsFiltered.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row) }>{ row.itemCode }</li>
                        ) )
                        }
                    </ul>
                    )
                    : null
                }
                </div>
                <div className = "cellSelectionBlockItemNames sm-scrollbar">
                {
                    warehouseItemsFiltered.length ? (
                    <ul>
                        {warehouseItemsFiltered.length && warehouseItemsFiltered.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row) }>{ row.itemName }</li>
                        ) )
                        }
                    </ul>
                    )
                    : null
                }
                </div>
                {
                    Boolean(state.isInvalidAccess) && (
                    <AlertModal
                        title={'Alert: Invalid Access'}
                        customModalStyle={ modelStyle }
                        description={ state.message }
                        onContinue={handleOnContinue}
                        onClose={handleOnContinue}
                    />
                    )
                }

            </div>
        </>
    );
}