import React, { useEffect, useState } from 'react';
import { APIEndpoint } from "../../../utils/axios";
import { FilterBodyTemplate } from "../../../utils/configs";
import { DataGrid,Loading, Input, NGODatePicker, Modal, SpreadsheetModal, EditModal } from "../../../common";
import {onDownloadXlsFile,onDownloadImageFile, onDownloadPDFFile} from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import editIcon2 from "../../../assets/edit2.svg";
import confirmIcon from "../../../assets/icons/confirm.svg";
import Currency from '../../../assets/json/currency.json'
import { AttachmentCard } from "./depositSectorCard";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import usePermissions from '../../../utils/userPermissions';


const Columns = [
  { field: 'code', headerName: 'Code',tooltipField:'code',resizable:true,flex:1,
  suppressSizeToFit:true,minWidth:230,},
  { field: 'cashDrawerText', headerName: 'Cash Drawer', resizable:true,flex:1,
  suppressSizeToFit:true },
  { field: 'date', headerName: 'Date', suppressSizeToFit:true,resizable:true,flex:1},
  { field: 'createdByName', headerName: 'Created By',suppressSizeToFit:true, resizable:true,flex:1},
  { field: 'createdAtText', headerName: 'Created At',suppressSizeToFit:true,resizable:true,flex:1 },
  { field: 'attachment', headerName: 'Attachment', renderIfValueExist: true, cellType: 'button',className: 'grid-download-btnStyle1', buttonTextKey: 'Download', resizable:true,
   suppressSizeToFit:true,flex:1},
  { field: 'stateText', headerName: 'State', resizable:true,
  suppressSizeToFit:true,width:150,flex:1}
]
const BankTranferColumns = [
  { field: 'currency', headerName: 'Currency'},
  { field: 'amount', headerName: 'Amount'},
]
const EditTransferColumns = [
  { label: 'Currency Code', type: 'select', labelProp: 'code', attribute: 'currencyCode', required: true },
  { label: 'Currency Name', type: 'select', labelProp: 'currency', attribute: 'currencyName', required: true },
  { label: 'Amount', type: 'number', attribute: 'amount', required: true },
]
const NewTransferFields = [
  { label: 'Cash Drawer', attribute: 'cashDrawerId', type: 'selectSearch2', required: true, options: [] },
  { label: 'Date', attribute: 'targetDate', type: 'date', required: true },
  { label: 'Attachment', attribute: 'attachment', type: 'file', required: false },
]

export const CashBankTransferPage = (props) => {
  const navigate = useNavigate()
  const { t,ready } = useTranslation();
  const [state, setState] = useState({
    cashDrawerList: null, filterDate: '', filterState: "", filterCashDrawer: '',
    resetDataSource: false, showDialog: ''
  });
  const [uploadFileStatusBlock,setUploadFileStatusBlock]=useState("");
  const { hasPermission: createBnkTr } = usePermissions('create', 'cashier.bank-transfer');
  const { hasPermission: editBnkTr } = usePermissions('edit', 'cashier.bank-transfer');
  const { hasPermission: viewBnkTr } = usePermissions('viewDetails', 'cashier.bank-transfer');
  const { hasPermission: saveBnkTr } = usePermissions('save', 'cashier.bank-transfer');


  const getStateList = () => {
    return [
      { label: t('pages.cashRegisterContent.cashDeposits.statePlaceHolder'), value: "" },
      { label: t('pages.cashRegisterContent.cashDeposits.stateDraft'), value: "draft" },
      { label: t('pages.cashRegisterContent.cashDeposits.stateFinalized'), value: "finalized" },
    ]
  }

  useEffect(() => {
    fetchCashDrawersList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const fetchCashDrawersList = async () => {
    try {
      const res = await APIEndpoint.get('cashRegister/cashDrawers');
    const list = (res.result || []).map((_,index) => ({ key:index,value: _.id, label: _.value }));
    list.unshift({ label: t('pages.cashRegisterContent.cashDrawer.selectPlaceholder'), value: '' })
    setState((_) => ({ ..._, cashDrawerList: list }))
    } catch (error) {

    }

  }
  const fetchBankDeposits = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    filterData.filters = [];
    if (state.filterDate) filterData.filters.push({ targetDate: moment(state.filterDate).toISOString() });
    if (state.filterState) filterData.filters.push({ state: state.filterState });
    if (state.filterCashDrawer) filterData.filters.push({ cashDrawerId: state.filterCashDrawer });
    const res = await APIEndpoint.post('cashRegister/bankTransfers/listing', filterData);
    if (res.items) {
      const statuss = getStateList();
      res.items = res.items.map((_) => {
        const date = moment(_.createdAt);
        const state = statuss.find((s) => s.value === _.state)
        return {
          ..._,
          cashDrawerText: _.cashDrawer.airport.locationName,
          date: date.format("DD-MM-YYYY"),
          createdAtText: date.format("DD-MM-YYYY hh:mm a"),
          createdByName: `${_.createdBy.employeeName} (${_.createdBy.employeeCode})`,
          stateText: state ? state.label : _.state
        }
      })
    }
    return res;
  }
  const handleFilterInput = (e) => {
    setState((_) => ({ ..._, [e.target.name]: e.target.value, resetDataSource: true }));
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      setState((_) => ({ ..._, resetDataSource: false }));
    }, 100)
  }
  const handleAction = async (e, action, row) => {
    let dialogData = { code: row.code }, res, showSheet = false;
    switch (action.value) {
      case 'details':
      case 'submit':
        res = await APIEndpoint.get("cashRegister/bankTransfers/" + row.id);
        dialogData.rows = (res.bankTransfer.items || []).map((_) => ({ ..._, currency: `${_.currencyName} (${_.currencyCode})` }))
        dialogData.id = res.bankTransfer.id;
        dialogData.btnLabel = action.value === 'submit' ? t('Submit') : undefined;
        dialogData.title = `${t(`pages.cashRegisterContent.bankTransfer.${action.value === 'submit' ? 'submitModalTitle' : 'detailsModalTitle'}`)}${dialogData.code}`
        break;
      case 'edit':
        res = await APIEndpoint.get("cashRegister/bankTransfers/" + row.id);
        dialogData.bankTransferId = res.bankTransfer.id;
        // let res2 = await APIEndpoint.get("cashRegister/cashDrawers/" + res.bankTransfer.cashDrawer.id + "/balance");
        dialogData.rows = res.bankTransfer.items
        dialogData.title = `${t(`pages.cashRegisterContent.bankTransfer.editBankTransfer`)}${dialogData.code}`
        showSheet = true
        navigate("/cashRegister/bankTransfers/create",{state:{...state,...dialogData}})
        break;
      default:
        break;
    }
    setState((_) => ({ ..._, showDialog: action.value, dialogData: dialogData, showSheet: showSheet }))
  }
  const getRowActions = (colDef, data) => {
    if (data) {
      if (data.state === 'draft' && editBnkTr) {
        return [{ src: editIcon2, value: "edit", title: "Edit" },{ src: confirmIcon,value: "submit", title: "Submit" }]
      } else if (data.state === 'finalized' && viewBnkTr) {
        return [{ value: "details", title: "Details", className: 'mod-banktranser-button' }]
      }
    }
    return []
  }
  const handleModalCancel = () => {
    setState((_) => ({ ..._, showDialog: '', dialogData: null, showSheet: false, resetDataSource: false,message:null }))
    setUploadFileStatusBlock("");

  }
  const handleModalDone = async () => {
    if (state.showDialog === 'details') {
      return handleModalCancel()
    }
    try {
      setState((_) => ({ ..._, showAPILoading: true }))
      const res = await APIEndpoint.post('cashRegister/bankTransfers/finalize/' + state.dialogData.id)
      setState((_) => ({ ..._, showAPILoading: false, resetDataSource: true, message: { success: true, message: res.message } }))
      setTimeout(() => {
        setState((_) => ({ ..._, showDialog: "", message: null, resetDataSource: false, dialogData: null }))
      }, 3500)
    } catch (error) {
      let { data } = JSON.parse(error.message);
      setState((_) => ({ ..._, showAPILoading: false, message: { success: false, message: data?.errors?.message || 'Something went wrong. Please try again after some time.' } }));

if(data.errors && data.errors?.errors){
  let msgContent = "";
      data.errors.errors.forEach( _ => {
        const errorMsgArr = [];
          errorMsgArr.push(`${_.message.replace(/</g, '(').replace(/>/g, ')')} <br>` );
          msgContent += errorMsgArr.join(' ');
    });
      setUploadFileStatusBlock(
      "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
    );
}
setTimeout(() => {
  setState((_) => ({ ..._, message: null }))
}, 3500)

    }
  }
  const getTransferRow = (rowData) => {
    let row = [];
    EditTransferColumns.forEach(({ attribute, readOnly }, i) => {
      let cellVal = { readOnly: readOnly || false, value: '' }
      if (Array.isArray(rowData)) {
        cellVal.value = rowData[i].value;
        cellVal.error = rowData[i].error;
        if (!rowData[i].updated) {
          if (attribute === 'currencyCode' && rowData[1].value !== '') {
            let cellval = Currency.find((_) => _.currency === rowData[1].value)?.code;
            if(cellval){
              cellVal.value = cellval
              cellVal.error = false
            }else{
              cellVal.value = ""
              cellVal.error = false
            }
          }
          else if (attribute === 'currencyName' && rowData[0].value !== '') {
            let cellval = Currency.find((_) => _.code === rowData[0].value)?.currency
            if(cellval){
              cellVal.value = cellval
              cellVal.error = false
            }else{
              cellVal.value = ""
              cellVal.error = false
            }
          }
        }
      } else {
        cellVal.value = rowData[attribute] || ''
      }
      row.push(cellVal)
    });
    return row;
  }
  const handleSaveTransfer = (rows) => {
    const rowsFormatted = rows.map((row,i)=>{
      return {
        currencyCode:row[0]["value"],
        currencyName:row[1]["value"],
        amount:+row[2]["value"],
        index:i
      }
    }).filter(d=>d.currencyCode&&d.currencyName)
    const body = { items: rowsFormatted }
    if (state.newtransfer) {
      body.attachment = state.newtransfer.attachment;
      body.cashDrawerId = state.newtransfer.cashDrawerId;
      body.targetDate = state.newtransfer.targetDate;
    } else {
      body.bankTransferId = state.dialogData.bankTransferId;
    }
    return APIEndpoint.post("cashRegister/bankTransfers/save", body)
  }
  const handleNewTransfer = async () => {
    await fetchCashDrawersList()
    setState((_) => ({ ..._, showDialog: 'add', newtransfer: null }))
  }

  const onButtonCellClick=async(e,type,det)=>{
    if(type&&det){
      if (det.attachment.extension === 'xlsx') {
          await onDownloadXlsFile(`loi/downloads/${type}/${det?.attachment?.name}`).then( () => {
          });
      }
      else if (det.attachment.extension === 'pdf'||det.attachment.extension==='PDF') {
          await onDownloadPDFFile(`loi/downloads/${type}/${det?.attachment?.name}`).then( () => {
          });
      }
      else if (det.attachment.extension === 'jpg' || det.attachment.extension === 'JPG') {
        await onDownloadImageFile(`loi/downloads/${type}/${det?.attachment?.name}`).then( () => {
        });
    }
    }
  }

  const handleNewTranferChange = async (e, newUpdates) => {
   if(e?.target){
    const { name, files } = e.target;
    if (name === 'attachment') {
      if (state.newtransfer && state.newtransfer.attachment) {
        newUpdates.attachment.error = true
      } else {
        newUpdates.attachment = { loading: true }
      }
    }
    newUpdates.enableAdd = Boolean(newUpdates.cashDrawerId) && Boolean(newUpdates.targetDate)
    setState((_) => ({ ..._, newtransfer: newUpdates }))
    if (name === 'attachment') {
      const formdata = new FormData();
      formdata.append('file', files[0])
      const res = await APIEndpoint.post('attachments/cashRegister/upload', formdata, { 'Content-Type': "multipart/form-data" });
      newUpdates.attachment = res.attachment;
      setState((_) => ({ ..._, newtransfer: newUpdates }))
    }
   }
   if(!e && Object.keys(newUpdates).length > 0){
    setState((_) => ({ ..._, newtransfer: newUpdates }))
   }

  }

  const handleRemoveAttachment = () => {
    setState((_) => ({ ..._, newtransfer: { ...state.newtransfer, attachment: null } }))
  }
  const handleNewTransferSubmit = async () => {
    const date = moment(state.newtransfer.targetDate).format("DD-MM-YYYY");
    const matchingCashDrawer = state.cashDrawerList.find(item => item.value === state.newtransfer.cashDrawerId);
    const drawer = matchingCashDrawer ? matchingCashDrawer.label : null;
    const cId = matchingCashDrawer ? matchingCashDrawer.value : null;
    let dialogData = { title: `${t('pages.cashRegisterContent.bankTransfer.bankTransfer')} - ${drawer}/${date}` }
    const res = await APIEndpoint.get(`cashRegister/cashDrawers/${ cId}/balance`);
    dialogData.rows = res.items.map((_) => ({ ..._, amount: _.closingBalance }));
    setState((_) => ({ ..._, showDialog: _.showDialog, dialogData: dialogData, showSheet: true }))
    navigate("/cashRegister/bankTransfers/create",{state:{...state,...dialogData}})
  }
  const handleOnSaveSuccess = (res) => {
    if (state.showDialog === 'edit' && editBnkTr) {
      return handleModalCancel()
    }
    setState((_) => ({ ..._, showSheet: false, newtransfer: { ..._.newtransfer, resetDataSource: true, message: { text: res.message } } }))
    let timmerId = setTimeout(() => {
      clearTimeout(timmerId)
      return handleModalCancel()
    }, 750)
  }

  function fetchStateDetails(){
if(!Object.keys(state).length)return;
  }

  return (
    <div className="flexCol width100 ag-theme-alpine-dark cash-reg cash-reg-bank-tr">
      {
        state.cashDrawerList === null ?
          <Loading />
          :
          <div className='flexCol full-flex'>
            <DataGrid
              columnResize
              showDefaultFilters
              enableGridSearch
              className='full-flex'
              columns={Columns}
              width={250}
              getRows={fetchBankDeposits}
              onButtonCellClick={onButtonCellClick}
              actions={[{ attribute: 'EDIT/SUBMIT' }]}
              getActionCellIcons={getRowActions}
              onAction={handleAction}
              RightComponent={(props) => {
                return (
                  <div className='flexRow alignItemsCenter'>
               { fetchStateDetails()&& <> <Input
                      type='select'
                      style={{width: '10rem',paddingRight:"1rem"}}
                      className='filter-cash'
                      name='filterCashDrawer'
                      value={state.filterCashDrawer}
                      options={state.cashDrawerList}
                      onChange={handleFilterInput}
                    />
                    <Input
                      type='select'
                      style={{width: '10rem'}}
                      name='filterState'
                      className='filter-cash'
                      value={state.filterState}
                      options={getStateList()}
                      onChange={handleFilterInput}
                    /></>   }
                    <NGODatePicker
                      value={state.filterDate}
                      name='filterDate'
                      className='filter-cash'
                      onChange={handleFilterInput} />
                    <button className={`add-item-button`}
                      style={{marginLeft:'.8rem',fontSize: '.85rem'}}
                      onClick={handleNewTransfer}>
                      {/* <i className='icon-plus add-icon' /> */}
                      {t('pages.cashRegisterContent.bankTransfer.addButtonTitle')}
                    </button>
                  </div>
                )
              }}
              resetDataSource={state.resetDataSource}
            />
          </div>
      }
      {
        ((state.showDialog === 'submit' && saveBnkTr) || (state.showDialog === 'details' && viewBnkTr)) &&
        <Modal
          showDone={!Boolean(state.showAPILoading)}
          buttonLabel={state.dialogData.btnLabel}
          className='cash-deposit-summary'
          title={state.dialogData.title}
          onSubmit={handleModalDone}
          onClose={handleModalCancel}>
          <div className='flexCol width100' style={{height: state.dialogData.rows?.length > 4 ? '55vh' : 'auto'}}>
            {
              state.showDialog === 'submit' &&
              <div className='alert alert-warning'>
                {t('pages.cashRegisterContent.bankTransfer.submitWarning')}
              </div>
            }
            <DataGrid
              columnResize
              showDefaultFilters={false}
              enableGridSearch={false}
              className='full-flex'
              rowModelType='clientSide'
              columns={BankTranferColumns}
              rowData={state.dialogData.rows}
            />
            {
              Boolean(state.showAPILoading) &&
              <Loading />
            }
            {
              Boolean(state.message) &&
              <div className={`margTop11 alert alert-${state.message.success ? 'success' : 'danger'}`}>
                {state.message.message}
              </div>
            }
            {uploadFileStatusBlock?
            <div style={{padding:'1rem'}}className="dialog__footer">
                <div dangerouslySetInnerHTML={{ __html:uploadFileStatusBlock }}></div>
              </div>:null
            }
          </div>
        </Modal>
      }
      {
        state.showDialog === 'add' &&createBnkTr &&
        <EditModal
          showDone={false}
          showFieldsInColumn
          fields={NewTransferFields.map((_) => {
            return { ..._, options: state.cashDrawerList }
          })}
          className='cash-deposit-summary'
          title={t('pages.cashRegisterContent.bankTransfer.addButtonModalTitle')}
          onChange={handleNewTranferChange}
          onClose={handleModalCancel}>
          {
            Boolean(state.newtransfer && state.newtransfer.attachment) &&
            <>
              <AttachmentCard
                canDownload={false}
                onRemove={handleRemoveAttachment}
                {...state.newtransfer.attachment} />
            </>
          }
          {
            Boolean(state.newtransfer && state.newtransfer.message) &&
            <div className='alert alert-success'>{state.newtransfer.message.text} </div>
          }
          <button
            className={`add-item-button margTop24`}
            onClick={handleNewTransferSubmit}
            disabled={(state.newtransfer && !state.newtransfer.enableAdd)===null||undefined?true:(state.newtransfer && !state.newtransfer.enableAdd)}>
            {t('pages.cashRegisterContent.bankTransfer.addCurrencyInfoBtn')}
          </button>
        </EditModal>
      }
      {
        Boolean(state.showSheet) &&
        <SpreadsheetModal
          showDone={false}
          darkMode={true}
          isSubModal={state.showDialog === 'add'}
          columns={EditTransferColumns.map((_) => {
            return { ..._, options: Currency }
          })}
          getRow={getTransferRow}
          data={state.dialogData.rows}
          save={handleSaveTransfer}
          onSave={handleOnSaveSuccess}
          onClose={handleModalCancel}
          title={state.dialogData.title} />
      }
      {
                !ready &&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}