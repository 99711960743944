/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, Loading, Input, Collapseble, EditModal, EditIconButton, Modal } from "../../../common";
import { APIEndpoint,DFPAPIEndpoint } from "../../../utils/axios";
import deposit from "../../../assets/deposit.svg";
import Currency from '../../../assets/json/currency.json'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver';
import moment from 'moment';
import CustomSpreadSheet from '../../../common/CustomSpreadSheet';

const DepositsColumns = [
  { field: 'currency',headerName: 'pages.cashRegisterContent.cashDeposits.cashDepositsDepositsTblColumns.currency',  valueGetter: (params) => {
        return params.data.currencyCode && params.data.currencyName ? `${params.data.currencyName} (${params.data.currencyCode})`: "";
      }, flex:1,autoHeight: true,suppressSizeToFit:true, resizable:true},
  { field: 'amount', headerName: 'pages.cashRegisterContent.cashDeposits.cashDepositsDepositsTblColumns.amount', suppressSizeToFit:true, resizable:true, flex:1,autoHeight: true},
]
const RecordedSalesColumns = [
  { field: 'currency', headerName: 'pages.cashRegisterContent.cashDeposits.cashDepositsRecordedSalesColumns.currency', },
  { field: 'cash', headerName: 'pages.cashRegisterContent.cashDeposits.cashDepositsRecordedSalesColumns.cashSales', },
  { field: 'card', headerName: 'pages.cashRegisterContent.cashDeposits.cashDepositsRecordedSalesColumns.cardSales', },
]
const CardSalesFormFields = [
  { label: 'Card Sales', attribute: 'reportedCardSales', type: 'text', required: true },
]
const RemarkFormFields = [
  { label: 'Remarks', attribute: 'remarks', type: 'textarea', required: true, rows: 4 },
]
const EditDepositColumns = [
  { label: 'Currency Code', type: 'select', labelProp: 'code', attribute: 'currencyCode', required: true },
  { label: 'Currency Name', type: 'select', labelProp: 'currency', attribute: 'currencyName', required: true },
  { label: 'Value', type: 'number', attribute: 'amount', required: true },
]

const colDefCurrency = [
  { label: 'Currency Code',showPopup:true, type: 'select', labelProp: 'code', attribute: 'currencyCode', required: true,options:Currency.map(c=>c.code) },
  { label: 'Currency Name',showPopup:true, type: 'select', labelProp: 'currency', attribute: 'currencyName', required: true,options:Currency.map(c=>c.currency) },
  { label: 'Value', type: 'number', attribute: 'amount', required: true },
]

export const AttachmentCard = (props) => {
  const canDownload = props.canDownload !== undefined ? props.canDownload : true;
  const getIcon = () => {
    switch (props.extension) {
      case "jpeg":
      case "png": return 'icon-file-image';
      case "pdf": return 'icon-file-pdf';
      case "docx": return 'icon-file-word';
      case "xlsx": return 'icon-file-excel';
      default: return 'icon-file-empty';
    }
  }


  const onDownload = async () => {
    const header = { ...APIEndpoint.getHeaders().headers, 'Accept': props.type };
    try {
      const options = { method: 'GET', responseType: 'arrayBuffer', headers: header }
      const res = await fetch(APIEndpoint.getFullPath("loi/downloads/attachments/" + props.name), options);
      const blob = await res.blob()
      saveAs(blob, props.originalName)
    } catch (error) {
      props.setState((_)=>({..._,message:{error:'Unable to Download Attachments'}}))
    }
  }
  const onDelete = async () => {
    if (!props.id && !props.sectorId) {
      return props.onRemove()
    }
    try {
      const body = { attachmentId: props.id, sectorId: props.sectorId }
      await APIEndpoint.post(`cashRegister/deposits/${props.depositId}/attachments/remove`, body);
      props.onRemove();
      props.setRefreshDepositDetails(prev=>!prev);
    } catch (error) {
      props.setState((_)=>({..._,message:{error:'Unable to Delete Attachments'}}))
    }
  }
  return (
    <div className='attachment-card flexCol alignItemsCenter'>
      {
        props.uploading ?
          <Loading />
          :
          <>
            <div className='flexRow justifyContentSpaceBetween alignItemsCenter actions'>
              {
                canDownload &&
                <button className='btn btn-default ' onClick={onDownload}><i className="icon-cloud-download" /></button>
              }
              <button className='btn btn-default ' onClick={onDelete}><i className="icon-bin" /></button>
            </div>
            <i className={`icon-attach ${getIcon()}`} />
            <span className='file-name'>{props.originalName}</span>
          </>
      }
    </div>
  )
}

const SectorHeader = (props) => {
  return (
    <div className='sector-header'>
      <span className='normal-button-text-regular justifyContentFlexStart instance-member fontExtrabold key-value'>{props.flightNum}</span>
      <span className='caption-regular key-value' style={{ marginLeft: '8px' }}>{props.date}</span>
    </div>
  )
}
export const CashDepositSectorCard = (props) => {

  const { sectorsInfo, isEdit, depositId,setRefreshDepositDetails } = props;
  const [state, setState] = useState({ showModal: '', isSubmiting: false, message: null, sector: props.sector })
  const [sector, setSector] = useState(props.sector)
  const { t,ready } = useTranslation();
  useEffect(() => {
    handleSectorTablesData(sectorsInfo)
    handleRefereshSector()
  }, [t])
  useEffect(() => {
    setSector(props?.sector)
  }, [props])

  useEffect(() => {
    showToast();
    const timer = setTimeout(() => {
      setState((_)=>({..._,message:null}))
    }, 3000);
    return () => clearTimeout(timer);
  }, [state?.message]);

  const handleSectorTablesData = (sectorsInfo) => {
    let deposits = (sectorsInfo.items || []).map((_i) => {
      return { currency: `${_i.currencyName} (${_i.currencyCode})`, amount: _i.amount }
    });
    let recordSales = (sectorsInfo.info || []).map(getRecordedInfo)
    setModal(state.showModal, null, null, { deposits: deposits, recordSales: recordSales })
  }

  const closeModal = () => {
    setModal('');
  }
  const setModal = (target, message = null, close = false, sector = null) => {
    let timerId,
      handleTimeOut = (t, m) => {
        if (timerId) {
          clearTimeout(timerId)
        }
        setState((_) => ({ ..._, showModal: t, message: m, sector: sector ? { ...state.sector, ...sector } : state.sector }));
      }
    if (close) {
      setState((_) => ({ ..._, message: message }));
      timerId = setTimeout(handleTimeOut.bind(null, target, null), 1000)
    } else {
      handleTimeOut(target, message);
    }
  }
  const showToast = () => {
    toast.dismiss();
     if (state.message && state.message?.error) {
      toast.error(state.message.error, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: false,
        autoClose: 3000,
        style: {
          backgroundColor: '#d9534f',
          color: '#fff',
          borderRadius: '8px',
          fontSize: '1rem',
          width:'20rem'
        }
      });
    } else {

    }
  };

  const getEditTitle = () => {
    return { title: sector.key.replace("/","-") }
  }
  const handleNewCardSales = async (e, values) => {
    let message = { type: '', msg: '' }
    let reportedCardSales = Number(values.reportedCardSales)
    if (Number.isNaN(reportedCardSales)) {
      message.success = false;
      message.msg = 'Value must be number!'
      return setModal(state.showModal, message, true)
    }
    const body = { depositId: depositId, sectorId: sector.id, reportedCardSales: reportedCardSales }
    try {
      const res = await APIEndpoint.post(`cashRegister/deposits/${depositId}/reportCardSales`, body);
      message.success = true;
      message.msg = res.message;
      setModal('', message, true, { reportedCardSales: res.reportedCardSales });
      setRefreshDepositDetails(prev=>!prev);
    } catch (er) {
      message.success = false;
      message.msg = er?.data?.message
      setModal(state.showModal, message, true)
    }
  }
  const handleUpdatedRemarks = async (e, values) => {
    let message = { type: '', msg: '' }
    if (!values.remarks) {
      message.success = false;
      message.msg = 'Required'
      return setModal(state.showModal, message, true)
    }
    const body = { depositId: depositId, sectorId: sector.id, remarks: values.remarks }
    try {
      const res = await APIEndpoint.post(`cashRegister/deposits/${depositId}/remarks`, body);
      message.success = true;
      message.msg = res.message;
      setModal('', message, true, { remarks: res.remarks });
      setRefreshDepositDetails(prev=>!prev);
    } catch (er) {
      message.success = false;
      message.msg = er.data.message
      setModal(state.showModal, message, true)
    }
  }
  const handleRemoveAttachment = (index) => {
    const attachments = [...state.sector.attachments];
    attachments.splice(index, 1);
    setModal(state.showModal, null, false, { attachments: attachments })
  }
  const handleUploadAttachment = async (e) => {
    const input = e.target
    const file = input.files[0];
    const formData = new FormData()
    formData.append('file', file)
    const attachments = [...state.sector.attachments];
    let index = attachments.length;
    attachments.push({ uploading: true });
    setModal(state.showModal, null, false, { attachments: attachments })
    try {
      const upload = await APIEndpoint.post("attachments/cashRegister/upload", formData, { 'Content-Type': "multipart/form-data" });
      if (upload.success) {
        const body = {
          ...upload.attachment,
          sectorId: sector.id,
          extension:file.name.substring(file.name.lastIndexOf('.') + 1)
        }
        const res = await APIEndpoint.post(`cashRegister/deposits/${props.depositId}/attachments`, body);
        attachments[index] = res.attachment;
        setModal(state.showModal, null, false, { attachments: attachments })
        setRefreshDepositDetails(prev=>!prev);
      }
      input.value = '';
    } catch (error) {
      attachments.splice(index, 1);
      setModal(state.showModal, null, false, { attachments: attachments })
      input.value = '';
    }
  }
  const handleRefereshSector = async () => {
    setModal(state.showModal, null, null, { recordSales: 'loading' })
    const res = await getSalesInfo({
      cashDrawerId: props.cashDrawerId,
      flightDate: props.flightDate,
      storeId: sector.storeId
    });
    if(res){
      let recordSales = res?.salesInfo?.map(getRecordedInfo)
    setModal(state.showModal, null, null, { recordSales: recordSales })
    }

  }
  const handleRemoveSector = async () => {
    let message = { type: '', msg: '' }
    try {
      const body = { sectorId: sector.id }
      const res = await APIEndpoint.post(`cashRegister/deposits/removeSector/${depositId}`, body);
      message.success = true;
      message.msg = res.message;
      setModal('', message, true);
      props.onRemoveSector()
    } catch (er) {
      message.success = false;
      message.msg = er.data.message
      setModal(state.showModal, message, true)
    }
  }
  const saveSectorDeposit = (rows) => {
    const rowsFormatted = rows.map((row,i)=>{
      if(row[0].value && row[1].value){
        const returnData = {
          amount:Number(row[2].value || 0),
          currencyCode:row[0].value,
          currencyName:row[1].value,
          index:i,
        }
        return returnData
      }
    }).filter(data=>data!==undefined)
    const body = { sectorId: sector.id, items: [...rowsFormatted] }
    return APIEndpoint.post("cashRegister/deposits/update/" + props.depositId, JSON.stringify(body))
  }
  const onSaveDeposit = (res) => {
    let deposits = (res.items || []).map((_i) => {
      return { currency: `${_i.currencyName} (${_i.currencyCode})`, amount: _i.amount }
    });
    setModal('', null, null, { deposits: deposits })
  }
  const getSectorDepositRowData = (rowData) => {
    let row = [];
    EditDepositColumns.forEach(({ attribute, readOnly }, i) => {
      let cellVal = { readOnly: readOnly || false, value: '' }
      if (Array.isArray(rowData)) {
        cellVal.value = rowData[i].value;
        cellVal.error = rowData[i].error;
        if (!rowData[i].updated) {
          if (attribute === 'currencyName' && rowData[0].value !== '') {
            cellVal.value = Currency.find((_) => _.code === rowData[0].value)?.currency || "";
            cellVal.error = false
          } else if (attribute === 'currencyCode' && rowData[1].value !== '') {
            cellVal.value = Currency.find((_) => _.currency === rowData[1].value)?.code || "";
            cellVal.error = false
          }
        }
      } else {
        cellVal.value = rowData[attribute] || ''
      }
      row.push(cellVal)
    });
    return row;
  }
  return (
    <Collapseble
      open={false}
      Header={<SectorHeader flightNum={sectorsInfo.flightNum} date={sectorsInfo.date} />}
      className="sector-details">
      {
        Boolean(isEdit) &&
        <div className='flexRow alignItemsCenter justifyContentSpaceBetween margBot10' >
          <button className='btn btn-primary' onClick={() => setModal('edit')}>
            <span className='small-body-text-regular-label-upload'>{t("pages.cashRegisterContent.cashDeposits.sectorEditBtn")}</span>
          </button>
          <button className='btn btn-danger' onClick={() => setModal('remove')} >
            <span className='small-body-text-regular-label-upload'>{t("pages.cashRegisterContent.cashDeposits.sectorRemoveBtn")}</span>
          </button>
        </div>
      }
      <div className='sub-heading fontExtrabold key-value gap'>{t("pages.cashRegisterContent.cashDeposits.depositsTitle")}</div>
      <DataGrid
        columnResize
        showDefaultFilters={false}
        enableGridSearch={false}
        className='full-flex'
        rowModelType='clientSide'
        columns={DepositsColumns}
        rowData={sector.deposits? sector.deposits:sector.items?sector.items:[]}
      />
      <div className='flexRow alignItemsCenter margBtm06'>
        <div className='sub-heading fontExtrabold key-value'>
          {t("pages.cashRegisterContent.cashDeposits.cardsTitle")}
        </div>&nbsp;
        {
          Boolean(isEdit) && <EditIconButton onClick={() => setModal('cardsales')} />
        }
      </div>
      <div className='small-body-text-regular key-value gap'>
      {(sectorsInfo?.info && sectorsInfo?.info[0]?.currencyCode)??""}&nbsp;{sector.reportedCardSales || 0}
      </div>
      <div className='flexRow alignItemsCenter margBtm06' >
        <div className='sub-heading fontExtrabold key-value'>
          {t("pages.cashRegisterContent.cashDeposits.remarksTitle")}
        </div>&nbsp;
        {
          Boolean(isEdit) && <EditIconButton onClick={() => setModal('remark')} />
        }
      </div>
      <div className='small-body-text-regular key-value gap'>
        {sector.remarks || t("none")}
      </div>
      <div className='sub-heading fontExtrabold key-value'>{t("pages.cashRegisterContent.cashDeposits.attachmentsTitle")}</div>&nbsp;
      {
        Boolean(isEdit) && (
          <>
            <Input
              type="file"
              onChange={handleUploadAttachment}
              accept="image/*,.pdf,.csv,.doc,.docx,.xlsx"
              inpClassName="fullWidth"
            />
            <span style={{marginTop:"-25px",fontStyle:"italic",marginBottom:"20px"}}>
              <small style={{color:"#172f3e"}}>Supported Formats : image/*,.pdf,.csv,.doc,.docx,.xlsx</small>
            </span>
          </>
        )
      }
      <div className='flexRow'>
        {
          (sector.attachments || []).map((_, i) => {
            return (
              <AttachmentCard
                key={i} {..._}
                sectorId={sector.id}
                depositId={depositId}
                setState={setState}
                setRefreshDepositDetails={setRefreshDepositDetails}
                onRemove={() => handleRemoveAttachment(i)} />
            )
          })
        }
      </div>
      <ToastContainer />
      <div className='sub-heading fontExtrabold key-value flexRow alignItemsCenter justifyContentSpaceBetween gap'>
        <div className='flexRow alignItemsCenter '>
          <div className='sub-heading fontExtrabold key-value'>{t("pages.cashRegisterContent.cashDeposits.recordedSalesTitle")}</div>
          <button className='small-body-text-regular fontExtrabold key-value btn-sync'>
           <img style={{width:'30px',height:'40px'}} src={deposit} onClick={handleRefereshSector} alt='refresh-deposits' />
           {sectorsInfo?.sectors && sectorsInfo.sectors.length ? (t('pages.cashRegisterContent.cashDeposits.devicesSynced',{ devices: sectorsInfo.sectors.filter(sec=>sec?.syncStatus==='synced')?.length })) : (<span className='small-body-text-regular fontExtrabold key-value btn-sync-zero'>{t('pages.cashRegisterContent.cashDeposits.noDevicesSynced', { defaultValue: 'No Devices Synced' })}</span>)}
          </button>
        </div>
        <div className={`small-body-text-regular-label-upload fontExtrabold log-status ${sectorsInfo?.loggedIn ? 'success' : ''}`}>
      { sectorsInfo?.loggedIn?`${t("pages.cashRegisterContent.cashDeposits.loggedInText", { defaultValue: "Logged In" })}`:`${t("pages.cashRegisterContent.cashDeposits.noLoggedInText", { defaultValue: "No devices synced" })}`}
        </div>
      </div>
      {
        sector.recordSales === 'loading' ?
          <Loading />
          :
          <DataGrid
            columnResize
            showDefaultFilters={false}
            enableGridSearch={false}
            className='full-flex'
            rowModelType='clientSide'
            columns={RecordedSalesColumns}
            rowData={state?.sector?.recordSales || []}
          />
      }
      {
        state.showModal === 'cardsales' &&
        <EditModal
          isSubModal
          // showDone={false}
          buttonLabel="Save"
          showFieldsInColumn
          title={t("pages.cashRegisterContent.cashDeposits.cardSaleModalTitle", getEditTitle())}
          fields={
            CardSalesFormFields.map((_) => ({ ..._, initialValue: sector.reportedCardSales || '' }))
          }
          onSubmit={handleNewCardSales}
          onClose={closeModal}>
          {
            Boolean(state.message) &&
            <div className={`alert alert-${state.message.success ? 'success' : 'danger'}`}>
              {state.message.msg}
            </div>
          }
        </EditModal>
      }
      {
        state.showModal === 'remark' &&
        <EditModal
          isSubModal
          // showDone={false}
          showFieldsInColumn
          title={t("pages.cashRegisterContent.cashDeposits.sectorRemarkModalTitle", getEditTitle())}
          fields={
            RemarkFormFields.map((_) => ({ ..._, initialValue: sector.remarks || '' }))
          }
          onSubmit={handleUpdatedRemarks}
          onClose={closeModal}>
          {
            Boolean(state.message) &&
            <div className={`alert alert-${state.message.success ? 'success' : 'danger'}`}>
              {state.message.msg}
            </div>
          }
        </EditModal>
      }
      {
        state.showModal === 'remove' &&
        <Modal
          isSubModal
          buttonLabel={t("pages.cashRegisterContent.cashDeposits.sectorRemoveBtn")}
          className='cash-sector-remove'
          title={t("pages.cashRegisterContent.cashDeposits.sectorRemoveModalTitle", getEditTitle())}
          onSubmit={handleRemoveSector}
          onClose={closeModal}>
          <div className='flexRow from-to'>
            <div className='subheading-text-regular flexCol alignItemsCenter justifyContentCenter fl-no'><span>{sector.flightNumber}</span></div>
            <div className='flexRow fl-dt full-flex alignItemsCenter justifyContentSpaceBetween'>
              <div className='flexCol'>
                <span className='subheading-text-regular loc'>{sector.origin}</span>
                <span className='small-body-text-regular'>{sector.originName}</span>
              </div>
              <div className='flexCol alignItemsCenter justifyContentCenter'>
                <i className='icon-arrow-right2' />
                <span className='small-body-text-regular-label-upload'>{moment(props.flightDate).format('DD-MM-YYYY')}</span>
              </div>
              <div className='flexCol alignItemsEnd'>
                <span className='subheading-text-regular loc'>{sector.destination}</span>
                <span className='small-body-text-regular'>{sector.destinationName}</span>
              </div>
            </div>
          </div>
          <DataGrid
            columnResize
            showDefaultFilters={false}
            enableGridSearch={false}
            className='full-flex'
            rowModelType='clientSide'
            columns={DenominationColumns}
            rowData={sector.recordSales || []}
          />
          {
            Boolean(state.message) &&
            <div className={`alert alert-${state.message.success ? 'success' : 'danger'}`}>
              {state.message.msg}
            </div>
          }
        </Modal>
      }
      {
        state.showModal === 'edit' &&
          <EditModal
           isSubModal
           title={`${t("pages.cashRegisterContent.cashDeposits.depositsTitle")} ${getEditTitle().title}`}
           onClose={closeModal}
           showDone={false}
          >
          <CustomSpreadSheet
            columns={EditDepositColumns.map((_) => {
              return _.label
            })}
            saveData={saveSectorDeposit}
            colDefs={colDefCurrency}
            insideModal={true}
            initialData={sectorsInfo?.items}
            onClose={() => setState((_) => ({ ..._, showModal: '' }))}
            setDeposit={props.setDeposit}
            depositId={depositId}
          />
        </EditModal>
      }
      {
        !ready &&<div className='overlay_hide sub'></div>
      }
    </Collapseble>
  )
}

export const getRecordedInfo = (_i) => {
  const { cash, card } = _i.salesSummary;
  return {
    currency: _i.currencyName,
    cash: `${cash ? `${_i.currencyCode} ${cash}` : ''}`,
    card: `${card ? `${_i.currencyCode} ${card}` : ''}`
  }
}

export const fetchCashDeposit = async (detail) => {
  let promises = [], promise;
  const res = await APIEndpoint.get('cashRegister/deposits/' + detail.id);
  let cashDeposit = res.cashDeposit;
  for (let i = 0; i < cashDeposit.sectorDeposits.length; i++) {
    const sector = cashDeposit.sectorDeposits[i];
    promise = getSalesInfo({
      cashDrawerId: cashDeposit.cashDrawer.id,
      flightDate: moment(cashDeposit.createdAt).startOf("day").format(),
      storeId: sector.storeId
    });
    promises.push(promise);
  }

  let salesInfo = await Promise.all(promises);

  cashDeposit.sectorsInfo = cashDeposit?.sectorDeposits.map((_s, i) => {
    return {
      flightNum: `${_s.flightNumber} ${_s.origin} - ${_s.destination}`,
      date: moment(_s.flightDate).format("DD-MM-YYYY"),
      loggedIn: salesInfo[i].loggedIn,
      items: _s.items,
      info: salesInfo[i].salesInfo,
      sectors:salesInfo[i]?.sectors
    }
  });
  cashDeposit.denominations = cashDeposit?.denominations.map((_) => ({ ..._, currency: `${_.currencyName} (${_.currencyCode})` }))
  return cashDeposit;
}
export const getSalesInfo = (body) => {
  return APIEndpoint.post('cashRegister/sector/salesInfo', body);
}

export const DenominationColumns = [
  { field: 'currency', headerName: 'pages.cashRegisterContent.cashDeposits.detailsDenominationTableColumns.currency',suppressSizeToFit:true ,flex:1},
  { field: 'denomination', headerName: 'pages.cashRegisterContent.cashDeposits.detailsDenominationTableColumns.denomination',suppressSizeToFit:true ,flex:1 },
  { field: 'value', headerName: 'pages.cashRegisterContent.cashDeposits.detailsDenominationTableColumns.value',suppressSizeToFit:true ,flex:1 },
  { field: 'netAmount', headerName: 'pages.cashRegisterContent.cashDeposits.detailsDenominationTableColumns.netAmount',suppressSizeToFit:true ,flex:1},
]
export const getPendingSectors = (employeeId, date) => {
  const body = { employeeId: Number(employeeId), targetDate: date }
  return DFPAPIEndpoint.post('cashRegister/getDepositPendingSectors', body);
}
export const SectorSelect = (props) => {
  const { label, sectors, selected, placeholder } = props
  const selectedCount = Object.values(selected || {}).filter((_) => _).length;
  const { t } = useTranslation();
  return (
    <div className='input-container sector-select'>
      <label className='req label'>{label}</label>
      <span></span>
      {
        sectors === 'wait' ?
          <Loading className="flexRow" label={t("pages.cashRegisterContent.cashDeposits.waitForPendingSectors")} />
          :
          Array.isArray(sectors) ?
            <>
              {
                sectors.length === 0 ?
                  <div className='flexCol'>
                    <div>No Pending Sectors</div>
                  </div>
                  :
                  <>
                    {
                      selectedCount === 0 &&
                      <div className='labe-warn'>{t("pages.cashRegisterContent.cashDeposits.warningSelectoSector")}</div>
                    }
                    {
                      sectors.map((sector) => {
                        return (
                          <button
                            onClick={() => {
                              props.onSelect(sector.key)
                            }}
                            className='flexRow sector-card alignItemsCenter justifyContentSpaceBetween'
                            key={sector.key}>
                            <div className='flexRow'>
                              <span className='small-button-text-regular '>{sector.flightNumber} {sector.origin} - {sector.destination}</span>
                              <span className='date small-body-text-regular-label-upload'>{moment(sector.flightDate).format('DD-MM-YYYY')}</span>
                              <span className='code small-button-text-regular'>{sector.aircraftNumber}</span>
                            </div>
                            {
                              Boolean(selected[sector.key]) && <i className='icon-checkmark' />
                            }
                          </button>
                        )
                      })
                    }
                  </>
              }
            </>
            : Boolean(placeholder) ?
              <div className='labe-warn'>{placeholder}</div>
              : null
      }

    </div>
  )
}