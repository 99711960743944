import React, {  useRef, useState } from 'react';
import {DFPAPIEndpoint, DFPAPIEndpointGalley } from "../../../utils/axios";
import searchIcon from '../../../assets/icons/search.svg';
import '../../../assets/styles/styles.scss';
import deleteIcon from '../../../assets/icons/deleteRole.svg';
import editIcon from "../../../assets/edit.svg";
import { DataGrid, EditModal,UploadModal ,DeleteConf, NGODatePicker  } from "../../../common";
import { ConfigureGalley } from './configureGalley';
import { onDownloadXlsFile, translationLength } from '../../../utils/commonFunctions';
import { PageSizes } from "../../../utils/configs";
import { useTranslation } from 'react-i18next';
import { CreateAircraft } from './createAircraft';
import moment from 'moment';
import usePermissions from '../../../utils/userPermissions';
export const AircraftTypeAka = (props) => {
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState('');
  const[state,setState]=useState();
  const [airCraft,setAirCraft]=useState({});
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [classCapacityConfig,setClassCapacityConfig]=useState([]);
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [perPageLimit, setPerPageLimit] = useState(50);
  const [priceSector, setPriceSector] = useState('');
  const [dateChange, setDateChange] = useState(new Date(moment()));
  const [seatPriceList, setSeatPriceList] = useState({date:new Date(moment()),sector:'dom'});
  const [data, setData] = useState([]);
  const PriceDate=moment(dateChange, "ddd MMM DD YYYY ").format("YYYY-MM-DD")
  const tableRef = useRef();

  const { hasPermission: editAircraftType } = usePermissions('edit', "configuration.lcc.aircraft-type");
  const { hasPermission: createAircraftType  } = usePermissions('create', "configuration.lcc.aircraft-type");
  const { hasPermission: viewDetailsAircraftType  } = usePermissions('viewDetails', "configuration.lcc.aircraft-type");
  const { hasPermission: deleteAircraftType  } = usePermissions('delete', "configuration.lcc.aircraft-type");
  const { hasPermission: uploadAircraftType  } = usePermissions('upload', "configuration.lcc.aircraft-type");

React.useEffect(() => {
  if(state&&state?.selected && state.resetDataSource){
    fetchAirtcraftsType()
    setAirCraft(state.selected)
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [state]);


React.useEffect(() => {
 if(state && state.selected.action === 'SeatPriceList') {
  handleViewSeatprice(state.selected.data);
 }

}, [seatPriceList.date,seatPriceList.sector]);

React.useEffect(() => {
  fetchConfigClassCodes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preCompile = {
    save:t('buttons.save'),
    uploadLayout:t('pages.pagesContent.aircrafts.uploadLayout')
  }
  function layoutComponent(props) {

    return (
      props.data?
      <div>
      { viewDetailsAircraftType? <button className="grid-download-btnStyle1" onClick={(e) => setSector(props.data, 'viewLayout')}>View</button>:<></>}
      { uploadAircraftType? <button  style={{marginLeft:'2rem'}} className="grid-download-btnStyle1" onClick={(e) => setSector(props.data, 'uploadLayout')}>Upload</button>:<></>}
      </div> : <div></div>
    );
  }

function configureGalley(props){

    return (
        props.data?
        <div>
          <button className="grid-download-btnStyle1" onClick={(e) => setSector(props.data, 'configureGalley')}>Configure</button>
        </div> : <div></div>
      );
}

function PriceListComponent(props) {

  return (
    props.data?
    <div>
      <button className="grid-download-btnStyle1" onClick={(e) => handleViewSeatprice(props.data,  'SeatPriceList')}>View</button>
      <button  style={{marginLeft:'2rem'}} className="grid-download-btnStyle1" onClick={(e) => setSector(props.data, 'PricelistUploadLayout')}>Upload</button>
    </div> : <div></div>
  );
}

const fetchConfigClassCodes=async()=>{
  try {
    const resp = await DFPAPIEndpointGalley.get('galley/mealMenuPlan/configs');
    if(resp.data&&resp.data.classCodes?.list?.length>0){
    setClassCapacityConfig(resp.data.classCodes?.list?.map(cl=>({
      classCode: cl.id,
      seatCapacity: 0,
      className: cl.label
  })))
    }
    }
    catch (error) {

    }
}

function actionsAircrafts(props){
  return (
    props.data?
    <>
    <div className='flexRow margTop8'>
    {  editAircraftType? <div className='icon-aircraft-container'>
      <img className='aircraft-actions' alt='edit' onClick={(e) => setSector(props.data, 'editAircraft')} src={editIcon}/>
      <span className="tooltip-action">Edit</span>
      </div>:<></>}
     { editAircraftType?<div className='icon-aircraft-container'>
      <img className='aircraft-actions' alt='delete' onClick={(e) => setSector(props.data, 'deleteAircraft')} src={deleteIcon}/>
      <span className="tooltip-action">Delete</span>
      </div>:<></>}
    </div>
    </> : <></>);
}


const Columns = [
  { field: 'model',headerName: 'Aircraft Model', resizable: true,flex:1,suppressSizeToFit:true, width:150,minWidth: 130,tooltipField: 'model',pinned:'left',autoResize:true },
  { field: 'variant',headerName: 'Sub-fleet', resizable: true,flex:1,minWidth: 200, width:230,suppressSizeToFit:t },
  { field: 'aircraftType',headerName: 'Type', resizable: true, flex:1,minWidth: 200,width:230,suppressSizeToFit:t },
  { field: 'classCapacity',headerName: 'Economy',flex:1,valueFormatter: params=>{
    if(params&&classCapacityConfig.length>0){
      const data=params?.value?.find(v=>v.classCode===classCapacityConfig[3].classCode)
      if(data){
        return data.seatCapacity
      }
      else{
        return null
      }
     }

    }, minWidth: 180,width:200, suppressSizeToFit:true},
 {
  headerName:'Actions',
  pinned:'right',
    width:160,
    minWidth:120,
    resizable: false,
    suppressSizeToFit:true,
    cellRenderer: actionsAircrafts,
    valueGetter: function(params) {
      return params.sectors;
    }
  }
,
     {headerName:'Seat Layout',
     pinned:'right',
     minWidth:230,
      width:250,
      resizable:false,
      suppressSizeToFit:true,
      cellRenderer: layoutComponent,
      valueGetter: function(params) {
        return params.sectors;
      }
    },


  {
    headerName:'Galleys',
    pinned:'right',
      width:130,
      minWidth:110,
      resizable: false,
      suppressSizeToFit:true,
      cellRenderer: configureGalley,
      valueGetter: function(params) {
        return params.sectors;
      }
    },

    {headerName:'Seat Pricelist',
      pinned:'right',
      minWidth:230,
       width:250,
       resizable:false,
       suppressSizeToFit:true,
       cellRenderer: PriceListComponent,
       valueGetter: function(params) {
         return params.sectors;
       }
     },
]

  const EditFormFields = [
    {label: 'Csv', attribute: 'layout', type: 'csv4', disabled: false, options: [], value: "" },
  ];

  const setSector = (data,type) => {
    if(type==='configureGalley'){
      setAirCraft(data);
      setState( (_prev) => {
        return { ..._prev, selected: {...data ,action: type},resetDataSource:false}
      });
    }
    else if(type==='editAircraft'){
      setState((_) => {
        return { ..._, resetDataSource: false, selected: { data,action: 'editAircraftType' },edit:true};
      })
    }
   else if(type==='deleteAircraft'){
    setState((_) => {
      return { ..._, resetDataSource: false,selected:data,delete:true ,deleteProg:false,edit:false};
    })
   }
   else{
    setState( (_prev) => {
      return { ..._prev, selected: {...data ,action: type},resetDataSource:false}
    });
   }
  };

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 50,
    page: 1,
    searchParam: ''
  };
  const fetchAirtcraftsType = async (page, perPage=50) => {
    filterData.page = page;
    filterData.limit = perPage;
    filterData.filters =  [];
    filterData.searchParam = searchKey;
    try {
      const res = await DFPAPIEndpointGalley.post('galley/aircraftTypes/process', filterData);
    return res;
    } catch (error) {

    }

  }
  const handleFormSubmit = async (e,updated) => {
    if(updated){
     const body={
    model: updated.model,
    variant: updated.variant,
    capacity: parseInt(updated.capacity),
}
    try {
        const resp = await DFPAPIEndpoint.post(`aircraftTypes`,body);
        if(resp.success){
            setModalFormStatusObj({ text: resp.message});
    }
        }
         catch (resp) {
            setModalFormStatusObj({ text: resp.message});
         }
    }

          }

    const onClose = () => {
        setState((_prev) => {
            return { ..._prev, selected: false,resetDataSource:true,edit:false}
          })
        setModalFormStatusObj({ text: '' });
        setSeatPriceList({date:new Date(moment()),sector:'dom'})

    }

  const onAddItemHandler=()=>{
    setState( (_prev) => {
        return { ..._prev, selected: { action: 'createAircraftType' },resetDataSource:false ,edit:false};
      });
  }
  const onPerLimitChange = (e) => {
    setPerPageLimit(Number(e.target.value));
};

const onUploadClose=()=>{
  if(state.selected.action==='configureGalley'){
    setState((_prev) => {
        return { ..._prev, selected: false,edit:false,resetDataSource: true  }
    });
  }
  else if(state.selected.action==='uploadLayout'&&uploadFileStatusBlock.length>0){
    setState((_prev) => {
      return { ..._prev, selected: false,edit:false,resetDataSource: true  }
  });
  }else if(state.selected.action==='PricelistUploadLayout' &&uploadFileStatusBlock.length>0){
    setState((_prev) => {
      return { ..._prev, selected: false,edit:false,resetDataSource: true  }
  });
  }
  else{
    setState((_prev) => {
      return { ..._prev, selected: false,edit:false,resetDataSource: false  }
  });
  }
setUploadFileStatusBlock('');
setModalFormStatusObj({text:''});
setPriceSector('')
setUploadedFile('')
}

const onCloseDelete=()=>{
  setState((_prev) => {
    return { ..._prev,resetDataSource:false,delete:false}
  });
setModalFormStatusObj({ text: "" });
}

const onContinue=async()=>{
  setState((_prev) => {
    return { ..._prev,deleteProg:true}
});


  try {
    const res= await DFPAPIEndpointGalley.delete(`galley/aircraftTypes/${state.selected.id}`);
    if(res.success){
      setState((_prev) => {
        return { ..._prev,deleteProg:false,resetDataSource:true}
    });
      setModalFormStatusObj({ text: res.message });
      setTimeout(() => {
        setModalFormStatusObj({ text:''});
        setState((_prev) => {
          return { ..._prev,resetDataSource:false,delete:false}
        });
          }, 2500);

    }
    }
     catch (err) {
      setModalFormStatusObj({ text: err.data.message });
      setTimeout(() => {
        setModalFormStatusObj({ text:''});
        setState((_prev) => {
          return { ..._prev,resetDataSource:false,delete:false}
        });
          }, 2500);
     }


}
const delProps = {
  onContinue,
  onCloseDelete,
    }

    const handleViewSeatprice = async (props) => {
      setData([]);
        const body={
          date:  moment(seatPriceList.date, "ddd MMM DD YYYY ").format(),
          sectorType:seatPriceList.sector
        }

      try {
        const res =  await DFPAPIEndpoint.post(`aircraftTypes/${props.id}/seatPricelists/byDate`,body)

        setSector(props.data,"SeatPriceList")
        setState((_prev) => {
          return { ..._prev, selected: {..._prev.selected,action: 'SeatPriceList',data:props  },edit:false,resetDataSource: false  }
      });
      setData(res.items);
      } catch (error) {

      }
    };


    const handleViewIntlPriceClick = () => {
      setState((_prev) => ({..._prev,selected: {..._prev.selected,action: "IntlPriceList"},data:props}));
    };

    const onUploadFileChangeHandler = async (e) => {
      const file=e.target.files[0];
      setUploadedFile(e.target.files[0]);
      setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

      const formData = new FormData();
      formData.append('file', file);
      try {
        const res = await DFPAPIEndpointGalley.post(`api/aircraftTypes/${state?.selected?.id}/layout/upload`, formData);
        setUploadedFile([]);
        console.log(res)
        setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
      } catch (error) {
        e.target.value = null;
        setUploadedFile([]);
        const errorObj = JSON.parse(error.message)
        let errHtml = "<div class='alert-danger'>"
        errHtml+=`<div class='w-100 flexCol margBottom10'>${errorObj.data.Error}</div>`
        { errorObj.data?.errors&&Array.isArray(errorObj.data.errors)?errorObj.data.errors.map((er, i) => {
          errHtml+=`
            <div key=${i} class="flexRow margBottom10">
            <div class='key'>Line Number: ${er.lineNumber}</div>
            <div class='flexCol'>
          `
          er.errors.map((_, i) => {
            errHtml+=`<span key=${i}>${_.message}</span>`
            return null
          })
          errHtml+=`</div></div>`
          return null
        }):<></>}
        errHtml+=`</div>`
        setUploadFileStatusBlock(errHtml);
        setUploadedFile([]);
      }
  };

  const onPriceListUploadFileChangeHandler = async (e) => {
    const file=e.target.files[0];
    setUploadedFile(e.target.files[0]);
    setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');
    const body={
      st:priceSector,
      efd:moment(dateChange, "ddd MMM DD YYYY ").format("YYYY-MM-DD")
    }



    const formData = new FormData();
    formData.append('scheduleLater', false);
    formData.append('listName', undefined);
    formData.append('targetSectorType', priceSector);
    formData.append('stores', undefined);
    formData.append('warehouses', undefined);
    formData.append('effectiveDate','');
    formData.append('file', file);
    try {
      const res = await DFPAPIEndpoint.post(`aircraftTypes/${state?.selected?.id}/seatPricelists/upload?st=${priceSector}&efd=${PriceDate}`, formData,body);
      setUploadedFile([]);
      console.log(res)
      setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
    }  catch(error) {
      let { status, data } = JSON.parse( error.message );
      let msgHeading = data?.Error || data?.message || error.message;

      let msgContent = "";
      if (status === 400 && data?.errors) {
        data.errors.forEach( _ => {
        const lineNum = _.lineNumber;
        const errorMsgArr = [];
        _.errors.forEach(erObj => {
          errorMsgArr.push( erObj.field?erObj.field:"" + ' | ' + erObj.message + ' \n<br>' );
        });

        msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
    });
  } else if(status===0 && !data){
    msgContent += `Error occurred while uploading seat pricelist sheet!<br>Please try again.`;
}
else if(status === 400 && data && data?.Error){
  msgHeading = data?.Error;
  msgContent += `Error occurred while uploading seat pricelist sheet!<br>Please try again.`;
}
  else {
    msgContent += `Error occurred while uploading seat pricelist sheet!<br>Please fix the errors and re-upload.`;
  }


      setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                              );
    }

};

const onDownloadSampleFile=()=>{
    onDownloadXlsFile('samples/downloads/AircraftTypeLayout_Upload_Sample','AircraftTypeLayout_Upload_Sample')
}
const onDownloadPricelistSampleFile = async() => {
  onDownloadXlsFile('samples/downloads/SeatPricelist_Upload_Sample','seat_pricelist')
 }

  const onSearch = (e) => {
    setSearchKey(e.target.value);
  };
  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
           <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>
            {/* <button className='page-button'>
            <select className="darkBlue-bg" onChange={onPerLimitChange} value={perPageLimit} id="page-size">
                {
                PageSizes.map((_) => {
                    return (
                    <option key={_.value} value={_.value}>{_.label}</option>
                    )
                })
                }
            </select>
            </button> */}
            <div className='width100Menu justifyContentCenter flexRow'>
            <div className="margLeft margBot10 underline">
            <span><img  className="icon-size" alt='search' src={searchIcon} /></span>
            <span><input className="search" onChange={onSearch} type="search" placeholder="Search" value={searchKey}/></span>
            </div>
            </div>

          {createAircraftType?<div className="margLeft flexRow justifyContentFlexEnd alignItemsBaseline">
            <button className="add-item-button" onClick={ onAddItemHandler }>
                {t('buttons.create')}
            </button>
            </div>:<></>}
            </div>

          <DataGrid
            ref={tableRef}
            className='full-flex'
            columnResize
            columns={Columns}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            rowSelection="multiple"
            resetDataSource={state?.resetDataSource}
            setSearchKey={(e) => onSearch(e)}
            getRows={fetchAirtcraftsType}
          />
        </>
      }
      {state && Boolean(state.selected)&& state.selected?.action === "createAircraftType"&&(
           <CreateAircraft
            key={state.selected?.action}
            title={"Create Aircraft Model"}
            airlines={'akasa'}
            buttonLabel={preCompile.save}
            onSubmit={handleFormSubmit}
            setModalFormStatusObj={setModalFormStatusObj}
            onClose={onClose}
          />
        )
     }
     {state && Boolean(state.selected)&& state.selected?.action === "editAircraftType"&&(
           <CreateAircraft
            key={state.selected?.action}
            title={`Edit Aircraft Model ${state.selected?.data.model} `}
            airlines={'akasa'}
            buttonLabel={preCompile.save}
            editData={state.selected?.data}
            onSubmit={handleFormSubmit}
            setModalFormStatusObj={setModalFormStatusObj}
            onClose={onClose}
          />
        )
     }

      {state && Boolean(state.selected) &&state.selected?.action === "viewLayout" && viewDetailsAircraftType && (
          <EditModal
            key={state.selected?.action}
            title={ `Seat Layout  ${state.selected?.model}`}
            onClose={onClose}
            showDone={false}
            showFieldsInColumn={true}
            fields={ EditFormFields.map( (elt) => {
                  return {...elt, initialValue: state.selected[elt.attribute]}
                })
            }
          />
        )
     }
     {
        state && Boolean(state.selected)&&state.selected.action==='uploadLayout'&&
        (<UploadModal
            modelTitle={`${preCompile.uploadLayout}-${state.selected.key}`}
            onUploadClose={onUploadClose}
            onDownloadSampleFile={onDownloadSampleFile}
            uploadedFile = { uploadedFile }
            onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
            uploadFileStatusBlock = { uploadFileStatusBlock }
        />)
      }
       {state&&state?.delete && <DeleteConf   {...delProps} modalFormStatusObj={modalFormStatusObj} description={`Are you sure you want to delete ${state?.selected?.model} ?`} />}

      {
        state && Boolean(state.selected)&&state.selected.action==='configureGalley'&&
        (<ConfigureGalley
            modelTitle={`Configure Galleys ${state.selected.model}`}
            onUploadClose={onUploadClose}
            airCraft={airCraft}
            configuration={'LCC'} // to identify LCC or FSC
            state={state}
            onUpdateGalley={(min,max)=>fetchAirtcraftsType(min,max)}
            onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
            uploadFileStatusBlock = { uploadFileStatusBlock }
        />)
      }
      {state && Boolean(state.selected) &&state.selected?.action === "SeatPriceList"&& (
        <>
          <EditModal
            key={state.selected?.action}
            title={ `Seat Pricelist -  ${state.selected?.data?.model}`}
            onClose={onClose}
            showFieldsInColumn={true}
            showDone={false}
          >
          <div className='dialog__body_container flexRow justifyContentSpaceBetween flexWrap'>
         <div className={`flexRow width100 justifyContentFlexStart alignItemsCenter margBot10`}>
         <div className='flexCol justifyContentSpaceEvenly' style={{marginRight:'1rem'}}>
          <label className={`fontRegular req`} >Effective Date</label>
          <div className={`dialog__description`}>
           <NGODatePicker
             style={{width: '17rem'}}
             isclearable={false}
             value={seatPriceList.date}
             minDate={new Date()}
             placeholder={t('effectiveDate')}
             placeholdertext={t('datePickerPlaceholder')}
            onChange={(e)=>setSeatPriceList({ ...seatPriceList, date: new Date(e.target.value) })}
             />

       </div>
       </div>
         <div className='flexCol justifyContentSpaceEvenly '>
          <label className={`fontRegular req`}>Sectors</label>
          <div className={`dialog__description`}>
            <select className='select-input-galley1'
             onChange={(e) => setSeatPriceList({ ...seatPriceList, sector:(e.target.value) })}
             value={seatPriceList.sector}
            >
              <option value="">Select Sector</option>
             <option value="dom">Domestic</option>
             <option value="int">International</option>
            </select>

          </div>
          </div>

        </div>

      <div className="flexRow alignItemsCenter"  id="caterer-table" style={{whiteSpace:'nowrap', width:'100%'}}>


      <table>
        <thead>
          <tr style={{width:'100%'}} className='sticky'>
          <th style={{width:"20%"}}>From</th>
            <th style={{width:"20%"}}>To</th>
            <th>CGST</th>
            <th>SGST</th>
            <th>UTGST</th>
            <th>IGST</th>
            <th>Price</th>
            <th>Price(SSR)</th>
            <th>Intl.Pricing</th>
          </tr>
        </thead>
        <tbody>

              {data?.map((_) => (
                <tr key={_._id}>
                  <td>{_.fromCategory}</td>
                  <td>{_.toCategory}</td>
                  <td>{_.cgst}</td>
                  <td>{_.sgst}</td>
                  <td>{_.utgst}</td>
                  <td>{_.igst}</td>
                  <td>{_.itemPrice}</td>
                  <td>{_.priceSSR?_.priceSSR:"NA"}</td>
                  <td><button className="grid-download-btnStyle1" style={{marginLeft:"1rem"}} onClick={() => handleViewIntlPriceClick(_._id)} >View</button></td>
                </tr>
              ))}
              {data?.length===0 && (
                <tr>
                  <td colSpan={8} style={{textAlign:"center"}}>No Items Found</td>
                </tr>
              )}
            </tbody>
      </table>

    </div>
    </div>

          </EditModal>
          </>
        )
     }
     {state && Boolean(state.selected) &&state.selected?.action === "IntlPriceList"&& (
      <EditModal
            key={state.selected?.action}
            title={ `Intl Pricing -  ${state.selected?.data?.model}`}
            onClose={onClose}
            showDone={false}
            showFieldsInColumn={true}>
          <div className="flexRow alignItemsCenter"  id="caterer-table" style={{whiteSpace:'nowrap', width:'100%'}}>
            <table>
             <thead>
              <tr style={{width:'100%'}} className='sticky'>
                <th >Currency</th>
                <th>Amount</th>
               <th>Amount(SSR)</th>
             </tr>
             </thead>
               <tbody>

                 {data?.intlPrices?.map((_) => (
                <tr key={_._id}>
                  <td>{_.currency}</td>
                  <td>{_.amount}</td>
                  <td>{_.amountssr}</td>
                </tr>
              ))}
              {!data.intlPrices  && (
                <tr>
                  <td colSpan={3} style={{textAlign:"center"}}>No Items Found</td>
                </tr>
              )}
               </tbody>
             </table>
          </div>
        </EditModal>
     )}

      {
        state && Boolean(state.selected)&&state.selected.action==='PricelistUploadLayout'&&
        (<UploadModal
            modelTitle={`Upload Seat Pricelist - ${state.selected?.model}`}
            onUploadClose={onUploadClose}
            onDownloadSampleFile={onDownloadPricelistSampleFile}
            uploadedFile = { uploadedFile }
            onUploadFileChangeHandler = { (e) => onPriceListUploadFileChangeHandler(e) }
            uploadFileStatusBlock = { uploadFileStatusBlock }
            priceSector={priceSector }
            setPriceSector={setPriceSector}
            dateChange={dateChange}
            setDateChange={setDateChange}
        />)
      }
       {
            t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
        }
    </div>
  )
}

