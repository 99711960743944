import React, { useEffect, useState } from 'react'
import HorizontalBarChart from '../../../../common/BarHorizondal'

const BarData = {
    salesLeft:{
        data:[60.95, -20,40, -40.13,20],
        labels:['NULL', 'DOMLUX', 'MERCHANDISE', 'NON-PERISHABLE',"PERISHABLE"]
    },
    qtyLeft:{
        data:[4, 0,-3, -4,-3],
        labels:['NULL', 'DOMLUX', 'MERCHANDISE', 'NON-PERISHABLE',"PERISHABLE"]
    },
    salesRight:{
        data:[42.5],
        labels:['LGK-SIN|SIN-LGK', '', '', '',""]
    },
    qtyRight:{
        data:[0],
        labels:['LGK-SIN|SIN-LGK', '', '', '',""]
    }
}

const Legend = ({label,color})=>{
    return (
        <div style={{display:"flex",alignItems:"center",gap:"3px"}}>
            <span style={{width:"10px",height:"10px",backgroundColor:color}}></span>
            <span>{label}</span>
        </div>
    )
}

const IrTopCards = ({chartData,chartData2}) => {
  const [data, setData] = useState(BarData)
  useEffect(() => {
    if(Object.keys(chartData).length === 0 || chartData2.length === 0) return;
    setData(prev=>{
      return {
        ...prev,
        qtyLeft:{data:[...Object.keys(chartData).map(key=>chartData[key]?.varianceCount)],labels:[...Object.keys(chartData)]},
        salesLeft:{data:[...Object.keys(chartData).map(key=>chartData[key]?.varienceValue)],labels:[...Object.keys(chartData)]},
        qtyRight:{
          labels:[...chartData2.map(data=>data.sector)],
          data:[...chartData2.map(data=>data.varianceData.varianceCount)]
        },
        salesRight:{
          labels:[...chartData2.map(data=>data.sector)],
          data:[...chartData2.map(data=>data.varianceData.varienceValue)]
        }
      }
    })
  }, [chartData])

  return (
    <div className='IrTopCardsWrapper'>
        <div className='IrTopCard'>
        <div className='IrTopCardInner'>
        <p>Sales Variance by product category</p>
        <div className='canvasWrapper'>
          <div className='canvasWrapper__inner'>
            <HorizontalBarChart dataS={data.salesLeft.data} labels={data.salesLeft.labels} />
            <div
              className='canvasTitle'
            >
              <p>Sales Variance</p>
            </div>
          </div>
          <div className='canvasWrapper__inner'>
            <HorizontalBarChart displayLabel={false} showCurrency={false} dataS={data.qtyLeft.data} labels={data.qtyLeft.labels} />
            <div className='canvasTitle'>
              <p>Quantity Variance</p>
            </div>
          </div>
        </div>
        {/* <div className='legendWrapper'>
            <Legend label="NULL" color="#4e4b93"/>
            <Legend label="DOMLUX" color="#4e4b93a6"/>
            <Legend label="MERCHANDISE" color="yellow"/>
            <Legend label="NON-PERISHABLE" color="pink"/>
            <Legend label="PERISHABLE" color="lightblue"/>
        </div> */}
        </div>
      </div>
      <div className='IrTopCard'>
        <div style={{border:"1px solid #fff",padding:"0.5rem",borderRadius:10,height:"100%"}}>
        <p>Top 10 Sales Variance by sector pairing</p>
        <div style={{ display: "flex" }}>
          <div className='canvasWrapper__inner'>
            <HorizontalBarChart dataS={data.salesRight.data} labels={data.salesRight.labels} />
            <div className='canvasTitle'>
              <p>Sales Variance</p>
            </div>
          </div>
          <div
            className='canvasWrapper__inner'
          >
            <HorizontalBarChart showCurrency={false} displayLabel={false} dataS={data.qtyRight.data} labels={data.qtyRight.labels} />
            <div className='canvasTitle'>
              <p>Quantity Variance</p>
            </div>
          </div>
        </div>
        </div>
      </div>
        </div>
  )
}

export default IrTopCards