/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useParams,useNavigate} from 'react-router-dom';
import Spreadsheet, { createEmptyMatrix } from "react-spreadsheet";
import { APIEndpoint ,DFPAPIEndpoint} from "../../../utils/axios";
import { updateButtonTxtStatus, validateCellAndGetData } from "../../../utils/commonFunctions";
import { FaRegCheckSquare ,FaSave} from "react-icons/fa";
import closeIcon from "../../../images/icons/Icon2.png"
import { AlertModal } from "../../../common";
import { BiSolidSelectMultiple } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import * as ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";
import { DEFAULT_SHEET_ROWS, ErrorFields } from "../../../utils/configs";

let spreadSheetErrTimout;

const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    ITEM_UNIT: 2,
    ITEM_REQ_QTY: 3,
    ITEM_RECI_QTY: 4,
    ITEM_QTY: 5,
    ITEM_REMARKS: 6
};
const sType = localStorage.getItem('sType');
const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);
const grnSheetLabels = ['Item Code', 'Item Name', 'Unit', 'Requested Quantity', 'Received Quantity', 'Quantity', 'Remarks'];

/**
 * Automatically creating rows
 */
// const initialData = createEmptyMatrix(10, grnSheetLabels.length);
/**
 * Manually creating rows
 */
const createEmptySheetRows = (noOfRows = DEFAULT_SHEET_ROWS, grnSheetData) => {
let len = grnSheetData ? +grnSheetData.length:0
const initialData = [];
for( var i=0; i < noOfRows; i++) {
    initialData.push([
        {
            id: 'itemCode',
            value: '',
            className: 'cellClass cellClass_' + len + '_' + iIndexes.ITEM_CODE,
            width: '200px',
            showPopup: true
        },
        {
            id: 'itemName',
            value: '',
            className: 'cellClass cellClass_' + len + '_' + iIndexes.ITEM_NAME,
            width: '200px',
            showPopup: true
        },
        {
            id: 'unit',
            value: '',
            className: 'cellClass1',
            width: '400px',
            readOnly: true,
            required:true
        },
        {
            id: 'requestedQty',
            value: '',
            className: 'cellClass1',
            width: '200px',
            readOnly: true,
            required:true

        },
        {
            id: 'receivedQty',
            value: '',
            className: 'cellClass1',
            width: '200px',
            readOnly: true,
            required:true
        },
        {
            id: 'quantity',
            value: '',
            className: 'cellClass1',
            width: '200px',
            type:'number',
            readOnly:false,
            required:true
        },
        {
            id: 'remarks',
            value: '',
            className: 'cellClass1',
            width: '200px',
            readOnly:false,
            required:false
        }
    ]);
    len++
}
return initialData;
}
const initialData = [];// createEmptySheetRows(DEFAULT_SHEET_ROWS - 1);

const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 100,
    page: 1,
    searchParam: ''
  };

export const GRNForPurchaseOrderCreate = (props) => {
    const urlParams = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { warehouseId, purchaseOrderId, id: poId, invoiceInputs } = urlParams;
    const invoiceObj = JSON.parse(invoiceInputs);
    const [count, setCount] = useState(1);
    const [itemData, setItemData] = useState([]);
    const [cellChange,setCellChange]=useState({});
    const [sheetTitle, setSheetTitle] = useState("");
    const [state, setState] = useState({ isInvalidAccess: false, message: '' });
    const [grnSheetData, setGrnSheetData] = useState(createEmptySheetRows( DEFAULT_SHEET_ROWS ));
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const modelStyle = { maxWidth: '50%', margin: 'auto 10% 100% 10%' };
    const [activeCell, setActiveCell] = useState([])

    const targetEltCodes = document.getElementsByClassName("cellSelectionBlockItemCodes")[0];
    const targetEltNames = document.getElementsByClassName("cellSelectionBlockItemNames")[0];

    const goBack = () => {
        localStorage.removeItem('grnList');
        navigate(-1); //navigates back to the previous page
    };
    useEffect(()=>{
        localStorage.removeItem('grnList');
        getVendorDetails();
    },[])


    const getVendorDetails = async() => {
        try {
            const resVendorId = await APIEndpoint.get(`purchaseOrders/findById/${urlParams.purchaseOrderId}`)
              if (resVendorId && resVendorId?.data) {
                const payload={
                    "purchaseOrderId":urlParams.id,
                    "warehouseId":resVendorId?.data.warehouseId,
                    "vendorId": resVendorId?.data?.vendorId,

                }
                try {
                    const [resVendor,resItems] = await Promise.all([
                        APIEndpoint.post('warehouses/vendorItems',payload),
                        APIEndpoint.get('items/enumerate'),
                      ]);
                      if (resVendor.success && resVendor.items.length && resItems.success && resItems.items.length) {
                        setSheetTitle(resVendorId?.data?.code)
                        setEditTransferItemsToSheet(resVendorId?.data?.items);
                        setItemData( resItems.items.filter(_=>resVendor.items.find(rsv=>rsv?.itemId===_?.itemId)))
                    }
                } catch (error) {
                    console.log('Invalid page access!, Letter of Intent ID not found.', error.message);

                    setState({ ...state, isInvalidAccess: true,
                        message: "Invalid page access!, GRN ID not found, On click OK, you will be redirected to  Purchase Order page." });
                }
            }
        } catch (error) {
            console.log('Invalid page access!,  GRN ID not found.', error.message);

            setState({ ...state, isInvalidAccess: true,
                message: "Invalid page access!,  GRN ID not found, On click OK, you will be redirected to Purchase Order page." });
        }
    };



    //Api call for fetch purchase order items
    const checkSheetData=()=>{
        let data=false;
        grnSheetData?.map(upl=>{
            if(Array.isArray(upl)){
                upl?.forEach((val,ind)=>{
                    if(val&&val?.value){
                        data=true
                    }
                })
            }else{
                if(upl.value){
                    data=true
                }
            }


        })
        return data
    }


    const addRowsToSheet = (rowsToAdd = 10) => {
        const newRows = createEmptySheetRows(rowsToAdd,grnSheetData);
        setGrnSheetData([...grnSheetData,...newRows])
    };
    const selectAllCells = (e) => {
        setCount((prev)=>prev+1)
        let updatedSheetData=[]
        if(count%2!==0){
            updatedSheetData = grnSheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `selectcell-class cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
        }else{
            updatedSheetData = grnSheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
        }

    setGrnSheetData(updatedSheetData);

    };


    const removeRow = () => {
        hideItemListPopup();
        let jsonSpreadsheetData = JSON.stringify(grnSheetData);
        let inSheetData = JSON.parse(jsonSpreadsheetData);
        if (Object.keys(activeCell).length === 0) {
          inSheetData.pop();
          setGrnSheetData([...inSheetData]);
        } else {
          let selectedRowIndex = activeCell.row;
          if (selectedRowIndex || selectedRowIndex === 0) {
            inSheetData.splice(selectedRowIndex, 1);
            setGrnSheetData([...inSheetData]);
          }
        }
      }


      useEffect(() => {
        const addErrorDiv = (cells, message) => {
          cells.forEach((cell) => {
            if (!cell.querySelector(".sheetErrDiv")) {
              const errorDiv = document.createElement("div");
              errorDiv.classList.add("sheetErrDiv");
              errorDiv.textContent = message;
              cell.appendChild(errorDiv);
            }
          });
        };

        const timeout = setTimeout(() => {
          const errorCellGRN = document.querySelectorAll(".errorCellGRNrev");
          if (errorCellGRN.length) {
            addErrorDiv(errorCellGRN, ErrorFields[1]?.message);
          }
        }, 100);

        return () => clearTimeout(timeout);
      }, [ activeCell]);


      useEffect(() => {
        if(!Object.keys(cellChange).length)return;
        const timeout = setTimeout(() => {

            let jsonSpreadsheetData = JSON.stringify(grnSheetData);
            let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

            const updatedSheetData = updatedSpreadsheetData.map((row, rowIndex) => {
                return row.map((cell, cellIndex) => {
                  if (rowIndex === activeCell.row) {
                    const errDiv = document.querySelector(`.Spreadsheet__cell.cellClass.cellClass_${activeCell.row}_${activeCell.column} .sheetErrDiv`);
                    const errDivSheet = document.querySelectorAll(`.sheetErrDiv`);
                    if (errDiv !== null) {
                        errDiv.remove();
                    }

                    if (errDivSheet && errDivSheet.length) {
                        errDivSheet.forEach(el => el.remove());
                    }

                    if ((cell.id === "quantity") && cell.className === cellChange.className && cell.className.includes('errorCellGRNrev')) {

                      const updatedClassName = cell.className.split(" ").filter(className => className !== "errorCellGRNrev").join(" ");

                      return {
                        ...cell,
                        className: updatedClassName,
                      };
                    }
                  }

                  return { ...cell };
                });
              });
              setGrnSheetData([...updatedSheetData]);
              setCellChange({});
        }, 100);

        return () => clearTimeout(timeout);
      }, [cellChange]);

    function onChangeSheet(data){
         setGrnSheetData(data);
         setSpreadsheetErrors([]);
        const initialSheetData = JSON.parse(localStorage.getItem("grnList"));
        let jsonSpreadsheetData = JSON.stringify( data );
        let SpreadsheetData = JSON.parse(jsonSpreadsheetData);

        const updatedSpreadsheetData = SpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                sheetRow=sheetRow.map((sheetData,celInd)=>{
            if(sheetData && sheetData.id ==='receivedQty'){
                if(!isNaN(sheetRow[iIndexes.ITEM_QTY]?.value)){
                    sheetData.value = Number(initialSheetData[activeCell.row][4].value)+ Number(sheetRow[iIndexes.ITEM_QTY]?.value);
                    return {...sheetData,className:`cellClass1`}
                }else{
                            return {...sheetData}
                        }

                        }
                        if(sheetData && sheetData.id ==='quantity'){
                            if(isNaN(sheetRow[iIndexes.ITEM_QTY]?.value)){
                                return {...sheetData,value:''}
                            }
                                    }

                    return sheetData;
                    }



                    )
            }



            return sheetRow;
        } );
        setGrnSheetData([...updatedSpreadsheetData])
    }


    // save create grn

    const saveSheetData = (e) => {
        clearTimeout(spreadSheetErrTimout)
        // updateButtonTxtStatus(true, e, 'Saving...', true);
        const postItems = [];
        setSpreadsheetErrors([]);
        let inSheetData = [ ...grnSheetData ];
       const saveGrn= inSheetData.map( (cellRow, idx) => {
            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE]);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME]);
            const unit = validateCellAndGetData(cellRow[iIndexes.ITEM_UNIT]);
            const requestedQty = Number(validateCellAndGetData(cellRow[iIndexes.ITEM_REQ_QTY]));
            const receivedQty = Number(validateCellAndGetData(cellRow[iIndexes.ITEM_RECI_QTY]));
            const quantity = validateCellAndGetData(cellRow[iIndexes.ITEM_QTY]);
            const remarks = validateCellAndGetData(cellRow[iIndexes.ITEM_REMARKS]);


            if (itemCode && itemName && unit && (typeof requestedQty==='number' && requestedQty>=0) && (typeof receivedQty==='number' && receivedQty>=0) && (typeof quantity==='number' && quantity>=0) && remarks) {
                postItems.push({
                    itemCode,
                    itemName,
                    unit,
                    requestedQuantity: parseInt(requestedQty),
                    receivedQuantity: parseInt(receivedQty),
                    quantity: parseInt(quantity),
                    remarks
                });
            }
            else if (!quantity) {
                cellRow=cellRow.map((cell,celInd)=>{
                    const itemNameCheck=cellRow.some(cl=>{
                        if(cl.id==='itemCode'&&cl.value){
                            return cl
                        }
                    });

                    if ( cell && cell?.required === true && cell?.readOnly === false ) {
                        if(itemNameCheck && cell.id==='quantity' && (!cell.value || cell.value===undefined) && !cell.className.includes('errorCellGRNrev')){

                            return {...cell,className:`errorCellGRNrev cellClass ${cell.className}`}
                        }

                    }
                    return cell;
                    }

                    )
                setSpreadsheetErrors([ { message: 'Please fix the errors and try again.' } ]);
            }
            return cellRow;
        });


        setGrnSheetData(saveGrn);

        if (postItems.length === 0) {
            // show global alert message
            // setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'We did not find anything to save!' } ]);
            return false;
        }

        if ( !postItems.length ) {
            return false;
        }

        const data = {
            id: purchaseOrderId,
            warehouseId,
            grnId: null,
            invoiceNo: (invoiceObj.invoiceNo || ''),
            invoiceDate: (invoiceObj.invoiceDate || ''),
            items: postItems
        }
        // GET /api/purchaseOrders/findById/{poid}
        // GET /api/purchaseOrders/grns/findById/' + $scope.grnId

        APIEndpoint.post('purchaseOrders/saveGrn', data)
        .then( apiResp => {
            /**
             * Update success response
             */
            updateButtonTxtStatus(true, e, 'Redirecting...', true, { defaultButtonText: 'Save'} );
            setGrnSheetData(initialData);
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiResp.msg || 'Successfully created GRN.' } ]);
            setTimeout( () => {
                goBack();
            }, 1500);
        })
        .catch( (error) => {
            // api response error handling
            const apiData = JSON.parse(error.message);
            setSpreadsheetErrors([]);
            ReactDOM.flushSync( () => {
                if ( apiData?.data.errors ) {
                    const errorMsg = apiData.data.errors.message;
                    const errorArr = apiData.data.errors.errors[0].message;
                    setSpreadsheetErrors([ ...spreadsheetErrors, { message: errorMsg, error: 1 },...errorArr ]);
                    spreadSheetErrTimout=setTimeout(() => {
                        setSpreadsheetErrors([])
                    }, 1500);
                }
                else if (apiData.msg) {
                    setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiData.msg, error: 1 } ]);

                }
                else {
                    setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'Something went wrong, please try again later!', error: 1 } ]);

                }
            });

        });

    };

     // create popup itemlist

       const showItemListPopup = (eltPosition, activeCellIn) => {
        if (activeCellIn.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'block';
                targetEltCodes.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltCodes.style.left = eltPosition.left.toFixed() + 'px';
            }
        } else if (activeCellIn.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'block';
                targetEltNames.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltNames.style.left = eltPosition.left.toFixed() + 'px';
            }
        }
    }

    const handleActiveCellChange = (activeCell) => {
        setActiveCell(activeCell)

        if ( grnSheetData[activeCell.row][activeCell.column] ) {
            const cellDetailsObj = grnSheetData[activeCell.row][activeCell.column];
            if ( cellDetailsObj.hasOwnProperty('showPopup') && cellDetailsObj.showPopup === true ) {

                const sourceElt = document.getElementsByClassName('cellClass_' + activeCell.row + '_' + activeCell.column)[0];
                if ( sourceElt ) {
                    const eltPosition = sourceElt.getBoundingClientRect();


                    showItemListPopup(eltPosition, activeCell);
                }

            }

        }
    }

    const onClickPopupListItem = (e, row) => {
        setPurchaseorderItemToSheet(row);
    }


        const setEditTransferItemsToSheet = (grnItems) => {
            let jsonSpreadsheetData = JSON.stringify( grnSheetData );
            let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

            grnItems.map( (item, i) => {
                updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = item.itemCode;
                updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = item.itemName;
                updatedSpreadsheetData[i][iIndexes.ITEM_UNIT].value = item.unit;
                updatedSpreadsheetData[i][iIndexes.ITEM_REQ_QTY].value = item.requestedQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_RECI_QTY].value = item.receivedQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_REMARKS].value = item.remarks;

                return item;
            });

            ReactDOM.flushSync( () => {
                setGrnSheetData( [ ...updatedSpreadsheetData ]);

            });
            localStorage.setItem("grnList",JSON.stringify([ ...updatedSpreadsheetData ]))
        };

    const setPurchaseorderItemToSheet = (item) => {
        if (Object.keys(item).length === 0) {
            return 0;
        }
        if (Object.keys(item).length === 0) {
            return 0;
        }

        let jsonSpreadsheetData = JSON.stringify( grnSheetData);
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                sheetRow[iIndexes.ITEM_CODE].value = item.itemCode;
                sheetRow[iIndexes.ITEM_NAME].value = item.itemName;
                sheetRow[iIndexes.ITEM_UNIT].value = item.unit;
                sheetRow[iIndexes.ITEM_REQ_QTY].value = item.requestedQuantity;
                sheetRow[iIndexes.ITEM_RECI_QTY].value = item.receivedQuantity;
                sheetRow[iIndexes.ITEM_REMARKS].value = item.remarks;

            }
            return sheetRow;
        });
        ReactDOM.flushSync( () => {
            setGrnSheetData( [ ...updatedSpreadsheetData ]);
        });

        setActiveCell({});
        hideItemListPopup();
    }

    const hideItemListPopup = () =>{
        // if (activeCell.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'none';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'none';
            }
        // }
    }

    const onCellCommitHandle = (prevCell, nextCell, coordinates) => {
        setCellChange(nextCell);
        const errLabel=document.querySelectorAll('.sheetErrDiv');
        if(errLabel.length>0){
        errLabel.forEach((el)=>el.remove());
        }
    }


    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                <div className="header m-2 mb-2 flexRow justifyContentSpaceBetween" >

                <div className="leftBlock alignItemsCenter flexRow">
                <button onClick={ (e) => saveSheetData(e) }
                className="alignItemsCenter add-item-button"><FaSave style={{marginRight:'5px'}}/>{ t('buttons.save') }</button> &nbsp;
                <><button onClick={ (e) => selectAllCells(e) }
                className="alignItemsCenter flexRow add-item-button-all m-3">{count%2===0?<BiSolidSelectMultiple  style={{marginRight:'5px'}} />:<FaRegCheckSquare  style={{marginRight:'5px'}} />}Select All</button>   <button onClick={ (e) => removeRow(e) }
                className="alignItemsCenter flexRow remove-button"><RiDeleteBin6Line style={{marginRight:'5px'}} />{ t('buttons.deleteRows') }</button></>&nbsp;
                </div>


                {spreadsheetErrors.length >= 1? <div className={`padding4 margTop9-5 alert alert-${spreadsheetErrors[0].message.includes('Successfully') ? 'success' : 'danger'}`}>
                {spreadsheetErrors.length >= 1 ? spreadsheetErrors[0]?.message : null}
                </div>:null}
                <button onClick={goBack}  >
                <img src={closeIcon} alt=""/>
                </button>
                </div>
                <div className="se-titlebar">
               {<span>{urlParams&&urlParams?`GRN for purchase order -${sheetTitle}`:null}</span>}
                   </div>
                <div className="sheet-container sm-scrollbar reconCreate">

                    <Spreadsheet data={grnSheetData}
                        onChange={onChangeSheet}
                        columnLabels={grnSheetLabels}
                        darkMode={true}
                        onActivate={handleActiveCellChange}
                        onCellCommit={onCellCommitHandle}

                    />

                    <div className="flexRow alignItemsCenter margTop8">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button" title={ t('spreadsheet.addmoreRows') }>{ t('buttons.addRows') }</button>
                    </div>
                    <div className = "cellSelectionBlockItemCodes sm-scrollbar">
                       <ul>
                        {
                            itemData.map((d,i)=>{
                                return <li key={i} onClick={(e) => onClickPopupListItem(e, d) }>{d.itemCode}</li>
                            })
                        }
                       </ul>
                    </div>
                    <div className = "cellSelectionBlockItemNames sm-scrollbar">
                       <ul>
                        {
                            itemData.map((d,i)=>{
                                return <li key={i} onClick={(e) => onClickPopupListItem(e, d) }>{d.itemName}</li>
                            })
                        }
                       </ul>
                    </div>
                </div>

                {
                    Boolean(state.isInvalidAccess) && (
                    <AlertModal
                        title={'Alert: Invalid Access'}
                        customModalStyle={ modelStyle }
                        description={ state.message }
                        onContinue={goBack}
                        onClose={goBack}
                    />
                    )
                }
            </div>
        </>
    );
}