import React,{useMemo,useState,useEffect} from "react";
import { getDateFromTimezone } from "./Cards";
import {SectorRenderer} from "../../sales/inner-components/renderers";

let items=[];
const CountTable = (props) => {
  return (<>  {props.mode==='paxSummary'?showPaxSummary(props):showSectorCapacity(props)}</>
  )
}
const showPaxSummary=(px)=>{
  const{paxSum}=px;
return(<div className="flexCol">
  <table className="sub-table">
    <thead>
      <tr>
        <th>Economy</th>
        <th>Premium Economy</th>
        <th>Business Class</th>
        <th>First Class</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{backgroundColor:" #001d2b",color:'#ffff',width:'auto'}}>{paxSum?.EC??""}</td>
        <td>{paxSum?.PC ??""}</td>
        <td>{paxSum?.BC ??""}</td>
        <td>{paxSum?.FC ??""}</td>
      </tr>
    </tbody>
  </table>
</div>)
}
const getCount=(c)=>{
  if(c){
    if (items.some(item => JSON.stringify(item) === JSON.stringify(c))) {
      return;
    }
    items.push(c);
  }
}

const showSectorCapacity=(px)=>{
  const {status}=px;
  let cap={};
  status?.forEach(({ class: className }) => {
    cap[className] = (cap[className] || 0) + 1;
  });
  getCount(cap);

  return(<div className="flexCol">
    <table className="sub-table">
      <thead>
        <tr>
          <th>Economy</th>
          <th>Premium Economy</th>
          <th>Business Class</th>
          <th>First Class</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{backgroundColor:" #001d2b",color:'#ffff',width:'auto'}}>{cap.EC??""}</td>
          <td>{cap.PC??""}</td>
          <td>{cap.BC??""}</td>
          <td>{cap.FC??""}</td>
        </tr>
      </tbody>
    </table>
  </div>)
  }
export const CateringSummary = (props) => {
  const { group, timezone,status,defaultTimezone,getCorrectedTailNo} = props
  const [paxSum, SetpaxSum] = useState({});
  const [itemsPax,setItemsPax]=useState([]);

  const summForpaxTotal=()=>{
    const sumPaxxed={};
    itemsPax?.forEach((item,index)=>{
      Object.keys(item).forEach((key)=>{
        sumPaxxed[key]=(sumPaxxed[key]||0)+item[key]
      })
    })
    return sumPaxxed;
  }
  const cachSum = useMemo(() => {
    return summForpaxTotal();
  }, [itemsPax]);

useEffect(() => {
  if(cachSum){
    SetpaxSum(cachSum);
  }
}, [cachSum])

useEffect(() => {
  items=[]
}, [])

useEffect(() => {
  if(items.length){
    setItemsPax(items)
  }
}, [items])


  return (
    <div className="flexCol cntrl-calender-summary op-aircraft-container ng-grid-right flexCol full-flex ag-theme-alpine-dark">
      <span>
          <div className='table-header'></div>
          <table>
            <tbody>
            <tr >
              <td>Flight Date</td>
              <td>{getDateFromTimezone(group.date, timezone,defaultTimezone).format("YYYY-MM-DD")}</td>
            </tr>
            <tr>
              <td>STD</td>
              <td>{getDateFromTimezone(group.startTime, timezone,defaultTimezone).format("YYYY-MM-DD HH:mm")}</td>
            </tr>
            <tr>
              <td>ETD</td>
              <td>{getDateFromTimezone(group.endTime, timezone,defaultTimezone).format("YYYY-MM-DD HH:mm")}</td>
            </tr>
            <tr>
              <td>Tail Number</td>
              <td>{group.sectors.length > 0 ?getCorrectedTailNo(group.sectors[0].tailNumber) : ''}</td>
            </tr>
            <tr>
              <td>Pax Summary</td>
              <td>
                <CountTable status={status} mode={"paxSummary"} paxSum={paxSum}/>
              </td>
            </tr>
            <tr>
            <td colSpan={2} className="spl-row-class fontExtrabold" style={{textAlign:'center'}}>Sectors</td>
            </tr>
            {
              group.sectors.map((sector, index) => {
                return (
                  <tr key={index}>
                    <td>{SectorRenderer({data:sector})}</td>
                    <td>
                      <CountTable mode={"paxSectors"} status={status.manifests[index]?.passengers??null} key={index}/>
                    </td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
      </span>

    </div>

  )
}