import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { useNavigate } from "react-router-dom";

import { DataGrid, EditModal } from "../../../common";
import { translationLength, updateButtonTxtStatus } from "../../../utils/commonFunctions";
import { APIEndpoint } from "../../../utils/axios";
import { FilterBodyTemplate } from "../../../utils/configs";
import searchIcon from '../../../assets/icons/search.svg';
import viewIcon from "../../../assets/view.svg";
import editIcon from "../../../assets/edit2.svg";
import grnIcon from "../../../assets/check.svg";
import * as ReactDOM from 'react-dom';
import CustomSelect from '../../../common/CustomSelect';
import usePermissions from '../../../utils/userPermissions';
const Columns = [
    { field: 'code', headerName: 'Transfer Code',resizable:true,
        minWidth: 167,flex:1, suppressSizeToFit:true
    },
    { field: 'source', headerName: 'From',resizable:true,
        minWidth: 250,flex:1, suppressSizeToFit:true,
        tooltipField: 'source.name',
        valueGetter: params => params.data ? params.data?.source.code + ' (' + params.data?.source.name + ')' : '',
    },
    { field: 'destination', headerName: 'To',resizable:true,
        minWidth: 250,flex:1, suppressSizeToFit:true,
        tooltipField: 'destination.name',
        valueGetter: params => params.data ? params.data?.destination.code + ' (' + params.data?.destination.name + ')' : '',
    },
    { field: 'createdAt', headerName: 'Created At',
        minWidth: 176,flex:1, suppressSizeToFit:true,
        valueGetter: params => {
            return params.data?.createdAt ? moment(params.data?.createdAt).format('DD-MM-YYYY hh:mm A') : '';
        }
    },
    { field: 'createdBy', headerName: 'Created By',resizable:true,
        minWidth: 180,flex:1, suppressSizeToFit:true,
        valueGetter: params => params.data ? params.data?.createdBy?.employeeName : ''
    },
    { field: 'finalizedAt', headerName: 'Finalized At',resizable:true,
        minWidth: 176,flex:1, suppressSizeToFit:true,
        valueGetter: params => {
            return params.data && params.data?.finalizedAt ? moment(params.data?.finalizedAt).format('DD-MM-YYYY hh:mm A') : '';
        }
    },
    { field: 'finalizedBy', headerName: 'Finalized By',resizable:true,
        minWidth: 180,flex:1, suppressSizeToFit:true,
        valueGetter: params => params.data ? params.data?.finalizedBy?.employeeName : ''
    },
    { field: 'status', headerName: 'Status',
        minWidth: 150,flex:1, suppressSizeToFit:true,
        valueGetter: params => params.data ? params.data?.status.toUpperCase() : ''
    },
    { field: 'items', headerName: 'Summary',resizable:true,
        minWidth: 150,flex:1, suppressSizeToFit:true,
        valueGetter: params => params.data ? params.data?.items?.length + ' items' : ''
    },
];

const EditFormFields = [
    { label: 'View Purchase Order Items', type: 'viewWarehouseTransferItems', attribute: 'items' }
];

export const WarehouseTransfers = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [state, setState] = useState({});
    const [perPageLimit, setPerPageLimit] = useState(10);
    const [searchKey, setSearchKey] = useState('');

    const [ sourceWarehouseOption, setSourceWarehouseOption ] = useState('');
    const [ destinationWarehouseOption, setDestinationWarehouseOption ] = useState('');
    const [filterWarehouses, setFilterWarehouse] = useState([]);

    const [actionItems, setActionItems] = useState([]);

    const [ modalTitle, setModalTitle ] = useState('View Purchase Order Items');
    const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
    const [modalCustomButtons, setModalCustomButtons] = useState(null);
    const [modalFormData, setModalFormData] = useState({});
    const modelStyle = { maxWidth: '80%' };

    const [editFormFields, setEditFormFields] = useState(EditFormFields);

    const { hasPermission: createWhsTx } = usePermissions('create', "inventory.warehouse-transfers");
    const { hasPermission: editWhsTx } = usePermissions('edit', "inventory.warehouse-transfers");
    const { hasPermission: viewWhsTx } = usePermissions('viewDetails', "inventory.warehouse-transfers");
    const { hasPermission: downloadWhsTx } = usePermissions('download', "inventory.warehouse-transfers");

    const preCompile = useMemo(()=>({
        selectSourceWarehouse: 'Source Warehouse',
        selectDestinationWarehouse: 'Destination Warehouse',
    }),[]);

    useEffect( () => {

        setActionItems([
            {
                title: '',
                attribute: 'viewItems',
            },
            // {
            //     title: 'Actions',
            //     attribute: 'Suspend',
            // },
            {
                title: '',
                attribute: 'GRN',
            }
        ]);

        getWarehouses();
    }, []);

    const getActionCellIcons = (cellDef, data) => {

        if (cellDef.attribute === 'viewItems' && viewWhsTx) {
            if (data && data.status === 'draft') {
                return [
                    {src: editIcon, value:"editItems", title: 'Edit Items'},
                ];
            }
            else {
                return [
                    {src: viewIcon, value:"viewItems", title: 'View Items'},
                ];
            }
        }

        if (cellDef.attribute === 'GRN' && editWhsTx) {

            if (data && data.status !== 'draft') {
                return [
                    {src: grnIcon, value:"grn", title: 'GRN'},
                ];
            }
            else if (data && data.status === 'draft') {
                return [
                    {src: grnIcon, value:"finalize", title: 'Finalize'},
                ];
            }
        }

        return [];
    };

    useEffect( () => {
        /**
         * Load filter(warehouses) only after translation is available
         */
        preCompile.selectSourceWarehouse = t('filters.selectSourceWarehouse');
        preCompile.selectDestinationWarehouse = t('filters.selectDestinationWarehouse');

    }, [t,preCompile]);

    const getWarehouseIdByName = (warehouseId) => {
        const warehouseObj = filterWarehouses.find( row => row.value === warehouseId );
        if (warehouseObj) {
            return warehouseObj.id * 1;
        }
        return '';
    };

    const fetchWarehouseTransferRows = async (page=1, perPage=10) => {
        const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
        filterData.page = page;
        filterData.limit = perPage;
        filterData.searchParam = searchKey;
        if (sourceWarehouseOption) {
            filterData.filters.push({ sourceId: getWarehouseIdByName(sourceWarehouseOption) });
        }
        if (destinationWarehouseOption) {
            filterData.filters.push({ destinationId: getWarehouseIdByName(destinationWarehouseOption) });
        }

        const res = await APIEndpoint.post('warehouseTransfers/process', JSON.stringify(filterData));
        return res;
    };

    const onActionInner = (action, row) => {
        let updatedEditFields = [];

        if (action.value === 'finalize') {
            setModalTitle('Finalize Warehouse Transfer - ' + row.code);

            updatedEditFields = [
                {
                    label: 'You will not be able to modify this transfer once you finalize it. Are you sure you want to continue?',
                    attribute: 'alertMessage', type: 'alertMessage'
                },
            ];

            setModalCustomButtons([

                { label: 'Finalize', attribute: 'button', type: 'button', disabled: false, className: 'submit-button', action: 'onSubmit'
                    // , action: (e) => {  onSubmit(e); return false; }
                }
            ]);
        }
        else if (action.value === 'editItems' && editWhsTx) {
            navigate('/procurement/warehouse-transfers/items/edit/' + row.id );
        }
        else if (action.value === 'grn') {
            navigate('/procurement/warehouse-transfers/grn/list/' + row.destination.id + '/' + row.id );
        }
        else {
            /** Here, Resetting default input fields to array then updating */
            setEditFormFields(EditFormFields);
            updatedEditFields = EditFormFields.filter( field => {
                if (field.type === 'viewWarehouseTransferItems') {
                    setModalTitle('Warehouse Transfers - ' + row.code );
                    field.value = row;
                }
                return field;
            });

            setModalCustomButtons([[]]);
        }
        setEditFormFields( updatedEditFields );

        setState((_prev) => {
            return { ..._prev, selected: { ...row, action: action.value} };
        });
    };

    const onAddItemHandler = async (e) => {

        if ( Object.keys(filterWarehouses).length === 0 ) {
            updateButtonTxtStatus( false, e, t('oneWord.pleaseWait'), true, { defaultButtonText: t('buttons.createTransfer') });
            return false;
        }

        setModalTitle('Create Transfer');

        ReactDOM.flushSync( () => {
            setState( (_prev) => {
                delete _prev.selected;
                return { ..._prev, selected: { action: 'AddNewItem' } };
            });

            setEditFormFields([

                /**
                * The options array should contain objects.
                * Required keys are "name" and "value" but you can have and use any number of key/value pairs.
                */
                { key: 1, label: 	t('filters.selectSourceWarehouse'), attribute: 'sourceWarehouse', name: 'sourceWarehouse', type: 'selectSearch'
                    , defaultValue: ''
                    , value: ''
                    , className: 'select-search-cls'
                    , options: filterWarehouses
                    // , getOptions: () => {
                    //     return filterWarehouses;
                    // }
                    /**
                        * multiple(false): true/false
                        * search(false): true/false
                        * closeOnSelect(true): true/false
                        * debounce(0): Number of ms to wait until calling get options when searching.
                        * autoComplete(off): Options are on/off
                        * autoFocus(false): true/false
                        */
                    , multiple: false
                    , search: true
                    // , closeOnSelect: false
                    // , debounce: 1000
                    , autoComplete: 'on'
                    , autoFocus: false
                    , disabled: false, placeholder: t('oneWord.startTyping')
                },
                { key: 2, label: t('filters.selectDestinationWarehouse'), attribute: 'destinationWarehouse', name: 'destinationWarehouse', type: 'selectSearch'
                    , defaultValue: ''
                    , value: []
                    , className: 'select-search-cls'
                    , options: filterWarehouses
                    // , getOptions: () => {
                    //     return filterWarehouses
                    // }
                    /**
                        * multiple(false): true/false
                        * search(false): true/false
                        * closeOnSelect(true): true/false
                        * debounce(0): Number of ms to wait until calling get options when searching.
                        * autoComplete(off): Options are on/off
                        * autoFocus(false): true/false
                        */
                    , multiple: false
                    , search: true
                    // , closeOnSelect: false
                    // , debounce: 1000
                    , autoComplete: 'on'
                    , autoFocus: false
                    , disabled: false, placeholder: t('oneWord.startTyping')
                }
            ]);


            setModalCustomButtons([

                { label: t('buttons.continue'), attribute: 'button', type: 'submit', disabled: false, className: 'submit-button', action: 'onSubmit'
                    // , action: (e) => {  onSubmit(e); return false; }
                }
            ]);

            // fetchDistributorList();

            /** Here, Resetting default input fields to array then updating */
            // const updatedEditFields = EditFormFields.filter( (field) => {
            //     if (distributors.length > 1 && field.attribute === 'distributorName') {
            //         field.disabled = false;
            //         field.options = distributors;
            //         field.getOptions = () => {
            //             return distributors;
            //         }
            //         setModalFormStatusObj({ text: '' });
            //     }
            //     return field;
            // });

            // setEditFormFields( updatedEditFields );

        });

    }

    // const updateEditFormInputs = (reportTypes=null, warehouseArr=null) => {
    //     const updatedEditFields = editFormFields.filter( (field) => {

    //         if (reportTypes && field.attribute === 'reportType') {
    //             field.disabled = false;
    //             field.options = reportTypes;
    //             field.getOptions = () => {
    //                 return reportTypes;
    //             }
    //             setModalFormStatusObj({ text: '' });
    //         }
    //         if (warehouseArr && field.attribute === warehouseArr['id'] ) {
    //             field.disabled = false;
    //             field.options = warehouseArr['data'];
    //             field.getOptions = () => {
    //                 return warehouseArr['data'];
    //             }
    //             setModalFormStatusObj({ text: '' });
    //         }

    //         return field;
    //     });
    //     console.log('New Updatyed:', updatedEditFields);
    //     setEditFormFields( updatedEditFields );
    // };

    const onAction = (e, action, row) => {
        onActionInner(action, row);
    };

    const onSearch = (e) => {
        setSearchKey(e.target.value);
    };

    const onFilterWarehouseChange = (e, sourceDest) => {
        if (sourceDest === 'source') {
            setSourceWarehouseOption(e?.value);
        }
        else if (sourceDest === 'destination') {
            setDestinationWarehouseOption(e?.value);
            fetchWarehouseTransferRows();
        }
    }

    const getWarehouses = async () => {
        try {
        const resp = await APIEndpoint.get('warehouses');
        if (resp.result.length > 0) {
            const options = []; //{key: 0, label: preCompile.warehouse, value: ''}
            resp.result.filter( (fieldValue, index) => {
              options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.value}`, name: `${fieldValue.value}`, id: `${fieldValue.id}`});
              return fieldValue;
            });
            setFilterWarehouse(options);
        }
        } catch (error) {

        }

    }

    const onModalFormInputChange = (e, newFormData) => {
        setModalFormStatusObj({text:''})
        const { name, value } = e.target;

        let newUpdates = modalFormData ? { ...modalFormData } : {}
        newUpdates[name] = value;

        setModalFormData( { ...newFormData } );
    }

    const updateEditFormValues = async (e, updatedFormObj, inpObj) => {
        ReactDOM.flushSync( () => {
            if (e) {
                const selectedWarehouse = filterWarehouses.find( item => item.value === e);

                /** Set to respective fields */
                const updatedEditFields = editFormFields.filter( (field) => {

                    if ( selectedWarehouse ) {
                        if ( inpObj.attribute === field.attribute &&
                                ( field.attribute === 'sourceWarehouse' || field.attribute === 'destinationWarehouse' ) ) {
                            field.value = selectedWarehouse.label;
                        }
                    }

                    return field;

                });

                setEditFormFields( _prev => {
                    return [ ...updatedEditFields ] ;
                });
            }
        })


    };
    const onClose = (e, action, row) => {
        setState((_prev) => {
            return { ..._prev, selected: false ,resetDataSource:!_prev.resetDataSource};
        });
        setModalFormStatusObj({ text: '' });
    };

    const validateFormInputs = (body, e) => {
        setModalFormStatusObj({ text: '' });
        if (!body) {
            setModalFormStatusObj({ error: 'Input validation failed!' });
            return false;
        }

        if ( state.selected?.action === 'AddNewItem' ) {
            if ( !body.sourceWarehouse || !body.destinationWarehouse ) {
                setModalFormStatusObj({ error: 'Please select from and to warehouses!' });
                return false;
            }
            if ( body.sourceWarehouse  === body.destinationWarehouse ) {
                setModalFormStatusObj({ error: 'Source and destinations cannot be the same warehouse!' });
                return false;
            }

        } else if ( state.selected?.action === 'finalize' ) {

            if ( !body.transferId || !body.warehouseId ) {
                setModalFormStatusObj({ error: 'Unauthorized access! Please try again after some time by reloading the current page.' });
                return false;
            }
        }
        // else if ( state.selected?.action === 'Suspend' ) {
        //     if ( !body.remarks ) {
        //         setModalFormStatusObj({ text: 'Please enter suspend remarks!', error: 1 });
        //         return false;
        //     }
        // }
        return true;
    };

    const onEditSubmit = async (e, updated) => {
        try {
            if ( state.selected?.action === 'viewItems' ) {
                setState((_prev) => {
                    return { ..._prev, selected: false };
                });
            }
            else if ( state.selected?.action === 'finalize' ) {
                const btnText = 'Finalize';
                updateButtonTxtStatus( false, e, 'Finalizing...', true, { defaultButtonText: btnText });
                const body = {
                    ...updated,
                    transferId: state.selected.id,
                    warehouseId: state.selected.source.id,
                };

                if (!validateFormInputs(body, e) ) {
                    updateButtonTxtStatus(false, e, 'Finalize failed', false, { defaultButtonText: btnText });
                    return false;
                }
                APIEndpoint.post('warehouseTransfers/finalize', body)
                .then(resp => {
                    if (resp.success) {
                        updateButtonTxtStatus( true, e, btnText, false, { defaultButtonText: btnText });

                        // setIsReloadTableData(true);
                        fetchWarehouseTransferRows();
                        setModalFormStatusObj({ text: resp.message });
                        setTimeout(()=>{
                            onClose()
                          },1500)

                    }
                    else {
                        // You do not have permission to perform this action.
                        updateButtonTxtStatus( false, e, 'Finalize failed', false, { defaultButtonText: btnText });
                        setModalFormStatusObj({ error: JSON.stringify(resp) });
                    }

                }).catch(error => {
                    updateButtonTxtStatus( false, e, 'Finalize failed', false, { defaultButtonText: btnText });
                    let msg = 'Something went wrong. Please try again after some time.';
                    try {
                        msg = error.data.msg;
                    }
                    catch(e) { }

                    setModalFormStatusObj({ error: msg });
                });
            }
            else if ( state.selected?.action === 'AddNewItem') {
                setModalFormStatusObj({ text: 'Submitting...' });

                const body = {
                    ...updated,
                };
                if (!validateFormInputs(body, e) ) {
                    return false;
                }

                navigate('/procurement/warehouse-transfers/items/create/' + updated.sourceWarehouse_id + '/' + updated.destinationWarehouse_id );
            }
            // if ( state.selected?.action === 'Suspend') {
            //     setModalFormStatusObj({ text: 'Submitting...' });
            //     const body = {
            //         ...updated,
            //         indentId: state.selected.id,
            //         // warehouseId: state.selected.warehouseId,
            //     };
            //     if (!validateFormInputs(body, e) ) {
            //         return false;
            //     }
            //     APIEndpoint.post('indents/suspendLOI', body)
            //     .then(resp => {
            //         if (resp.success) {
            //             setIsReloadTableData(true);
            //             setModalFormStatusObj({ text: resp.message });
            //         }
            //         else {
            //             setModalFormStatusObj({ text: JSON.stringify(resp), error: 1 });
            //         }
            //     }).catch(error => {
            //         setModalFormStatusObj({ text: 'Something went wrong. Please try again after some time.', error: 1 });
            //     });
            // }


        }
        catch(err) {
            if ( err.hasOwnProperty('data') ) {
                setModalFormStatusObj({ error: 'Error: ' + err.data.msg });
            }
            else {
                setModalFormStatusObj({ error: 'Error: ' + err.message });
            }
        }
    };

    const fetchTransferState = async () => {
        if (!state.selected) return;
      };

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
            {
                    <>
                        {/* <div className={ "flexRow alignItemsCenter margBot10 justifyContentSpaceBetween" } >
                            <h5>Warehouse Transfer</h5> */}
                            {/* <button className='download dropdownStyle1-TextMedium margRight' onClick={(e) => onDonwloadReportsClick(e)}><span><img className="icon-size" alt='download reports' src={downloadMasterIcon} /><u>{t('filters.downloadReports')}</u></span></button> */}
                        {/* </div> */}
                        <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>
                        <div className="width100 flexRow justifyContentCenter alignItemsCenter">
                                <div className='margLeft margBot10 margTop8  underline'>
                                    <span><img  className="icon-size" alt='search' src={searchIcon} /></span>
                                    <span><input className="search margBot10" onChange={onSearch} type="search" placeholder="Search" value={searchKey}/></span>
                                </div>
                                </div>
                        </div>
                        <div className='flexRow gap10 width100 margTop8 margBot10  justifyContentFlexStart alignItemsCenter'>


                            <div className="flexRow justifyContentFlexEnd alignItemsBaseline">

                                <button className='saleType-div m-2'>
                                    {/* <select className='select-item darkBlue-bg' onChange={ (e) => onFilterWarehouseChange(e, 'source') } value={sourceWarehouseOption} id="saleTypeId">
                                        <option key="0" value="">{preCompile.selectSourceWarehouse}</option>
                                        {filterWarehouses.map( (item, index) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))
                                        }
                                    </select> */}
                                    <CustomSelect
                                    options={filterWarehouses}
                                    placeHolder={preCompile.selectSourceWarehouse}
                                    onChange={ (e) => onFilterWarehouseChange(e, 'source') }
                                    />
                                </button>
                                <button className='saleType-div m-2'>
                                    {/* <select className='select-item darkBlue-bg' onChange={ (e) => onFilterWarehouseChange(e, 'destination') } value={destinationWarehouseOption} id="saleTypeId">
                                        <option key="0" value="">{preCompile.selectDestinationWarehouse}</option>
                                        {filterWarehouses.map( (item, index) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))
                                        }
                                    </select> */}
                                    <CustomSelect
                                    options={filterWarehouses}
                                    placeHolder={preCompile.selectDestinationWarehouse}
                                    onChange={ (e) => onFilterWarehouseChange(e, 'destination') }
                                    />
                                </button>

                            </div>

                           {createWhsTx? <div className='flexRow width100 justifyContentFlexEnd'>

                                <div className="flexRow justifyContentFlexEnd alignItemsBaseline">
                                    <button className="add-item-button m-2" onClick={ onAddItemHandler }>
                                        {t('buttons.createTransfer')}
                                    </button>
                                </div>
                                </div>:<></>}



                        </div>

                        <DataGrid
                            className="full-flex"
                            columns={Columns}
                            initialPageLimit={perPageLimit}
                            showDefaultFilters={false}
                            searchKey={searchKey}
                            selWarehouse={ sourceWarehouseOption }
                            selDesignation={destinationWarehouseOption}
                            getRows={ fetchWarehouseTransferRows }
                            rowSelection="multiple"
                            actions={actionItems}
                            getActionCellIcons={getActionCellIcons}
                            onAction={ (e, action, data) => { return onAction(e, action, data) } }
                            onAddItemHandler={ () => {} }
                            columnResize={false}
                            resetDataSource={state.resetDataSource}
                        />
                    </>
                }
                {
                   fetchTransferState() && state && Boolean(state.selected) ? (
                        <EditModal
                            title={ modalTitle }
                            customModalStyle={ modelStyle }
                            onClose={onClose}
                            onSubmit={onEditSubmit}
                            modalFormStatusObj={modalFormStatusObj}
                            modalCustomButtons={modalCustomButtons}
                            fields={ editFormFields.map( (elt) => {
                                    if (state.selected.action === 'viewItems'
                                        // || state.selected.action === 'Suspend'
                                        ||( state.selected.action === 'downloadPOReportsPopup' && downloadWhsTx )) {
                                        return {...elt, initialValue: state.selected[elt.attribute] || ''}
                                    }
                                    else {
                                        return {...elt, initialValue: '' }
                                    }
                                })
                            }
                            showFieldsInColumn={true}

                            onChange={ (e, updated, inpObj = '') => {

                                setModalFormStatusObj({text:''})
                                    if (typeof e === 'string') { // Search select
                                        updateEditFormValues(e, updated, inpObj);
                                    }
                                    else {
                                        onModalFormInputChange(e, updated);
                                    }
                                }
                            }
                        />
                    )
                    : null
                }
                {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
            </div>
        </>
    )
}