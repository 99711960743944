
import React, { useState } from 'react';
import { APIEndpoint } from "../../../utils/axios";
import { DataGrid, EditModal, DeleteConf, Modal, UploadModal, UploadButton,DeviceStat, DownloadButton, Loading } from "../../../common";
import { onDownloadXlsFile, translationLength } from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import Edit2 from "../../../assets/edit2.svg";
import View from "../../../assets/view.svg";
import usePermissions from '../../../utils/userPermissions';
import { MIMETypes } from '../../../utils/configs';
import HeaderSelectAll from '../../../common/HeaderSelectAll';

const AddFormField = [
  { label: 'Device Name', attribute: 'name', type: 'text', required: true, },
  { label: 'Device Model', attribute: 'model', type: 'text', required: true, },
  { label: 'IMEI', attribute: 'macId', type: 'text', required: true, },
  { label: 'Bill Number Start After', attribute: 'startFrom', type: 'number', required: true, },
  {
    label: 'Device Type',
    attribute: 'deviceType',
    type: 'select',
    required: true,
    options: [
      { value: null, label: 'Select Device Type' },
      { value: 'phone', label: 'Android phone' },
      { value: 'tablet', label: 'Android tablet' },
      { value: 'mswipe', label: 'Mswipe' },
      { value: 'ngx', label: 'Ngx Device' },
      { value: 'mosambee', label: 'Mosambee' },
    ]
  },
];

const EditFormFields = [
  AddFormField[0], AddFormField[1], AddFormField[2], AddFormField[4]
]

const DeviceUtilizationFields = [
  { label: 'Date', attribute: 'date', type: 'date', required: true, },
]

const DetailsFields = [
  { label: "Name", attribute: 'name' },
  { label: "Model", attribute: 'model' },
  { label: "Unique ID", attribute: 'macId' },
  { label: "App Version", attribute: 'appVersion' },
  { label: "Activity Info", heading: true },
  { label: "Last Login At", attribute: 'lastLoginAt' },
  { label: "Last Login From", attribute: 'lastLoginLoc' },
  { label: "Battery Level", attribute: 'batteryLevel' },
  { label: "Last Sector", attribute: 'lastSector' },
  { label: "Sync Status", attribute: 'status' },
  { label: "Last Synced At", attribute: 'lastSyncedAt' },
  { label: "Crew Member", attribute: 'crewName' },
  { label: "Last Reported Location", attribute: 'lastLoginLoc' }
]

export const DevicesPage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ resetDataSource: false, showAddForm: false, editRow: null, detailsRow: null, deleteRows: null });
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [checkbox,setCheckBox]=useState(false)
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [selectAll,setSelectAll]= useState({isChecked:false,label:'Name'});
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');

  const { hasPermission: createDevices } = usePermissions('create', "configuration.fsc.devices");
  const { hasPermission: editdevices } = usePermissions('edit', "configuration.fsc.devices");
  const { hasPermission: uploaddevices } = usePermissions('upload', "configuration.fsc.devices");
  const { hasPermission: deletedevices } = usePermissions('delete', "configuration.fsc.devices");
  const { hasPermission: viewDetailsdevices } = usePermissions('view', "configuration.fsc.devices");
  const { hasPermission: downloaddevices } = usePermissions('download', "configuration.fsc.devices");

  const HeaderCheckBoxRenderer=()=>{
    return (
      <HeaderSelectAll
      selectAll={selectAll}
      setSelectAll={setSelectAll}/>
  );

  }

  const Columns = [
    { field: 'name', headerName: 'Name', checkboxSelection: checkbox,resizable:true,flex:1, minWidth: 180,width:190, headerComponentFramework : HeaderCheckBoxRenderer,suppressSizeToFit:true},
    { field: 'model', headerName: 'Model',  flex:1, minWidth: 140, suppressSizeToFit:true },
    { field: 'macId', headerName: 'Unique ID',  resizable:true,flex:1, minWidth: 170, suppressSizeToFit:true },
    { field: 'deviceType', headerName: 'Device Type ',  flex:1, minWidth: 140, suppressSizeToFit:true },
    { field: 'expiryDate', headerName: 'Expiry Date',  flex:1, minWidth: 130, suppressSizeToFit:true },
  ]

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 50,
    page: 1,
    searchParam: ''
  };
  const fetchDevices = async (iPageNo = 1, iPageLimit = 50, iSearchText = '') => {
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    try {
      const res = await APIEndpoint.post('devices/process', filterData);
      if(res.count === 0 || res.items.length === 0){
        setCheckBox(false)
        setState((_) => {
          return { ..._, resetDataSource: false};
        })
      }
      else{
        setCheckBox(true)
        setState((_) => {
          return { ..._, resetDataSource: true};
        })
      }
        return res;
    } catch (error) {

    }
  }
  const handleAddDevice = () => {
    setState((currentState) => {
      return { ...currentState, showAddForm:createDevices? true :false}
    })
  }
  const handleDeleteDevices = (e, selected) => {
    setState((currentState) => {
      return { ...currentState, deleteRows: selected }
    })
  }
  const addNewDevice = async (iNewDeviceInfo) => {
    try {
      const res = await APIEndpoint.post('device', iNewDeviceInfo);
      if (res.success) {
        setModalFormStatusObj({ text: res.message });

        handleModalClose(null, true, 6000);
      }
      else {
        setModalFormStatusObj({ text: 'Added new device details.' });
        handleModalClose(null, true, 6000);
      }

    } catch (error) {
      let errMsg = 'Failed to add new device details!';
      const errObj = JSON.parse(error.message);

      if ( errObj?.data?.msg ) {
        errMsg = errObj?.data?.msg;
      }
      else if ( errObj?.data?.message ) {
        errMsg = errObj?.data?.message;
      }

      setModalFormStatusObj({ error: errMsg });
      console.error('Log: addNewDevice', errMsg);
    }
  }

  const updateDevice = async (iUpdatedInfo) => {
    try {
      const res = await APIEndpoint.put('device/' + state.editRow.itemId, iUpdatedInfo);

      if (res.success) {
        setModalFormStatusObj({ text: res.msg});

        handleModalClose(null, true, 6000);
      }
      else {
        setModalFormStatusObj({ text: 'Saved new device details.', error: 0 });
        handleModalClose(null, true, 6000);
      }

    } catch (error) {

      let errMsg = 'Failed to save new device details!';
      const errObj = JSON.parse(error.message);

      if ( errObj?.data?.msg ) {
        errMsg = errObj?.data?.msg;
      }
      else if ( errObj?.data?.message ) {
        errMsg = errObj?.data?.message;
      }

      setModalFormStatusObj({ error: errMsg });
      console.error('Log: updateDevice', errMsg);
    }
  }

  const onUploadFileChangeHandler = async (e) => {
    const file=e.target.files[0];
    setUploadedFile(e.target.files[0]);
    setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

    const formData = new FormData();
    formData.append('scheduleLater', false);
    formData.append('listName', undefined);
    formData.append('targetSectorType', undefined);
    formData.append('stores', []);
    formData.append('warehouses', []);
    formData.append('file', file);

    try {
      await APIEndpoint.post('devices/upload', formData);
      setUploadedFile([]);
      setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
      setState( (_prev) => {
        return { ..._prev,resetDataSource:true}
      });

    }
    catch(error) {

      e.target.value = null;
      setUploadedFile([]);
      let { status, data } = JSON.parse( error.message );
      const msgHeading = data?.Error || data?.message || error.message;

      let msgContent = "";
      if (status === 400) {
        if(data.errors){
                data.errors?.forEach( _ => {
            const lineNum = _.lineNumber;
            const errorMsgArr = [];
            _.errors.forEach(erObj => {
              errorMsgArr.push( erObj.field + ' | ' + erObj.message + ' \n<br>' );
            });

            msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
        });
        }else{
          msgContent += `Failed to upload devices sheet.`;
        }
      } else if(status===0 && !data){
        msgContent += `Error occurred while uploading devices sheet!<br>Please try again.`;
    }
      else {
        msgContent += `Error occurred while uploading devices sheet!<br>Please fix the errors and re-upload.`;
      }
      setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                              );
    }
};

  const handleModalClose = (e, iReset=false, autoCloseMSec = null) => {

    if (autoCloseMSec) {
      if ( !typeof autoCloseMSec === 'number') {
        autoCloseMSec = parseInt(autoCloseMSec) || 100;
      }
    }

    if ( autoCloseMSec ) {
      let timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);

        if (iReset) {
          setModalFormStatusObj({ text: '' });
          setState((currentState) => {
            return { ...currentState, devUtilModal: false, showAddForm: false, editRow: null, detailsRow: null, deleteRows: null, resetDataSource: iReset ? true : state.resetDataSource }
          });
        }

        setState((_) => {
          return { ..._, resetDataSource: false }
        });
      }, autoCloseMSec);
    }
    else {

      if (iReset) {
        setModalFormStatusObj({ text: '' });
        setState((currentState) => {
          return { ...currentState, devUtilModal: false, showAddForm: false, editRow: null, detailsRow: null, deleteRows: null, resetDataSource: iReset ? true : state.resetDataSource }
        });
      }
      else {
        setModalFormStatusObj({ text: '' });
        setState((currentState) => {
          return { ...currentState, devUtilModal: false, showAddForm: false, editRow: null, detailsRow: null, deleteRows: null, resetDataSource: iReset ? true : state.resetDataSource }
        });
      }
    }
    if(selectAll.isChecked){
      setSelectAll((_)=>({..._,isChecked:!_.isChecked}));
    }
  }

  const handleAddDeviceSubmit = (e, values) => {
    let isValid = true;
    if (values != null) {
      isValid = AddFormField.every(((field) => {
        if (field.required && !values[field.attribute]) {
          return false;
        }
        return true
      }));
      if (isValid) {
        addNewDevice(values);
      }
    }
  }
  const handleEditDeviceSubmit = (e, values) => {
    let isValid = true;
    if (values != null) {
      isValid = EditFormFields.every(((field) => {
        if (field.required && !(values[field.attribute] || state.editRow[field.attribute])) {
          return false;
        }
        return true
      }));
      if (isValid) {
        updateDevice({ ...state.editRow, ...values })
      }
    }
  }
  const onUploadClose=()=>{
    setState((_) => ({ ..._, showUpload: false }))
    setUploadedFile([]);
    setUploadFileStatusBlock('');
  }

  const getDeviceDetails = (device) => {
    if (!device || !device.activity) {
      let details = JSON.parse(JSON.stringify(device));
      details.appVersion = 'N/A'
        return details;
    }

    let details = JSON.parse(JSON.stringify(device));
    const { activity } = details;
    details.appVersion = activity.appVersion || 'N/A';
    details.lastLoginAt = moment(activity.connectedAt).format('YYYY-MM-DD hh:mm A');
    details.lastLoginLoc = `${activity.store.origin} (${activity.store.originName})`;
    details.batteryLevel = `${activity.batteryLevel}%`;
    details.lastSector = activity.store.storeCode;
    details.status = 'Synced';
    details.lastSyncedAt = moment(activity.disconnectedAt).format('YYYY-MM-DD hh:mm A');
    details.crewName = `${activity.employee.employeeName} (${activity.employee.employeeCode})`;
    return details;
}
  const handleRowAction = (e, action, row) => {
    e.stopPropagation();
    let editRow = null, detailsRow = null;
    if (action.value === 'edit' && editdevices) {
      editRow = JSON.parse(JSON.stringify(row))
    } else {
      detailsRow = getDeviceDetails(row)
    }
    setState((currentState) => {
      return { ...currentState, editRow: editRow, detailsRow: detailsRow }
    })
  }
  const handleDeleteConfirm = async () => {
    const items = state.deleteRows.map((_) => _.itemId);
    try {
      const res = await APIEndpoint.put('devices/remove', { ids: items });
      if(res.success){
        setModalFormStatusObj({ text: res.message, error: 0 });
         setState((_) => {
      return { ..._, resetDataSource: true };
    })
        handleModalClose(null, true, 3000);
      }
    } catch (error) {
      console.error('delete devices', error)
      setModalFormStatusObj({ text: error.message });
    }
  }
  const handleUploadDevices = (e) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0]);
    return APIEndpoint.post('devices/upload', formData, { 'Content-Type': "multipart/form-data" })
  }
  const onDownloadSampleFile = async (e) => {
    onDownloadXlsFile('samples/downloads/Device_Upload_Sample', 'Device_Upload_Sample')
  }
  const handleDeviceUtilization = async (e, updated) => {
    let utilDate = new Date();
    if (updated && updated.date) {
      utilDate = new Date(updated.date)
    }
    setState((_) => ({ ..._, devUtilModal: true, utilDate: utilDate, devUtilInfo: null }));
    try {
      const res = await APIEndpoint.post('devices/utilization', { date: moment(utilDate).format() });
      if(res){
        setState((_) => ({ ..._, devUtilModal: true, utilDate: utilDate, devUtilInfo: res.utilizationInfo }));
      }
    } catch (error) {
    }

  }
  return (
    <div className="device-page op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margBot10">
         {uploaddevices?   <UploadButton
              className="buttonWithMargin"
              onClick={() => setState((_) => ({ ..._, showUpload: true }))}
              label={<u>{t('pages.pagesContent.devices.uploadBtn')}</u>} />:<></>}
            {downloaddevices?
            <><DeviceStat
            className="buttonWithMargin"
            onClick={handleDeviceUtilization}
              label={<u>{t('pages.pagesContent.devices.deviceUtilizationBtn')}</u>}/>

            <DownloadButton
              downloadLink='devices/activityReport'
              fileName={`Device_Activity_Report-${moment().format('DD-MM-YYYY')}`}
              label={<u>{t('pages.pagesContent.devices.downloadBtn')}</u>} /></>:<></>}
          </div>
          <DataGrid
            columnResize
            showDefaultFilters
            className='full-flex'
            initialPageLimit={filterData.limit}
            columns={Columns}
            pageType={false}
            getRows={fetchDevices}
            selectAll={selectAll}
            resetDataSource={state.resetDataSource}
            addButton={createDevices?{
              title: t('pages.pagesContent.devices.addButtonTitle'),
              onClick: handleAddDevice
            }:null}
            deleteButton={deletedevices?{
              title: t('pages.pagesContent.devices.deleteButtonTitle'),
              onClick: handleDeleteDevices,
            }:null}
            actions={[
              { detail: editdevices?[{ src: Edit2, value: "edit" ,title:'Edit'}]:[] },
              { detail: viewDetailsdevices?[{ src: View, value: "details",title:'Details' }]:[] }
            ]}
            onAction={handleRowAction}
          />
        </>
      }

      {
        state.showAddForm &&
        <EditModal
          title={t('pages.pagesContent.devices.addModalTitle')}
          modalFormStatusObj={modalFormStatusObj}
          onClose={handleModalClose}
          onSubmit={handleAddDeviceSubmit}
          fields={AddFormField.map((elt) => {
            return { ...elt, initialValue: '' }
          })
          }
        />
      }
      {
        Boolean(state.editRow) &&
        <EditModal
          title={t('pages.pagesContent.devices.editModalTitle')}
          modalFormStatusObj={modalFormStatusObj}
          onClose={handleModalClose}
          onSubmit={handleEditDeviceSubmit}
          fields={EditFormFields.map((elt) => {
            return { ...elt, initialValue: state.editRow[elt.attribute] }
          })
          }
        />
      }
      {
        Boolean(state.deleteRows) &&
        <DeleteConf
          description={t('pages.pagesContent.devices.deleteModelDesc')}
          onCloseDelete={handleModalClose}
          onContinue={handleDeleteConfirm}
          modalFormStatusObj={modalFormStatusObj}
        />
      }

      {
        state.showUpload &&
        <UploadModal
          uploadAPI={handleUploadDevices}
          modelTitle={t('pages.pagesContent.devices.uploadBtn')}
          onUploadClose={onUploadClose}
          onDownloadSampleFile={onDownloadSampleFile}
          uploadedFile = { uploadedFile }
          onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
          uploadFileStatusBlock = { uploadFileStatusBlock }
          allowedExtensions={ MIMETypes.excel }
        />
      }
      {
        Boolean(state.devUtilModal) &&
        <EditModal
          showFieldsInColumn
          buttonLabel={t('buttons.okay')}
          title={t('pages.pagesContent.devices.deviceDetails')}
          modalFormStatusObj={modalFormStatusObj}
          onClose={handleModalClose}
          onSubmit={(e) => handleModalClose(e)}
          onChange={handleDeviceUtilization}
          fields={
            DeviceUtilizationFields.map((elt) => {
              return { ...elt, initialValue: state.utilDate, maxDate: state.utilDate }
            })
          }>
          {
            Boolean(state.devUtilInfo) ?
              <div className='flexCol dev-util'>
                <div className='flexRow alignItemsCenter justifyContentSpaceBetween item'>
                  <span className=''>{t('pages.pagesContent.devices.totalNumOfDevices')}</span>
                  <span className=''>{state.devUtilInfo.totalNumberOfDevices}</span>
                </div>
                <div className='flexRow alignItemsCenter justifyContentSpaceBetween item'>
                  <span className='key'>{t('pages.pagesContent.devices.numOfDevicesUsed')}</span>
                  <span className=''>{state.devUtilInfo.numberOfDevicesUsed}</span>
                </div>
              </div>
              :
              <Loading />
          }
        </EditModal>
      }
        {
        Boolean(state.detailsRow) &&
      <Modal
      title={t('pages.pagesContent.devices.detailsModalTitle')}
      onClose={handleModalClose}
      onSubmit={handleModalClose}>
      <div>

      {DetailsFields.filter((field) =>["name", "model", "macId", "appVersion"].includes(field.attribute)).map((field, index) => (
      <table>
      <tbody>
      <tr key={index}>
      <td style={{padding:'5px',whiteSpace:'nowrap',backgroundColor:`${index%2===0?'#0d2536':"none"}`}}>
      <div  style={{width:'13rem',margin:'0.5rem'}} className={`${field.heading ? 'h6' : 'header-footer-text'}`}>{field.label}</div>
      </td>
      <td style={{padding:'5px',whiteSpace:'nowrap',backgroundColor:`${index%2===0?'#0d2536':"none"}`}}>
      <div  style={{width:'13rem',margin:'0.5rem'}} className='header-footer-text'>{state.detailsRow[field.attribute]}</div>
      </td>
      </tr>
      </tbody>
      </table>
     ))}

    <div style={{ marginTop: "1rem", marginBottom: "1rem" }} className="h6">Activity Info</div>

    {state.detailsRow?.activity ? (
    <>
      {DetailsFields.filter((field) =>["lastLoginAt","lastLoginLoc","batteryLevel","lastSector","status","lastSyncedAt","crewName"].includes(field.attribute) ).map((field, index) => (
      <table >
      <tbody>
      <tr key={index}>
      <td style={{padding:'5px',whiteSpace:'nowrap',backgroundColor:`${index%2===0?'#0d2536':"none"}`}}>
      <div  style={{width:'13rem',margin:'0.5rem'}} className={`${field.heading ? 'h6' : 'header-footer-text'}`}>{field.label}</div>
      </td>
      <td style={{padding:'5px',whiteSpace:'nowrap',backgroundColor:`${index%2===0?'#0d2536':"none"}`}}>
      <div  style={{width:'13rem',margin:'0.5rem'}} className='header-footer-text'>{state.detailsRow[field.attribute]}</div>
      </td>
      </tr>
      </tbody>
      </table>
      ))}
    </>
      ) : (
    <div className='device-info-msg'>No info available</div>
     )}
      </div>
      </Modal>
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
       }
    </div>

  )
}