/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import searchIcon from '../../../assets/icons/search.svg';
import { DataGrid ,NGODatePicker} from "../../../common";
import { APIEndpoint } from "../../../utils/axios";
import { formatDateByTimezone, translationLength, truncateString } from "../../../utils/commonFunctions";
import CustomSelect from "../../../common/CustomSelect";
const Columns = [
    {field: 'itemCode', headerName: 'Item Code',resizable:true,pinned:'left', minWidth: 120,valueGetter:params=>truncateString(params?.data?.itemCode,10),tooltipField:"itemCode"},
    {field: 'itemName', headerName: 'Item Name',resizable:true,tooltipField:'itemName', minWidth: 120,pinned:'left',width:150,
    valueGetter: (params) => {
        if (params.data&&params.data.itemName) {
           return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
        }
        return null;
      },
    },
    {field: 'openingStock', headerName: 'Opening Stock', cellStyle: params => {
       if(params.value<0){
        return {color: '#EB0A0A',fontWeight:"700"};
       }else if(params.value>0){
        return {color: 'rgb(10 255 12)'};
       }else{
        return null;
       }
      }, minWidth: 95,resizable: true},
    {field: 'grn', headerName: 'GRN', minWidth: 75,resizable: true},
    {field: 'reverseGrn',headerName: 'Reverse GRN', minWidth: 80,resizable: true},
    {field: 'transferIn', headerName: 'Transfer In', minWidth: 80,resizable: true},
    {field: 'offload', headerName: 'Offload', minWidth: 80,resizable: true},
    {field: 'wastage', headerName: 'Wastage', minWidth: 80,resizable: true},
    {field: 'variance', headerName: 'Variance', minWidth: 80,resizable: true},
    {field: 'uplifted', headerName: 'Uplifted',resizable: true, minWidth: 80, valueFormatter: params=>{
        if(params.value === undefined && params.data!==undefined){
           return 0
         }
     }},
    {field: 'transferOut',headerName: 'Transfer Out', minWidth: 80,resizable: true},
    {field: 'pliferage', headerName: 'Pliferage', valueFormatter: params=>{
        if(params.value === undefined && params.data!==undefined){
           return 0
         }
     }, minWidth: 80,resizable: true},
    {field: 'onboardSales', headerName: 'Onboard Sales', minWidth: 80,resizable: true},
    {field: 'stockAdjustment', headerName: 'Stock Adjustment',valueFormatter: params=>{
        if(params.value === undefined && params.data!==undefined){
           return 0
         }
     }, minWidth: 110,resizable: true},
    {field: 'closingStock',headerName: 'Closing Stock', cellStyle: params => {
        if(params.value<0){
            return {color: '#EB0A0A',fontWeight:"700"};
        }else if(params.value>0){
            return {color: 'rgb(10 255 12)'};
        }else{
            return null;
        }
       }, minWidth: 80,resizable: true},
    {field: 'unit',headerName: 'Unit', minWidth: 100,},

];

export const WarehouseInventory = (props) => {
  const { t } = useTranslation();
    const [warehouses, setWarehouses] = useState([]);
    const [wareHouseArr,setWareHouseArr]=useState([]);
    const [pageLimit, setPageLimit] = useState(10);
    const [searchKey, setSearchKey] = useState('');
    const [date, setDate] = useState(new Date());
    const [selWarehouse, setSelWarehouse] = useState('');
    const [selFiltersMessage, setSelFiltersMessage] = useState('');
    const [ isReloadTableData, setIsReloadTableData ] = useState(false);
    const [noResultsMessage, setNoResultsMessage] = useState("Please select Warehouse & Date filters")

    const preCompile = {
        warehouse: t('selectButtons.selWarehouse')
    }

    React.useEffect(() => {
        getWarehouses();
    }, [t]);

    React.useEffect(() => {
        getWarehousesDetails();
    }, [selWarehouse]);

    React.useEffect(() => {
        setSelFiltersMessage(t('filters.selectWarehouseNDateFilters'))
        if(selWarehouse&&selWarehouse.length > 0 && date !== null) {
            setSelFiltersMessage('');
            fetchWarehouseInventoryRows();
        }
    }, [t, date, selWarehouse]);


    const getEffectiveDate=(dateChange)=>{
        if(dateChange!==undefined){
            let effTime = formatDateByTimezone(dateChange);
            return effTime;
        }
    }

    const getWarehouses = async () => {
        try {
           const resp = await APIEndpoint.get('warehouses');
        if (resp.result.length > 0) {
            setWareHouseArr(resp.result);
            const options = [{key: 0, label: preCompile.warehouse, value: ''}];
            resp.result.filter( (fieldValue, index) => {
              options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.value}`});
              return fieldValue;
            });
            setWarehouses(options);
        }
        } catch (error) {

        }

    }


    const filterData = {
        action: 4,
        collection: '',
        filterOperation: 'or',
        filters: [],
        limit: 10,
        page: 1,
        searchParam: ''
    };
    const fetchWarehouseInventoryRows = async (page=1, perPage=10) => {
        if( selWarehouse?.length > 0 && date!==null){
            setIsReloadTableData(undefined)
            filterData.page = page;
            filterData.limit = perPage;
            filterData.searchParam = searchKey;
            filterData.filters=[{ targetDate: getEffectiveDate(date)}, { warehouseId: getWarehousesDetails()}]
            const res = await APIEndpoint.post('warehouses/inventory/process', JSON.stringify(filterData));
            return res;
        }
        else{
            setNoResultsMessage("Please select Warehouse & Date filters")
            setIsReloadTableData(!isReloadTableData)
            return {
                items:[],count:0
            }
            // return {}
        }
    };

    const onSearch = (e) => {
        setSearchKey(e.target.value);
    };

    const onItemTypeChange = (e) => {
        setSelWarehouse(e?.value);
    }

    const getWarehousesDetails = () =>{
        if(selWarehouse&&selWarehouse.length>0){
            const wareHs = wareHouseArr.find(w=>w.value===selWarehouse)
            return wareHs?.id;
        }
    };
    const onDateSelect = (e) => {
       setDate(e.target.value)
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                {
                    <>

                        <div className="flexRow width100 margBot10 margTop8 justifyContentFlexStart">
                        <button className='saleType-div m-2' style={{height:'2.8rem'}}>
                                <CustomSelect
                                  options={warehouses}
                                  placeHolder="Search Warehouse"
                                  onChange={onItemTypeChange}
                                />
                                </button>
                                <div className={`dialog__description`}>
                                 <NGODatePicker
                                    isclearable={false}
                                    value={date}
                                    placeholder={t('effectiveDate')}
                                    placeholdertext={t('datePickerPlaceholder')}
                                    onChange={onDateSelect}
                                    maxDate={new Date()}
                                />
                            </div>
                            <div className="flexRow width100 margBot10 margTop8 justifyContentSpaceBetween">
                            <div className=" margLeft margBot10 underline margTop10">
                                <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
                                <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={props.searchKey}/></span>
                            </div>
                            </div>
                        </div>
                            {/* <div className="flexRow  alignItemsBaseline">

                            </div> */}









                        <>
                            <DataGrid
                                className="full-flex"
                                columns={Columns}
                                initialPageLimit={pageLimit}
                                showDefaultFilters={false}
                                searchKey={searchKey}
                                selWarehouse={selWarehouse}
                                date={date}
                                getRows={fetchWarehouseInventoryRows}
                                noResultsMessage={ noResultsMessage }
                                rowSelection="multiple"
                                columnResize={true}
                            />
                        </>

                    </>
                }
                {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
            </div>
        </>
    );
}
