/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { DataGrid, EditModal } from "../../../common";
import { APIEndpoint } from "../../../utils/axios";
import { FilterBodyTemplate } from "../../../utils/configs";

import searchIcon from '../../../assets/icons/search.svg';
import viewIcon from "../../../assets/view.svg";

const columns = [
    {
        field: 'code', headerName: 'GRN Code', filter: true, checkboxSelection: false,
        minWidth: 180,
        tooltipField: 'code'
    },
    {
        field: 'destinationWarehouseCode', headerName: 'Warehouse', filter: true,
        minWidth: 260,
        tooltipField: 'destinationWarehouseCode',
        valueGetter: params => params.data ? params.data.destinationWarehouseCode + ' (' + params.data.destinationWarehouseName + ')' : ''
    },
    {
        field: 'sourceWarehouseCode', headerName: 'From', filter: true,
        minWidth: 260,
        tooltipField: 'sourceWarehouseName',
        valueGetter: params => params.data ? params.data.sourceWarehouseCode + ' (' + params.data.sourceWarehouseName + ')' : ''
    },
    { field: 'createdAt', headerName: 'Created At', filter: true,
        minWidth: 140,
        tooltipField: 'createdAt',
        valueGetter: params => {
            if ( params.data ) {
                return moment(params.data.createdAt).format('DD-MM-YYYY');
            }
        }
    },
    { field: 'createdBy', headerName: 'Created By', filter: true,
        minWidth: 200,
        tooltipField: 'createdBy.employeeName',
        valueGetter: params => params.data ? params.data.createdBy?.employeeName : ''
    },
    {
        field: '', headerName: 'Summary', filter: false,
        minWidth: '140',
        valueGetter: params => params.data ? params.data.items.length + ' items' : ''
    }
];

const EditFormFields = [
    { label: 'View GRN Details', type: 'viewWarehouseTransferGRNDetails', attribute: 'items' }
];

export const WarehouseTransfersGRNList = () => {
    const { t } = useTranslation();
    const urlParams = useParams();
    const navigate = useNavigate();

    const [state, setState] = useState({});
    const [editFormFields, setEditFormFields] = useState(EditFormFields);
    const [perPageLimit, setPerPageLimit] = useState(5);
    const [searchKey, setSearchKey] = useState('');
    const [editTransferDetails, setEditTransferDetails] = useState({});

    const [actionItems, setActionItems] = useState([]);
    const noResultsMessage = 'No GRNs found';

    const [ modalTitle, setModalTitle ] = useState('View Purchase Order Items');
    const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
    const [modalCustomButtons, setModalCustomButtons] = useState(null);
    const modelStyle = { maxWidth: '80%' };

    useEffect ( () => {
        if ( urlParams.hasOwnProperty('warehouseId') && urlParams.hasOwnProperty('transferId') ) {
            getEditTransferDetailsById(urlParams.transferId);
        }
    }, [urlParams]);

    useEffect( () => {
        setActionItems([
            {
                key: 1,
                title: '',
                attribute: 'viewWarehouseTransferGRNDetails',
            },
        ]);

        fetchGRNListAPI();
    }, []);

    const getActionCellIcons = (cellDef, data) => {

        if (cellDef.attribute === 'viewWarehouseTransferGRNDetails') {
            // if (data && data.status === 'draft') {
            //     return [
            //         {src: editIcon, value:"editItems", title: 'Edit Items'},
            //     ];
            // }
            // else {
            return [
                {src: viewIcon, value:"viewWarehouseTransferGRNDetails", title: 'View Items'},
            ];
            // }
        }

        return [];
    };

    const getEditTransferDetailsById = (transferId) => {
        APIEndpoint.get('warehouseTransfers/findById/' + transferId )
        .then( resp => {
            if (resp) {
                const warehouseTransferDetails = resp.data;
                setEditTransferDetails(warehouseTransferDetails);
            }
        })
        .catch( error => {
            console.log('Invalid page access!, Warehouse transfer details not found for given ID.', error.message);

            setState({ ...state, isInvalidAccess: true,
                message: "Invalid page access!, Warehouse transfer details are not found, On click OK, you will be redirected to Warehouse Transfer page." });
        });
    };

    const fetchGRNListAPI = async (page = 1, limit = 15) => {
        const filterData  = JSON.parse( JSON.stringify(FilterBodyTemplate) );
        filterData.page = page;
        filterData.limit = limit;
        if (searchKey) {
            filterData.searchKey = searchKey;
        }

        const res = await APIEndpoint.post('warehouses/' + urlParams.warehouseId + '/warehouseTransfers/' + urlParams.transferId + '/grns/process', JSON.stringify(filterData) );
        return res;
    };


    const onSearch = (e) => {
        setSearchKey(e.target.value);
    };

    const onActionInner = (action, row) => {
        let updatedEditFields = [];

        if (action.value === 'viewWarehouseTransferGRNDetails') {
            // setEditFormFields(EditFormFields);
            updatedEditFields = EditFormFields.filter( field => {
                if (field.type === 'viewWarehouseTransferGRNDetails') {
                    setModalTitle('GRN - ' + row.code );
                    field.value = row;
                }
                return field;
            });

            setModalCustomButtons([[]]);
        }

        setEditFormFields( updatedEditFields );

        setState((_prev) => {
            return { ..._prev, selected: { ...row, action: action.value} };
        });
    };

    const onAction = (e, action, row) => {
        onActionInner(action, row);
    };

    const onClose = (e, action, row) => {
        setState((_prev) => {
            return { ..._prev, selected: false };
        });
        setModalFormStatusObj({ text: '' });
    };

    const onCreateGRNHandler = () => {
        navigate('/procurement/warehouse-transfers/grn/create/' + urlParams.warehouseId + '/' + urlParams.transferId);
    }

    return (
    <>
        <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
            {
                <>
                    <div className={ "flexRow alignItemsCenter margBot10 justifyContentSpaceBetween" } >
                        <h6>GRN for Warehouse Transfer - { editTransferDetails.code }</h6>
                        {/* <button className='download dropdownStyle1-TextMedium margRight' onClick={(e) => onDonwloadReportsClick(e)}><span><img className="icon-size" alt='download reports' src={downloadMasterIcon} /><u>{t('filters.downloadReports')}</u></span></button> */}
                    </div>
                    <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween alignItemsCenter'>

                            <div className="underline">
                                <span><img  className="icon-size" alt='search' src={searchIcon} /></span>
                                <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey}/></span>
                            </div>
                            <div className="flexRow justifyContentFlexEnd alignItemsBaseline">
                                    <button className="add-item-button m-2" onClick={ onCreateGRNHandler }>
                                    {t('buttons.createGRN')}
                                    </button>

                        </div>
                    </div>

                    <DataGrid
                        className="full-flex"
                        columns={columns}
                        initialPageLimit={perPageLimit}
                        showDefaultFilters={false}
                        searchKey={searchKey}
                        getRows={ fetchGRNListAPI }
                        noResultsMessage={ noResultsMessage }
                        rowSelection="multiple"
                        actions={actionItems}
                        getActionCellIcons={getActionCellIcons}
                        onAction={ (e, action, data) => { return onAction(e, action, data) } }
                        onAddItemHandler={ () => {} }
                        columnResize={true}
                    />
                </>
            }
            {
                    state && Boolean(state.selected) ? (
                        <EditModal
                            title={ modalTitle }
                            customModalStyle={ modelStyle }
                            onClose={onClose}
                            // onSubmit={onSubmit}
                            modalFormStatusObj={modalFormStatusObj}
                            fields={ editFormFields.map( (elt) => {
                                    if (state.selected.action === 'viewWarehouseTransferGRNDetails') {
                                        return {...elt, initialValue: state.selected[elt.attribute] || ''}
                                    }
                                    else {
                                        return {...elt, initialValue: '' }
                                    }
                                })
                            }
                            showFieldsInColumn={true}
                            modalCustomButtons={modalCustomButtons}
                        />
                    )
                    : null
                }
        </div>
    </>
    );
};