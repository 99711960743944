/* eslint-disable react-hooks/exhaustive-deps */


import React, { useEffect, useState,useRef } from 'react';
import { IoMdGitMerge } from "react-icons/io";
import { IoPerson } from "react-icons/io5";
import {Modal} from '../../../../common';
import { APIEndpoint } from '../../../../utils/axios';
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import tick from '../../../../assets/icons/tick.svg';
import LoadingGIF from '../../../../assets/loading.gif';
import { BsCheckLg } from "react-icons/bs";
import { IoArrowForwardOutline } from "react-icons/io5";
import { MdLeakRemove } from "react-icons/md";
import { TbReload } from "react-icons/tb";
import { GiHazardSign } from "react-icons/gi";
import { MdAddBox,MdDelete,MdEditSquare } from "react-icons/md";
import deposit from "../../../../assets/deposit.svg";
import {formatDateByTimezone,formatCamelString} from '../../../../utils/commonFunctions';
import moment from 'moment';
import { CollapsebleSectorInstance } from '../../../../common';

function SectorsEditIsolate(props) {
const {loading,delOffld}=props;

  const [reload,setReload]=useState(false);
  const [state, setState] = useState({editSector:true});
  const [sectorIsolateData, setSectorIsolateData] = useState({});
  const [seletedSec, setSelectedSec] = useState([]);
  const [modalFormStatusObj, setModalFormStatusObj] = useState({text:""});
  const [sectors,setSectors]=useState([])
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [isOpen,setIsopen]=useState(false);
const buttonDrdn=useRef(null);
const dropdownOpt=useRef(null);
const prevId=useRef();

const toggleDropdown=()=>{
  setIsopen(!isOpen)
}
  const handleView = (id) => {
    setSelectedItemIndex(prevIndex => (prevIndex === id ? null : id));
  };

useEffect(() => {
  props.setEditedSectors(sectors);
  setSectors(props.header);
  }, [props])


useEffect(() => {
  if(props.navigateInstanceId){
    handleView(props.navigateInstanceId)
  }
  }, [props.navigateInstanceId])

  const handleClickOutside=(e)=>{
  if(buttonDrdn.current && !buttonDrdn.current.contains(e.target) && dropdownOpt.current && !dropdownOpt.current.contains(e.target)){
  setIsopen(!isOpen)
  }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

const getSectorData = async (id) => {

  try {
    const res = await APIEndpoint.get(`sandboxing/containerIsolates/${id}`);
    if (res.isolate) {
      setSectorIsolateData((prev) => ({ ...prev, ...res.isolate }));

    }
  } catch (error) {

  }
};

useEffect(() => {
  if (props.id!==prevId.current) {
    const { id } = props;
    getSectorData(id);
  }
  prevId.current=props.id;
}, [props.id,delOffld]);

useEffect(async() => {
  if(props.delOffld){
    const resEditDoc =await props.deleteRefresh();
    if(resEditDoc && resEditDoc.sectors){
      setSectors(resEditDoc.sectors);
    }
  }

}, [delOffld]);

const handleChange = (val) => {
  setSelectedValue(val);
  setIsopen(!isOpen)
};

const handleReset = () => {

  let timerId = setTimeout(() => {
    clearTimeout(timerId);
    setModalFormStatusObj({ text: '' })
    setState((_prev) => {
      return { ..._prev, selected: false}
    });
    setSelectedValue("");
    props.setEditAction(prev=>!prev)
  }, 2500)
}

const CrewLabels = (list) => {
  if (list.length) {
    return (
      <div className="crew-container">
        {list.map((item, index) => (
          <div className="employee-crew" key={item.employeeId}>
            <div className='flexRow'>
              {item.employeeName}&nbsp;({item.role})
            </div>
            <div className='flexRow'>{item.employeeCode}</div>
          </div>
        ))}
      </div>
    );
  } else {
    return "No crew members added yet";
  }
}


  const getSectorStatus = (sec) => {
    switch (sec) {
      case 'open':
        return <div className="status-instance" style={{ backgroundColor: '#03a5fc' }}>Active</div>;
      case 'locked':
        return <div className="status-instance" style={{ backgroundColor: '#f03630' }}>Locked</div>;
        case 'cancelled':
        return <div className="status-dynamic" style={{ backgroundColor: '#f03630' }}>Cancelled</div>;
        case 'unknown':
        return <div className="status-dynamic" style={{ backgroundColor: '#1d3f4f' }}>Unknown</div>;
      case 'committed':
        return <div className="status-instance" style={{ backgroundColor: '#09ab16' }}>Committed</div>;
        case 'completed':
          return <div className="status-dynamic" style={{ backgroundColor: '#09ab16' }}>Completed</div>;
      case 'commitScheduled':
        return <div className="status-instance" style={{ backgroundColor: '#f28016' }}>Commit Scheduled</div>;
      default:
        return "";
    }
  };

  const getSyncStatus = (sec) => {
    switch (sec) {
      case 'syncPending':
        return <div className="status-dynamic" style={{ backgroundColor: '#f03630' }}>{formatCamelString(sec)}</div>;
        case 'partiallySynced':
        return<><div className="status-dynamic" style={{ backgroundColor: '#ff9d15' }}>{formatCamelString(sec)}</div>&nbsp;<span>0%</span></> ;
        case 'synced':
        return<><div className="status-dynamic" style={{ backgroundColor: '#09ab16' }}>{formatCamelString(sec)}</div>&nbsp;<span>100%</span></> ;
      default:
        return <div className="status-dynamic" style={{ backgroundColor: '#1d3f4f' }}>{formatCamelString(sec)}</div>;
    }
  };

const detachSector=async(secId)=>{
  const payload={
    isolateId: props.id,
    sectorId:secId
}
  try {
    const resDetach = await APIEndpoint.post('sandboxing/containerIsolates/isolatedSectors/detach',payload);
  if(resDetach.message.length){
    props.setEditAction(prev=>!prev);
    props.setActionStatus({action:'Detach Sector',status:'Sector detached successfully'})
  }
  else{
  }
  } catch (error) {

  }
}
const upliftRefresh=async(e,uId)=>{
props.saveEdits(e,'upliftRefresh',uId)
const resEditDoc =await props.deleteRefresh();
if(resEditDoc && resEditDoc.sectors){
  setSectors(resEditDoc.sectors);
}
}

const arrangeSectors=(pos,index)=>{

  switch (pos) {
    case 'up':
      if(index>0){
        props.setActionStatus({action:'Move Up',status:'Sector moved up'})
        props.setSort(true);
            setSectors(prev=>{
      let updatedSector=[...prev];
      let temp=updatedSector[index];
      updatedSector[index]=updatedSector[index-1];
      updatedSector[index-1]=temp;
      return updatedSector;
    })
      }

      break;
      case 'down':
        if(index<sectors.length-1){
        props.setActionStatus({action:'Move Down',status:'Sector moved down'})
        props.setSort(true);
          setSectors(prev=>{
           let updatedSector=[...prev];
            let temp=updatedSector[index];
            updatedSector[index]=updatedSector[index+1];
            updatedSector[index+1]=temp;
            return updatedSector;
          })
        }

        break;

    default:
      break;
  }
}

const mergeSectors=async(data,action='mergeSectors')=>{
  setState( (_prev) => {
    return { ..._prev, selected: {data,action: action }}
  });

}

const getPermissionGrid=()=>{
  if(state.selected){
      const {data}=state?.selected;
return sectors.filter((sec)=>sec.key===data.key).filter((fl=>fl.name!==data.name)).map(m=>({...m,mergeSel:false}))
  }
  else{
    return []
  }

}

const upliftsSectors=(sect,list)=>{
  if (list.length) {
    return (
      <div className="flexRow">
        {list.map((item, index) => (
          <button  key={index} className="status-dynamic" onClick={()=>props.inventoryDetails(sect,index,'uplifts',props.editDetails,sectorIsolateData.sectors[index])} style={{ width:list.length<=1?'17rem':'10rem',marginRight: '2px',whiteSpace:'nowrap',backgroundColor: '#06cc48' }}>Uplift from {item.warehouse?.warehouseCode}</button>
        ))}
      </div>
    );
  } else {
    return null;
  }
}


const setMergeState=(arr,obj)=>{

setSelectedSec(arr.map(sec=>{
  if(sec.name===obj.name){
    return {...sec,mergeSel:!sec.mergeSel}
  }else{
    return {...sec,mergeSel:false}
  }

}))
}
const listItems = !seletedSec.length?getPermissionGrid().map((list,index) =>
{
  return(<React.Fragment key={index}>

        <li className="list-group-item">
        <div className='row'>
          <div  style={{width:'100%'}}>
            <div   onClick={()=>setMergeState( getPermissionGrid(),list)} className='flexRow justifyContentSpaceBetween'style={{padding:'5px'}}>
            <div className='flexRow justifyContentFlexStart'  ><strong>{list?.name}</strong>{list?.isolateAffinity?.name?<span className='code'>{list?.isolateAffinity?.name}</span>:<></>} </div>  <div className='flexRow justifyContentFlexEnd'><IoPerson size={17} style={{ marginRight: '3px' }} /><span style={{ paddingTop:'3px'}}>{list.createdBy.employeeName} @ {moment(list.createdAt).format('DD-MM-YYYY  hh:mm A')} <span className='margLeft8'>{list.mergeSel? <img width={'20px'} height={'20px'} alt='tick' src={tick} />:<></>}</span></span></div>
            </div>
          </div>
        </div>
      </li>

  </React.Fragment>)}
 ):seletedSec.map((list,index) =>
 {
   return(<React.Fragment key={index}>

         <li className="list-group-item">
         <div className='row'>
           <div  style={{width:'100%'}}>
             <div   onClick={()=>setMergeState(seletedSec,list)} className='flexRow justifyContentSpaceBetween'style={{padding:'5px'}}>
             <div className='flexRow justifyContentFlexStart'  ><strong>{list?.name}</strong>{list?.isolateAffinity?.name?<span className='code'>{list?.isolateAffinity?.name}</span>:<></>} </div>  <div className='flexRow justifyContentFlexEnd'><IoPerson size={17} style={{ marginRight: '3px' }} /><span style={{ paddingTop:'3px'}}>{list.createdBy.employeeName} @ {moment(list.createdAt).format('DD-MM-YYYY  hh:mm A')} <span className='margLeft8'>{list.mergeSel? <img width={'20px'} height={'20px'} alt='tick' src={tick} />:<></>}</span></span></div>
             </div>
           </div>
         </div>
       </li>

   </React.Fragment>)}
  );

  const handleMergeSectors=async()=>{

    const targetSector=seletedSec.find(fl=>fl.mergeSel);
    if(targetSector&&state.selected){
      const {data}=state.selected;
    const payload={
      "isolateId": props.id,
      "documentId": props.editDetails?.id,
      "edits": [
          {
              "name": "MergeSectors",
              "params": {
                  "target": {
                      id: targetSector.id,
                      key: targetSector.key,
                      name: targetSector.name
                  },
                  "source": {
                      "id": data.id,
                      "key": data.key,
                      "name": data.name
                  }
              }
          }
      ]
  }

  try {

    const res = await APIEndpoint.post(`sandboxing/containerIsolates/editDocument/edits`,payload);
  if(res){
  props.setActionStatus({action:'MergeSectors',status:'Sectors Merged'});
  handleModalCancelEvent();
  props.setEditAction(prev=>!prev)
  }

  } catch (error) {

  }
    }


  }
const resolvexC=(data,action)=>{
  setState( (_prev) => {
    return { ..._prev, selected: {data,action: action }}
  });

}
const getResolveOptions=(optArr)=>{

const res=optArr.map((item,index)=>{
  const labelMapping = {
    'acceptTarget': 'Accept value on the left',
    'acceptSource': 'Accept value on the right'
  };

  return {
    key: index + 1,
    label: labelMapping[item],
    value: item
  };

})

return res;
}


const handleResolveConflicts=async()=>{


if(!selectedValue)return;
const {data}=state?.selected;
  const payload={
    "isolateId": props.id,
    "documentId": props.editDetails.id,
    "edits": [
        {
            "name": "ResolveConflicts",
            "params": {
                "target": {
                    "id": data.id,
                    "key": data.key,
                    "name": data.name
                },
                "resolutions": [
                    {
                        "conflict": data.mergeConflicts.conflicts[0],
                        "strategy": selectedValue
                    }
                ]
            }
        }
    ]
}

  try {
    const res = await APIEndpoint.post(`sandboxing/containerIsolates/editDocument/edits`,payload);
    if (res) {
      setModalFormStatusObj({text:res?.message||'Conflicts Resolved Successfully'})
      handleReset();
    }
  } catch (error) {
    const parseErr=JSON.parse(error.message);
    setModalFormStatusObj({error:parseErr.data?.message||'Something went wrong. Please try again after some time.'})
    handleReset();
  }
}

const handleUndoMerge=async(item,action)=>{

    const payload={
      "isolateId": props.id,
      "documentId": props.editDetails.id,
      "edits": [
          {
              "name": "UndoSectorMerge",
              "params": {
                  "target": {
                      "id": item.id,
                      "key": item.key,
                      "name": item.name
                  },

              }
          }
      ]
  }

    try {
      const res = await APIEndpoint.post(`sandboxing/containerIsolates/editDocument/edits`,payload);
      if (res) {
        props.setActionStatus({action:'Undo Merge Sectors',status:res?.message ||'Success'});
        props.setEditAction(prev=>!prev)
      }
    } catch (error) {
      const parseErr=JSON.parse(error?.message);
      props.setEditAction(prev=>!prev)
      props.setActionStatus({action:'Undo Merge Sectors',status:parseErr?.data?.message ||'Failed to undo merge'});
    }
  }

const handleModalCancelEvent=()=>{
  setState( (_prev) => {
    return { ..._prev, selected:false}
  });
  setIsopen(!isOpen)
  setSelectedSec([]);
}


  return (
    <>
    <ul className='list-group' style={{ display: 'flex', flexWrap: 'wrap',width:'100%' }}>
     {

     loading ? (
      <li className='flexRow justifyContentCenter alignitemsCenter'>
        <div className='flexRow'>
          <img src={LoadingGIF} style={{ width: '25px', height: '25px' }} alt="Loading" />
          <div className="margTop5">Setting up the Editor...</div>
        </div>
      </li>
    ) :
     sectors && sectors?.length? (
        sectors?.map((item, index) => (
          <React.Fragment key={index}>
            <li
              className={`flexRow col-xs-11 wrapper-sector justifyContentSpaceBetween ${
                index === selectedItemIndex ? 'selected' : ''
              }`}
              onClick={() => handleView(item.id)}
              style={{ cursor: 'pointer' }}
            >
              <div className="flexRow justifyContentCenter alignItemsCenter">
                <span className="justifyContentFlexStart instance-member fontExtrabold">{item?.key?.split("/")[0]}</span><span className="margLeft5 fontSemibold">{item.key.split("/")[1]}</span>
              </div>
              <div className="flexRow">
                <span className="justifyContentFlexEnd instance-member">
                  <IoPerson size={15} style={{ marginRight: '7px' }} />
                  {item.createdBy.employeeName}

                </span>
              </div>
            </li>
            {selectedItemIndex === item.id && (
              <CollapsebleSectorInstance id={item.id} selectedItemIndex={selectedItemIndex}>
              <div className='fitcontent justifyContentSpaceBetween col-xs-11 wrapper-sector'>
              {sectors[index].originalSector?<div className='flexRow alignItemsCenter textAlignLeft sector-origin-m'>This sector was created because original sector<span className='code'>{sectors[index].originalSector.name}</span> was locked at the time of sync.</div>:null }
              <div className='flexRow justifyContentSpaceBetween'>
            {sectors.length>0 && !item?.mergeConflicts?<><div className='flexRow justifyContentFlexStart'>
            {index===0 && sectors.length>1?
            <button className='grid-download-btnStyle1' title='Move down' style={{backgroundColor:'#001B2B',border:'none',marginRight:'0.3rem'}} onClick={()=>arrangeSectors("down",index)}><FaLongArrowAltDown /></button>:index===sectors.length-1 && sectors.length>1?
            <button className='grid-download-btnStyle1' title='Move up' style={{backgroundColor:'#001B2B',border:'none',marginRight:'0.3rem'}} onClick={()=>arrangeSectors("up",index)}><FaLongArrowAltUp /></button>

            :<>{sectors.length>1?<><button title='Move up' className='grid-download-btnStyle1' style={{backgroundColor:'#001B2B',border:'none',marginRight:'0.3rem'}}  onClick={()=>arrangeSectors("up",index)}><FaLongArrowAltUp /></button>
            <button className='grid-download-btnStyle1' title='Move down'  style={{backgroundColor:'#001B2B',border:'none',marginRight:'0.3rem'}}  onClick={()=>arrangeSectors("down",index)}><FaLongArrowAltDown /></button></>:<></>}
            </>}
           <div onClick={()=>mergeSectors(item)} title='Merge sectors'>
            <button style={{backgroundColor:'#006385',border:'none'}} className='grid-download-btnStyle1'>
            <IoMdGitMerge />
            </button>
            </div>


            </div>
          { item.type !=='merged'? <div className='flexRow justifyContentFlexEnd' title='Detach sector'>
            <button className='grid-download-btnStyle1' style={{backgroundColor:'red',border:'none'}} onClick={()=>detachSector(item.id)}><MdLeakRemove /></button>
         </div>:<></>}

         {props.editorInstance.state==='locked' && item.type==='merged'? <div className='flexRow justifyContentFlexEnd' title='Undo Sector Merge'>
                   <button className='flexRow grid-download-btnStyle1 justifyContentCenter alignItemsCenter' style={{backgroundColor:'#d9534f',border:'none'}} onClick={()=>handleUndoMerge(item,'undoMerge')}><TbReload /></button>
                </div>:<></>}


            </>

            :<>
            <div className='flexRow justifyContentSpaceBetween'>
                <div className='flexRow justifyContentFlexStart'>
                   <div onClick={()=>mergeSectors(item)} title='Merge sectors'>
            <button style={{backgroundColor:'#006382',border:'none'}} disabled={item?.mergeConflicts} className='grid-download-btnStyle1'>
            <IoMdGitMerge />
            </button>
            </div>
             <div style={{display:'flex',alignItems:'center',minWidth:'8rem',maxWidth:'10rem',padding:"0px"}} className='conflict-resolve-button margRight10'>
                           <button  onClick={()=>resolvexC(item,'resolveConflicts')} title='Resolve Conflicts' style={{height:"1.5rem",width:'80%',whiteSpace:'nowrap',color:'white'}}>
                           <span className='flexRow justifyContentCenter alignItemsCenter' style={{verticalAlign: 'baseline' }} >   <GiHazardSign style={{marginRight:'5px'}}/> Conflicts</span>
                         </button>
                         <button onClick={()=>mergeSectors(item,'lockMessage')} style={{height:"1.5rem",width:'20%',borderLeft:"1px solid #c38e00"}}>
                         ?
                         </button>
                      </div>
            </div>



            </div>
            {sectors.length===1 && props.editorInstance.state==='locked' && item.type==='merged'? <div className='flexRow justifyContentFlexEnd' title='Undo Sector Merge'>
                   <button className='flexRow grid-download-btnStyle1 justifyContentCenter alignItemsCenter' style={{backgroundColor:'#d9534f',border:'none'}} onClick={()=>handleUndoMerge(item,'undoMerge')}><TbReload /></button>
                </div>:<></>}


            </>}



              </div>


              <div className='flexRow sector-banner justifyContentSpaceBetween'>
                <div className='flexRow justifyContentFlexStart'>
                   <div className='sector-number fontMedium'>{item.flightNumber}</div>
                   <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow sector-tag'>{item.origin}</div>
                <div className='flexRow fontMedium'>{item.originName}</div>
                </div>
                </div>

                <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow'> <IoArrowForwardOutline size={17} color={'#F06548'}/></div>
                <div className='flexRow fontMedium'>{item.key.split("/")[1]}</div>
                </div>
               <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow sector-tag'>{item.destination}</div>
                <div className='flexRow fontMedium'>{item.destinationName}</div>
                </div>
              </div>
              <div className='flexRow fitContent'>
                  <div style={{float:'left'}} className="flexCol width50 justifyContentFlexStart">
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Sector ID</label>
                  <div>{item.name}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Operation Status</label>
                  <div>{getSectorStatus(sectors[index]?.operationStatus)}&nbsp;{sectors[index]?.cancellationReason?sectors[index]?.cancellationReason.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^([a-z])/, (match) => match.toUpperCase()):""}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Created At</label>
                  <div>{item.createdAt?moment(item.createdAt).format('DD-MM-YYYY hh:mm A'):'Not available'}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Created By</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {item.createdBy.employeeName}
                    </div>
                    <div className='flexRow'>
                    {item.createdBy.employeeCode}
                    </div>
                    </div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Created Using</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {item.userAgent.appName}@ {item.userAgent.appVersion}
                    </div>
                    <div className='flexRow'>
                    {item.userAgent.deviceModel},&nbsp;{item.userAgent.macId?`MAC ID:${item.userAgent.macId}`:item.userAgent.deviceName}
                    </div>
                    </div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Started At</label>
                  <div>{sectors[index].startedAt?moment(sectors[index].startedAt).format('DD-MM-YYYY hh:mm A'):'Not Available'}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Ended At</label>
                  <div>{sectors[index].endedAt?moment(sectors[index].endedAt).format('DD-MM-YYYY hh:mm A'):"Not Available"}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Price List</label>
                  <div>{sectors[index].priceList??'Not available'}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Seat Price List</label>
                  <div>{sectors[index].seatPriceListName||'Unknown'}</div>
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Event Log</label>
                  <button className="status-dynamic dynamic-sl" onClick={()=>props.eventLogs(item,sectorIsolateData.sectors[index],props.editDetails)}>View Event Logs</button>
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Sales Summary</label>
               <button className="status-dynamic dynamic-sl" onClick={()=>props.inventoryDetails(sectors[index],null,'salesSummary',props.editDetails,sectorIsolateData?.sectors[0])}>{item?.name}</button>

                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>PAX Reported by Crew </label>
                  <div>{sectors[index].pax?sectors[index].pax:'Not Available'}</div>
                </div>
                <div>
                  <label className='fontExtrabold'>Crew</label>
                  <div>{CrewLabels(sectors[index].crew)}</div>
                </div>

              </div>
              <div className="flexCol width50">

              <div className='margBottom10'>
                  <label className='fontExtrabold'>Sync Status</label>
                  <div>{getSyncStatus(sectors[index].syncStatus)}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Synced At</label>
                  <div>{sectors[index].syncedAt?moment(sectors[index].syncedAt).format('DD-MM-YYYY hh:mm A'):'Not available'}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Number of Orders To Sync</label>
                  <div>{sectors[index].numberOfItemsToSync??'Not available'}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Number of Orders Synced</label>
                  <div>{sectors[index].numberOfItemsSynced??'Not available'}</div>
                </div>


                <div className='flexCol margBottom10'>
                  <div className='flexRow alignItemsCenter' title='Refresh Uplifts'>
                  <label className='fontExtrabold'>Uplifts</label>
                 <button className='small-body-text-regular fontExtrabold key-value btn-sync ' onClick={(e)=>upliftRefresh(e,sectors[index])}>
                  <img style={{height:'18px',width:"18px",marginLeft:"0.5rem",backgroundColor:'#3e687d',borderRadius:"5px",border:"1px solid #4c5c81"}} src={deposit} onClick={()=>setReload(!reload)} alt='refresh-sectors' />
                  </button>
                  </div>
                  {  sectors[index]?.uplifts.length? <>{upliftsSectors(sectors[index],sectors[index]?.uplifts)}</>:"None"}

               {/* {  sectors[index]?.uplifts.length? :<>None</>} */}
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Transfer In</label>
                { sectors[index]?.transferInReport? <button className="status-dynamic" onClick={()=>props.inventoryDetails(sectors[index],null,'transferIn',props.editDetails,sectorIsolateData.sectors[index])} style={{ width:'17rem',whiteSpace:'nowrap',backgroundColor: '#06cc48' }}>{sectors[index]?.transferInReport?.sector?.name}</button>:<>None</>}
                </div>
                <div className='flexCol margBottom10'>
                  <div className='flexRow alignItemsCenter'>
                  <label className='flexRow fontExtrabold margRight'>Offloads</label>
                  {!sectors[index]?.offloadReport?<span title='Create Offloads' style={{cursor:'pointer'}} onClick={()=>props.inventoryDetails(sectors[index],null,'createOffloads',props.editDetails,sectorIsolateData.sectors[index],props.editDetails)}><MdAddBox size={18} color='#3e687d'/></span>:<><span title='Edit Offloads' style={{cursor:'pointer'}} onClick={()=>props.inventoryDetails(sectors[index],null,'editOffloads',props.editDetails.sectors[index],sectorIsolateData.sectors[index],props.editDetails)}><MdEditSquare size={18} color='#3e687d'/></span>&nbsp;&nbsp;<span title='Delete Offloads' style={{cursor:'pointer'}} onClick={()=>props.inventoryDetails(sectors[index],null,'deleteOffloads',props.editDetails.sectors[index],sectorIsolateData.sectors[index],props.editDetails)}><MdDelete size={18} color='#d9534f'/></span></>}

                  </div>
                { sectors[index]?.offloadReport ? <button className="status-dynamic" onClick={()=>props.inventoryDetails(sectors[index],null,'offloads',props.editDetails.sectors[index],sectorIsolateData.sectors[index])} style={{ width:'17rem',whiteSpace:'nowrap'}}>{sectors[index]?.offloadReport.sector.name}</button>:<>None</>}
                </div>



                <div className='flexCol margBottom10'>
                  <div className='flexRow alignItemsCenter'>
                  <label className='flexRow fontExtrabold margRight'>Wastages</label>
                  {!sectors[index]?.wastageReport?<span title='Create Wastage' style={{cursor:'pointer'}} onClick={()=>props.inventoryDetails(sectors[index],null,'createWastages',props.editDetails,sectorIsolateData.sectors[index],props.editDetails)}><MdAddBox size={18} color='#3e687d'/></span>:<><span title="Edit Wastages" style={{cursor:'pointer'}} onClick={()=>props.inventoryDetails(sectors[index],null,'editWastages',props.editDetails.sectors[index],sectorIsolateData.sectors[index],props.editDetails)}><MdEditSquare size={18} color='#3e687d'/></span>&nbsp;&nbsp;<span title='Delete Wastages' style={{cursor:'pointer'}} onClick={()=>props.inventoryDetails(sectors[index],null,'deleteWastages',props.editDetails.sectors[index],sectorIsolateData.sectors[index],props.editDetails)}><MdDelete size={18} color='#d9534f'/></span></>}

                  </div>
                { sectors[index]?.wastageReport ? <button className="status-dynamic dynamic-act" onClick={()=>props.inventoryDetails(sectors[index],null,'wastages',props.editDetails.sectors[index],sectorIsolateData.sectors[index])} style={{ width:'17rem',whiteSpace:'nowrap'}}>{sectors[index]?.wastageReport.sector.name}</button>:<>None</>}
                </div>

                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Orders</label>
                    <button className="status-dynamic dynamic-act" onClick={()=>props.inventoryDetails(sectors[index],null,'ordersEditDoc',props.editDetails,sectorIsolateData.sectors[index])}>{item?.name}</button>
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Transfer Out</label>
               { sectors[index]?.transferOutReport?  <button className="status-dynamic dynamic-act" onClick={()=>props.inventoryDetails(sectors[index],null,'transferOut',props.editDetails,sectorIsolateData.sectors[index])}>{sectors[index]?.transferOutReport?.sector?.name}</button>:<>None</>}
                </div>
                <div className='flexCol margBottom10'>
                <div className='flexRow alignItemsCenter'>
                  <label className='flexRow fontExtrabold margRight'>Uplift Audit Report</label>
                  {sectors[index]?.upliftAuditReport && !sectors[index]?.upliftAuditReport?.discarded && state.editSector?<span title='Delete Uplift Audit Report' style={{cursor:'pointer'}} onClick={()=>props.inventoryDetails(sectors[index],null,'deleteUpliftAuditReport',props.editDetails.sectors[index],sectorIsolateData.sectors[index],props.editDetails)}><MdDelete size={18} color='#d9534f'/></span>:<></>}
                  </div>
                  { sectors[index]?.upliftAuditReport?  <button className={!sectors[index]?.upliftAuditReport?.discarded?'status-dynamic dynamic-upt':'status-dynamic dynamic-upt strikethrough'} onClick={()=>props.inventoryDetails(sectors[index],null,'upliftAudit',props.editDetails,sectorIsolateData.sectors[index])}>{sectors[index]?.upliftAuditReport?.sector?.name}</button>:<>None</>}
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Transfer Audit Report </label>
                  { sectors[index]?.transferAuditReport?  <button className="status-dynamic dynamic-act" onClick={()=>props.inventoryDetails(sectors[index],null,'transferAudit',props.editDetails,sectorIsolateData.sectors[index])}>{sectors[index]?.transferAuditReport?.sector?.name}</button>:<>None</>}
                </div>
                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Stock Movement Overview </label>
                 {sectors[index]?.name? <button className="status-dynamic dynamic-sl" onClick={()=>props.inventoryDetails(sectors[index],null,'stockMvmnt',props.editDetails,sectorIsolateData.sectors[index])}>{sectors[index]?.name||"None"}</button>:<>None</>}
                </div>
              </div>


              </div>
            </div>
              </CollapsebleSectorInstance>
            )}
          </React.Fragment>
        ))
      ) : (
        <li className='flexRow'>
          <div className='flexRow col-xs-11 wrapper-sector justifyContentCenter alignitemsCenter'>No sectors attached to this instance yet.</div>
        </li>
      )}
    </ul>





{
  state&&state.selected&&(state.selected.action === 'mergeSectors') &&<div className='flexRow width100'>
       <Modal
   className='cash-deposit-summary'
   title={`Merge Sectors to ${state.selected.data?.name}`}
   onSubmit={handleMergeSectors}
   onClose={handleModalCancelEvent}
   buttonLabel={'Merge'}
   disabled={!seletedSec.some(sl=>sl.mergeSel)}
   >
     <div className='flexCol full-flex'>
        <div className='fitcontent justifyContentSpaceBetween display wrapper-sector-dynamic'>
         <div className='flexRow sector-banner justifyContentSpaceBetween'>
           <div className='flexRow justifyContentFlexStart'>
              <div className='sector-number fontMedium'>{state.selected.data?.flightNumber}</div>
              <div className='flexCol justifyContentCenter alignItemsCenter'>
           <div className='flexRow sector-tag'>{state.selected.data?.origin}</div>
           <div className='flexRow fontMedium'>{state.selected.data?.originName}</div>
           </div>
           </div>

           <div className='flexCol justifyContentCenter alignItemsCenter'>
           <div className='flexRow'> <IoArrowForwardOutline size={17} color={'#F06548'}/></div>
            <div className='flexRow fontMedium'>{moment.parseZone(formatDateByTimezone(state.selected.data?.flightDate)).format('DD-MM-YYYY')}</div>
           </div>
          <div className='flexCol justifyContentCenter alignItemsCenter'>
           <div className='flexRow sector-tag'>{state.selected.data?.destination}</div>
           <div className='flexRow fontMedium'>{state.selected.data?.destinationName}</div>
           </div>
         </div>
         </div>

         {listItems.length?<div className="flexRow justifyContentCenter alert alert-info mt-8 ng-scope">
                Following sectors can be merged to the above sector. Make sure that you select the right sector for
                merge.If you make a mistake while merging, you can undo the changes by removing the merged sector.

            </div>:<div className="alert alert-info mt-8 ng-scope" >
            None of the sectors currently attached to instance <code className="ng-binding">{props.instance?.name}</code> can be merged to
            sector <code className="ng-binding">{state.selected.data.key}</code>. Use 'Find Sector' option to look for mergeable sectors, attach them to instance
            and retry merge.
        </div>}

            <div className="dialog__body_container flexRow justifyContentSpaceBetween flexWrap">
            <ul className='list-group' style={{overflowY:'scroll'}}>
                {listItems.length?listItems:< ></>}
            </ul>
          </div>

     </div>
 </Modal>
  </div>

 }

   {
       state && state.selected && state.selected.action === 'lockMessage' &&<div className='full-flex width100'><Modal
       className=''
       title={`Help - Resolve Conflicts`}
       onSubmit={handleModalCancelEvent}
       buttonLabel={'Okay'}
       onClose={handleModalCancelEvent}>
         <div className='flexCol full-flex'>
         <div class="modal-body">
        <article>
            <h4>Introduction</h4>
            <p className='margBottom28 lineheight15'>
            Merge conflicts occur when the sectors being merged has different values for some of their properties. For e.g. both sectors could set a different value for 'PAX' or report two different sets of 'Crew'. In such cases, the merge won't succeed until a particular value is chosen for the merged sector. It could be either of the values set by the sectors being merged or, in some cases, a combination of both.
            </p>
        </article>
    </div>
         </div>
     </Modal>
        </div>

      }


   {
       state && state.selected && state.selected.action === 'resolveConflicts' &&<div className='full-flex width100'>

        <Modal
       className=''
       title={`Resolve Conflicts in - ${state.selected.data.name}`}
       onSubmit={handleResolveConflicts}
       buttonLabel={'Resolve'}
       disabled={!selectedValue.length}
       modalFormStatusObj={modalFormStatusObj}
       onClose={handleModalCancelEvent}>

         <div className='flexCol full-flex'>
         <div className='fitcontent justifyContentSpaceBetween display wrapper-sector-dynamic'>
         <div className='flexRow sector-banner justifyContentSpaceBetween'>
           <div className='flexRow justifyContentFlexStart'>
              <div className='sector-number fontMedium'>{state.selected.data?.flightNumber}</div>
              <div className='flexCol justifyContentCenter alignItemsCenter'>
           <div className='flexRow sector-tag'>{state.selected.data?.origin}</div>
           <div className='flexRow fontMedium'>{state.selected.data?.originName}</div>
           </div>
           </div>

           <div className='flexCol justifyContentCenter alignItemsCenter'>
           <div className='flexRow'> <IoArrowForwardOutline size={17} color={'#F06548'}/></div>
            <div className='flexRow fontMedium'>{moment.parseZone(formatDateByTimezone(state.selected.data?.flightDate)).format('DD-MM-YYYY')}</div>
           </div>
          <div className='flexCol justifyContentCenter alignItemsCenter'>
           <div className='flexRow sector-tag'>{state.selected.data?.destination}</div>
           <div className='flexRow fontMedium'>{state.selected.data?.destinationName}</div>
           </div>
         </div>
         </div>
         <div className='table-header'></div>
      <table border="1" style={{ width: '100%', margin: 'auto', textAlign: 'center'}}>
      <tbody>
      <tr>
      <td>Field</td>
      {state.selected.data.mergeSources.map((item, index) => (
      <td key={item.id}><div className='flexCol'>
      <div className='flexRow margBottom5'>{item.name}</div>
      <div className='flexRow margBottom5'>{item.createdBy.employeeName}</div>
      <div className='flexRow'>{item.createdBy.employeeCode}</div>

      </div></td>
      ))}
      </tr>
      {state.selected.data.mergeConflicts.conflicts.map((item, index) => (<tr key={item.id}>
      <td><div className='flexRow justifyContentFlexStart alignItemsCenter'>{item.property.displayName}&nbsp;{selectedValue && <BsCheckLg color={'#4cae4c'} size={20}/>}</div></td><td
  style={{
    borderLeft: selectedValue === 'acceptTarget' ? '5px solid #4cae4c' : 'none'
  }}
> {item.targetValue}</td>


      <td  style={{
    borderLeft: selectedValue === 'acceptSource' ? '5px solid #4cae4c' : 'none'
  }}><div className='flexRow justifyContentSpaceBetween'><span className='flexRow alignItemsCenter texxtAlignCenter'>{item.sourceValue}</span><span className='flexRow justifyContentFlexEnd'>

      <div className="dropdown">
      <button ref={buttonDrdn} onClick={toggleDropdown} className="dropdown-button">
      ⋮
      </button>
      {isOpen && (
        <div ref={dropdownOpt} className="dropdown-content">
          {getResolveOptions(item.resolutions).map((option, index) => {
      return (
       <div  key={index} onClick={() => handleChange(option.value)}>{option.label}</div>
      );
      })}
        </div>
      )}
    </div>


      </span>


      </div>


      </td>
      </tr>
      ))}

      </tbody>

      </table>

         </div>

     </Modal>

        </div>


      }


 </>
  )




}

export default SectorsEditIsolate;