import React, { useEffect, useRef, useState } from 'react';
import { APIEndpoint, DFPAPIEndpoint } from "../../../utils/axios";
import { DataGrid, AddProductModal, UploadModal, DeleteConf } from "../../../common";
import { onDownloadXlsFile, translationLength } from '../../../utils/commonFunctions';
import { MIMETypes } from '../../../utils/configs';
import moment from "moment";
import { useTranslation } from 'react-i18next';
import Edit2 from "../../../assets/edit2.svg";
import usePermissions from '../../../utils/userPermissions';
import CustomSelect from "../../../common/CustomSelect";
import  LoadingGIF from '../../../assets/loadingIn.gif'
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import HeaderSelectAll from '../../../common/HeaderSelectAll';



export const ManageProductsPage = (props) => {
  const priceListOptions = useRef(null);
  const Fields = useRef([]);
  const { t } = useTranslation();
  const [state, setState] = useState({ isListReady: false, filterPrice: '', filterPriceValue:[],resetDataSource: false, })
  const [isDownloading, setIsDownloading] = useState(false);
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [checkbox,setCheckBox]=useState(false)
  const [buttonStat,setButtonStat]=useState(false);
  const [showUpload, setShowUpload] = useState(false);
 const [fromDetails,setFormDetails]=useState([]);
  const [buttonData, setButtonData] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectAll,setSelectAll]= useState({isChecked:false,label:'Item Code'});
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const sType = localStorage.getItem('sType');
  const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);


  const { hasPermission: editpriceMaster } = usePermissions('edit', "inventory.price-master");
  const { hasPermission: downloadpriceMaster } = usePermissions('download',"inventory.price-master");
  const { hasPermission: uploadpriceMaster } = usePermissions('upload',"inventory.price-master");

  React.useEffect(() => {
    fetchPriceList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(products?.count === 0){
      setCheckBox(false)
      setState(prev=>({...prev,resetDataSource:!state.resetDataSource}))
    }else{
      setCheckBox(true)
      setState(prev=>({...prev,resetDataSource:!state.resetDataSource}))
    }
  }, [products?.count])

  const CustomHeader = (props) => {
    return (
      <div className='flexCol'>
        <div className='ag-header-cell-text'>{props.displayName}</div>
      {!state.filterPrice
      ?  <div className='ag-header-cell-text'>(select a price list)</div>:<></>}
      </div>
    );
  };

  const HeaderCheckBoxRenderer=()=>{
    return (
      <HeaderSelectAll
      selectAll={selectAll}
      setSelectAll={setSelectAll}/>
  );

  }


  const Columns = [
    { field: 'itemCode', headerName: 'Item Code',valueGetter: (params) => {
      if (params.data&&params.data.itemCode) {
         return params.data.itemCode.length>21? params.data.itemCode.slice(0,22)+"...": params.data.itemCode;
      }
      return null;
    }, tooltipField: 'itemCode',suppressSizeToFit:true,resizable:true,checkboxSelection: checkbox ,headerComponentFramework : HeaderCheckBoxRenderer, minWidth: 230,width:250 },
    { field: 'itemName', headerName: 'Item Name',  flex:1,suppressSizeToFit:true, resizable:true,minWidth: 230 ,width: 255 ,tooltipField: 'itemName',valueGetter: (params) => {
      if (params.data&&params.data.itemName) {
         return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
      }
      return null;
    }, },
    { field: 'category', headerName: 'Category',   flex:1,suppressSizeToFit:true,resizable:true,minWidth: 155 },
    { field: 'subCategory', headerName: 'Sub Category',  flex:1,suppressSizeToFit:true,resizable:true, minWidth: 155 },
    { field: 'itemPrice', headerName: 'Price', headerComponent:CustomHeader,valueGetter: (params) => {
      return params?.data?.itemPrice != null
        ? parseFloat(params?.data?.itemPrice).toFixed(2)
        : !params?.data?.itemPrice ? null:"NA";
    },  flex:1,suppressSizeToFit:true, resizable:true,minWidth: 125},
  ];

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchProducts = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    if(!state.filterPrice){
      // setIsDownloading(true)
      setButtonData(null)
      // setCheckBox(false)
    } else{
      setIsDownloading(false)
      setButtonData(
        {deleteButton:{
             title: t('pages.productsContent.manageProducts.remove'),
            className:'m-2 flex justifyContentCenter alignItemsCenter',
             onClick: handleDeleteAction,
           },
           addButton:{
            title:`+ ${t('pages.productsContent.manageProducts.addItem')}`,
            className:'m-2',
             onClick: handleAddSector
           }
         })
        //  setCheckBox(true)
    }
    if (state.filterPrice) {
      filterData.filters = [{ listName: state.filterPrice }]
    }
    const res = await DOMAIN.post('items/process', filterData);
    setProducts(res)
    return res;
  }

  const fetchPriceList = async () => {

    try {
       const priceList = await DOMAIN.get('items/price-list');
    if (priceList.result.length) {
      priceListOptions.current = priceList.result.map((_pl,index) => {
        return {key:index, value: _pl, label: _pl }
      })
      setState((_) => {
        return { ..._, isListReady: true }
      })
    }
    } catch (error) {

    }

  };

  const handleModalClose = (e, iReset=null) => {
    if(!e && !iReset){
      setState((currentState) => {
        return { ...currentState, showAddForm: false, editRow: null,detailsRow: null }
      })
    }

   else if (!e && iReset) {
      let timeoutId = setTimeout(() => {
       setModalFormStatusObj({text:""})
       setButtonStat(false);
        clearTimeout(timeoutId)
        setState((_) => {
          return { ..._, detailsRow: null, deleteRows: null, resetDataSource: iReset ? !state.resetDataSource : state.resetDataSource }
        });
      }, 1500)
    }else{
      setState((_) => {
        return { ..._, showAddForm: false, editRow: null,detailsRow: null, deleteRows: null, resetDataSource: iReset ? !state.resetDataSource : state.resetDataSource }
      });
    }
    if(selectAll.isChecked){
      setSelectAll((_)=>({..._,isChecked:!_.isChecked}));
    }
  }

  const handleAddSector = () => {
    setState((_) => {
      return { ..._, showAddForm: true }
    })
  }

  const onUploadFileChangeHandler = async (e) => {
    const file=e.target.files[0];
    setUploadedFile(e.target.files[0]);
    setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

    const formData = new FormData();
    formData.append('scheduleLater', false);
    formData.append('listName',fromDetails[0]?.name|| undefined);
    formData.append('targetSectorType',fromDetails[0]?.type|| undefined);
    formData.append('effectiveDate',fromDetails[0]?.date?moment(fromDetails[0]?.date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").format():"" || undefined);
    formData.append('stores',JSON.stringify(fromDetails[0]?.stores || [undefined]));
    formData.append('warehouses', JSON.stringify(fromDetails[0]?.warehouses || [undefined]));
    formData.append('file', file);

    try {
      await DOMAIN.post('excel/upload/items', formData);
      setUploadedFile([]);
      setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
      setState( (_prev) => {
        return { ..._prev,resetDataSource:true}
      });

    }
    catch(error) {
      console.log("ERROR data raw:",error)

      e.target.value = null;
      setUploadedFile([]);
      let { status, data } = JSON.parse( error.message );
      let msgHeading = data?.Error || data?.message || error.message;

      let msgContent = "";
      if (status === 400 && data && data.errors) {
          data.errors.forEach( _ => {
            const lineNum = _.lineNumber;
            const errorMsgArr = [];
            _.errors.forEach(erObj => {
              errorMsgArr.push( erObj.field + ' | ' + erObj.message + ' \n<br>' );
            });

            msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
        });
      }
      else if(status === 400 && data && data?.Error){
       msgHeading = data.Error;
       msgContent += 'Error occured while upload price List sheet!';
      }
      else {
        msgContent += 'Error occured while upload price List sheet!';
      }

      setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                 "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                              );
    }
};


  const handleAddSectorSubmit = async (e, values) => {
    try {
        if (values) {

      const res = await DOMAIN.post('item', values);
      if (res.success) {
        setModalFormStatusObj({ text:res.msg||'Item added to Price List'})

        handleSelectPriceList({ target: { value: state.filterPrice } })
      }

    }
    } catch (error) {
      let {data}=JSON.parse(error.message)
        setModalFormStatusObj({ error:data.msg})

      }
    let timeoutId = setTimeout(() => {
        clearTimeout(timeoutId)
        setModalFormStatusObj({text:""})
      }, 3500)

    }

  const handleEditSectorSubmit = async (e, values) => {
    let isValid = true;
    if (values != null) {
      try {
        const res = await DOMAIN.put('item/' + values.itemId, values);
      if (res.success) {
        setModalFormStatusObj({ text:res.msg || "Item updated successfully"})
        setTimeout(() => {
          handleSelectPriceList(state.filterPriceValue[0])
        }, 2000);
      }
      } catch (error) {
        let {data}=JSON.parse(error.message)
        setModalFormStatusObj({ error:data.msg})

      }
    let timeoutId = setTimeout(() => {
        clearTimeout(timeoutId)
        setModalFormStatusObj({text:""})
      }, 3500)

    }
  }

  const handleSelectPriceList = (e) => {
    setState((_) => {
      return { ..._, filterPrice:e?e.value:null,filterPriceValue:[e],resetDataSource:!state.resetDataSource, showAddForm: false, editRow: null }
    });
    let timeoutId = setTimeout(() => {
      clearTimeout(timeoutId)
      setModalFormStatusObj({text:""})
      setState((_) => {
        return { ..._,resetDataSource:!state.resetDataSource}
      });
    }, 100)
  }

  const handleRowAction = async (e, action, row) => {
    setState((_prev) => {
      return { ..._prev, editRow: row }
    })
  }

  const handleDeleteAction = (e, selected) => {
    setState((currentState) => {
      return { ...currentState, deleteRows: selected }
    })
  }

  const handleDeleteConfirm = async () => {
    const items = state.deleteRows.map((_) => _.itemId);
    try {
      setButtonStat(true);
      const res = await DOMAIN.put('items/remove?pl='+state.filterPrice, { ids: items });
      if(res.success){
        setModalFormStatusObj({text:res.message||'Item deleted successfully'})
      }
    } catch (error) {
      const dataErr=JSON.parse(error.message)
      setModalFormStatusObj({error:dataErr.data.message||'Failed!'})
    }
    handleModalClose(null, true);

  }



  const onDownloadPriceList=async()=>{
    if(isDownloading) return;
    let header={
      'Accept':'text/csv'
    }
    try {
      setIsDownloading(true)
    const date = new Date().toISOString()
    let request_payload = {
      startDate: date,
      endDate: date,
      listName: state.filterPrice
    }
    const res= await DOMAIN.post(`downloads/priceList`,request_payload,header);
    if(res.status==='scheduled'){
      setTimeout(() => {
        const dowId = DOMAIN.get(`downloads/status/${res.id}`);
        dowId.then((val) => {
          if (val.status === "completed") {
              DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                  .then((ItemMasterDwnld) => {
                      const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', val.fileName + '.csv');
                      document.body.appendChild(link);
                      link.click();
                      setIsDownloading(false);
                  })
                  .catch((error) => {

                      console.error('Error downloading CSV:', error);
                      setIsDownloading(false);
                  });
          }
      });
        }, 8000);
    }
    }

        catch (error) {
          setState((_) => {
            return { ..._,message:{text:error?.data?.message},};
          })
          setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              message: null,
            }));
          }, 3500);
           setIsDownloading(false)
         }

  }

  const onDownloadSampleFile = () => {
    onDownloadXlsFile('samples/downloads/Price_List_Upload_Sample','Price_List_Upload_Sample')
  }

  const onUploadPriceList=()=>{
    setShowUpload(true)
  }

  const onUploadClose = () => {
    setShowUpload(false);
    setUploadedFile([]);
    setUploadFileStatusBlock("")
  }
  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        Boolean(state.isListReady) &&
        <>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
{         uploadpriceMaster?   <button className='upload dropdownStyle1-TextMedium' onClick={() => onUploadPriceList(true)}> <span><img  className="icon-size" alt='uploadItem' src={uploadItemIcon} /><u>{t('pages.productsContent.manageProducts.uploadPriceList')}</u></span></button>:<></>}
         { downloadpriceMaster?  <button className='download dropdownStyle1-TextMedium' disabled={!state.filterPrice} onClick={onDownloadPriceList}>
         {isDownloading ? (<span><img  className='icon-size' style={{width:'25px',height:'25px'}} alt='downloadMaster' src={LoadingGIF} /><u className={`download__banner_`}>Downloading...</u> </span> ):
         (<span><img className="icon-size" alt='downloadMaster' src={downloadMasterIcon} /><u className={`download__banner_`}>{isDownloading ? "Downloading...":t('pages.productsContent.manageProducts.download')}</u></span>)}
         </button> :<></>}
          </div>


          <DataGrid
            // pageType={false}
            columnResize
            resetDataSource={state.resetDataSource}
            showDefaultFilters
            className='full-flex'
            columns={Columns}
            modalFormStatusObj={modalFormStatusObj}
            selectAll={selectAll}
            getRows={fetchProducts}
            {...buttonData}
            RightComponent={() => {
              return (
              <button className='flexRow justifyContentFlexEnd saleType-div'>
              <CustomSelect
                options={priceListOptions.current}
                placeHolder="Search Price List"
                width={'17rem'}
                value={state.filterPriceValue}
                onChange={handleSelectPriceList}
              />
              </button>
              )
            }}
            actions={[
              { detail: [{ src: Edit2, value: "edit",title:'Edit' }] }
            ]}
            onAction={handleRowAction}
          />
          {
            state.showAddForm &&
            <AddProductModal
              title={t('pages.productsContent.manageProducts.addItem')}
              onClose={handleModalClose}
              onSubmit={handleAddSectorSubmit}
              modalFormStatusObj={modalFormStatusObj}
              priceList={state.filterPrice}
              options={products}
              fields={Fields.current}
            />
          }
          {
            Boolean(state.editRow) && editpriceMaster &&
            <AddProductModal
              title={t('pages.productsContent.manageProducts.editItem')}
              onClose={handleModalClose}
              onSubmit={handleEditSectorSubmit}
              priceList={state.filterPrice}
              options={products}
              fields={state.editRow}
              modalFormStatusObj={modalFormStatusObj}
            />
          }
           {
            Boolean(state.deleteRows) &&
              <DeleteConf
                description={t('pages.productsContent.manageProducts.removeMsg')}
                onCloseDelete={handleModalClose}
                buttonStat={buttonStat}
                modalFormStatusObj={modalFormStatusObj}
                onContinue={handleDeleteConfirm}
              />
            }
           {
            showUpload &&
            <UploadModal
              modelTitle={t('pages.productsContent.manageProducts.uploadPriceList')}
              inputFields={"manage_products"}
              onUploadClose={onUploadClose}
              onDownloadSampleFile={onDownloadSampleFile}
              setFormDetails={setFormDetails}
              uploadedFile = { uploadedFile }
              onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
              uploadFileStatusBlock = { uploadFileStatusBlock }
              allowedExtensions={ MIMETypes.excel }
            />
          }
        </>
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}
