import React, {  useRef, useState } from 'react';
import { DFPAPIEndpoint, DFPAPIEndpointGalley } from "../../../utils/axios";
import searchIcon from '../../../assets/icons/search.svg';
import '../../../assets/styles/styles.scss';
import deleteIcon from '../../../assets/icons/deleteRole.svg';
import editIcon from "../../../assets/edit.svg";
import { DataGrid, EditModal,UploadModal ,DeleteConf} from "../../../common";
import { ConfigureGalley } from './configureGalley';
// import { useNavigate } from "react-router-dom";
import { onDownloadXlsFile, translationLength,copyToClipBoard } from '../../../utils/commonFunctions';
import usePermissions from '../../../utils/userPermissions';
import { useTranslation } from 'react-i18next';
import { CreateAircraft } from './createAircraft';
import { FaCopy } from 'react-icons/fa6';

export const AircraftType = (props) => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState('');
  const[state,setState]=useState({deleteProg:false});
  const [airCraft,setAirCraft]=useState({});
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [classCapacityConfig,setClassCapacityConfig]=useState([]);
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [perPageLimit, setPerPageLimit] = useState(10);
  const tableRef = useRef();

  const { hasPermission: editAircraftType } = usePermissions('edit', "configuration.fsc.aircraft-type");
  const { hasPermission: createAircraftType  } = usePermissions('create', "configuration.fsc.aircraft-type");
  const { hasPermission: viewDetailsAircraftType  } = usePermissions('viewDetails', "configuration.fsc.aircraft-type");
  const { hasPermission: deleteAircraftType  } = usePermissions('delete', "configuration.fsc.aircraft-type");
  const { hasPermission: uploadAircraftType  } = usePermissions('upload', "configuration.fsc.aircraft-type");


React.useEffect(() => {
  if(state&&state?.selected && state.resetDataSource){
    fetchAirtcraftsType()
    setAirCraft(state.selected)
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [state]);

React.useEffect(() => {
fetchConfigClassCodes();
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  const preCompile = {
    save:t('buttons.save'),
    uploadLayout:t('pages.pagesContent.aircrafts.uploadLayout')
  }
  function layoutComponent(props) {

    return (
      props.data?
      <div>
     { viewDetailsAircraftType ? <button className="grid-download-btnStyle1" onClick={(e) => setSector(props.data, 'viewLayout')}>View</button>:<></>}
       { uploadAircraftType ?<button  style={{marginLeft:'0.9rem'}} className="grid-download-btnStyle1" onClick={(e) => setSector(props.data, 'uploadLayout')}>Upload</button>:<></>}
      </div> : <div></div>
    );
  }

function configureGalley(props){
    return (
        props.data?
        <div>
          <button className="grid-download-btnStyle1" onClick={(e) => setSector(props.data, 'configureGalley')}>Configure</button>
        </div> : <div></div>
      );
}


function actionsAircrafts(props){
  return (
    props.data?
    <>
    <div className='flexRow margTop8'>
      {editAircraftType? <div className='icon-aircraft-container'>
      <img className='aircraft-actions' alt='edit' onClick={(e) => setSector(props.data, 'editAircraft')} src={editIcon}/>
      <span className="tooltip-action">Edit</span>
      </div>:<></>}
      {deleteAircraftType?<div className='icon-aircraft-container'>
      <img className='aircraft-actions' alt='delete' onClick={(e) => setSector(props.data, 'deleteAircraft')} src={deleteIcon}/>
      <span className="tooltip-action">Delete</span>
      </div>:<></>}
    </div>

    </> : <></>
  );
}

const fetchConfigClassCodes=async()=>{
  try {
    const resp = await DFPAPIEndpointGalley.get('galley/mealMenuPlan/configs');
    if(resp.data&&resp.data.classCodes?.list?.length>0){
    setClassCapacityConfig(resp.data.classCodes?.list?.map(cl=>({
      classCode: cl.id,
      seatCapacity: 0,
      className: cl.label
  })))
    }
    }
    catch (error) {

    }
}
  const Columns = [
    { field: 'key',headerName: 'Aircraft Model', valueGetter: function(params) {
      if(params&&params.data){
        return params.data.key.slice(0,params.data.key.indexOf("-"))
      };
    },
    cellRendererSelector: (params) => {
      return {
          component: AirCraftCodeRenderer,
      };
  },
    resizable: true,suppressSizeToFit:true,flex:1, width:130,minwidth:100,tooltipField: 'key',pinned:'left',autoResize:true },
    { field: 'variant',headerName: 'Variant',resizable: true, suppressSizeToFit:true,flex:1, minWidth: 180},
    { field: 'aircraftType',headerName: 'Type',resizable: true,suppressSizeToFit:true,flex:1, minWidth: 130 },
    { field: 'bodyType',headerName: 'Body',resizable: true,suppressSizeToFit:true,flex:1, minWidth: 130 },
    { field: 'classCapacity',headerName: 'First Class',valueFormatter: params=>{

      if(params&&classCapacityConfig.length>0){
        const data=params?.value?.find(v=>v.classCode===classCapacityConfig[0].classCode)
        if(data){
          return data.seatCapacity
        }
        else{
          return ''
        }
       }
   }, minWidth: 130 , suppressSizeToFit:true,flex:1,resizable:true},
    { field: 'classCapacity',headerName: 'Business Class', flex:1, valueFormatter: params=>{
      if(params&&classCapacityConfig.length>0){
        const data=params?.value?.find(v=>v.classCode===classCapacityConfig[1].classCode)
        if(data){
          return data.seatCapacity
        }
        else{
          return ''
        }
       }
   }, minWidth: 130, suppressSizeToFit:true,resizable:true },
    { field: 'classCapacity',headerName: 'Premium Economy', flex:1, valueFormatter: params=>{
      if(params&&classCapacityConfig.length>0){
        const data=params?.value?.find(v=>v.classCode===classCapacityConfig[2].classCode)
        if(data){
          return data.seatCapacity
        }
        else{
          return ''
        }
       }
   }, minWidth: 130 , resizable:true,suppressSizeToFit:true},
    { field: 'classCapacity',headerName: 'Economy', flex:1, valueFormatter: params=>{
      if(params&&classCapacityConfig.length>0){
        const data=params?.value?.find(v=>v.classCode===classCapacityConfig[3].classCode)
        if(data){
          return data.seatCapacity
        }
        else{
          return ''
        }
       }
   }, minWidth: 130, resizable:true,suppressSizeToFit:true },
   {
    headerName:'Actions',
    pinned:'right',
     autoResize:true,
      width:170,
      resizable: false,
      suppressSizeToFit:true,
      cellRenderer: actionsAircrafts,
      valueGetter: function(params) {
        return params.sectors;
      }
    }
,
       {headerName:'Seat Layout',
       pinned:'right',
       autoResize:true,
        width:200,
        resizable:false,
        suppressSizeToFit:true,
        cellRenderer: layoutComponent,
        valueGetter: function(params) {
          return params.sectors;
        }
      },

    {
      headerName:'Galleys',
      pinned:'right',
       autoResize:true,
        width:140,
        resizable: false,
        suppressSizeToFit:true,
        cellRenderer: configureGalley,
        valueGetter: function(params) {
          return params.sectors;
        }
      }
  ];

  const EditFormFields = [
    {label: 'Csv', attribute: 'layout', type: 'csv4', disabled: false, options: [], value: "" },
  ];

  const setSector = (data,type) => {
    if(type==='configureGalley'){
      setAirCraft(data);
      setState( (_prev) => {
        return { ..._prev, selected: {...data ,action: type},resetDataSource:false}
      });
    }
    else if(type==='editAircraft'){
      setState((_) => {
        return { ..._, resetDataSource: false, selected: { data,action: 'editAircraftType' },edit:true};
      })
    }
   else if(type==='deleteAircraft'){
    setState((_) => {
      return { ..._, resetDataSource: false,selected:data,delete:true ,deleteProg:false,edit:false};
    })
   }
   else{
    setState( (_prev) => {
      return { ..._prev, selected: {...data ,action: type},resetDataSource:false}
    });
   }
  };
  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };
  const fetchAirtcraftsType = async (page = 1, perPage = 10) => {
    filterData.page = page;
    filterData.limit = perPage;
    filterData.filters =  [
     
  ];
    filterData.searchParam = searchKey;
    try {
      const res = await DFPAPIEndpointGalley.post('galley/aircraftTypes/process', filterData);
    return res;
    } catch (error) {

    }

  }
  const handleFormSubmit = async (e,updated) => {
    if(updated){
      const body={
        model: updated.model,
        variant: updated.variant,
        capacity: parseInt(updated.capacity),
      }
      try {
          const resp = await DFPAPIEndpoint.post(`aircraftTypes`,body);
          if(resp.success){
              setModalFormStatusObj({ text: resp.message});
          }
      }
      catch (resp) {
        setModalFormStatusObj({ text: resp.message});
      }
    }

  }
  const onClose = () => {
    setState((_prev) => {
      return { ..._prev, selected: false,resetDataSource:true,edit:false}
    });

    setModalFormStatusObj({ text: '' });
  }
  const onAddItemHandler=()=>{
    setState( (_prev) => {
        return { ..._prev, selected: { action: 'createAircraftType' },resetDataSource:false ,edit:false};
      });
  }
  const onPerLimitChange = (e) => {
    setPerPageLimit(Number(e.target.value));
  };

const onUploadClose=()=>{
  if(state.selected.action==='configureGalley'){
    setState((_prev) => {
        return { ..._prev, selected: false,edit:false,resetDataSource: true  }
    });
  }
  else if(state.selected.action==='uploadLayout'&&uploadFileStatusBlock.length>0){
    setState((_prev) => {
      return { ..._prev, selected: false,edit:false,resetDataSource: true  }
  });
  }else{
    setState((_prev) => {
      return { ..._prev, selected: false,edit:false,resetDataSource: false  }
  });
  }
setUploadFileStatusBlock('')
}

const onCloseDelete=()=>{
  setState((_prev) => {
    return { ..._prev,resetDataSource:false,delete:false}
  });
setModalFormStatusObj({ text: "" });
}

const onContinue = async () => {
  setState((_prev) => ({
    ..._prev,
    deleteProg: true,
  }));

  try {
    const res = await DFPAPIEndpointGalley.delete(`galley/aircraftTypes/${state.selected.id}`);
    if (res.success) {
      setModalFormStatusObj({ text: res.message });
      setTimeout(() => {
        setModalFormStatusObj({ text: '' });
        setState((_prev) => ({
          ..._prev,
          resetDataSource: !state.resetDataSource,
          delete: false,
          deleteProg: false,
        }));
      }, 2500);
    }
  } catch (err) {
    let errorMessage = 'Something went wrong. Please try again after some time.';

      const errData = JSON.parse(err.message);
      errorMessage = errData?.data?.message || errorMessage;
    setModalFormStatusObj({ error: errorMessage });
    setTimeout(() => {
      setModalFormStatusObj({ text: '' });
      setState((_prev) => ({
        ..._prev,
        delete: false,
        deleteProg: false,
      }));
    }, 2500);
  }
};

const delProps = {
  onContinue,
  onCloseDelete,
    }
    const onUploadFileChangeHandler = async (e) => {
      const file=e.target.files[0];
      setUploadedFile(e.target.files[0]);
      setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

      const formData = new FormData();
      formData.append('file', file);
      try {
        const res = await DFPAPIEndpointGalley.post(`api/aircraftTypes/${state?.selected?.id}/layout/upload`, formData);
        setUploadedFile([]);
        setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
      } catch (error) {
        e.target.value = null;
        setUploadedFile([]);
        const errorObj = JSON.parse(error.message)
        let errHtml = "<div class='alert-danger'>"
        errHtml+=`<div class='w-100 flexCol margBottom10'>${errorObj.data.Error}</div>`
        { errorObj.data?.errors&&Array.isArray(errorObj.data.errors)?errorObj.data.errors.map((er, i) => {
          errHtml+=`
            <div key=${i} class="flexRow margBottom10">
            <div class='key'>Line Number: ${er.lineNumber}</div>
            <div class='flexCol'>
          `
          er.errors.map((_, i) => {
            errHtml+=`<span key=${i}>${_.message}</span>`
            return null
          })
          errHtml+=`</div></div>`
          return null
        }):<></>}
        errHtml+=`</div>`
        setUploadFileStatusBlock(errHtml);
        setUploadedFile([]);
      }
  };

const onDownloadSampleFile=()=>{
    onDownloadXlsFile('samples/downloads/AircraftTypeLayout_Upload_Sample','AircraftTypeLayout_Upload_Sample')
}

  const onSearch = (e) => {
    setSearchKey(e.target.value);
  };

  const AirCraftCodeRenderer = (props)=>{
    if(props.data&&props.data?.key){
    return (
      <div className="orderNo-renderer">
           <span>{props.data.key.slice(0,props.data.key.indexOf("-"))}</span>
           <button title='Copy to clipboard' onClick={(e)=>copyToClipBoard(e,props.data?.key)} className='margLeft5'>
             <FaCopy/>
           </button>
       </div>
   );
  }else
  return (
     null
  );
  }
  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
           <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>
           <div className='width100Menu justifyContentCenter flexRow'>
           <div className="margLeft margBot10 underline">
            <span><img  className="icon-size" alt='search' src={searchIcon} /></span>
            <span><input className="search" onChange={onSearch} type="search" placeholder="Search" value={searchKey}/></span>
            </div>
           </div>
           {createAircraftType? <div className="margLeft flexRow justifyContentFlexEnd alignItemsBaseline">
            <button className="add-item-button" onClick={ onAddItemHandler }>
            {t('pages.pagesContent.aircrafts.createAircraft')}
            </button>
            </div>:<></>}
            </div>

          <DataGrid
            ref={tableRef}
            className='full-flex'
            columnResize
            columns={Columns}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            rowSelection="multiple"
            resetDataSource={state?.resetDataSource}
            setSearchKey={(e) => onSearch(e)}
            getRows={fetchAirtcraftsType}
          />
        </>
      }
      {state && Boolean(state.selected)&& state.selected?.action === "createAircraftType"&&(
           <CreateAircraft
            key={state.selected?.action}
            title={"Create Aircraft Model"}
            airlines={'pal'}
            buttonLabel={preCompile.save}
            onSubmit={handleFormSubmit}
            setModalFormStatusObj={setModalFormStatusObj}
            onClose={onClose}
          />
        )
     }
     {state && Boolean(state.selected)&& state.selected?.action === "editAircraftType"&&(
           <CreateAircraft
            key={state.selected?.action}
            title={`Edit Aircraft Model - ${state.selected?.data.key} `}
            airlines={'pal'}
            buttonLabel={preCompile.save}
            editData={state.selected?.data}
            onSubmit={handleFormSubmit}
            setModalFormStatusObj={setModalFormStatusObj}
            onClose={onClose}
          />
        )
     }

      {state && Boolean(state.selected) &&state.selected?.action === "viewLayout"&& viewDetailsAircraftType && (
          <EditModal
            key={state.selected?.action}
            title={ `Seat Layout - ${state.selected?.key}`}
            onClose={onClose}
            showFieldsInColumn={true}
            fields={ EditFormFields.map( (elt) => {
                  return {...elt, initialValue: state.selected[elt.attribute]}
                })
            }
          />
        )
     }
     {
        state && Boolean(state.selected)&&state.selected.action==='uploadLayout'&&
        (<UploadModal
            modelTitle={`${preCompile.uploadLayout}-${state.selected.key}`}
            onUploadClose={onUploadClose}
            onDownloadSampleFile={onDownloadSampleFile}
            uploadedFile = { uploadedFile }
            onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
            uploadFileStatusBlock = { uploadFileStatusBlock }
        />)
      }
       {state&&state?.delete && <DeleteConf   {...delProps} buttonStat={state.deleteProg} modalFormStatusObj={modalFormStatusObj} description={`Are you sure you want to delete ${state?.selected?.model} ?`} />}

      {
        state && Boolean(state.selected)&&state.selected.action==='configureGalley'&&
        (<ConfigureGalley
            modelTitle={`Configure Galleys -${state.selected.key}`}
            onUploadClose={onUploadClose}
            airCraft={airCraft}
            state={state}
            onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
            onUpdateGalley={fetchAirtcraftsType}
            uploadFileStatusBlock = { uploadFileStatusBlock }
        />)
      }
       {
            t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
        }
    </div>
  )
}