import { FaArrowRight,FaCopy } from 'react-icons/fa6';
import { copyToClipBoard } from '../../../../utils/commonFunctions';

const getSectorDetails = (row) => {

 if (row) {
if(row.sectors && Array.isArray(row.sectors) &&row.sectors.length){
  let sectorScrt=row.storeName?.split("-");
  return (
    <div className='sector-container card-list' style={{overflow:'auto'}}>
      <span className='uplift-user'>
        { sectorScrt[0] ?? '' } | {  sectorScrt[1] ?? ''} <FaArrowRight color={'DC143C'} size={16} /> {  sectorScrt[2] ?? ''}
      </span>
    </div>
  )
}else{
   return (
    <div className='sector-container'>
      <span className='uplift-user'>
          { row.flightNumber ?? '' } | { row.origin ?? ''} <FaArrowRight color={'DC143C'} size={16} /> { row.destination ?? ''}
        </span>
        </div>
      )
}
    }
    return '';
};

export const SectorRenderer = (props) => {
    const cellValue = getSectorDetails(props.data);// props.valueFormatted ? props.valueFormatted : props.value;
    return (
        <div className="sector-renderer">
            {cellValue}
        </div>
    );
};

export const OrdNoRenderer = (props) => {
  if(props.data&&props.data?.orderNo){
    return (
      <div className="orderNo-renderer">
           <span>{props.data?.orderNo}</span>
           <button title='Copy to clipboard' onClick={(e)=>copyToClipBoard(e,props.data?.orderNo)} className='margLeft5'>
             <FaCopy/>
           </button>
       </div>
   );
  }else
  return (
     null
  );
};