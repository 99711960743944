/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState,useRef } from 'react'
import { APIEndpoint, DFPAPIEndpoint } from '../../../../utils/axios';
import { Modal,EditModal,Loading} from '../../../../common';
import { FaSearch } from "react-icons/fa";
import LoadingIn from '../../../../assets/loadingIn.gif';
import { IoReloadCircleSharp } from "react-icons/io5";
import { FaRegSave,FaFlag } from "react-icons/fa";
import moment from 'moment';
// import SectorInstanceCard from '../sectorInstanceCard';
import { FaLock } from "react-icons/fa";
import SectorListing from './sectorListing';
import SectorsEditIsolate from './sectorsEditIsolate';

export const  EditContainer=({id,instance,delOffld,setSorting,setNavigateInstanceId,setEditAction,editAction,navigateInstanceId,scrollContainer,setEditDetailsContainer,sorting,eventLogs,inventoryDetails})=> {

const sType = localStorage.getItem('sType');
const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);
const [state,setState]=useState({ignoreWarningCheck:false});
const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
const [stores,setStores]=useState(()=>{
  const respStor=sessionStorage.getItem('storL');
  return respStor?JSON.parse(respStor):[]
});
const [editorInstance,setEditorInstance]=useState(instance);
const[sectorListing,setSectorListing]=useState([]);
const [editDetails,setEditDetails]=useState({});
const [employees,setEmployees]=useState(()=>{
  const respEm=sessionStorage.getItem('emple');
  return respEm?JSON.parse(respEm):[]
});
const [loading, setLoading] = useState(true);
const [page, setPage] = useState(1);
const [loadingEditSectors, setLoadingEditSectors] = useState(true);
const [sectors,setSectors]=useState([]);
const [employee,setEmployee]=useState([]);
const [flights,setFilghts]=useState("");
const [editedSectors,setEditedSectors]=useState([]);
const [EditFieldsCess, setEditFieldsCess] = useState([]);
const [modalCustomButtons,setModalCustomButtons]=useState([]);
const [ modalTitle, setModalTitle ] = useState('');
const [searchKeyOrders,setSearchKeyOrders]=useState("");
const [filterSectors,setFilterSectors]=useState(false);
const [isOpen, setIsOpen] = useState(false);
const [actionStatus,setActionStatus]=useState({action:'',status:""});

let updatedEditFields = [];

const flagItem=useRef(null);
const containerRef = useRef();

useEffect(() => {
  const updateHeight = () => {
    const availableHeight = window.innerHeight * 0.55;
    if (containerRef.current) {
      containerRef.current.style.maxHeight = `${availableHeight}px`;
    }
  };

  updateHeight();
  window.addEventListener('resize', updateHeight);

  return () => {
    window.removeEventListener('resize', updateHeight);
  };
}, []);
useEffect(() => {
 if(employees.length ){
sessionStorage.setItem('emple',JSON.stringify(employees))
 }
 if(stores.length){
sessionStorage.setItem('storL',JSON.stringify(stores))

 }
}, [employees,stores])

useEffect(() => {
  if (id) {
    if(!employees?.length){
      fetchEmployees();
    }
    if(!stores.length){
      fetchStores();
    }
    fetchEditDocument();
    fetchSectorLists();
  }
}, [id,instance]);

useEffect(() => {
  if (instance) {
    setEditorInstance(instance)
  }
}, [instance]);

useEffect(() => {
  if(state?.selected && state.selected?.action==='findSectors'){
    fetchSectorLists();
  }
}, [state,page]);

useEffect(() => {
    sectorSearchFilters(1);
  }, [filterSectors,searchKeyOrders])

useEffect(() => {
  const timer = setTimeout(() => {
    if ((!editDetails || !editDetails.sectors?.length) && loadingEditSectors) {
      setLoadingEditSectors(false);
    }
  }, 5000);

  if (editDetails && editDetails.sectors && editDetails.sectors?.length) {
    setLoadingEditSectors(false);
  }

  return () => clearTimeout(timer);
}, [editDetails, loadingEditSectors]);

  const handleContainerClick = (e) => {
    if(flagItem.current && !flagItem.current.contains(e.target)) {
      setIsOpen(false);
      scrollContainer(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleContainerClick);
    return () => {
      document.removeEventListener('mousedown', handleContainerClick);
    };
  }, []);


  const sectorSearchFilters=(pageSearch=1)=>{
    setSectorListing([])
let filtersSec=[];
    const filterData={
      page: pageSearch??page,
      limit: 20,
      ...(searchKeyOrders?.trim() && { searchParam: searchKeyOrders })
  }
const getSearchParams=(srchArr)=>{
if(srchArr.length> 0 && !srchArr.includes(null)){
  return Number(srchArr[0].value)
}
return null;
}

const filterConditions = [
  {condition:"isolate",filter:{
    key: 'isolateId',
    value: id,
    condition: 'NotEquals'
  }},
  {condition:"state",filter:{
    key: "state",
    value: [
        "open",
        "locked"
    ]
  }},
  { condition: employee, filter:  {
key: "employeeId",
value: getSearchParams(employee)
} },
  { condition: sectors, filter:   {
    key: "storeId",
    value:getSearchParams(sectors)
}, },
  { condition: flights, filter: {
    key: "flightDate",
    value:(flights && typeof flights==='object')? moment(new Date(flights)).format("YYYY-MM-DDTHH:mm:ssZ"):null
} }
]
filterConditions.forEach(({ condition, filter }) => {
  if(filter.value && filter.value!=='Invalid date'){
    filtersSec.push(filter);
  }
  }
);
filterData.filters = filtersSec;
fetchSectorLists(filterData);

  }

const handleReset = () => {

  let timerId = setTimeout(() => {
    clearTimeout(timerId);
    setModalFormStatusObj({ text: '' })
    setState((_prev) => {
      return { ..._prev, selected: false,ignoreWarningCheck:false}
    });

  }, 3500)
}

const sectorDetails={
  sectors,setSectors,
employee,setEmployee,
editAction,page,setPage,
flights,setFilghts,filterSectors,loading,setLoading,
searchKeyOrders,setSearchKeyOrders,setFilterSectors
}


const saveEdits=async(e,alt=null,updateObj=null)=>{
setLoading(true);
if(alt==='saveChanges' && !updateObj){
if(sorting && editedSectors.length){
     const payload={
    isolateId: id,
    documentId: editDetails?.id,
    edits: [
        {
            name: "ReorderSectors",
            params: {
                order:editedSectors?.map(fl=>fl.id)
            }
        }
    ]
}
try {

  const res = await APIEndpoint.post(`sandboxing/containerIsolates/editDocument/edits`,payload);
if(res){
setActionStatus({action:'Save Changes',status:'Saved pending changes'})
setEditAction(prev=>!prev);
setSorting(false);
setLoading(false);
return res;
}

} catch (error) {
  setLoading(false);
}
  }else{
setLoading(false);
  }
}
else{
  const payload={
    isolateId: id,
    documentId: editDetails?.id,
    edits:[
      {
          name: "RefreshUplifts",
          params: {
              target: {
                  id: updateObj.id,
                  key: updateObj.key,
                  name: updateObj.name
              }
          }
      }
  ]
}
try {

  const res = await APIEndpoint.post(`sandboxing/containerIsolates/editDocument/edits`,payload);
if(res){
setActionStatus({action:'Refresh Uplifts',status:'Uplifts refreshed'})
setLoading(false);

return res;
}

} catch (error) {

}
}


}


const fetchStores=async()=>{
  if(stores.length)return;
  try {
    const respStore = await DOMAIN.get('stores');
    if (respStore.result.length > 0) {
        const options = [];
        respStore.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.value} (${fieldValue.originName}-${fieldValue.destinationName})`, value: `${fieldValue.id}`});
          return fieldValue;
        });
        setStores(options);
    }
    else{
        console.log("error")
    }
  } catch (error) {

  }
}

const attachSector=async(sectorId)=>{
  const payload={
    isolateId: id,
    sectorId
}
  try {
    const resAttach = await DOMAIN.post('sandboxing/containerIsolates/isolatedSectors/attach',payload);
  if(resAttach.sector){
    setModalFormStatusObj({text:"Sector Attached to Instance.You can attach more or close this dialog"})
    setEditAction(prev=>!prev);
  }
  else{
  }
  } catch (error) {
    const errResp=JSON.parse(error.message);
    setModalFormStatusObj({text:errResp.data?.message})
    handleReset();
  }
}

const fetchEditDocument=async(act=null)=>{
  setLoading(true);
  const payload={
    "isolateId": id
}
  try {
    const respEditDoc = await DOMAIN.post('sandboxing/containerIsolates/getEditDocument',payload);

    if (respEditDoc) {
      setLoading(false);
      if(act && act==='touch'){
        setActionStatus({action:'Touch',status:'Success'})
      }
        setEditDetails(respEditDoc.editDocument);
        setEditDetailsContainer(respEditDoc.editDocument);
        return respEditDoc.editDocument;
    }
    else{
        console.log("error")
    setLoading(false);

    }
  } catch (error) {

  }
}
const fetchEmployees=async()=>{
if(employees.length)return;
  try {
    const respEmp = await DOMAIN.get(`sandboxing/employees?includeAdmin=yes`);
    if (respEmp.result.length > 0) {
        const options = [];
        respEmp.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.employeeName} (${fieldValue.employeeCode})`, value: `${fieldValue.employeeId}`});
          return fieldValue;
        });
        setEmployees(options);
    }
    else{
        console.log("error")
    }
  } catch (error) {

  }
}

  const  fetchSectorLists=async(fil=null)=>{
    let filterData;
    if(!fil){
  filterData={
      page: page,
      limit: 20,
      filters: [
          {
              key: 'isolateId',
              value: id,
              condition: 'NotEquals'
          },
          {
              key: "state",
              value: [
                  "open",
                  "locked"
              ]
          }
      ],
      searchParam: searchKeyOrders
  }
    }else{

      filterData=fil
    }

  try {
    const res = await APIEndpoint.post('sandboxing/containerIsolates/isolatedSectors/list', filterData);
    if(res.items){
        setSectorListing((_)=>[..._,...res.items])
    }

  } catch (error) {

  }
  };

  const onClose = () => {
    setModalFormStatusObj({ text: '' });
    setActionStatus({action:'Commit Instance',status:'Operation cancelled by user'})
    setState((_prev) => {
      return { ..._prev, selected: false,ignoreWarningCheck:false}
    });

    }


    const onEnableDisableAction = async () => {

      const payload = {
        "isolateId": editorInstance?.id,
        "documentId": editDetails.id,
        ...(state.ignoreWarningCheck && { "ignoreWarnings": true })
      };
      if(editorInstance && editorInstance.id){
         try {
        const res = await APIEndpoint.post('sandboxing/containerIsolates/commit', payload);
        if(res.commitResult && res.commitResult.status!=='failed' && !res.commitResult.canForceCommit){
          setEditAction(prev=>!prev);
          setActionStatus({action:'Commit Instance',status:'Success'})
          setModalFormStatusObj({ text: res.commitResult.status.charAt(0).toUpperCase()+ res.commitResult.status.slice(1) });
          handleReset();
        }else if(res.commitResult && res.commitResult.canForceCommit && res.commitResult.status==='failed'){
          setModalFormStatusObj({ error: 'Failed to commit instance.' });
          setState((prev)=>({...prev,selected:{...prev.selected,action:"forceCommit",dataWarnings:res.commitResult?.validationResult.documentErrors}}))
        }else if(res.commitResult && !res.commitResult.canForceCommit && res.commitResult.status==='failed'){
          setState((prev)=>({...prev,selected:{...prev.selected,action:"noForceCommit",dataWarnings:res.commitResult?.validationResult.documentErrors}}))

          setModalFormStatusObj({ error: 'Failed to commit instance.' });
        }

      } catch (error) {
        setModalFormStatusObj({ error:error.message });

      }
      }

        }

        const warningLists=()=>{
          setIsOpen(!isOpen);
          scrollContainer(!isOpen)
        }
  const sectorSearchModal=async()=>{
setState((prev)=>({...prev,selected:{action:"findSectors"}}))
  }

const commitInstance=()=>{
  setModalTitle('Commit Instance -' +editorInstance?.name );
  updatedEditFields = [
    {
        label: 'Committing an instance is irreversible. Make sure that everything is in order before proceeding.',
        attribute: 'alertMessage', type: 'alertMessage'
    }
];
setModalCustomButtons([
                     {label:'Proceed',attribute:'button',type:"button",disabled:false,className:'submit-button',action:(state)=>onEnableDisableAction(state)}])
setState( (_prev) => {
  return { ..._prev,selected: {instance,action: 'commitInstance' }}
   });
setEditFieldsCess(updatedEditFields);
}
const sectorEditDetails={
 loading,setLoading,editorInstance,eventLogs,delOffld,inventoryDetails,navigateInstanceId,setNavigateInstanceId,saveEdits,setActionStatus,setEditedSectors,id,setEditAction,editAction,editDetails
}
const fetchComitState=()=>{
  if(!state.selected)return;
  return true
}
const handleIgnoreCheck=()=>{
  setState( (_prev) => {
    return { ..._prev,ignoreWarningCheck:!state.ignoreWarningCheck}

     });
}
  const handleModalCancelEvent=(act=null)=>{

    setState((prev)=>({...prev,selected:false}))
    setModalFormStatusObj({ text: '' })
    setSectors([]);
    setEmployee([]);
    setFilghts("");
    if(act==='findSector')
    setActionStatus({action:'Find Sector',status:'Operation cancelled by user'})
    setPage(1);
   }

   const lockInstance=async()=>{
    const payload=
      {
        "isolateId": id
    }

  try {

    const res = await APIEndpoint.post(`sandboxing/containerIsolates/lock`,payload);
  if(res&&res.state==='locked'){
  setActionStatus({action:'Lock Instance',status:'Instance Locked'})
  setEditAction(prev=>!prev);
  }

  } catch (error) {

  }
   }
  return (<>
   { !editorInstance ?
          <Loading />: <div ref={containerRef} className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark" style={{overflowY:'scroll'}}>
      <div className='flexRow justifyContentSpaceBetween' style={{margin:'0rem 0.5rem'}}>

        <div className="flexRow justifyContentFlexStart alignItemsBaseline">
        { editorInstance.state==='open'?<> <div style={{display:'flex',alignItems:'center',minWidth:'12rem',maxWidth:'14rem',padding:"0px"}} className='lock-instance-button margRight10'>
               <button  onClick={lockInstance}  style={{height:"2.89rem",width:'80%',whiteSpace:'nowrap',color:'black'}}>
               <span style={{ verticalAlign: 'baseline' }} >   <FaLock style={{marginRight:'5px'}}/> Lock Instance</span>
             </button>
             <button onClick={()=>setState((prev)=>({...prev,selected:{action:"lockMessage"}}))} style={{height:"2.89rem",width:'20%',borderLeft:"1px solid #072a47",color:'black'}}>
             ?
             </button>
          </div></>:editorInstance && editorInstance.id && !loadingEditSectors && editorInstance.state !=='open'?<button onClick={()=>fetchEditDocument("touch")} disabled={editorInstance&&(editorInstance.state==='open'||editorInstance.state==='committed')?true:false} className={editorInstance&&(editorInstance.state==='open'||editorInstance.state==='committed')?"isolate-cont-control-opaque margRight10":"isolate-cont-control margRight10"}>
          <span style={{ verticalAlign: 'baseline',marginBottom:0 }}><IoReloadCircleSharp style={{marginRight:'5px'}}/>Touch</span>
             </button>:null}
            {editorInstance && editorInstance.id && !loadingEditSectors?<><button onClick={sectorSearchModal} disabled={editorInstance&&(editorInstance.state==='open'||editorInstance.state==='committed')?true:false}  className={editorInstance&&(editorInstance.state==='open'||editorInstance.state==='committed')?"isolate-cont-control-opaque margRight10":"isolate-cont-control margRight10"}>
             <span style={{ verticalAlign: 'baseline' }} >  <FaSearch style={{marginRight:'5px'}}/>  Find Sector</span>
             </button>
             <button onClick={(e)=>saveEdits(e,'saveChanges',null)} disabled={editorInstance&&(editorInstance.state==='open'||editorInstance.state==='committed')?true:false}  className={editorInstance&&(editorInstance.state==='open'||editorInstance.state==='committed')?"isolate-cont-control-opaque margRight10":"isolate-cont-control margRight10"}>
             <span style={{ verticalAlign: 'baseline' }} >   <FaRegSave  style={{marginRight:'5px'}} /> Save Changes</span>
             </button>
             <button onClick={commitInstance} disabled={editorInstance&&(editorInstance.state==='open'||editorInstance.state==='committed')?true:false}  className={editorInstance&&(editorInstance.state==='open'||editorInstance.state==='committed')?"isolate-cont-commit-opaque margRight10":"isolate-cont-commit margRight10"}>
             <span style={{ verticalAlign: 'baseline' }} >   <FaLock style={{marginRight:'5px'}} /> Commit</span>
             </button></>:<> <img src={LoadingIn}  className="loading-img" alt="Loading" /></>}
             </div>

<div className='flexRow justifyContentFlexEnd'>

{actionStatus.status?<div  style={{border:'1px solid #0C5061',whiteSpace:'nowrap',minWidth:"160px",maxWidth:'540px',marginRight:"5px",borderRadius:'5px'}}  >
<div className='flexRow status-bar-flag' style={{borderBottom:'0px'}}>
  { actionStatus.action} : {actionStatus.status}
</div>

</div>:<></>}

  {editDetails.validationResult?.documentErrors.length?<button ref={flagItem} className="grid-download-btnStyle1" onClick={()=>warningLists()}>
    <span className="">
    <FaFlag style={{color:editDetails.validationResult?.documentErrors.length?'#f03630':'white'}} />
                                            </span>


    </button>:<></>}

</div>
      </div>



      {isOpen?<>
        <div className="flexCol chat-popup margRight"  style={{height: '50%', maxHeight: 'calc(100vh - 50px)', overflow: 'hidden' }}>
    <div className='flexRow status-bar-flag justifyContentSpaceBetween'>
      <div className='justifyContentFlexStart alignItemsCenter'>
      <FaFlag  size={16} style={{marginTop:'8px',color:'#f03630'}}/>
    </div>
    <div className='justifyContentFlexEnd flexRow alignItemsCenter justifyContentCenter'>
  <span className='margRight10'> Errors</span> <span style={{marginRight:0,backgroundColor:'#f03630',borderColor:'#f03630',color:'white'}} className="round-border-count">{editDetails.validationResult?.documentErrors.filter(er=>er.level==='error').length}</span>
  <span className='margLeft6 margRight10'>Warnings </span> <span style={{marginRight:0,backgroundColor:'#e7db76',borderColor:'#e7db76',color:'#595422'}} className="round-border-count">{editDetails.validationResult?.documentErrors.filter(er=>er.level==='warning').length}</span>
    </div>
</div>
<div class="form-container">
  <ul className='list-group' style={{ listStyleType: 'disc', height: '40vh',overflowY: 'scroll' }}>
    {editDetails.validationResult?.documentErrors.map((errDet,index) => (
      <li key={index} className={errDet.level==='warning'?'error-det-isolates-mild':'error-det-isolates-heavy'}>
        <p>{errDet.message}</p>
        <p>Resolutions:</p>
              <ul className='margBottom10'>
              {errDet.resolutions.map(resl => (
              <li key={resl.id} className='margBottom10 flexRow justifyContentFlexStart'>
              &bull;&nbsp;{resl}
              </li>
              ))}
              </ul>
      </li>
    ))}
  </ul>
</div>
</div>
</>:<></>}

{loadingEditSectors && editorInstance && editorInstance.state!=='committed' && editorInstance.state!=='open' ? (
  <div className='flexRow justifyContentCenter alignItemsCenter margTop36'>
    <img src={LoadingIn} className="loading-img" alt="Loading" />
    <div className="margTop5">Please wait while we fetch necessary details. This might take a while...</div>
  </div>
) : editDetails.sectors && editDetails.sectors.length ? (
  <div style={{ border: '1px solid #0C5061', borderRadius: '5px', margin: '2rem 0.5rem' }}>
    <div className='flexRow status-bar-flag'>
      <div className='justifyContentFlexStart'>Sectors</div>
    </div>
    <SectorsEditIsolate setSort={setSorting} {...sectorEditDetails} header={editDetails.sectors} deleteRefresh={fetchEditDocument} />
  </div>
) : (  editorInstance && editorInstance.state==='committed'?  <div class="alert alert-success margTop10">Instance has been committed.</div>:<>
        { editorInstance && editorInstance.state==='open'?  <div class="alert alert-info margTop10">Instance needs to be locked before you can start editing. Click on 'Lock Instance' above to
         lock the instance. <br/>Click on '?' for more help.</div>:<>
         <div style={{ border: '1px solid #0C5061', borderRadius: '5px', margin: '2rem 0.5rem' }}>
  <div className='flexRow status-bar-flag'>
    <div className='justifyContentFlexStart'>Sectors</div>
  </div>
  <li className='flexRow'>
    <div  style={{margin:'1rem'}}className='flexRow col-xs-11 wrapper-sector justifyContentCenter alignItemsCenter'>
      No sectors attached to this instance yet.
    </div>
  </li>
</div>
         </>
      }

</>




)}


{editDetails.sectors && editDetails.sectors.length?<div  style={{width:'50%',border:'1px solid #0C5061',borderRadius:'5px',margin:'2rem 0.5rem' }} className='margTop16'>

    <div className='flexRow status-bar-flag'>
      <div className='justifyContentFlexStart'>
       Preview
    </div>

</div>
<div className="flexRow">
<div className="width90 textAlignCenter alert alert-success margTop10">No Preview Available.</div>
</div>

</div>:<></>}





       {
       state&&state.selected&&state.selected.action === 'findSectors' &&<div className='full-flex width100'> <Modal
        className=''
        title={`Find Sector`}
        onSubmit={handleModalCancelEvent}
        onClose={()=>handleModalCancelEvent("findSector")}
        modalFormStatusObj={modalFormStatusObj}
        >
     <SectorListing stores={stores} employees={employees} {...sectorDetails} attachSector={attachSector} sectorListing={sectorListing}/>

      </Modal></div>

      }
     {
       state&&state.selected&&state.selected.action === 'lockMessage' &&<div className='full-flex width100'><Modal
       className=''
       title={`Help - Lock Instance`}
       onSubmit={handleModalCancelEvent}
       onClose={handleModalCancelEvent}>
         <div className='flexCol full-flex'>
         <div class="modal-body">
        <article>
            <h4>Introduction</h4>
            <p className='margBottom28 lineheight15'>
                An instance, <code>{editorInstance.name}</code> in this case, represents an
                Aircraft on a
                particular date from start to day-end. This would mean that all user activity on an aircraft is attached
                to an instance.
                This can be montiored and issues resolved using control panel. For editing to work properly, the
                instance needs to be locked.
            </p>

            <h4>What locking does?</h4>
            <p className='margBottom28 lineheight15'>
                When you lock an instance, it remains closed to all future operations that would otherwise be captured
                in that particular instance. i.e, if a crew member tries to sync data to a locked instance, the system
                will create another instance with
                same aircraft number and date to capture the data being synced. Such duplicates can be merged to the
                locked instance afterwards.
                This means that even if you accidentaly lock an instance, there won't be any data loss.

            <div className="alert alert-warning margTop10">
                Caution: Locking is irreversible. This means that, once you lock an instance, it stays locked.
            </div>
            </p>

            <h4>When to lock an instance?</h4>
            <p className='margBottom28 lineheight15'>
                Consider locking an instance only after ensuring that all sectors are complete for that aircraft and is
                ready for day-end.
                Following this process makes sure that you have all the required sectors before editing starts.
            </p>
        </article>

    </div>


         </div>
     </Modal>
        </div>

      }
      {
         Boolean(state?.selected) && (state?.selected?.action==='commitInstance') && fetchComitState() ? (
        <div className='flexRow width100'>
          <EditModal
            key={state.selected.action}
            title={ modalTitle }
            onClose={onClose}
            mainContentCenter={true}
            modalFormStatusObj={modalFormStatusObj}
            onSubmit={onEnableDisableAction}
            modalCustomButtons={modalCustomButtons}
            fields={EditFieldsCess.map( (elt) => {
              return { ...elt, initialValue: state.selected.instance[elt.attribute] }
              })
            }
          />
          </div>
        )
        : null
      }

{
         Boolean(state?.selected) && (state?.selected?.action==='forceCommit' ||state?.selected?.action==='noForceCommit') ? (

        <div className='full-flex width100'>
        <Modal
        className=''
        title={modalTitle}
        key={state.selected.action}
        onSubmit={onEnableDisableAction}
        onClose={onClose}
        modalFormStatusObj={modalFormStatusObj}
        modalCustomButtons={[
          {
            label: 'Proceed',
            attribute: 'button',
            type: "button",
            disabled: !state.ignoreWarningCheck,
            className: 'submit-button',
            action: (state)=>onEnableDisableAction(state)
          }
        ]}
        >
       <>
       <p  style={{margin:0,width:'98.5%'}}className='dialog__alertMessage' dangerouslySetInnerHTML={ {__html:'Committing an instance is irreversible. Make sure that everything is in order before proceeding.'}}></p>
       <ul className='list-group' style={{ listStyleType: 'disc', maxHeight: '47vh', overflowY: 'scroll' }}>
         {state.selected.dataWarnings?.map((errDet, index) => (
        <li key={index} className={errDet.level === 'warning' ? 'error-det-isolates-mild' : 'error-det-isolates-heavy'}>
            <p>{errDet.message}</p>
             <p>Resolutions:</p>
              <ul className='margBottom10'>
              {errDet.resolutions.map(resl => (
              <li key={resl.id} className='margBottom10 flexRow justifyContentFlexStart'>
              &bull;&nbsp;{resl}
              </li>
              ))}
              </ul>
        </li>
  ))}
</ul>
<>
     {state?.selected?.action!=='noForceCommit' ?<div className={`dialog__container_item input-container`}>
            <div className={`dialog__description__checkbox `}>
              <input
                  id='2'
                  type={'checkbox'}
                  onClick={handleIgnoreCheck}
                  checked={state.ignoreWarningCheck}
                  value={state.ignoreWarningCheck}
                  required={true}
                />
                <label htmlFor='2' className='margTop8'>Ignore warnings</label>
            </div>
        </div>:<></>}
        </>
</>


      </Modal></div>

        )
        : null
      }
      </div>}



</>
  )
}