import React,{useEffect,useRef,useState} from 'react'
import moment from 'moment';
import { APIEndpoint } from '../../../../utils/axios';
import LoadingGIF from '../../../../assets/loadingIn.gif';
import {formatDateByTimezone} from '../../../../utils/commonFunctions';
import { IoArrowForwardOutline } from "react-icons/io5";
import { CollapsebleSectorInstance } from '../../../../common';

export const  Schedulecontainer=({id})=> {
  const [scheduledata, setScheduleData] = useState(()=>{
    const schData=sessionStorage.getItem('SchedDat');
    return schData?JSON.parse(schData):{};
  });
  const [loading, setLoading] = useState(false);
  const [instanceState,setInstaceState]=useState("");
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const containerRef=useRef();

  useEffect(() => {
   if(id){
    getSectorData(id,false);
   }

  }, [id]);

  useEffect(() => {
    const updateHeight = () => {
      const availableHeight = window.innerHeight * 0.55;
      if (containerRef.current) {
        containerRef.current.style.maxHeight = `${availableHeight}px`;
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);
const onRefresh=(id,ref=false)=>{
  sessionStorage.removeItem('SchedDat');
  getSectorData(id,ref);
}
  const  formatString=(str)=> {
    const formatted = str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    return formatted.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}
  const getSectorData = async (sectorId,ref=false) => {
    if(Object.keys(scheduledata).length && !ref)return;
    setLoading(true);

    try {
      const res = await APIEndpoint.get(`sandboxing/isolates/${sectorId}/schedule`);
      if (res) {
        setLoading(false);
        sessionStorage.setItem('SchedDat',JSON.stringify(res.schedule||{}))
        setScheduleData(res.schedule||{});
        if(!res?.schedule && res?.message){
          setInstaceState(res.message)
        }

      }
    } catch (error) {
      let {data} = JSON.parse(error.message);
      if(data ){
        setInstaceState(data?.message)
        setLoading(false);
      }
    }
  };
  const handleView = (id) => {
    setSelectedItemIndex(prevIndex => (prevIndex === id ? null : id));
  };

  const CrewLabels = (list) => {
    if (list.length) {
      return (
        <div className="crew-container margTop5">
          {list.map((item, index) => (
            <div className="employee-crew margBottom5" key={item.employeeId}>
              <div className='flexRow'>
                {item.employeeName}&nbsp;({item.role})
              </div>
              <div className='flexRow'>{item.employeeCode}</div>
            </div>
          ))}
        </div>
      );
    } else {
      return "No crew members added yet";
    }
  }


  useEffect(() => {
    const timer = setTimeout(() => {
      if (!scheduledata?.sectors?.length) {
        setLoading(false);
      }
      if (instanceState.length) {
        setLoading(false);
      }
    }, 4000);

    return () => clearTimeout(timer);
  }, [scheduledata?.sectors?.length, instanceState.length]);


  return (
    <>
      <div ref={containerRef} className="op-aircraft-container flexRow full-flex ag-theme-alpine-dark" style={{ overflowY: 'scroll' }}>
        <div className="flexCol width100">
          <div className='margBottom10'>
            <label className='fontExtrabold'></label>
            <button style={{ width: '6rem', whiteSpace: 'nowrap' }} className="status-dynamic dynamic-sl" onClick={() => onRefresh(id,true)}>Refresh</button>
          </div>

          {loading ? (
            <img src={LoadingGIF} className='icon-size' style={{ width: '25px', height: '25px' }} alt="Loading" />
          ) : (
            <>
              <div className='margBottom10'>
                <label className='fontExtrabold'>Last Updated At</label>
                <div>{scheduledata.lastUpdatedAt?moment(scheduledata.lastUpdatedAt).format('DD-MM-YYYY hh:mm A'):'Not available'}</div>
              </div>

              <div className='margBottom10'>
                <label className='fontExtrabold'>Source</label>
                <div>{scheduledata.source||'Not available'}</div>
              </div>

              <div className='flexCol'>
                <label className='fontExtrabold'>Sectors</label>
              </div>
            </>
          )}

          {loading ? (
            <div className='flexRow justifyContentCenter alignitemsCenter'>
              <img src={LoadingGIF} className='icon-size' style={{ width: '25px', height: '25px' }} alt="Loading" />
              <div className="margTop9-5">Refreshing aircraft schedule, This may take a while...</div>
            </div>
          ) : null}

          {scheduledata?.sectors?.length ? (
            <ul className='list-group' style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
              {scheduledata?.sectors?.map((item, index) => (
                <React.Fragment key={index + 1}>
                  <li
                    className={`flexRow col-xs-11 wrapper-sector justifyContentSpaceBetween ${index + 1 === selectedItemIndex ? 'selected' : ''}`}
                    onClick={() => handleView(index + 1)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="flexRow justifyContentCenter alignItemsCenter">
                      <span className="justifyContentFlexStart instance-member fontExtrabold">{item.flightNumber} {item.origin}-{item.destination}</span>
                      <span className="margLeft5 fontSemibold">{moment.parseZone(formatDateByTimezone(item.flightDate)).format('DD-MM-YYYY')}</span>
                    </div>
                  </li>
                  {selectedItemIndex === index + 1 && (
                    <CollapsebleSectorInstance id={index + 1} selectedItemIndex={selectedItemIndex} key={index}>
                      <div className='fitcontent justifyContentSpaceBetween col-xs-11 wrapper-sector'>
                        <div className='flexRow sector-banner justifyContentSpaceBetween'>
                          <div className='flexRow justifyContentFlexStart'>
                            <div className='sector-number fontMedium'>{item.flightNumber}</div>
                            <div className='flexCol justifyContentCenter alignItemsCenter'>
                              <div className='flexRow sector-tag'>{item.origin}</div>
                              <div className='flexRow fontMedium'>{item.originName}</div>
                            </div>
                          </div>

                          <div className='flexCol justifyContentCenter alignItemsCenter'>
                            <div className='flexRow'> <IoArrowForwardOutline size={17} color={'#F06548'} /></div>
                            <div className='flexRow fontMedium'>{moment.parseZone(formatDateByTimezone(item.flightDate)).format('DD-MM-YYYY')}</div>
                          </div>

                          <div className='flexCol justifyContentCenter alignItemsCenter'>
                            <div className='flexRow sector-tag'>{item.destination}</div>
                            <div className='flexRow fontMedium'>{item.destinationName}</div>
                          </div>
                        </div>

                        <div className='flexRow fitContent'>
                          <div style={{ float: 'left' }} className="margTop8 flexCol">
                            <div className='flexCol margBottom10'>
                              <label className='fontExtrabold'>Group Identifier</label>
                              <div>{item.groupIdentifier ?? 'None'}</div>
                            </div>
                            <div className='flexCol margBottom10'>
                              <label className='fontExtrabold'> Status</label>
                              <div className="status-dynamic dynamic-sl" style={{ cursor: 'auto', backgroundColor: '#1d3f4f' }}>{formatString(item.operationStatus)}</div>
                            </div>

                            <div className='flexCol margBottom10'>
                              <label className='fontExtrabold'>Standard Departure Time</label>
                              <div>{item.departureDateTimeLocal?moment(item.departureDateTimeLocal).format('DD-MM-YYYY hh:mm A'):'Not available'}</div>
                            </div>

                            <div className='flexCol margBottom10'>
                              <label className='fontExtrabold'>Standard Arrival Time</label>
                              <div>{item.arrivalDateTimeLocal?moment(item.arrivalDateTimeLocal).format('DD-MM-YYYY hh:mm A'):'Not available'}</div>
                            </div>

                            <div className='flexCol margBottom10'>
                              <label className='fontExtrabold'>Sector Type</label>
                              <div>{item.sectorType}</div>
                            </div>

                            <div className='flexCol margBottom10'>
                              <label className='fontExtrabold'>Crew</label>
                              <div>{CrewLabels(item.crew)}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CollapsebleSectorInstance>
                  )}
                </React.Fragment>
              ))}
            </ul>
          ) : (
            <div className='flexRow'>{!loading && !instanceState?'No sectors scheduled for this aircraft.':!loading && instanceState?instanceState:''}</div>
          )}
        </div>
      </div>
    </>
  );

}
