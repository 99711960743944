import React, { useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import { useTranslation } from "react-i18next";

import searchIcon from '../../../assets/icons/search.svg';
import viewIcon from "../../../assets/view.svg";

import { DataGrid, EditModal ,Loading} from "../../../common";
import { APIEndpoint } from "../../../utils/axios";
import {  FilterBodyTemplate } from "../../../utils/configs";

const EditFormFields = [
    { label: 'View GRN Details', type: 'viewReverseGRNDetails', attribute: 'items' }
];



export const ReverseGRNList = (props) => {
    const { t,ready } = useTranslation();
    const navigate = useNavigate();
const {state:revGrnState}=useLocation();
    const urlParams = useParams();
    const warehouseId = urlParams.warehouseId;

console.log(ready)
    const grnId = urlParams.grnId;
    const [state, setState] = useState({});
    const [editFormFields, setEditFormFields] = useState(EditFormFields);
    const [perPageLimit, setPerPageLimit] = useState(5);
    const [searchKey, setSearchKey] = useState('');
    const [ modalTitle, setModalTitle ] = useState('View Purchase Order Items');
    const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
    const [modelStyle, setModalStyle] = useState({ maxWidth: '80%' });
    const [modalCustomButtons, setModalCustomButtons] = useState([]);
    const [noResultsMessage, setNoResultsMessage] = useState( 'No Data' );

    const Columns = [
        { field: 'code', headerName: 'GRN Code', checkboxSelection: false,
            minWidth: 190,
            tooltipField: 'code'
        },
        { field: 'warehouseCode', headerName: 'Warehouse',
            minWidth: 290,
            tooltipField: 'warehouseName',
            valueGetter: params => params.data ? params.data.warehouseCode + ' (' + params.data?.warehouseName + ')' : '',
        },
        { field: 'vendorCode', headerName: 'Vendor',
            minWidth: 230,
            width:280,
            tooltipField: 'vendorName',
            valueGetter: params => params.data ? params.data.vendorCode + ' (' + params.data?.vendorName + ')' : '',
        },
        { field: 'createdAt', headerName: 'Created At',
            minWidth: 140,
            tooltipField: 'createdAt',
            valueGetter: params => {
                if ( params.data ) {
                    return moment(params.data.createdAt).format('DD-MM-YYYY');
                }
            }
        },
        { field: 'createdBy', headerName: 'Created By',
            minWidth: 200,
            tooltipField: 'createdBy.employeeName',
            valueGetter: params => params.data ? params.data.createdBy?.employeeName : ''
        },
        { field: 'items', headerName: 'Summary',
            minWidth: 150,
            valueGetter: params => params.data ? params.data.items?.length + ' items' : ''
        },
        { field: 'actions',headerName:"Actions", minWidth: 170,pinned:'right', flex:1,cellRenderer: ActionComponent }

    ];

    useEffect( () => {
        setNoResultsMessage(t('pages.procurementSub.purchaseOrdersSub.noReverseGRNFound', { defaultValue: 'No Reverse GRN found' }));
    }, [t]);



    const onActionInner = (action, row) => {
        let updatedEditFields = [];

        if (action === 'viewReverseGRNDetails') {
            /** Here, Resetting default input fields to array then updating */
            setModalStyle( (_prev) => {
                return { ..._prev, maxWidth: '80%'};
            });
            setEditFormFields(EditFormFields);
            updatedEditFields = EditFormFields.filter( field => {
                if (field.type === 'viewReverseGRNDetails') {
                    setModalTitle('Reverse GRN - ' + row.code );
                    field.value = row;
                }
                return field;
            });
            setModalCustomButtons([[]]); // [[]]: To show no button
        }
        setEditFormFields( updatedEditFields );
        setState((_prev) => {
            return { ..._prev, selected: { ...row, action: action} };
        });
    };


    const onClose = (e, action, row) => {
        setState((_prev) => {
            return { ..._prev, selected: false };
        });
        setModalFormStatusObj({ text: '' });
    };



    function ActionComponent(props){

        if(!props.data) return null
        return (
          <div className='flexRow justifyContentSpaceBetween' style={{gap:"35px",padding:"10px"}}>
            <img src={viewIcon} className="action-Component-items" alt='edit' title='View Reverse GRN' onClick={()=>onActionInner("viewReverseGRNDetails",props.data)}/>

          </div>
        )
      }

    const fetchReverseGRNRows = async (page=1, perPage=10) => {
        const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
        filterData.page = page;
        filterData.limit = perPage;
        filterData.searchParam = searchKey;

        const res = await APIEndpoint.post('warehouses/' + warehouseId + '/reversegrns/' + grnId + '/process', JSON.stringify(filterData));
        return res;
    };

    const onSearch = (e) => {

        ReactDOM.flushSync( () => {
            setSearchKey( _prev => {
                return e.target.value
            } );

            fetchReverseGRNRows();
        } );

    };

    const onCreateReverseGRNHandler = () => {
        navigate(`/procurement/purchase-orders/reversegrn/create/${revGrnState?.warehouseId}/${grnId}`,{state:{...revGrnState}} );
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                {
                    <>
                        <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>
                        <div className="justifyContentFlexStart">
                                <button className="add-item-button margRight" onClick={ onCreateReverseGRNHandler }>
                                    {t('buttons.createReverseGRN')}
                                </button>
                        </div>

                                <div className="flexRow justifyContentCenter content-tabs">
	                                <span className="underline-grn"><img  className="icon-grn" alt='search' src={searchIcon} /></span>
	                                <input className="underline search-grn" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey}/>
	                            </div>
							</div>

                     { ready?  <DataGrid
                            className="full-flex"
                            columns={Columns}
                            initialPageLimit={perPageLimit}
                            showDefaultFilters={false}
                            // searchKey={searchKey}
                            getRows={ fetchReverseGRNRows }
                            noResultsMessage={ noResultsMessage }
                            rowSelection="multiple"
                            columnResize={true}
                        />:<Loading/>}
                    </>
                }
                {
                    state && Boolean(state.selected) ? (
                        <EditModal
                            title={ modalTitle }
                            customModalStyle={ modelStyle }
                            onClose={onClose}
                            // onSubmit={onSubmit}
                            modalFormStatusObj={modalFormStatusObj}
                            fields={ editFormFields.map( (elt) => {
                                    if (state.selected.action === 'viewReverseGRNDetails') {
                                        return {...elt, initialValue: state.selected[elt.attribute] || ''}
                                    }
                                    else {
                                        return {...elt, initialValue: '' }
                                    }
                                })
                            }
                            showFieldsInColumn={true}
                            modalCustomButtons={modalCustomButtons}
                        />
                    )
                    : null
                }

            </div>
        </>
    );
}