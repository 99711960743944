/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid, EditModal,Modal } from "../../../common";
import { APIEndpoint, DFPAPIEndpoint } from "../../../utils/axios";
import {downloadFileCaterer} from '../../../utils/commonFunctions';
import purchaseReqData from "../../../assets/json/PR_mock.json";
import DownloadVector from '../../../assets/icons/svg/download-vector.svg';
import usePermissions from "../../../utils/userPermissions";
import {
  updateButtonTxtStatus,
  checkDownloadStatus
} from "../../../utils/commonFunctions";
import {
  MIMETypes
} from "../../../utils/configs";
import editIcon2 from "../../../assets/edit2.svg";
import viewIcon from "../../../assets/view.svg";
import cancelIcon from "../../../assets/icons/cancel.svg";
import confirmIcon from "../../../assets/icons/confirm.svg";
import * as ReactDOM from "react-dom";

import searchIcon from "../../../assets/icons/search.svg";


let axiosAbortCtrl = [];
axiosAbortCtrl["orderListAPI"] = new AbortController();


const abortEarlierRequest = (apiName = "orderListAPI") => {
  axiosAbortCtrl[apiName].abort(
    apiName + ": Cancelling API running earlier..."
  );
};

const OrderByFilters = [
  { value: "orderDateDesc", label: "Order Date - Desc" },
  { value: "orderDateAsc", label: "Order Date - Asc" },
];


export const PurchaseRequisition = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({});
  const [perPageLimit] = useState(10);
  const [EditFieldsCess,setEditFieldsCess]=useState([]);
  const [ isReloadTableData, setIsReloadTableData ] = useState(false);
  const [modalTitle, setModalTitle] = useState("Order Details");
  const [modalCustomButtons, setModalCustomButtons] = useState(null);
  const [modalFormStatusObj] = useState({ text: '' });
  const [caterer,setCaterer]=useState([{key:1,label:'Taj',value:'Taj'},{key:2,label:'SAFS',value:'SAFS'},{key:3,label:'CAF',value:'CAF'},{key:4,label:'DTA',value:'DTA'},{key:3,label:'ASC',value:'ASC'}]);
  const [sectorList, setSectorsList] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [searchKey, setSearchKey] = useState("");


  const { hasPermission: createPurchaseReqn } = usePermissions('create', "procurement.purchase-requisitions");
  const { hasPermission: editPurchaseReqn } = usePermissions('edit', "procurement.purchase-requisitions");
  const { hasPermission: downloadPurchaseReqn } = usePermissions('download', "procurement.purchase-requisitions");
  const { hasPermission: deletePurchaseReqn } = usePermissions('delete', "procurement.purchase-requisitions");
  const { hasPermission: viewDetailsPurchaseReqn } = usePermissions('viewDetails', "procurement.purchase-requisitions");
  const { hasPermission: approvePurchaseReqn } = usePermissions('approve', "procurement.purchase-requisitions");
  const { hasPermission: printPurchaseReqn } = usePermissions('print', "procurement.purchase-requisitions");


  const [salesFilter, setSalesFilter] = useState({
    sectorVal: "",
    orderByVal: OrderByFilters[0].value,
    orderDateVal: "",
    searchKey: "",
    orderStatusVal: "",
    saleType: "",
    paymentType: "",
  });
  const InstanceState=[{id:1,label:'Active',value:'open'},{id:2,label:'Locked',value:'locked'},{id:3,label:'Commit Scheduled',value:'commitScheduled'},{id:4,label:'Commited',value:'commited'}]

  const EditFormFieldsInv = [
    {label: 'Item Name', attribute: 'itemName', type: 'text', disabled: false,required:true },
    {label: 'Quantity', attribute: 'quantity', type: 'number',options:caterer, disabled: false,required:true },
    {label: 'Warehouse', attribute: 'warehouseName', type: 'select',options:sectorList, disabled: false,required:true },
  ];

  const [sectorPageObj] = useState({
    page: 1,
    size: 50,
    q: "",
  });


  const [, setEditFormFields] = useState([]);

  useEffect(() => {
    fetchOrders();
    getSectorList();
    getSectors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesFilter,purchaseReqData]);


  const  getSectors=async(src=null,dst=null)=>{
    try{
  const res=await  DFPAPIEndpoint.get('stores');
  if(res.success){
    const options = [{key: 0, label:`Select sector`,label2:'Select sector', value: ''}];
    res.result.filter( (fieldValue, index) => {
      options.push({key: index+1, label:`${fieldValue.value}`,label2:fieldValue.originName, value: fieldValue.origin});
      return fieldValue;
    })
    setSectors(options)

  }
    }
    catch(err){

    }
    }

  const AddFormFields=[
    {label: 'Item Name', attribute: 'itemName', type: 'text', disabled: false,required:true },
    {label: 'Quantity', attribute: 'quantity', type: 'number',options:caterer, disabled: false,required:true },
    {label: 'Warehouse', attribute: 'warehouseName', type: 'select',options:sectorList, disabled: false,required:true },
]

   const fetchOrders = useCallback(
    async (page = 1, perPage = 10) => {
      setIsReloadTableData(!isReloadTableData);
if(!searchKey){
  return {count:purchaseReqData.length,items:purchaseReqData};

}else{
  const searchKeyUncase=searchKey.toLowerCase()
  const purchaseReqData = purchaseReqData.filter(ct=>ct.catererGroup.toLowerCase().includes(searchKeyUncase))
  return {count:purchaseReqData.length,items:purchaseReqData};
}

    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      searchKey

    ]
  );

  const getSectorList = useCallback(() => {
    APIEndpoint.get("warehouses").then(
      (resp) => {

        const list = [];
        if (resp.result.length > 0) {
          // eslint-disable-next-line array-callback-return
          resp.result.map((fieldValue, index) => {
            list.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.value}`});
          });

          setSectorsList(list);
        }
      },



    );
    const list = [];

    if (1) {
      // eslint-disable-next-line array-callback-return
      caterer.map((fieldValue, index) => {
        list.push({key: index+1, label: `${fieldValue.label}`, value: fieldValue.value});
      });

      setCaterer(list);
    }

    return false;
  }, [sectorPageObj]);


  const handleAction=(type,data)=>{
let updatedEditFields = [];
    if(type==='view' && viewDetailsPurchaseReqn){
      setState((_prev) => {
        return { ..._prev, selected: { items:[data],catData:data, action: type } };
      });
    }
    else if(type==='edit' && editPurchaseReqn){

      EditFormFieldsInv.forEach(edv=>{
        if(edv.attribute==='warehouseName'){
          edv.options=sectorList
        }
        if(edv.attribute==='status'){
          edv.options=[{key:0,label:"Select Status",value:""},{key:1,label:"Accepted",value:"accepted"},{key:2,label:"Rejected",value:"rejected"},{key:0,label:"Clarification",value:"clarification"}]
        }
      })
      setState((_prev) => {
        return { ..._prev, selected: { ...data, action: type } };
      });
    }
    else if(type==='accept' && approvePurchaseReqn){

      setModalTitle('Accept Purchase requisition -' + data.purchaseReqNumber);
      updatedEditFields = [
        {
            label: 'You are about to accept this purchase requisition details. Are you sure you want to continue?',
            attribute: 'alertMessage', type: 'alertMessage'
        }
    ];
    setModalCustomButtons([
                         {label:'Confirm',attribute:'button',type:"button",disabled:false,className:'submit-button',action:()=>handleModalCancel()}])
    setState( (_prev) => {
      return { ..._prev,selected: {data, action:type }}
       });
    setEditFieldsCess(updatedEditFields);

    }
    else if(type==='reject' && deletePurchaseReqn){
      setModalTitle('Reject Purchase requisition -' + data.purchaseReqNumber);
      updatedEditFields = [
        {
            label: 'You are about to reject this purchase requisition details. Are you sure you want to continue?',
            attribute: 'alertMessage', type: 'alertMessage'
        }
    ];
    setModalCustomButtons([
                         {label:'Confirm',attribute:'button',type:"button",disabled:false,className:'submit-button',action:()=>handleModalCancel()}])
    setState( (_prev) => {
      return { ..._prev,selected: {data, action:type }}
       });
    setEditFieldsCess(updatedEditFields);
    }
    else if(type==='clarfy' && editPurchaseReqn){
      setModalTitle('Clarification of Invoice -' + data.warehouseName+"-"+data.invoiceId);
      updatedEditFields = [
        {
            label: 'You are about to ask clarification for this invoice details. Are you sure you want to continue?',
            attribute: 'alertMessage', type: 'alertMessage'
        }
    ];
    setModalCustomButtons([
                         {label:'Confirm',attribute:'button',type:"button",disabled:false,className:'submit-button',action:()=>handleModalCancel()}])
    setState( (_prev) => {
      return { ..._prev,selected: {data, action:type }}
       });
    setEditFieldsCess(updatedEditFields);
    }
  }
     function actionSets(props) {

      return (
        props.data?
        <div>
            <span title={"View"} className="margRight10" onClick={()=>handleAction("view",props.data)}><img  className="icon-size" alt={"view"} src={viewIcon} /></span>
           <span  title={"Edit"} className="margRight10" onClick={()=>handleAction("edit",props.data)}><img  className="icon-size" alt={"edit"} src={editIcon2} /></span>
            <span  title={"Accept"} className="margRight10" onClick={()=>handleAction("accept",props.data)}><img  className="icon-size" alt={"accept"} src={confirmIcon} /></span>
            <span  title={"Reject"} className="margRight10" onClick={()=>handleAction("reject",props.data)}><img  className="icon-size" alt={"reject"} src={cancelIcon} /></span>
            {/* <span title={"Clarification"} onClick={()=>handleAction("clarfy",props.data)}><AiFillQuestionCircle size={19} style={{marginBottom:'6px',cursor:'pointer'}} /></span> */}
        </div> : <div></div>
      );
    }



    const Columns = [
      {
        field: "purchaseReqNumber",
        headerName: "PR number",
        minWidth: 140,
        autoHeight: true,
        pinned: "left",

      },
      {
        field: "date",
        headerName: "created At",
        sortable: false,
        filter: false,
        flex:1,
        minWidth: 140,
        autoHeight: true,
      },
      {
        field: "raisedBy",
        headerName: "Raised By",
        sortable: false,
        filter: false,
        flex:1,
        minWidth: 140,
        autoHeight: true,
      },
      {
        field: "department",
        headerName: "Department",
        sortable: false,
        filter: false,
        flex:1,
        minWidth: 140,
        autoHeight: true,
      },
      {
        field: "item",
        headerName: "Item Name",
        sortable: false,
        filter: false,
        flex:1,
        minWidth: 140,
        autoHeight: true,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        sortable: false,
        filter: false,
        flex:1,
        minWidth: 140,
        autoHeight: true,
      },
      {
        field: "status",
        headerName: "Status",
        valueGetter: (params) => {
          if (params.data&&params.data.status) {

            return params.data.status.charAt(0).toUpperCase()+params.data.status.slice(1)
          }
          return null;
        },
        sortable: false,
        filter: false,
        flex:1,
        minWidth: 100,
        autoHeight: true,
      },

      {
        field: "reviewedBy",
        headerName: "Reviewed By",
        sortable: false,
        filter: false,
        flex:1,
        minWidth: 140,
        autoHeight: true,
      },
      {
        field: "approvedBy",
        headerName: "Approved By",
        sortable: false,
        filter: false,
        flex:1,
        minWidth: 140,
        autoHeight: true,
      },


      {
        headerName:'Actions',
        pinned:'right',
          width:180,
          minWidth:10,
          resizable: false,
          flex:1,
          suppressSizeToFit:true, sortable: true,
          cellRenderer: actionSets,
          valueGetter: function(params) {
            return params.sectors;
          }
        }
    ];

    const downloadCaters=(data)=>{
      downloadFileCaterer(data)
    }
    const onInstanceStateChange=(e)=>{
      setState( (_prev) => {
          return { ..._prev,InstanceState:e.target.value,resetDataSource:!state.resetDataSource}
        });
  }
    const sectorTitle=()=>{
      const {catData}=state.selected;
        const excelSheetTitles = {};
        for (const key in catData) {
            const newKey = key.replace(/([A-Z])/g, " $1")
            .replace(/^./, function(key){ return key.toUpperCase(); })
            .replace(/\s+/g, ' ');
            excelSheetTitles[newKey] = catData[key];
        }

      return(<div className='flexRow justifyContentCenter'>
      {printPurchaseReqn && downloadPurchaseReqn?  <div className='flexRow'>
          <div className='flexRow'>Purchase Requisition<div className='instance-member-name'>{catData?.purchaseReqNumber}</div></div>
            <button className='small-body-text-regular fontExtrabold key-value btn-sync'>
            <img style={{margin:'0.1rem 0rem 0rem 0rem',width:'20px',height:'20px'}} className="icon-charts" src={DownloadVector} alt="Download icon" onClick={()=>downloadCaters(excelSheetTitles)} />
            </button>
        </div>:<></>}
      </div>)
      }

  const onSectorChange = (e) => {
    setSalesFilter({ ...salesFilter, sectorVal: e.target.value });
    abortEarlierRequest("orderListAPI");
  };
  // const onOrderByChange = (e) => {
  //   setSalesFilter({ ...salesFilter, orderByVal: e.target.value });
  //   abortEarlierRequest("orderListAPI");
  // };
  const onSearch = (e) => {
    const searchTxt = e.target.value;
    setSearchKey(searchTxt);

    // if (searchTxt.length >= 3) {
    // setSalesFilter({ ...salesFilter, searchKey: searchKey });
    // }
    abortEarlierRequest("orderListAPI");
  };

  const onAction = (e, action, row) => {
    onActionInner(e, action, row);
  };

  const onActionInner = (e, action, row) => {

    ReactDOM.flushSync(() => {
      let updatedEditFields = [];

      if (action.value === "viewItems" && viewDetailsPurchaseReqn) {
        setModalTitle("Order No - " + row.orderNo);

        updatedEditFields = [
          {
            label: "View Order Details",
            type: "viewSalesDetails",
            attribute: "items",
          },
        ];
        updatedEditFields = updatedEditFields.filter((row) => {
          if (row.type === "viewSalesDetails") {
            row.value = row;
          }
          return row;
        });

        // setModalCustomButtons([[]]);
        setModalCustomButtons([
          /**
           * Buttons
           */
          {
            label: "Close",
            attribute: "button",
            type: "button",
            disabled: false,
            className: "close-button",
            action: (e) => {
              onClose();
              return false;
            },
          },
        ]);

        setEditFormFields([...updatedEditFields]);

        setState((_prev) => {
          return { ..._prev, selected: { ...row, action: action.value } };
        });
      } else if (action.value === "downloadReportPopup" && downloadPurchaseReqn) {
        setState((_prev) => {
          return { ..._prev, selected: { ...row, action: action.value } };
        });
      } else if (action.value === "downloadInvoices" && downloadPurchaseReqn) {

        // onDownloadPDFFile('downloads/invoice', 'order_invoice_' + row.id, { method: 'POST', payload: { orderId: row.id } } );

        const linkTxt = action.title;
        updateButtonTxtStatus(false, e, "...", true);
        APIEndpoint.post(
          "downloads/invoice",
          { orderId: row.id },
          { Accept: MIMETypes.pdf }
        )
          .then((resp) => {
            if (resp.status === "scheduled") {
              checkDownloadStatus(resp.id, e);
            } else {
              updateButtonTxtStatus(true, e, linkTxt, false);
            }
          })
          .catch((error) => {

            updateButtonTxtStatus(false, e, "Failed!", false, {
              defaultButtonText: linkTxt,
              className: "failedCls",
            });
          });
      }
    });
  };
  const handleModalCancel=()=>{
setState((prev)=>({...prev,selected:false}))
  }

  const onClose = () => {
    setState((_prev) => {
      return { ..._prev, selected: false };
    });
  };
const onAddInvoice=()=>{
  setState((prev)=>({...prev,dialogData:{title:"Add Invoice*"},selected:{action:"addInvoice"}}))
}

  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>

          <div className="flexRow width100 margBot10 justifyContentSpaceBetween">
          <div className="flexRow width100 justifyContentSpaceBetween alignItemsCenter">
          <div className="flexRow width100 justifyContentSpaceBetween alignItemsCenter margBottom10">
          <div className="flexRow width100 justifyContentFlexStart alignItemsCenter">
          <button className='saleType-div m-2'> <select
                className="select-item darkBlue-bg"
                onChange={onInstanceStateChange}
                value={state.InstanceState}
                id="saleTypeId"
              >
                <option key="" value="">
                Status
                </option>
                {InstanceState.map((_) => {
                  return (
                    <option key={_.key} value={_.value}>
                      {_.label}
                    </option>
                  );
                })}
              </select></button>


            </div>



            <div className="flexRow width100 alignItemsCenter">
            <div className="underline m-2"> <span>
                <img
                  className="icon-size"
                  alt={t("filters.searchText")}
                  src={searchIcon}
                />
              </span>
              <span>
                <input
                  className="search"
                  onChange={onSearch}
                  type="search"
                  placeholder={t("filters.searchText") + " requisition "}
                  value={searchKey}
                />
              </span></div>

            </div>


          </div>
          {createPurchaseReqn?  <div className="margLeft flexRow justifyContentFlexEnd alignItemsBaseline">
                  <button className="add-item-button" onClick={onAddInvoice}>
                 + Create
                  </button>
              </div>:<></>}
          </div>
          </div>


          <DataGrid
            // ref={tableRef}
            className="full-flex"
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            // selItemType={selItemType}
            getRows={fetchOrders}
            rowSelection="multiple"

            onAction={(e, action, data) => {
              return onAction(e, action, data);
            }}
            columnResize={true}
            resetDataSource={isReloadTableData}
          />
        </>
      }

{state && Boolean(state.selected) &&state.selected?.action === "addInvoice"&& (
          <EditModal
            key={state.selected?.action}
            title={ `Create Purchase Requisition`}
            onClose={onClose}
            onSubmit={handleModalCancel}
            fields={ AddFormFields.map( (elt) => {
                  return {...elt, initialValue:""}
                })
            }
          />
        )
     }
     {state && Boolean(state.selected) &&state.selected?.action === "view"&& (


          <Modal
          className='cash-deposit-summary'
          key={state.selected?.action}
            title={ ``}
            onClose={onClose}
            TitleComponent={sectorTitle}
            onSubmit={handleModalCancel}>
         <>
         <div className="flexRow alignItemsCenter"  id="caterer-table" style={{whiteSpace:'nowrap', width:'100%',overflow: "scroll" }}>
        {/* <img className="icon-charts" src={DownloadVector} alt="Download icon" onClick={onBtExport}/> */}

        <table>
          <thead>
            <tr style={{width:'100%'}} className='sticky'>
            <th >PR No:</th>
              <th>Date</th>
              <th>Raised By</th>
              <th>Quantity</th>
              <th>Department</th>
              <th>Item Name</th>
              <th>Status</th>
              <th>Reviewed By</th>
              <th>Approved By</th>

            </tr>
          </thead>
          <tbody>
            {state && state.selected && state.selected?.items.map((_, index) => (
              <tr key={_.id}>
                <td>{_.purchaseReqNumber}</td>
                <td>{_.date}</td>
                <td>{_.raisedBy}</td>
                <td>{_.quantity}</td>
                <td>{_.department}</td>
                <td>{_.item}</td>
                <td>{_.status}</td>
                <td>{_.reviewedBy}</td>
                <td>{_.approvedBy}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
         </>
        </Modal>

        )
     }

{
        state && Boolean(state.selected)&& state.selected.action==='edit' ? (
          <EditModal
            key={state.selected.action}
            title={"Edit Purchase Requisition"}
            onClose={handleModalCancel}
            modalFormStatusObj={modalFormStatusObj}
            onSubmit={handleModalCancel}
            fields={EditFormFieldsInv.map((_) => {
              return { ..._, initialValue: state.selected[_.attribute] }
            })}
          />
        )
        : null
      }

{
        (state?.selected?.action==='accept' || state?.selected?.action==='reject'|| state?.selected?.action==='clarfy')&& Boolean(state?.selected) ? (
          <EditModal
            key={state.selected.action}
            title={ modalTitle }
            onClose={handleModalCancel}
            mainContentCenter={true}
            modalFormStatusObj={modalFormStatusObj}
            onSubmit={handleModalCancel}
            modalCustomButtons={modalCustomButtons}
            // onChange = {onFieldChangeHandler}
            fields={EditFieldsCess.map( (elt) => {
              return { ...elt, initialValue: state.selected[elt.attribute] }
              })
            }
          />
        )
        : null
      }

    </div>
  );
};
